import React, { useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router';
import { Routes } from '../../app/Routes';
import { localized } from '../../config/localization';
import { AuthContext, AuthContextProps } from '../../services/AuthContext';
import { Button } from '../../components/Button/Button';
import { toast } from 'react-toastify';
import { Input } from '../../components/Input/Input';
import { formReducer } from '../../components/form/formReducer';
import { BlechconAuthToken } from '../../services/api/Internal/Api';
import { BlankPage } from '../../components/BlankPage/BlankPage';
import { confirmSignUp, verifyCurrentUserAttribute } from '../../services/Auth';

export function EmailConfirmationPage(): JSX.Element {
  const history = useHistory();

  const [state, dispatch] = useReducer(formReducer, {
    values: {
      passcode: '',
    },
    errors: {
      passcode: null,
    },
    valid: false,
  });

  const { setLoginUser } = useContext<AuthContextProps>(AuthContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!history.location.state) {
      history.push(Routes.LOGIN);
    }
  }, [history]);

  const resendConfirmationCode = async () => {
    try {
      await verifyCurrentUserAttribute();
    } catch (error) {
      console.error(error);
      toast.error(String(error));
    }
  };

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setIsSubmitting(true);
      const userCredentials = history.location.state as { username: string; password: string };

      confirmSignUp(userCredentials.username, userCredentials.password, state.values.passcode)
        .then(({ user, token }) => {
          BlechconAuthToken.storeToken(token);
          setLoginUser(user);

          history.push(Routes.WELCOME_PAGE);
        })
        .catch((error) => dispatch({ errors: { passcode: error.message } }))
        .finally(() => setIsSubmitting(false));
    },
    [history, setLoginUser, state.values.passcode]
  );

  return (
    <BlankPage title={localized.EMAIL_CONFIRMATION_ENTER_PASSCODE}>
      <form onSubmit={handleSubmit}>
        <Input
          autoFocus
          name="passcode"
          type="text"
          placeholder={localized.PASSCODE}
          errors={state.errors}
          values={state.values}
          onChange={(event) => {
            const value = event.target.value.trim();
            dispatch({
              values: { passcode: value },
              errors: {
                passcode: (value.length || 0) < 6 ? localized.VERIFICATION_CODE : null,
              },
            });
          }}
          dataCy="emailConfirmationPasscodeField"
        />

        <BlankPage.Links title={localized.FORGOT_PASSWORD} onClick={resendConfirmationCode} />

        <BlankPage.Buttons>
          <Button type="submit" disabled={!state.valid || isSubmitting} dataCy="emailConfirmationSubmitButton">
            {localized.CONFIRM}
          </Button>
        </BlankPage.Buttons>
      </form>
    </BlankPage>
  );
}
