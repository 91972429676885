import React, { useCallback, useReducer, useState } from 'react';
import { v4 } from 'uuid';
import { Button } from './Button/Button';
import { localized } from '../config/localization';
import { BlechconAddress } from '../blechcon';
import { Input } from './Input/Input';
import { CenteredRing } from './CenteredRing/CenteredRing';
import { formReducer } from './form/formReducer';
import { Checkbox } from './Checkbox/Checkbox';
import { findDistance } from '../services/api/blechconApi';

type Props = {
  readonly showUseForShippingCheckbox: boolean;
  readonly addressData: BlechconAddress;
  readonly onChangeAddress: (addressData: BlechconAddress) => void;
  readonly onRemoveAddress?: () => void;
  readonly onCancel?: () => void;
};

const twoChars = formatMessage(2);
const fourChars = formatMessage(4);

function min(limit: number, value: string) {
  return (value?.length || 0) < limit;
}

export function AddressForm({
  showUseForShippingCheckbox,
  addressData,
  onChangeAddress,
  onRemoveAddress,
  onCancel,
}: Props): JSX.Element {
  const [state, dispatch] = useReducer(formReducer, {
    values: {
      addressLine: addressData.addressLine,
      addressLineTwo: addressData.addressLineTwo,
      city: addressData.city,
      id: addressData.id || v4(),
      label: addressData.label,
      state: addressData.state,
      street: addressData.street,
      zip: addressData.zip,
      useForShipping: addressData.useForShipping ? 'true' : 'false',
    },
    errors: {
      addressLine: null,
      addressLineTwo: null,
      city: null,
      label: null,
      state: null,
      street: null,
      zip: null,
    },
    valid: false,
  });

  const [isAddressValid, setIsAddressValid] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleAddress = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setIsLoading(true);

      const a = {
        addressLine: state.values.addressLine,
        addressLineTwo: state.values.addressLineTwo,
        city: state.values.city,
        id: state.values.id,
        label: state.values.label,
        state: state.values.state,
        street: state.values.street,
        zip: state.values.zip,
        useForShipping: state.values.useForShipping === 'true',
      } as BlechconAddress;

      const addressLine = `${state.values?.street || ''} ${state.values?.zip || ''} ${state.values?.city || ''} ${
        state.values?.state || ''
      }`;

      findDistance(addressLine)
        .then((response) => {
          setIsAddressValid(true);
          onChangeAddress({
            ...a,
            ...response,
          });
        })
        .catch((error) => {
          console.log(error);
          setIsAddressValid(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [
      onChangeAddress,
      state.values.addressLine,
      state.values.addressLineTwo,
      state.values.city,
      state.values.id,
      state.values.label,
      state.values.state,
      state.values.street,
      state.values.useForShipping,
      state.values.zip,
    ]
  );

  return (
    <div className="flex flex-1 pb-8">
      {isLoading ? (
        <div className="flex flex-1 items-center justify-center">
          <CenteredRing />
        </div>
      ) : (
        <form className="w-full max-w" onSubmit={handleAddress}>
          <Input
            name="label"
            type="text"
            placeholder={localized.ADDRESS_LABEL}
            errors={state.errors}
            values={state.values}
            autoFocus
            onChange={(event) => {
              const value = event.target.value;
              dispatch({
                values: { label: value },
                errors: { label: min(2, value) ? twoChars : null },
              });
            }}
          />
          <Input
            name="addressLine"
            type="text"
            placeholder={localized.ADDRESS_LINE1}
            errors={state.errors}
            values={state.values}
            onChange={(event) => {
              const value = event.target.value;
              dispatch({
                values: { addressLine: value },
                errors: { addressLine: min(2, value) ? twoChars : null },
              });
            }}
          />
          <Input
            name="addressLineTwo"
            type="text"
            placeholder={localized.ADDRESS_LINE2}
            errors={state.errors}
            values={state.values}
            onChange={(event) => {
              const value = event.target.value;
              dispatch({
                values: { addressLineTwo: value },
                errors: { addressLineTwo: min(2, value) ? twoChars : null },
              });
            }}
          />
          <Input
            name="street"
            type="text"
            placeholder={localized.ADDRESS_STREET}
            errors={state.errors}
            values={state.values}
            onChange={(event) => {
              const value = event.target.value;
              dispatch({
                values: { street: value },
                errors: { street: min(2, value) ? twoChars : null },
              });
            }}
          />
          <Input
            name="zip"
            type="text"
            placeholder={localized.ZIP}
            errors={state.errors}
            values={state.values}
            onChange={(event) => {
              const value = event.target.value;
              dispatch({
                values: { zip: value },
                errors: { zip: min(4, value) ? fourChars : null },
              });
            }}
          />
          <Input
            name="city"
            type="text"
            placeholder={localized.ADDRESS_CITY}
            errors={state.errors}
            values={state.values}
            onChange={(event) => {
              const value = event.target.value;
              dispatch({
                values: { city: value },
                errors: { city: min(2, value) ? twoChars : null },
              });
            }}
          />
          <Input
            name="state"
            type="text"
            placeholder={localized.ADDRESS_COUNTRY}
            errors={state.errors}
            values={state.values}
            onChange={(event) => {
              const value = event.target.value;
              dispatch({
                values: { state: value },
                errors: { state: min(2, value) ? twoChars : null },
              });
            }}
          />

          {showUseForShippingCheckbox && (
            <Checkbox
              label={localized.SHIPPING_ADDRESS_USE}
              checked={state.values.useForShipping === 'true'}
              onClick={(value) => {
                dispatch({
                  values: {
                    useForShipping: value ? 'true' : 'false',
                  },
                });
              }}
            />
          )}

          {isAddressValid === false && <p className="text-error my-4">{localized.ADDRESS_NOT_VALID}</p>}

          <div className="justify-between flex mt-10">
            {onCancel && (
              <Button onClick={onCancel} secondary>
                {localized.ABORT}
              </Button>
            )}
            {onRemoveAddress && (
              <Button onClick={onRemoveAddress} dataCy="btn-remove" danger>
                {localized.REMOVE}
              </Button>
            )}
            <Button disabled={!state.valid} type="submit" dataCy="btn-save">
              {localized.SAVE}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
}

function formatMessage(value: number): string {
  return localized.formatString(localized.MUST_CONTAIN_AT_LEAST_CHARACTERS, value) as string;
}
