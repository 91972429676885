import {
  confirmResetPassword,
  fetchAuthSession,
  resetPassword,
  sendUserAttributeVerificationCode,
  confirmSignUp as amplifyConfirmSignUp,
  signUp as amplifySignUp,
  signIn as amplifySignIn,
  signOut as amplifySignOut,
  getCurrentUser,
  AuthUser,
  fetchUserAttributes,
  FetchUserAttributesOutput,
  AuthTokens,
} from 'aws-amplify/auth';
import { localized } from '../config/localization';
import { BlechconUser, BlechconUserGroupType } from '../blechcon';

// messages from aws
const LOGIN_FAILED_ERRORS = ['User does not exist.', 'Incorrect username or password.'];

export const PASSWORD_RESET_REQUIRED = 'PasswordResetRequiredException';
export const USER_NOT_CONFIRMED = 'UserNotConfirmedException';
export const USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException';
export const USERNAME_EXISTS_EXCEPTION = 'UsernameExistsException';
export const CODE_MISMATCH_EXCEPTION = 'CodeMismatchException';
export const EXPIRED_CODE_EXCEPTION = 'ExpiredCodeException';
export const LIMIT_EXCEEDED_EXCEPTION = 'LimitExceededException';

export async function getAccessTokenForCurrentUser(): Promise<string | null> {
  const session = await fetchAuthSession();
  return session.tokens?.idToken?.toString() ?? null;
}

export async function forgotPassword(email: string): Promise<void> {
  return resetPassword({ username: email })
    .then(() => undefined)
    .catch((error) => {
      throw new Error(error.name === USER_NOT_FOUND_EXCEPTION ? localized.USER_NOT_FOUND : localized.GENERAL_ERROR);
    });
}

export function verifyCurrentUserAttribute(): Promise<void> {
  return sendUserAttributeVerificationCode({ userAttributeKey: 'email' })
    .then(() => undefined)
    .catch(() => {
      throw new Error(localized.GENERAL_ERROR);
    });
}

function toBlechconUser(
  user: AuthUser,
  attributes: FetchUserAttributesOutput,
  tokens: AuthTokens | undefined
): BlechconUser {
  return {
    username: user.username,
    groups: (tokens?.idToken?.payload['cognito:groups'] as BlechconUserGroupType[]) ?? [],
    email: attributes.email as string,
    ustid: attributes['custom:ustid'] ?? null,
    emailVerified: attributes.email_verified === 'true',
  };
}

export async function confirmSignUp(
  username: string,
  password: string,
  confirmationCode: string
): Promise<{ user: BlechconUser; token: string }> {
  return amplifyConfirmSignUp({ username, confirmationCode })
    .then(async (result) => {
      if (result.isSignUpComplete) {
        return await amplifySignIn({ username, password }).then(async () => {
          const user = await getCurrentUser();
          const { tokens } = await fetchAuthSession();
          const attributes = await fetchUserAttributes();

          return {
            user: toBlechconUser(user, attributes, tokens),
            token: tokens?.idToken?.toString() ?? '',
          };
        });
      } else {
        return Promise.reject({ name: result.nextStep.signUpStep });
      }
    })
    .catch((error) => {
      if (error.name === CODE_MISMATCH_EXCEPTION || error.name === EXPIRED_CODE_EXCEPTION) {
        throw new Error(localized.CODE_MISMATCH);
      } else if (error.name === LIMIT_EXCEEDED_EXCEPTION) {
        throw new Error(localized.LIMIT_EXCEEDED_EXCEPTION);
      } else {
        throw new Error(localized.GENERAL_ERROR);
      }
    });
}

export function forgotPasswordSubmit(email: string, confirmationCode: string, newPassword: string): Promise<void> {
  return confirmResetPassword({
    username: email,
    confirmationCode,
    newPassword,
  })
    .then(() => undefined)
    .catch((error) => {
      if (error.name === USER_NOT_FOUND_EXCEPTION) {
        throw new Error(localized.USER_NOT_FOUND);
      } else if (error.name === CODE_MISMATCH_EXCEPTION || error.name === EXPIRED_CODE_EXCEPTION) {
        throw new Error(localized.CODE_MISMATCH);
      } else if (error.name === LIMIT_EXCEEDED_EXCEPTION) {
        throw new Error(localized.LIMIT_EXCEEDED_EXCEPTION);
      } else {
        throw new Error(localized.GENERAL_ERROR);
      }
    });
}

export async function signIn(email: string, password: string): Promise<{ user: BlechconUser; token: string }> {
  return amplifySignIn({ username: email, password })
    .then(async (result) => {
      if (result.isSignedIn) {
        const user = await getCurrentUser();
        const { tokens } = await fetchAuthSession();
        const attributes = await fetchUserAttributes();
        return { user: toBlechconUser(user, attributes, tokens), token: tokens?.idToken?.toString() ?? '' };
      } else if (result.nextStep.signInStep === 'RESET_PASSWORD') {
        return Promise.reject({ name: PASSWORD_RESET_REQUIRED });
      } else if (result.nextStep.signInStep === 'CONFIRM_SIGN_UP') {
        return Promise.reject({ name: USER_NOT_CONFIRMED });
      }

      return Promise.reject({ name: result.nextStep.signInStep });
    })
    .catch((error) => {
      if (error.name === PASSWORD_RESET_REQUIRED || error.name === USER_NOT_CONFIRMED) {
        throw new Error(error.name);
      } else if (LOGIN_FAILED_ERRORS.includes(error.message)) {
        throw new Error(localized.LOGIN_INCORRECT_CREDENTIALS);
      } else {
        throw new Error(localized.GENERAL_ERROR);
      }
    });
}

export function signUp(email: string, password: string, ustid: string): Promise<void> {
  return amplifySignUp({
    username: email,
    password,
    options: {
      userAttributes: {
        email: email,
        'custom:ustid': ustid,
      },
    },
  })
    .then(() => undefined)
    .catch((error) => {
      throw new Error(error.code === USERNAME_EXISTS_EXCEPTION ? localized.EMAIL_EXISTS_ERROR : error.message);
    });
}

export function signOut(): Promise<void> {
  return amplifySignOut().catch(() => undefined);
}

export async function currentAuthenticatedUser(forceRefresh?: boolean): Promise<BlechconUser | null> {
  return getCurrentUser()
    .then(async (user) => {
      const attributes = await fetchUserAttributes();
      const session = await fetchAuthSession({ forceRefresh });
      return toBlechconUser(user, attributes, session.tokens);
    })
    .catch(() => null);
}

export async function getIdentityId(): Promise<string | undefined> {
  const { identityId } = await fetchAuthSession();
  return identityId;
}

export async function getCredentials(): Promise<
  | {
      accessKeyId: string;
      secretAccessKey: string;
      sessionToken?: string;
      expiration?: Date;
    }
  | undefined
> {
  const { credentials } = await fetchAuthSession();
  return credentials;
}
