import React, { useCallback, useReducer } from 'react';
import { localized } from '../../config/localization';
import { Routes } from '../../app/Routes';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import { Input } from '../../components/Input/Input';
import { EMAIL_PATTERN, PASSWORD_PATTERN } from '../../utils';
import { formReducer } from '../../components/form/formReducer';
import { BlankPage } from '../../components/BlankPage/BlankPage';
import { forgotPasswordSubmit } from '../../services/Auth';

type LocationState = {
  email: string;
};

export function NewPasswordPage(): JSX.Element {
  const history = useHistory();
  const location = useLocation<LocationState>();

  const [state, dispatch] = useReducer(formReducer, {
    values: {
      email: location.state?.email || '',
      confirmationCode: '',
      newPassword: '',
      confirmPassword: '',
    },
    errors: {
      email: null,
      confirmationCode: null,
      newPassword: null,
      confirmPassword: null,
    },
    valid: false,
  });

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      forgotPasswordSubmit(state.values.email, state.values.confirmationCode, state.values.newPassword)
        .then(() => history.push(Routes.START_PAGE))
        .catch((error) => dispatch({ errors: { email: error.message } }));
    },
    [history, state.values.confirmationCode, state.values.email, state.values.newPassword]
  );

  return (
    <BlankPage title={localized.PASSWORD_RESET} showGoBack>
      <form onSubmit={handleSubmit}>
        <Input
          autoFocus
          name="email"
          type="text"
          placeholder={localized.EMAIL}
          errors={state.errors}
          values={state.values}
          onChange={(event) => {
            const value = event.target.value.toLocaleLowerCase().trim();
            dispatch({
              values: { email: value },
              errors: { email: !EMAIL_PATTERN.test(value) ? localized.LOGIN_EMAIL_INVALID : null },
            });
          }}
        />
        <Input
          name="confirmationCode"
          type="text"
          placeholder={localized.PASSCODE}
          errors={state.errors}
          values={state.values}
          onChange={(event) => {
            const value = event.target.value;
            dispatch({
              values: { confirmationCode: value },
              errors: {
                confirmationCode: (value.length || 0) !== 6 ? localized.VERIFICATION_CODE : null,
              },
            });
          }}
        />
        <Input
          name="newPassword"
          type="password"
          placeholder={localized.PASSWORD}
          errors={state.errors}
          values={state.values}
          onChange={(event) => {
            const value = event.target.value;
            dispatch({
              values: { newPassword: value },
              errors: {
                newPassword:
                  (value.length || 0) < 12 && !PASSWORD_PATTERN.test(value) ? localized.LOGIN_PASSWORD_RULE : null,
                confirmPassword:
                  (value.length || 0) === 0 || state.values.confirmPassword !== value
                    ? localized.SIGN_UP_PW_MISMATCH
                    : null,
              },
            });
          }}
        />
        <Input
          name="confirmPassword"
          type="password"
          placeholder={localized.REPEAT_PASSWORD}
          errors={state.errors}
          values={state.values}
          onChange={(event) => {
            const value = event.target.value;
            dispatch({
              values: { confirmPassword: value },
              errors: {
                confirmPassword:
                  (value.length || 0) === 0 || state.values.newPassword !== value
                    ? localized.SIGN_UP_PW_MISMATCH
                    : null,
              },
            });
          }}
        />

        <BlankPage.Buttons>
          <Button type="submit" disabled={!state.valid}>
            {localized.CONFIRM}
          </Button>
        </BlankPage.Buttons>
      </form>
    </BlankPage>
  );
}
