import React from 'react';

type Props = {
  onClick?: (event: React.MouseEvent<SVGSVGElement>) => void;
};

export function Globe({ onClick }: Props): JSX.Element {
  return (
    <svg width={18} height={18} viewBox="0 0 18 18" onClick={(event) => onClick && onClick(event)}>
      <path
        fill="#bcbccb"
        d="M11,2C9.925,2,8.772,3.54,8.068,5.973a.418.418,0,0,0,.4.527h5.059a.417.417,0,0,0,.4-.527C13.227,3.54,12.075,2,11,2ZM6.554,3.274a.392.392,0,0,0-.257.058,9.064,9.064,0,0,0-2.656,2.5.416.416,0,0,0,.332.663H5.735a.414.414,0,0,0,.4-.322,14.215,14.215,0,0,1,.758-2.333A.416.416,0,0,0,6.554,3.274Zm8.891,0a.419.419,0,0,0-.339.573,14.1,14.1,0,0,1,.759,2.333.414.414,0,0,0,.4.32h1.761a.416.416,0,0,0,.332-.663,9.075,9.075,0,0,0-2.656-2.5A.392.392,0,0,0,15.446,3.274ZM2.731,8.3a.422.422,0,0,0-.406.3,9,9,0,0,0,0,4.8.421.421,0,0,0,.406.3H5.306a.408.408,0,0,0,.408-.452C5.642,12.526,5.6,11.778,5.6,11s.042-1.526.114-2.248A.408.408,0,0,0,5.306,8.3Zm5.219,0a.414.414,0,0,0-.415.362,20.259,20.259,0,0,0,0,4.676.414.414,0,0,0,.415.362h6.1a.414.414,0,0,0,.415-.362A19.992,19.992,0,0,0,14.6,11a20.19,20.19,0,0,0-.135-2.338A.414.414,0,0,0,14.05,8.3Zm8.743,0a.408.408,0,0,0-.408.452c.072.722.114,1.471.114,2.248s-.042,1.526-.114,2.248a.408.408,0,0,0,.408.452h2.575a.422.422,0,0,0,.406-.3,9,9,0,0,0,0-4.8.421.421,0,0,0-.406-.3ZM3.974,15.5a.416.416,0,0,0-.332.663,9.075,9.075,0,0,0,2.656,2.5.416.416,0,0,0,.6-.515,14.1,14.1,0,0,1-.759-2.333.414.414,0,0,0-.4-.32Zm4.5,0a.417.417,0,0,0-.4.527C8.773,18.46,9.925,20,11,20s2.228-1.54,2.932-3.973a.418.418,0,0,0-.4-.527Zm7.794,0a.411.411,0,0,0-.4.32,14.215,14.215,0,0,1-.758,2.333.415.415,0,0,0,.594.515,9.064,9.064,0,0,0,2.656-2.5.416.416,0,0,0-.332-.663Z"
        transform="translate(-2 -2)"
      />
    </svg>
  );
}
