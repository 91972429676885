import './AdminOrderDetailsPage.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BlechconDocument, BlechconOrder, hasNestingJob } from '../../../blechcon';
import { useParams } from 'react-router';
import { Page } from '../../../components/Page/Page';
import { localized } from '../../../config/localization';
import { OrderDetailView } from '../../../components/OrderDetailView';
import {
  acceptOrder,
  cancelOrder,
  fetchOrder,
  fetchOrderCsv,
  rejectOrder,
  updateOrder as updateOrderApi,
} from '../../../services/orderApi';
import { downloadDocumentsInBatches, presentDownloadDialog } from '../../../components/helper';
import { DateInput } from '../../../components/DateInput/DateInput';
import { toast } from 'react-toastify';
import { CustomShippingCostInput } from './CustomShippingCostInput';
import { OrderDocuments } from '../../../components/OrderDocuments';
import { FileTableRowComponent } from '../../../components/FileTableRowComponent/FileTableRowComponent';
import { PartDetails } from '../../../components/PartDetails/PartDetails';
import DownloadAdditionalFile from '../../../components/DownloadAdditionalFile';
import { Button } from '../../../components/Button/Button';
import { CenteredRing } from '../../../components/CenteredRing/CenteredRing';
import { ConfirmButton } from '../../../components/ConfirmButton/ConfirmButton';
import { FileUploadButton } from '../../../components/FileUploadButton/FileUploadButton';
import { persistAnalysisModels } from '../../../components/PartViewer/persistAnalysisModels';
import { useConfiguration } from '../../checkout/useConfiguration';

type ParamsType = {
  readonly id: string;
};

export function AdminOrderDetailsPage(): JSX.Element {
  const { id } = useParams<ParamsType>();
  const configuration = useConfiguration();

  const abortRef = useRef(new AbortController());

  const [order, setOrder] = useState<BlechconOrder | null>(null);

  useEffect(() => {
    const copy = abortRef.current;
    return () => {
      copy.abort();
    };
  }, []);

  useEffect(() => {
    fetchOrder(id)
      .then((fetchedOrder) => {
        setOrder(fetchedOrder);
      })
      .catch((error) => {
        console.error('error in fetchOrder', error);
        toast.error(error?.response?.data?.error || String(error));
      });
  }, [id]);

  const updateOrder = useCallback(
    (partialOrder: Partial<BlechconOrder>) => {
      updateOrderApi(partialOrder, id)
        .then(setOrder)
        .catch((error) => {
          toast.error(error?.response?.data?.error || String(error));
        });
    },
    [id]
  );

  const downloadFiles = async () => {
    if (order) {
      await downloadDocumentsInBatches(order.fileDrawings);
    }
  };

  const downloadNesting = async () => {
    if (order?.nestingFiles) {
      await downloadDocumentsInBatches(order.nestingFiles);
    }
  };

  if (!order) {
    return (
      <Page title={localized.ADMIN_ORDERS}>
        <CenteredRing />
      </Page>
    );
  }

  return (
    <Page title={order.orderStatus === 'draft' ? localized.DRAFT : localized.ADMIN_ORDERS}>
      <OrderDetailView order={order}>
        {({ file, index }) => {
          return (
            <FileTableRowComponent
              key={file.id}
              file={file}
              index={index}
              adminOnly={true}
              onAnalysisResult={async (analysisModels) => {
                await persistAnalysisModels(order, file, analysisModels, updateOrder);
              }}
              configuration={configuration}>
              {({ part }) => {
                return (
                  <>
                    <PartDetails part={part}></PartDetails>
                    {part.document && <DownloadAdditionalFile document={part.document} />}
                  </>
                );
              }}
            </FileTableRowComponent>
          );
        }}
      </OrderDetailView>

      <div className="flex w-full justify-end mt-4">
        <Button
          className="w-56"
          onClick={async () => presentDownloadDialog(`calculation_${order.id}.csv`, await fetchOrderCsv(order.id))}
          secondary>
          {localized.DOWNLOAD_CSV}
        </Button>
      </div>
      {hasNestingJob(order) && (
        <div className="flex w-full justify-end mt-4">
          <Button className="w-56" onClick={downloadNesting} secondary>
            {localized.DOWNLOAD_NESTING}
          </Button>
        </div>
      )}
      <div className="flex w-full justify-end mt-4">
        <Button className="w-56" onClick={downloadFiles} secondary>
          {localized.DOWNLOAD_FILES}
        </Button>
      </div>

      <div className="flex w-full justify-end mt-4">
        <FileUploadButton
          className="w-56"
          onUpload={async (document: BlechconDocument) => updateOrder({ documents: [...order.documents, document] })}>
          {'+ ' + localized.ADD_FILES}
        </FileUploadButton>
      </div>

      <div className="admin-order-details-page__delivery-dates">
        <DateInput
          name="delivery_date_desired"
          label={order.delivery?.selfPickup ? localized.PICKUP_MESSAGE : localized.DELIVERY_DATE_DESIRED}
          value={order.delivery?.desiredDate ? new Date(order.delivery?.desiredDate) : undefined}
          readonly={true}
        />

        <DateInput
          name="delivery_date_estimated"
          label={order.delivery?.selfPickup ? localized.PICKUP_MESSAGE : localized.DELIVERY_DATE}
          value={order.delivery?.deliveryDate ? new Date(order.delivery?.deliveryDate) : undefined}
          minDays={0}
          onChange={(date) => {
            updateOrder({ delivery: { ...order.delivery, deliveryDate: date ? date.toISOString() : undefined } });
          }}
        />
      </div>

      {order.orderStatus === 'submitted' && !order.delivery?.selfPickup && (
        <div className="admin-order-details-custom-shipping-cost">
          <CustomShippingCostInput
            disabled={!order.prices.totalPrice}
            shippingCost={order.prices.shippingCost}
            onChange={(shippingCost) => {
              const totalPrice =
                (order.prices.totalPrice || 0) - (order.prices?.shippingCost?.price || 0) + shippingCost.price;
              updateOrder({ prices: { ...order.prices, totalPrice, shippingCost } });
            }}
          />
        </div>
      )}

      <div className="admin-order-details-page__status-buttons">
        {order.orderStatus === 'submitted' && (
          <>
            <ConfirmButton
              danger
              title={localized.REJECT_ORDER}
              description={localized.REJECT_ORDER_CONFIRMATION}
              onConfirm={() => {
                rejectOrder(id)
                  .then((updatedOrder) => setOrder(updatedOrder))
                  .catch((error) => toast.error(error?.response?.data?.error || String(error)));
              }}>
              {localized.REJECT_ORDER}
            </ConfirmButton>

            <ConfirmButton
              title={localized.CONFIRM_OFFER}
              description={localized.CONFIRM_ORDER_CONFIRMATION}
              onConfirm={() => {
                acceptOrder(id)
                  .then((updatedOrder) => setOrder(updatedOrder))
                  .catch((error) => toast.error(error?.response?.data?.error || String(error)));
              }}>
              {localized.CONFIRM_OFFER}
            </ConfirmButton>
          </>
        )}

        {order.orderStatus === 'withdraw' && (
          <ConfirmButton
            title={localized.WITHDRAW_REQUEST}
            description={localized.WITHDRAW_REQUEST_CONFIRMATION}
            danger
            onConfirm={() => {
              cancelOrder(order.id)
                .then((updatedOrder) => setOrder(updatedOrder))
                .catch((error) => toast.error(String(error)));
            }}>
            {localized.WITHDRAW_REQUEST}
          </ConfirmButton>
        )}
      </div>

      <OrderDocuments
        order={order}
        onChangeDocuments={(documents) => {
          updateOrder({ documents });
        }}
      />
    </Page>
  );
}
