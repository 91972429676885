/* eslint sonarjs/no-duplicate-string: 0 */
export const config = {
  aws_project_region: 'eu-central-1',
  aws_cognito_identity_pool_id: 'eu-central-1:93bbdc93-95fc-4af9-b220-6859b2e5b7cd',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_O5PHTBxe5',
  aws_user_pools_web_client_id: '5ti4en3ru8lcu6cvabjq8tvj01',
  oauth: {},
  aws_dynamodb_all_tables_region: 'eu-central-1',
  aws_dynamodb_table_schemas: [
    {
      tableName: 'blechonCadFiles-staging',
      region: 'eu-central-1',
    },
    {
      tableName: 'blechconMaterials-staging',
      region: 'eu-central-1',
    },
    {
      tableName: 'blechconUserInfo-staging',
      region: 'eu-central-1',
    },
    {
      tableName: 'blechconDiscounts-staging',
      region: 'eu-central-1',
    },
  ],
  aws_user_files_s3_bucket: 'blechcons3104019-staging',
  aws_user_files_s3_bucket_region: 'eu-central-1',
};

export const wicamV2Url = 'https://api-v2-development.wicam.com/';

export const optimateUrl = 'https://api.optimate.de/';

export const apigwUrl = 'https://5n2pzmu58d.execute-api.eu-central-1.amazonaws.com/staging/';
