import './Label.css';
import React from 'react';

type LabelProps = {
  classes?: string;
  children: React.ReactNode;
};

export function Label({ classes = '', children }: LabelProps): JSX.Element {
  return <div className={`label ${classes}`}>{children}</div>;
}
