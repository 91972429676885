import './NotFoundPage.css';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { localized } from '../../config/localization';
import { Button } from '../../components/Button/Button';

export function NotFoundPage(): JSX.Element {
  const history = useHistory();

  return (
    <div className="not-found-page">
      <span>404</span>
      <span>{localized.PAGE_NOT_FOUND}</span>
      <span>{localized.PAGE_NOT_FOUND_DETAILS}</span>
      <Button
        onClick={() => {
          history.goBack();
        }}>
        {localized.BACK}
      </Button>
    </div>
  );
}
