import * as React from 'react';

export const Upload = () => {
  return (
    <svg width={92.432} height={61.62} viewBox="0 0 92.432 61.62">
      <defs>
        <style>{'.a{fill:#e4e9ef;}'}</style>
      </defs>
      <path
        className="a"
        d="M72.038,22.272a26.927,26.927,0,0,0-47.937-7.7A25.064,25.064,0,0,0,.255,43.2C2.011,55.67,13.268,64.62,25.858,64.62H42.364V41.513H34.026A1.606,1.606,0,0,1,32.89,38.77L45.079,26.581a1.606,1.606,0,0,1,2.272,0L59.541,38.77A1.606,1.606,0,0,1,58.4,41.513H50.067V64.62H70.347c10.941,0,20.747-7.907,21.952-18.779A21.208,21.208,0,0,0,72.038,22.272Z"
        transform="translate(0 -3)"
      />
    </svg>
  );
};
