type State = {
  values: { [key: string]: string };
  errors: { [key: string]: string | null };
  valid: boolean;
};

type Action = { values?: { [key: string]: string }; errors?: { [key: string]: string | null } };

export function formReducer(state: State, action: Action): State {
  const errors = {
    ...state.errors,
    ...action?.errors,
  };

  return {
    ...state,
    values: {
      ...state.values,
      ...action?.values,
    },
    errors,
    valid: Object.values(errors).filter((it) => it).length === 0,
  };
}
