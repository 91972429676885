/*
 * Source https://iconduck.com/icons/242860/eye
 */
import React from 'react';

type Props = {
  readonly fill?: string;
};

export function Eye({ fill = 'currentColor' }: Props): JSX.Element {
  return (
    <svg width={16} height={16} viewBox="0 0 1920 1920" fill={fill}>
      <path
        d="m960 1277.853c-175.297 0-317.951-142.654-317.951-317.951s142.654-317.951 317.951-317.951 317.951 142.654 317.951 317.951-142.654 317.95-317.951 317.95zm948.342-341.585c-187.697-377.62-551.01-612.268-948.342-612.268-397.333 0-760.645 234.648-948.342 612.268l-11.658 23.634 11.658 23.634c187.697 377.62 551.01 612.268 948.342 612.268 397.333 0 760.645-234.648 948.342-612.268l11.658-23.634z"
        fillRule="evenodd"
      />
    </svg>
  );
}
