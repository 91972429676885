import { Api } from './api/Internal/Api';
import { BlechconUrls } from './api/Internal/URL';
import { BlechconDocument, BlechconOrder, BlechconOrderFilePart, isDataUrl, OrderId } from '../blechcon';
import { v4 } from 'uuid';
import { S3Client } from './s3Client';
import cloneDeep from 'lodash/cloneDeep';
import { getIdentityId } from './Auth';

const blechconApi = Api();

function dataURItoBlob(dataURI: string): Blob {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = window.atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  return new Blob([ab], { type: mimeString });
}

const uploadDocumentToS3 = async (document: BlechconDocument): Promise<string> => {
  // TODO href in blechconApi ignorieren
  delete document.href;

  const identityId = await getIdentityId();
  const key = `protected/${identityId}/${v4()}_${encodeURIComponent(document.name)}`;
  await S3Client.put(key, dataURItoBlob(document.data), document.mimetype);

  return key;
};

async function uploadFilePreviewToS3(part: BlechconOrderFilePart) {
  if (!part.previewImage) {
    return;
  }

  // TODO href in blechconApi ignorieren
  delete part.previewImage.image2D.href;

  if (isDataUrl(part.previewImage.image2D.data)) {
    const key = `public/preview/${v4()}_${part.previewImage.image2D.name}`;
    await S3Client.put(key, dataURItoBlob(part.previewImage.image2D.data), part.previewImage.image2D.mimetype);
    part.previewImage.image2D.data = key;
  }

  if (part.previewImage.image3D) {
    // TODO href in blechconApi ignorieren
    delete part.previewImage.image3D.href;

    if (isDataUrl(part.previewImage.image3D.data)) {
      const key = `public/preview/${v4()}_${part.previewImage.image3D.name}`;
      await S3Client.put(key, dataURItoBlob(part.previewImage.image3D.data), part.previewImage.image3D.mimetype);
      part.previewImage.image3D.data = key;
    }
  }
}

async function uploadPartDocument(part: BlechconOrderFilePart) {
  if (part.document && isDataUrl(part.document.data)) {
    part.document.data = await uploadDocumentToS3(part.document);
  }
}

async function uploadDocument(documentIn: BlechconDocument): Promise<BlechconDocument> {
  let document = documentIn;

  // TODO href in blechconApi ignorieren
  delete document.href;

  if (isDataUrl(document.data)) {
    const key = await uploadDocumentToS3(document);
    document = {
      ...document,
      data: key,
    };
  }

  return document;
}

export async function updateOrder(partialOrder: Partial<BlechconOrder>, id: OrderId | null): Promise<BlechconOrder> {
  const clone = cloneDeep(partialOrder);

  for (const file of clone?.files || []) {
    for (const part of file.parts) {
      await uploadFilePreviewToS3(part);
      await uploadPartDocument(part);
    }
  }

  if (clone?.documents) {
    const documents = [];

    for (const document of clone?.documents || []) {
      documents.push(await uploadDocument(document));
    }

    clone.documents = documents;
  }

  return blechconApi.post(id ? BlechconUrls.ORDER_ID(id) : BlechconUrls.ORDER, clone).then((response) => response.data);
}

export async function submitOrder(id: OrderId): Promise<BlechconOrder> {
  return blechconApi.post(BlechconUrls.SUBMIT_ORDER(id)).then((response) => response.data);
}

export async function rejectOrder(id: OrderId): Promise<BlechconOrder> {
  return blechconApi.post(BlechconUrls.REJECT_ORDER(id)).then((response) => response.data);
}

export async function cancelOrder(id: OrderId): Promise<BlechconOrder> {
  return blechconApi.post(BlechconUrls.CANCEL_ORDER(id)).then((response) => response.data);
}

export async function acceptOrder(id: OrderId): Promise<BlechconOrder> {
  return blechconApi.post(BlechconUrls.ACCEPT_ORDER(id)).then((response) => response.data);
}

export async function withdrawRequest(id: OrderId): Promise<BlechconOrder> {
  return blechconApi.post(BlechconUrls.WITHDRAW_REQUEST_ORDER(id)).then((response) => response.data);
}

export async function fetchOrder(id: OrderId): Promise<BlechconOrder> {
  return blechconApi.get(BlechconUrls.ORDER_ID(id)).then((response) => response.data);
}

export async function fetchOrderCsv(id: OrderId): Promise<string> {
  return blechconApi.get(BlechconUrls.ORDER_CSV(id)).then((response) => response.data);
}
