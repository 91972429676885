import './Page.css';
import { TopNavigation } from './TopNavigation';
import { Navigation } from '../Navigation';
import React from 'react';
import { CenteredRing } from '../CenteredRing/CenteredRing';
import { Impressum } from '../Impressum';

type Props = {
  title: string;
  loading?: boolean;
  showMenu?: boolean;
  children: React.ReactNode;
};

export function Page({ title, loading = false, showMenu = true, children }: Props): JSX.Element {
  return (
    <div className="page">
      <TopNavigation />
      <div className="page__area">
        <Navigation show={showMenu} />

        <div className="page__wrapper">
          <div className="page__main">
            <div className="page__title">{title}</div>
            <div className="page__content">{!loading ? children : <CenteredRing />}</div>
            <Impressum />
          </div>
        </div>
      </div>
    </div>
  );
}
