import './PreviewImages.css';
import { BlechconPreviewImage } from '../../blechcon';
import React from 'react';
import { ArrowBidirectional } from '../../assets/svg/ArrowBidirectional';

type Props = {
  readonly rotationAngle: number | null;
  readonly images: BlechconPreviewImage | null;
  readonly showOnly2D?: boolean;
  readonly children?: JSX.Element;
};

export function PreviewImages({ rotationAngle, images, showOnly2D = false, children }: Props): JSX.Element {
  const image2D = images?.image2D.href ?? images?.image2D.data;
  const image3D = images?.image3D?.href ?? images?.image3D?.data;

  return (
    <div className="preview-images">
      {children}
      <ArrowBidirectionalWrapper angle={rotationAngle} showAngle={rotationAngle !== null}>
        {image2D && <Img src={image2D} />}
      </ArrowBidirectionalWrapper>
      {image3D && !showOnly2D && <Img src={image3D} />}
    </div>
  );
}

function ArrowBidirectionalWrapper({
  angle,
  showAngle,
  children,
}: {
  readonly angle: number | null;
  readonly showAngle: boolean;
  readonly children?: React.ReactNode;
}): JSX.Element {
  return (
    <div className="preview-images__arrow-bidirectional-wrapper">
      {showAngle && <ArrowBidirectional style={{ transform: `rotate(${angle}deg)` }} />}
      {children}
    </div>
  );
}

function Img({ src }: { readonly src: string }): JSX.Element | null {
  return (
    <div className="preview-images__image-container">
      <img className="preview-images__image" alt="file preview" src={src} />
    </div>
  );
}
