import React from 'react';

type Props = {
  className?: string;
};

export function ArrowLeft({ className = '' }: Props) {
  return (
    <svg width={14.267} height={10.759} viewBox="0 0 14.267 10.759" className={className}>
      <defs>
        <style>{'.a{fill:#002f6c;}'}</style>
      </defs>
      <path
        className="a"
        d="M8.722,5.342a.806.806,0,0,0-.571.238L3.58,10.15a.807.807,0,0,0,0,1.143l4.57,4.57a.807.807,0,0,0,1.143,0l.069-.069a.807.807,0,0,0,0-1.143L6.241,11.53H16.8a.808.808,0,0,0,0-1.616H6.241L9.363,6.792a.807.807,0,0,0,0-1.143L9.293,5.58A.807.807,0,0,0,8.722,5.342Z"
        transform="translate(-3.343 -5.342)"
      />
    </svg>
  );
}
