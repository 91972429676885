import axios from 'axios';
import { BaseUrls, BlechconUrls } from './URL';
import { getItem, setItem } from '../../storage';
import { getAccessTokenForCurrentUser } from '../../Auth';

const blechconApiInstance = axios.create();

const request = {
  get: (url: string, headers = {}, timeout = 60000, responseType: any = null) => ({
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${BlechconAuthToken.getToken()}`,
    },
    url: url.startsWith('http') ? url : BaseUrls.BLECHCON_BASE + url,
    ...(responseType && { responseType }),
    timeout,
  }),
  post: (url: string, data: any = null, headers = {}, timeout = 60000) => ({
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${BlechconAuthToken.getToken()}`,
    },
    url: url.startsWith('http') ? url : BaseUrls.BLECHCON_BASE + url,
    data,
    timeout,
  }),
  put: (url: string, data: any, headers = {}, timeout = 60000) => ({
    method: 'PUT',
    headers: {
      ...headers,
      Authorization: `Bearer ${BlechconAuthToken.getToken()}`,
    },
    url: url.startsWith('http') ? url : BaseUrls.BLECHCON_BASE + url,
    data,
    timeout,
  }),
  delete: (url: string, data: any, headers = {}, timeout = 60000) => ({
    method: 'DELETE',
    headers: {
      ...headers,
      Authorization: `Bearer ${BlechconAuthToken.getToken()}`,
    },
    url: url.startsWith('http') ? url : BaseUrls.BLECHCON_BASE + url,
    data,
    timeout,
  }),
};

export const StorageKeys = {
  OPTIMATE_ACCESS_TOKEN: 'optimateAccessToken',
  WICAM_ACCESS_TOKEN: 'wicamAccessToken',
  BLECHCON_JWT: 'blechconJwt',
  LANGUAGE: 'app-language',
};

export const Api = () => ({
  get: (url: string) => {
    return blechconApiInstance(request.get(url));
  },
  put: (url: string, body: object = {}) => {
    return blechconApiInstance(request.put(url, body));
  },
  post: (url: string, body: object = {}) => {
    return blechconApiInstance(request.post(url, body));
  },
  delete: (url: string, body: object = {}) => {
    return blechconApiInstance(request.delete(url, body));
  },
});

blechconApiInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest._retry) {
      return getAccessTokenForCurrentUser()
        .then((newToken) => {
          BlechconAuthToken.storeToken(newToken);
          originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
          originalRequest._retry = true;
          return blechconApiInstance(originalRequest);
        })
        .catch(() => location.reload());
    }
    return Promise.reject(error);
  }
);

const blechconApi = Api();

function getWicamAccessToken() {
  return blechconApi
    .get(BlechconUrls.TOKEN)
    .then((response) => {
      return response.data.body;
    })
    .catch((err) => {
      console.log('ERROR ', err);
    });
}

function getOptimateAccessToken() {
  return blechconApi
    .get(BlechconUrls.OPTIMATE_TOKEN)
    .then((response) => {
      return response.data.body;
    })
    .catch((err) => {
      console.log('ERROR ', err);
    });
}

export async function wicamAccessToken() {
  const token = getItem(StorageKeys.WICAM_ACCESS_TOKEN);
  if (token) {
    return token;
  } else {
    const tokenData = await getWicamAccessToken();
    storeWicamAccessToken(tokenData?.access_token);
    return tokenData.access_token;
  }
}

export async function optimateAccessToken() {
  const token = getItem(StorageKeys.OPTIMATE_ACCESS_TOKEN);
  if (token) {
    return token;
  } else {
    const tokenData = await getOptimateAccessToken();
    storeOptimateAccessToken(tokenData?.access_token);
    return tokenData.access_token;
  }
}

const storeWicamAccessToken = (tokenData: string) => {
  setItem(StorageKeys.WICAM_ACCESS_TOKEN, tokenData);
};

const storeOptimateAccessToken = (tokenData: string) => {
  setItem(StorageKeys.OPTIMATE_ACCESS_TOKEN, tokenData);
};

export const WicamAuthToken = {
  getToken: wicamAccessToken,
  storeToken: storeWicamAccessToken,
};

export const OptimateAuthToken = {
  getToken: optimateAccessToken,
  storeToken: storeOptimateAccessToken,
};

export const BlechconAuthToken = {
  getToken: () => getItem<string>(StorageKeys.BLECHCON_JWT),
  storeToken: (token: string | null) => {
    setItem(StorageKeys.BLECHCON_JWT, token);
  },
};
