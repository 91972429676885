export type S3FileKey = string;
export type DataUrl = string;
export type HttpsUrl = string;
export type BlechconAsset = S3FileKey | DataUrl;

export const blechconTimezone = 'Europe/Berlin';

export const AVAILABLE_LANGUAGES = ['de', 'en'] as const;
export type AvailableLanguagesType = (typeof AVAILABLE_LANGUAGES)[number];

export type BlechconFile = {
  name: string;
  href?: HttpsUrl;
};

export type BlechconDocument = {
  mimetype: string;
  data: BlechconAsset;
} & BlechconFile;

export type NestingJobId = string;
export type FileId = string;

export type NestingFile = {
  nestingJobId: NestingJobId;
  fileId: FileId;
} & BlechconFile;

// base64 enkodiert
export type LastEvaluatedKey = string | undefined | null;

export type UserId = string;
export type CustomerId = string;
export type Username = string;
export type Email = string;
export type OrderId = string;
export type CollectionId = string;
export type CustomerReference = string;
export type UstId = string;
export type BlechconRawMaterialId = string | null;
export type CustomerMaterialId = string;
export type Milliseconds = number;

export const ROTATIONS = ['fixed', 'free'] as const;
export type RotationType = (typeof ROTATIONS)[number];

export const cadStpExtensions = ['stp', 'step'] as const;
export const cad3DExtensions = ['geo', 'scdoc', ...cadStpExtensions] as const;
export const cadExtensions = ['dxf', 'dwg', ...cad3DExtensions] as const;

export function isStpFile(fileName: string | undefined) {
  return isCadExtensionsFile(cadStpExtensions, fileName);
}

export function is3DFile(fileName: string | undefined) {
  return isCadExtensionsFile(cad3DExtensions, fileName);
}

function isCadExtensionsFile(extensions: ReadonlyArray<string>, fileName: string | undefined) {
  return extensions.some((extension: string) => fileName?.toLowerCase().endsWith(extension));
}

export type BlechconPreviewImage = {
  image2D: BlechconDocument;
  image3D: BlechconDocument | null;
};

export type ShippingCost = {
  name: 'self pickup' | 'undetermined' | string;
  price: number;
};

export type BlechconConfigurationShippingCostEntry = {
  distance: number;
  maxPrice: number;
  prices: Array<{
    palettes: number;
    price: number;
  }>;
};

export type BlechconConfigurationParcelCostEntry = {
  name: string;
  length: number; //mm
  width: number; //mm
  height: number; //mm
  distanceBetweenParts: number; //mm
  maxLoad: number; //kg
  price: number; // Euro-Cent
};

export type BendingCostsPerHour = {
  minWeightNetto: number;
  maxWeightNetto: number;
  costPerHour: number;
};

export type DeliveryDaysPerDistance = {
  minDistance: number;
  maxDistance: number;
  durationInDays: number;
};

export interface BlechconNestingJob {
  nestingMargins: {
    sheetLeft: number;
    sheetTop: number;
    sheetRight: number;
    sheetBottom: number;
    partDistance: number;
    commonCutDistance: number;
  };
  nestingSettings: {
    strategy: number;
    powerLevel: number;
    nestingCorner: number;
    nestingDirection: number;
    gridSize: number;
    exactSpacing: boolean;
    useOnlyOneSheetType: boolean;
    determineOptimalSheet: boolean;
  };
  sheetReduction: {
    minPercentageX: number;
    minPercentageY: number;
  };
  output: {
    sheetSvg: boolean;
    sheetDxf: boolean;
    nesting: boolean;
  };
}

type MaterialSurcharge = {
  min: number;
  max: number;
  materialTypeId: number;
  exponentialFactor: number;
};

type CuttingCostPerHour = {
  min: number;
  max: number;
  exponentialFactor: number;
};

export type PlateProcessingTime = {
  maxPartCount: number;
  processingTimeInMinutes: number;
};

export type WorkingStep = {
  id: string;
  sortPosition: number;
  name: string;
};

export type BlechconConfiguration = {
  MIN_VALUE_FOR_EMAIL_INFO: number;
  SEND_AUTOMATED_EMAILS: boolean;
  ROUNDED_EDGE_PER_PART: number;
  MATERIAL_PRICE_SURCHARGE_STAINLESS_STEEL: number;
  CHANGE_OF_PLATE: number;
  LASER_SETUP_TIME_PER_MATERIAL: number;
  BENDING_SETUP_TIME_PER_PLATE: number;
  MATERIAL_PRICE_SURCHARGE: number;
  MATERIAL_SURCHARGE: MaterialSurcharge[];
  CUTTING_COST_PER_HOUR: CuttingCostPerHour;
  CALCULATION_EXPIRY_IN_DAYS: number;
  SHIPPING_COST_SURCHARGE_FACTOR: number;
  MINIMUM_ORDER_VALUE_IN_CENT: number;
  DESIRED_DATE_MAX_DAYS: number;
  DESIRED_DATE_MIN_DAYS: number;
  BENDING_COSTS_PER_HOUR: BendingCostsPerHour[];
  BENDING_SETUP_COST_PER_HOUR: number;
  NESTING_JOB_TEMPLATE: BlechconNestingJob;
  BASIC_PRODUCTION_DAYS: number;
  START_OF_NEXT_ORDER_DAY: string;
  DEFAULT_IDLE_TIME_IN_MIN: number;
  ROUNDED_EDGE_TIME_PER_PART: number;
  ROUNDED_EDGE_SETUP_TIME_PER_PART: number;
  DIVISOR_PRODUCTION_MINUTES: number;
  DELIVERY_DAYS_PER_DISTANCE: DeliveryDaysPerDistance[];
  INSIDE_SOLINGEN_FIXED_PRICE: number;
  SHIPPING_COST_TABLE: Array<BlechconConfigurationShippingCostEntry>;
  PARCEL_COST_TABLE: Array<BlechconConfigurationParcelCostEntry>;
  PACKING_COST: number;
  HOLIDAYS: string;
  MAX_DELIVERY_DAYS: number;
  PART_QUANTITY_SURCHARGE: number;
  PLATE_PROCESSING_TIME: PlateProcessingTime[];
  DEFAULT_OPTIMATE_OPTION: boolean;
  OPTIONAL_WORKING_STEPS: WorkingStep[];
};

export type BlechconCustomerConfiguration = {
  customerMinimumOrderValueInCent?: number;
  customerAdditionalDeliveryDays: number;
  customerDiscount: number;
  customerBendingCostsPerHour?: BendingCostsPerHour[];
  optimateActive?: boolean;
} & Omit<BlechconConfiguration, 'DEFAULT_OPTIMATE_ACTIVE'>;

export type BlechconMaterialTypeIds = 1 | 2 | 3 | undefined;

export type BlechconMaterial = {
  rawMaterialID?: BlechconRawMaterialId;
  designation?: string;
  materialIDExt?: number;
  materialThickness?: number;
  materialTypeID?: BlechconMaterialTypeIds;
  pricePerKg?: number;
  length?: number;
  width?: number;
  height?: number;
  procurementDurationInDays?: number;
  nestedPlateRepeats?: number;
  forceEdgesRounded?: boolean;
  sortPosition?: number;
  isCustomerMaterial?: boolean;
};

export type BlechconPartPrices = {
  materialTotalPrice?: number;
  materialUnitPrice?: number;
  setUpCost?: number;
  setupTimeTotal?: number;
  priceForBending?: number;
  priceForLaserCuts?: number;
  priceForMaterial?: number;
  processingTimeMin?: number;
  bendingTimeMin?: number;
  setupBendingCostPerUnit?: number;
  bendingCostPerUnit?: number;
  neededPalettes?: number;
  cuttingCostPerHour?: number;
};
export type ProcessingTimeOfPlate = {
  totalPlateAmount: number;
  amount: number;
  processingTimeMsWicam: number;
  partProcessingTime: number;
  processingTimeMsBlechcon: number;
  repeats: number;
};

export type BlechconOrderFilePart = {
  id: string;
  collectionId: CollectionId;
  fileId: FileId;
  fileId3D: FileId | null;
  previewImage: BlechconPreviewImage | null;
  length: number;
  width: number;
  errorCode?: string | null;
  blechconErrorCode: string | null;
  isAssemblyPart: boolean;
  material: BlechconMaterial | null;
  rotation: RotationType;
  rotationAngle: number | null;
  edgesRounded: boolean;
  bending: boolean;
  bendable: boolean;
  bendingCount: number;
  weightGross: number | null;
  weightNetto: number | null;
  prices: BlechconPartPrices;
  document: BlechconDocument | null;
  /**
   *    @deprecated
   *  	Processing time of a single part
   *    TODO verschwindet mit BLEC-311
   */
  processingTimeMs?: Milliseconds;
  averageProcessingTimeMs?: Milliseconds;
  processingTimeOfPlates?: ProcessingTimeOfPlate[];
  bendingTimeMs?: Milliseconds;
  assemblyCountPerPart?: number;
  quantity?: number;
  thickness?: number;
  areaGross?: number;
  areaNetto?: number;
  cuts?: number;
  cutLength?: number;
  partType?: number;
  nestingJobId?: NestingJobId;
  cadFile3D?: BlechconDocument | null;
  analysisId?: string | null;
  workingSteps?: WorkingStep[];
};

export type BlechconFilePrices = {
  materialTotalPrice?: number;
  materialUnitPrice?: number;
};

export type BlechconOrderFile = {
  id: string;
  collectionId: CollectionId;
  fileId: FileId;
  isAssembly: boolean;
  name: string;
  quantity: number;
  prices: BlechconFilePrices;
  /**
   * @deprecated Nutze weightBrutto oder weightNetto
   */
  weight?: number;
  weightBrutto?: number;
  weightNetto?: number;
  parts: BlechconOrderFilePart[];
  cadFile3D: BlechconDocument | null;
  unitConverted?: boolean;
};

export function isValidFile(file: BlechconOrderFile): boolean {
  return !file.parts.some((it) => it.errorCode !== null);
}

export type BlechconOrderStatus = 'draft' | 'submitted' | 'confirmed' | 'withdraw' | 'rejected' | 'canceled';
export type BlechconOrderStatusGroup = 'draft' | 'submitted_withdraw' | 'confirmed' | 'rejected' | 'canceled';

export type CheckoutSteps = 1 | 2 | 3 | 4;

export type BlechconAddress = {
  addressLine: string;
  addressLineTwo: string;
  city: string;
  distance: number;
  id: string;
  label: string;
  state: string;
  /**
   * ISO 3166-1 alpha-3
   */
  country: string;
  street: string;
  zip: string;
  useForShipping?: boolean;
};

export type BlechconOrderDelivery = {
  invoiceAddress?: BlechconAddress;
  shippingAddress?: BlechconAddress;
  selfPickup?: boolean;
  desiredDate?: string;
  deliveryDate?: string;
};

export type BlechconOrderPrices = {
  materialTotalPrice?: number;
  discountedMaterialTotalPrice?: number;
  discountPrice?: number;
  discount?: number;
  calculatedAtDate?: string;
  expiresAtDate?: string;
  shippingCost?: ShippingCost;
  totalPrice?: number;
};

export type BlechconOrder = {
  id: OrderId;
  email: Email;
  // TODO userId und username aus Dynamo zusammenführen
  userId: UserId;
  checkoutStep: CheckoutSteps;
  customerId: CustomerId;
  ustid: UstId;
  customerReference?: CustomerReference;
  files: BlechconOrderFile[];
  fileDrawings: BlechconFile[];
  prices: BlechconOrderPrices;
  delivery?: BlechconOrderDelivery;
  orderStatus: BlechconOrderStatus;
  orderStatusGroup: BlechconOrderStatusGroup;
  documents: BlechconDocument[];
  createdAt: string;
  orderedAt: string | undefined;
  nestingJob?: BlechconNestingJob;
  nestingFiles?: NestingFile[];
  configuration?: BlechconCustomerConfiguration;
  /*
   * {@code parameters} kann Werte aufnehmen, die zur Berechnung der Preise und Lieferkosten herangezogen werden.
   * Diese Werte sind konfigurierbar und ändern sich im Laufe der Zeit.
   */
  parameters?: Record<string, unknown>;
};

export function isDataUrl(test: BlechconAsset): boolean {
  return test.startsWith('data:');
}

export function clearPrices(order: Partial<BlechconOrder>): Partial<BlechconOrder> {
  const files = (order?.files || []).map((file) => {
    const parts = file.parts.map((p) => ({
      ...p,
      prices: {},
    }));

    return {
      ...file,
      prices: {},
      parts,
    };
  });

  return {
    ...order,
    prices: {},
    files,
  };
}

export function hasNestingJob(order: BlechconOrder): boolean {
  return order.files.flatMap((it) => it.parts).some((part) => part.nestingJobId);
}

export const BlechconUserGroups = ['newuser', 'active', 'unactivated', 'admin'] as const;
export type BlechconUserGroupType = (typeof BlechconUserGroups)[number];

export type BlechconUser = {
  username: Username;
  email: string;
  emailVerified: boolean;
  ustid: string | null;
  groups: BlechconUserGroupType[];
};

export type BlechconCustomer = {
  customerId: CustomerId | null;
  discount: number;
  additionalDeliveryDays: number;
  minimumOrderValueInCent?: number;
  bendingCostsPerHour?: BendingCostsPerHour[];
  retainCsvImportOrder?: boolean;
  csvParameterImportEnabled?: boolean;
  optimateActive?: boolean;
};

export type BlechconUserInfo = {
  userId: UserId | null;
  ustid?: UstId;
  invoiceAddresses: BlechconAddress[];
  shippingAddresses: BlechconAddress[];
  welcomePageVisited: boolean;
  findUsOption: string | null;
  findUsFreetext: string | null;
} & BlechconCustomer;

export type BlechconMaterialAlias = {
  customerId: CustomerId;
  blechconMaterialId: BlechconRawMaterialId;
  blechconMaterialDesignation: string;
  customerMaterialId: CustomerMaterialId;
  customerMaterialThickness: number;
  customerMaterialName: string;
};

type BlechconCsvAttribute = { value: string | number | boolean; error: string | null };
type BlechconMaterialCsvAttribute = { value: string | number | boolean; rawMaterialId: string; error: string | null };

export type BlechconParameterCSV = {
  file: BlechconCsvAttribute;
  material: BlechconMaterialCsvAttribute;
  thickness: BlechconCsvAttribute;
  quantity: BlechconCsvAttribute;
  roundedEdges: BlechconCsvAttribute;
};

export function parameterCsvHasError(entries: BlechconParameterCSV[]): boolean {
  for (const entry of entries) {
    for (const entryAttributes of Object.entries(entry)) {
      if (entryAttributes[1].error) {
        return true;
      }
    }
  }
  return false;
}

export type BlechconTranslation = { [key: string]: string };

export type BlechconTranslations = {
  en: BlechconTranslation;
  de: BlechconTranslation;
};

export type BlechconPartAnalysis = {
  analysisId?: string;
  partId: string;
};
