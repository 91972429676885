import './ConfirmButton.css';
import React, { useState } from 'react';
import { Modal } from '../Modal';
import { localized } from '../../config/localization';
import { Button } from '../Button/Button';

type Props = {
  title: string;
  description: string;
  danger?: boolean;
  onConfirm: () => void;
  children: React.ReactNode;
};

export function ConfirmButton({ title, description, danger, onConfirm, children }: Props): JSX.Element {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && (
        <Modal
          title={title}
          onCancel={{
            label: localized.ABORT,
            execute: () => setShowModal(false),
          }}
          onSuccess={{
            label: localized.YES,
            execute: () => {
              setShowModal(false);
              onConfirm();
            },
          }}>
          <p className="confirm-button__description">{description}</p>
        </Modal>
      )}
      <Button onClick={() => setShowModal(true)} danger={danger}>
        {children}
      </Button>
    </>
  );
}
