/*
 * Source https://iconduck.com/icons/59947/input-mouse-click-middle
 */
import React from 'react';
import { Colors } from '../../config/colors';

type Props = {
  readonly fill?: string;
};

export function InputMouseMiddle({ fill = 'currentColor' }: Props): JSX.Element {
  return (
    <svg height="22" width="22">
      <path d="m9 4h4v7h-4z" fill={Colors.green} fillRule="evenodd" />
      <path
        d="m10 3a5 5 0 0 0 -5 5v6a5 5 0 0 0 5 5h2a5 5 0 0 0 5-5v-6a5 5 0 0 0 -5-5zm0 1h2a4 4 0 0 1 4 4v6a4 4 0 0 1 -4 4h-2a4 4 0 0 1 -4-4v-6a4 4 0 0 1 4-4z"
        fill={fill}
      />
    </svg>
  );
}
