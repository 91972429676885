import React, { useRef, useState } from 'react';
import { PartAnalysis } from '../../services/optimate/optimateTypes';
import { BlechconPartAnalysis, BlechconOrderFilePart } from '../../blechcon';
import { Button } from '../Button/Button';
import { localized } from '../../config/localization';
import { submitPartAnalysis as analyzePart, getPartAnalysis } from '../../services/optimate/optimateFile';
import { analysisModelToPartAnalysis } from '../../services/optimate/fetchStl';
import { toast } from 'react-toastify';
import { CenteredRing } from '../CenteredRing/CenteredRing';
import { PartViewer } from './PartViewer';
import { Modal } from '../Modal';
import { getObjFile } from '../../services/wicam/getObjFile';
import { mapObjToPartAnalysis } from './mapObjToPartAnalysis';

type Part = Pick<BlechconOrderFilePart, 'collectionId' | 'fileId3D' | 'material' | 'cadFile3D' | 'analysisId' | 'id'>;

type Props = {
  readonly disabled?: boolean;
  readonly parts: Part[];
  readonly onAnalysisResult?: (partAnalyses: BlechconPartAnalysis[]) => void;
};

export function PartViewerButton({ disabled, parts, onAnalysisResult }: Props): JSX.Element | null {
  const abortRef = useRef<AbortController>();

  const [loading, setLoading] = useState(false);
  const [partViewerOpen, setPartViewerOpen] = useState(false);
  const [partAnalyses, setPartAnalyses] = useState<PartAnalysis[]>([]);

  const isVisibleOptimate = onAnalysisResult && parts.every((part) => part.cadFile3D);
  const isVisibleWicam = !onAnalysisResult && parts.every((part) => part.fileId3D);
  const isPartViewerDisabled = (isVisibleOptimate && !parts.every((part) => part.material?.rawMaterialID)) || disabled;

  const openPartViewer = async () => {
    abortRef.current = new AbortController();
    setLoading(true);
    setPartViewerOpen(true);

    try {
      if (isVisibleOptimate) {
        const analysisForParts = await getOptimatePartAnalyses(parts, abortRef.current?.signal);

        onAnalysisResult(
          analysisForParts.map((analysis) => ({ analysisId: analysis.analysisId, partId: analysis.partId }))
        );
        setPartAnalyses(analysisForParts);
      } else if (isVisibleWicam) {
        const analysisForParts = await getWicamPartAnalyses(parts, abortRef.current?.signal);

        if (analysisForParts) {
          setPartAnalyses(analysisForParts);
        }
      }
    } catch (error) {
      const errorString = String(error) ?? localized.COULD_NOT_LOAD_3D_VIEW;
      toast.error(errorString);
      setPartViewerOpen(false);
    } finally {
      setLoading(false);
    }
  };

  const closePartViewer = () => {
    abortRef.current?.abort();
    setPartAnalyses([]);
    setPartViewerOpen(false);
  };

  return isVisibleOptimate || isVisibleWicam ? (
    <>
      {partViewerOpen && (
        <Modal className="part-viewer-modal" onSuccess={{ label: localized.CLOSE, execute: closePartViewer }}>
          {loading && partAnalyses ? (
            <CenteredRing />
          ) : (
            <PartViewer analyses={partAnalyses} viewerOnly={!onAnalysisResult} />
          )}
        </Modal>
      )}

      <div className="part-viewer-button">
        <Button secondary={true} disabled={isPartViewerDisabled} onClick={openPartViewer}>
          {onAnalysisResult ? localized.OPTIMIZATION_BUTTON : localized.SHOW_3D_VIEW}
        </Button>
      </div>
    </>
  ) : null;
}

async function getOptimatePartAnalyses(parts: Part[], signal: AbortSignal) {
  const allModels = await Promise.all(parts.map((part) => getOptimateAnalysis(part, signal)));
  return allModels.flatMap((modelsForPart) => modelsForPart);
}

async function getOptimateAnalysis(part: Part, signal: AbortSignal): Promise<BlechconPartAnalysis & PartAnalysis> {
  let optimateAnalysis;

  if (part.analysisId) {
    optimateAnalysis = await getPartAnalysis(signal, part.analysisId);
  } else {
    optimateAnalysis = await analyzePart(part.cadFile3D!, part.material!, signal);
  }

  if (optimateAnalysis?.result && !signal.aborted) {
    const analysis = await analysisModelToPartAnalysis(optimateAnalysis);

    return { ...analysis, analysisId: optimateAnalysis.id, partId: part.id };
  }

  return { partId: part.id, models: [], issues: [], notes: [] };
}

async function getWicamPartAnalyses(parts: Part[], signal: AbortSignal): Promise<PartAnalysis[]> {
  const allModels = await Promise.all(parts.map((part) => getWicamAnalysis(part, signal)));
  return allModels
    .filter((objContent): objContent is Exclude<typeof objContent, null> => objContent !== null)
    .flatMap((objContent) => mapObjToPartAnalysis(objContent));
}

async function getWicamAnalysis(part: Part, signal: AbortSignal): Promise<string | null> {
  return (await getObjFile(part.collectionId!, part.fileId3D!, signal)) ?? null;
}
