import S3 from 'aws-sdk/clients/s3';
import { configForEnvironment } from '../utils';
import { getCredentials } from './Auth';

async function getClient(config: any) {
  return new S3({
    region: config.aws_project_region,
    credentials: await getCredentials(),
  });
}

export class S3Client {
  static async put(key: string, body: Blob, contentType?: string): Promise<void> {
    const config = configForEnvironment().config;

    const s3 = await getClient(config);
    await s3
      .putObject({
        ContentType: contentType,
        Bucket: config.aws_user_files_s3_bucket,
        Key: key,
        Body: body,
      })
      .promise();
  }

  static async constructPreSignedUrl(key: string) {
    const config = configForEnvironment().config;

    const s3 = await getClient(config);
    const params = {
      Bucket: config.aws_user_files_s3_bucket,
      Key: key,
      Expires: 604800, // seven days
    };

    return s3.getSignedUrl('getObject', params);
  }
}
