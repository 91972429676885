import { useEffect, useState } from 'react';
import { BlechconUserInfo } from '../blechcon';
import { toast } from 'react-toastify';
import { localized } from '../config/localization';
import { getUsersMe } from './api/blechconApi';

export function useUserInfo(): [BlechconUserInfo | null, (userInfo: BlechconUserInfo) => void] {
  const [userInfo, setUserInfo] = useState<BlechconUserInfo | null>(null);

  useEffect(() => {
    getUsersMe()
      .then((response: BlechconUserInfo) => {
        setUserInfo(response);
      })
      .catch((error) => {
        console.error('Error in useUserInfo', error);
        toast.error(localized.USER_INFO_LOAD_ERROR);
      });
  }, []);

  return [userInfo, setUserInfo];
}
