import React from 'react';
import { Colors } from '../../config/colors';

type Props = {
  height?: number;
  width?: number;
  fill?: string;
  className?: string;
};

export function Bin({ height = 9.861, width = 7.889, className = '', fill = Colors.error }: Props) {
  return (
    <svg width={width} height={height} fill={fill} viewBox="0 0 7.889 9.861" className={className}>
      <path
        d="M6.958,2l-.493.493H4.493a.493.493,0,0,0,0,.986h6.9a.493.493,0,0,0,0-.986H9.424L8.931,2ZM4.493,4.465v6.41a.986.986,0,0,0,.986.986H10.41a.986.986,0,0,0,.986-.986V4.465Z"
        transform="translate(-4 -2)"
      />
    </svg>
  );
}
