import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Page } from '../../components/Page/Page';
import { localized } from '../../config/localization';
import { CheckoutSteps } from '../../components/CheckoutSteps/CheckoutSteps';
import { useUserInfo } from '../../services/useUserInfo';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Modal } from '../../components/Modal';
import { Routes } from '../../app/Routes';
import { BlechconOrder } from '../../blechcon';
import { fetchOrder, submitOrder, updateOrder } from '../../services/orderApi';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import { AuthContext, AuthContextProps } from '../../services/AuthContext';
import { useConfiguration } from './useConfiguration';

type Props = {
  component: React.ComponentType<any>;
};

type ParamsType = {
  id: string | undefined;
};

type LocationState = {
  skipSubmitCheck: boolean;
};

export function Checkout({ component }: Props): JSX.Element {
  const history = useHistory();
  const [userInfo] = useUserInfo();
  const configuration = useConfiguration();
  const location = useLocation<LocationState>();

  const { isAdmin } = useContext<AuthContextProps>(AuthContext);

  const { id = null } = useParams<ParamsType>();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);
  const [persistPending, setPersistPending] = useState(false);
  const [order, setOrder] = useState<BlechconOrder | null>(null);

  useEffect(() => {
    if (userInfo?.invoiceAddresses.length === 0) {
      setIsModalVisible(true);
    }
  }, [userInfo]);

  useEffect(() => {
    if (id) {
      setOrderLoading(true);
      fetchOrder(id)
        .then((response) => {
          setOrder(response);
        })
        .catch((error) => {
          if (error.response && [403, 404].includes(error.response.status)) {
            toast.error(localized.ORDER_NOT_FOUND);
            setNotFound(true);
          } else {
            toast.error(`${localized.GENERAL_ERROR}: ${String(error)}`);
          }
        })
        .finally(() => {
          setOrderLoading(false);
        });
    } else {
      setOrder(null);
    }
  }, [id, location]);

  //TODO: promise BlechconOrder zurückgeben oder error werfen, aber nicht null
  const persistOrder = useCallback(async (orderToUpdate: Partial<BlechconOrder>): Promise<BlechconOrder | null> => {
    setPersistPending(true);
    try {
      const updatedOrder = await updateOrder(orderToUpdate, orderToUpdate.id || null);
      setOrder(updatedOrder);
      return updatedOrder;
    } catch (error) {
      toast.error(String(error));
    } finally {
      setPersistPending(false);
    }
    return null;
  }, []);

  const finalizeOrder = useCallback(
    async (orderToUpdate: Partial<BlechconOrder>): Promise<BlechconOrder | null> => {
      const persistedOrder = await persistOrder(orderToUpdate);

      setPersistPending(true);

      try {
        const updatedOrder = await submitOrder(persistedOrder?.id || '');
        setOrder(updatedOrder);
        return updatedOrder;
      } catch (error) {
        toast.error(String(error));
      } finally {
        setPersistPending(false);
      }
      return null;
    },
    [persistOrder]
  );

  const pageIsLoading = (userInfo === null || configuration === null || orderLoading || (id !== null && order === null)) && !notFound;

  if (!location.state?.skipSubmitCheck && order && order.orderStatus === 'submitted') {
    if (isAdmin) {
      return <Redirect to={Routes.adminOrderDetails(order.id)} />;
    } else {
      return <Redirect to={Routes.customerOrdersDetails(order.id)} />;
    }
  }

  return (
    <Page title={localized.CALCULATOR_V2} loading={pageIsLoading}>
      {isModalVisible && (
        <Modal
          onSuccess={{
            label: localized.TO_SETTINGS,
            execute: () => {
              history.push(Routes.userSettings());
            },
          }}>
          <p className="text-label text-lg text-opacity-50">{localized.INVOICE_ADDRESS_NEEDED}</p>
          <p className="text-label text-lg text-opacity-50">
            {localized.INVOICE_ADDRESS_NEEDED_EXPLANATION} <strong>{localized.SETTINGS}</strong>.
          </p>
        </Modal>
      )}

      <CheckoutSteps />
      {!notFound &&
        React.createElement(component, {
          key: id || Date.now(), // geht nicht, wenn id schon undefined ist
          userInfo,
          configuration,
          order,
          persistPending,
          persistOrder,
          finalizeOrder,
          isAdmin,
        })}
    </Page>
  );
}
