import './Menu.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';

type Props = {
  title?: React.ReactNode;
  dots?: boolean;
  dataCy?: string;
  children: React.ReactNode;
};

export function Menu({ title = '', dataCy, dots, children }: Props) {
  const menuRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(menuRef, false);

  const onClick = useCallback(() => {
    setIsActive(true);
  }, [setIsActive]);

  return (
    <div className="menu-container" data-cy={dataCy}>
      <button onClick={onClick}>
        <span className={dots ? 'menu--vertical' : ''}>{dots ? '\u2026' : title}</span>
      </button>
      {isActive && (
        <nav ref={menuRef} className={`menu ${isActive ? 'active' : 'inactive'}`}>
          <ul>{children}</ul>
        </nav>
      )}
    </div>
  );
}

Menu.Item = ({ onSelect, dataCy, children }: { onSelect?: () => void; dataCy?: string; children: React.ReactNode }) => {
  return (
    <li data-cy={dataCy} onClick={onSelect}>
      {children}
    </li>
  );
};

const useDetectOutsideClick = (
  el: React.MutableRefObject<null>,
  initialState: boolean
): [boolean, (state: boolean) => void] => {
  const [isActive, setIsActive] = useState(initialState);

  useEffect(() => {
    const onClick = () => {
      setIsActive(!isActive);
    };

    // If the item is active (ie open) then listen for clicks outside
    if (isActive) {
      document.addEventListener('click', onClick);
    }

    return () => {
      document.removeEventListener('click', onClick);
    };
  }, [isActive, el]);

  return [isActive, setIsActive];
};
