import React, { useCallback, useContext, useReducer, useState } from 'react';
import { useHistory } from 'react-router';
import { Routes } from '../../app/Routes';
import { BlechconAuthToken } from '../../services/api/Internal/Api';
import { localized } from '../../config/localization';
import { AuthContext, AuthContextProps } from '../../services/AuthContext';
import { Button } from '../../components/Button/Button';
import { Input } from '../../components/Input/Input';
import { EMAIL_PATTERN } from '../../utils';
import { formReducer } from '../../components/form/formReducer';
import { BlankPage } from '../../components/BlankPage/BlankPage';
import { signIn, PASSWORD_RESET_REQUIRED, USER_NOT_CONFIRMED } from '../../services/Auth';

export function LoginPage(): JSX.Element {
  const history = useHistory();

  const [state, dispatch] = useReducer(formReducer, {
    values: {
      email: '',
      password: '',
    },
    errors: {
      email: null,
      password: null,
    },
    valid: false,
  });

  const { setLoginUser } = useContext<AuthContextProps>(AuthContext);
  const [loginError, setLoginError] = useState<string | null>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const startSignIn = useCallback(() => {
    if (!state.values.email || !state.values.password) {
      return;
    }

    setIsSubmitting(true);
    const userCredentials = {
      username: state.values.email,
      password: state.values.password,
    };

    signIn(userCredentials.username, userCredentials.password)
      .then(({ user, token }) => {
        BlechconAuthToken.storeToken(token);
        setLoginUser(user);

        if (user.emailVerified) {
          if (loginError) {
            setLoginError(null);
          }
          history.push(Routes.WELCOME_PAGE);
        } else {
          history.push(Routes.EMAIL_CONFIRMATION, userCredentials);
        }
      })
      .catch((error) => {
        if (error.message === PASSWORD_RESET_REQUIRED) {
          history.push(Routes.NEW_PASSWORD, { email: state.values.email });
        } else if (error.message === localized.LOGIN_INCORRECT_CREDENTIALS) {
          setLoginError(localized.LOGIN_INCORRECT_CREDENTIALS);
        } else if (error.message === USER_NOT_CONFIRMED) {
          history.push(Routes.EMAIL_CONFIRMATION, userCredentials);
        } else {
          setLoginError(error.message);
        }
      })
      .finally(() => setIsSubmitting(false));
  }, [history, loginError, setLoginUser, state]);

  return (
    <BlankPage title={localized.LOGIN_WELCOME}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          startSignIn();
        }}>
        <Input
          autoFocus
          name="email"
          type="text"
          autoComplete="email"
          placeholder={localized.EMAIL}
          dataCy="emailField"
          errors={state.errors}
          values={state.values}
          onChange={(event) => {
            const value = event.target.value.toLocaleLowerCase().trim();
            dispatch({
              values: { email: value },
              errors: { email: !EMAIL_PATTERN.test(value) ? localized.LOGIN_EMAIL_INVALID : null },
            });
          }}
        />
        <Input
          name="password"
          type="password"
          autoComplete="current-password"
          placeholder={localized.PASSWORD}
          aria-label="Password"
          dataCy="passwordField"
          errors={state.errors}
          values={state.values}
          onChange={(event) => {
            const value = event.target.value;
            dispatch({
              values: { password: value },
              errors: { password: !value ? localized.LOGIN_PASSWORD_MISSING : null },
            });
          }}
        />

        <BlankPage.Errors message={loginError} />
        <BlankPage.Links title={localized.FORGOT_PASSWORD} onClick={() => history.push(Routes.FORGOT_PASSWORD)} />
        <BlankPage.Buttons>
          <Button
            onClick={() => {
              history.push(Routes.SIGN_UP);
            }}
            secondary
            disabled={isSubmitting}
            dataCy="signUpButton">
            {localized.SIGN_UP}
          </Button>
          <Button type="submit" dataCy="signInButton" disabled={!state.valid || isSubmitting}>
            {localized.SIGN_IN}
          </Button>
        </BlankPage.Buttons>
      </form>
    </BlankPage>
  );
}
