import React from 'react';
import { localized, t } from '../../../config/localization';
import { Settings } from '../../../assets/svg/Settings';
import { Label } from '../../../components/Label/Label';
import { Quantity } from './Quantity';
import { Button } from '../../../components/Button/Button';
import {
  BlechconPartAnalysis,
  BlechconDocument,
  BlechconMaterial,
  BlechconOrderFile,
  BlechconOrderFilePart,
  ROTATIONS,
  RotationType,
  BlechconCustomerConfiguration,
  WorkingStep,
} from '../../../blechcon';
import { Bin } from '../../../assets/svg/Bin';
import { Tooltip } from '../../../components/Tooltip/Tooltip';
import { PreviewImages } from '../../../components/Images/PreviewImages';
import { FileUploadButton } from '../../../components/FileUploadButton/FileUploadButton';
import DownloadAdditionalFile from '../../../components/DownloadAdditionalFile';
import { calculatePartQuantity } from './ParametersPageHelper';
import { Colors } from '../../../config/colors';
import { MaterialSelection } from '../../../components/MaterialSelection/MaterialSelection';
import {
  BendingTooltip,
  EdgesRoundedTooltip,
  RotationAngleInputTooltip,
  RotationSelectTooltip,
} from './ParametersPageTooltips';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { Input } from '../../../components/Input/Input';
import { PartViewerButton } from '../../../components/PartViewer/PartViewerButton';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { WorkingStepsButton } from './WorkingStepsButton';

type Props = {
  readonly materials: BlechconMaterial[];
  readonly file: BlechconOrderFile;
  readonly disabled: boolean;
  readonly index: number;
  readonly updateFile: (file: BlechconOrderFile) => void;
  readonly handleApplyForAll: (part: BlechconOrderFilePart) => void;
  readonly handleRemove: (part: BlechconOrderFilePart) => void;
  readonly onAnalysisResult: (partAnalyses: BlechconPartAnalysis[]) => void;
  readonly configuration?: BlechconCustomerConfiguration;
};

export function FileParameterSelection({
  materials,
  file,
  disabled,
  index,
  updateFile,
  handleApplyForAll,
  handleRemove,
  onAnalysisResult,
  configuration,
}: Props): JSX.Element {
  const updatePart = (part: BlechconOrderFilePart) => {
    updateFile({ ...file, parts: file.parts.map((it) => (it.fileId === part.fileId ? part : it)) });
  };

  const updateDocument = (partDocument: BlechconDocument | null, part: BlechconOrderFilePart) => {
    part.document = partDocument;
    updatePart(part);
  };

  const updateQuantity = (quantity: number) => {
    const parts = file.parts.map((part) => ({
      ...part,
      quantity: calculatePartQuantity(part, quantity),
    }));

    updateFile({ ...file, parts, quantity });
  };

  const updateWorkingSteps = (part: BlechconOrderFilePart, values: string[]) => {
    if (!configuration) {
      return;
    }

    const workingSteps: WorkingStep[] = [];

    for (const value of values) {
      const workingStep = configuration.OPTIONAL_WORKING_STEPS.find((it) => it.name === value);

      if (workingStep) {
        workingSteps.push(workingStep);
      }
    }

    updatePart({
      ...part,
      workingSteps,
    });
  };

  return (
    <div className="parameters-page__file-selection">
      <div className="parameters-page__file-selection-images">
        {!file.isAssembly && file.parts.length > 1 && (
          <PreviewImages rotationAngle={file.parts[0].rotationAngle} images={file.parts[0].previewImage} />
        )}
      </div>

      <div className="parameters-page__file-selection-quantity">
        <Label>{localized.QUANTITY}</Label>
        <Quantity disabled={disabled} value={file.quantity} setValue={updateQuantity} />
      </div>

      {file.parts?.map((part, partIndex) => {
        const errorCode = part.blechconErrorCode ?? part.errorCode;
        const hasErrorCode = !!errorCode;

        return (
          <div
            className={`parameters-page__file-selection-list-item ${hasErrorCode ? 'bg-notAppliedBackground' : ''}`}
            key={part.id}
            data-cy={`file-editParameters-${index}-${partIndex}`}>
            <span>{part.isAssemblyPart && `${index + 1}.${partIndex + 1}`}</span>

            <div className="parameters-page__part-selection">
              <div className="parameters-page__part-selection-image">
                <PreviewImages rotationAngle={part.rotationAngle} images={part.previewImage}>
                  <>
                    <PartViewerButton disabled={disabled} parts={[part]} />
                    {configuration?.optimateActive && (
                      <PartViewerButton disabled={disabled} parts={[part]} onAnalysisResult={onAnalysisResult} />
                    )}
                  </>
                </PreviewImages>
              </div>

              <MaterialSelection
                materials={materials}
                disabled={disabled}
                partMaterial={part.material}
                partThickness={part.thickness}
                updateMaterial={(material) =>
                  updatePart({ ...part, analysisId: null, edgesRounded: material.forceEdgesRounded ?? false, material })
                }
              />

              <div className="parameters-page__part-selection-rotation">
                <Label classes="parameters-page__label">
                  {localized.NESTING_OPTION} <RotationSelectTooltip />
                </Label>
                <Dropdown
                  placeholder={localized.DROPDOWN_SELECTION}
                  options={ROTATIONS.map((rotation) => ({
                    value: rotation,
                    label: localized.getString(`ROTATION_TYPE_${rotation.toUpperCase()}`),
                  }))}
                  disabled={disabled}
                  onChange={(value) =>
                    updatePart({
                      ...part,
                      rotation: value as RotationType,
                      rotationAngle: value === 'free' ? null : 0,
                    })
                  }
                  value={part.rotation}
                />
              </div>

              <div>
                <Label classes="parameters-page__label">
                  {localized.ROTATION_ANGLE} <RotationAngleInputTooltip />
                </Label>
                <Input
                  name="rotationAngle"
                  type="number"
                  values={{ rotationAngle: String(part.rotationAngle ?? 0) }}
                  step={5}
                  onChange={(event) => {
                    const value = Number(event.target.value);
                    if (isFinite(value) && value >= 0 && value <= 360) {
                      updatePart({ ...part, rotationAngle: Math.floor(value) });
                    }
                  }}
                  disabled={part.rotation === 'free' || disabled}
                  rounded
                />
              </div>

              <div className="parameters-page__part-selection-bendandround">
                <div>
                  <Checkbox
                    checked={part.bending}
                    label={localized.BENDING}
                    disabled={!part.bendable || disabled}
                    onClick={() => updatePart({ ...part, bending: !part.bending })}
                  />
                  <BendingTooltip />
                </div>
                <div>
                  <Checkbox
                    checked={part.edgesRounded}
                    label={localized.EDGES_ROUNDED}
                    disabled={part.material?.forceEdgesRounded ?? disabled}
                    onClick={() => updatePart({ ...part, edgesRounded: !part.edgesRounded })}
                  />
                  <EdgesRoundedTooltip />
                </div>
              </div>
              <div className="parameters-page__file-selection-list-item-actions">
                <Button
                  disabled={!part.material || disabled}
                  onClick={() => {
                    handleApplyForAll(part);
                  }}
                  dataCy="applyToAllButton">
                  <Settings width={16} height={16} />
                  &nbsp;<span>{localized.APPLY_ON_ALL_PARAMETERS_LABEL}</span>
                </Button>

                {!part.document ? (
                  <>
                    <FileUploadButton
                      accept="application/pdf"
                      disabled={disabled}
                      onUpload={(partDocument) => updateDocument(partDocument, part)}>
                      {'+ ' + localized.ADD_PDF}
                    </FileUploadButton>

                    <Tooltip>{localized.TOOLTIP_ADDITIONAL_FILE}</Tooltip>
                  </>
                ) : (
                  <>
                    <DownloadAdditionalFile document={part.document} />

                    <Button disabled={disabled} onClick={() => updateDocument(null, part)} danger>
                      <Bin fill="currentColor" width={16} height={16} />
                      {localized.REMOVE}
                    </Button>
                  </>
                )}

                {configuration?.OPTIONAL_WORKING_STEPS && (
                  <WorkingStepsButton
                    configuration={configuration}
                    part={part}
                    disabled={disabled}
                    onChange={(values) => updateWorkingSteps(part, values)}
                  />
                )}

                {file.isAssembly && (
                  <>
                    <Button
                      className="parameters-page__file-selection-list-item-actions__remove"
                      danger
                      disabled={disabled}
                      onClick={() => handleRemove(part)}
                      dataCy={`removePartButton-${index}-${partIndex}`}>
                      <Bin fill={disabled ? Colors.label : undefined} />
                      &nbsp;{localized.REMOVE}
                    </Button>
                    <Tooltip>{localized.TOOLTIP_REMOVE_PART}</Tooltip>
                  </>
                )}
                {hasErrorCode ? (
                  <div className="parameters-page__file-selection-list-item-actions__error">{t(errorCode)}</div>
                ) : null}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
