import React from 'react';
import { useHistory } from 'react-router';
import { localized } from '../../../config/localization';
import { Routes } from '../../../app/Routes';
import { BlechconOrder } from '../../../blechcon';
import { formatMeasure } from '../../../utils';
import { formatDate } from '../../../components/helper';

type Props = {
  draftOrders: BlechconOrder[];
};

export function DraftListView({ draftOrders }: Props): JSX.Element {
  const history = useHistory();

  return (
    <table className="mt-4 table-fixed">
      <thead>
        <tr className="w-full bg-contentHeader text-primary font-bold">
          <td className="px-2 uppercase">{localized.OFFER_ID}</td>
          <td className="px-2 uppercase">{localized.EMAIL}</td>
          <td className="px-2 uppercase">{localized.CUSTOMER_ID}</td>
          <td className="px-2 uppercase">{localized.CUSTOMER_REFERENCE}</td>
          <td className="px-2 uppercase">{localized.DATE_CREATED}</td>
          <td className="px-2 uppercase">{localized.STEP}</td>
          <td className="px-2 uppercase">{localized.MATERIAL_COST} (EUR)</td>
        </tr>
      </thead>
      <tbody>
        {draftOrders?.map((order) => {
          return (
            <tr
              key={order.id}
              onClick={() => {
                history.push(Routes.adminOrderDetails(order.id));
              }}
              className="h-20 hover:bg-gray-300 border-t">
              <td className="px-2 text-mutedText">{order.id}</td>
              <td className="px-2 text-mutedText">{order.email}</td>
              <td className="px-2 text-mutedText">{order.customerId}</td>
              <td className="px-2 text-mutedText">{order.customerReference}</td>
              <td className="px-2 text-mutedText">{formatDate(order.createdAt)}</td>
              <td className="px-2 text-mutedText">{order.checkoutStep}</td>
              <td className="px-2 text-mutedText">
                {order.prices.materialTotalPrice ? formatMeasure(order.prices.materialTotalPrice) : '-'}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
