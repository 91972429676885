import * as develop from './aws-develop';
import * as staging from './aws-staging';
import * as prod from './aws-prod';
import * as cypress from './aws-cypress';

type NumberLike = number | null | undefined;
export const EMAIL_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const PASSWORD_PATTERN = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/;

const env: string | undefined = process.env.REACT_APP_BRANCH;

if (!process.env.AWS_REGION) {
  console.log(`our current environment: '${env}'`);
}

export function configForEnvironment(): any {
  if (env === 'prod') {
    return prod;
  } else if (env === 'staging') {
    return staging;
  } else if (env === 'cypress') {
    return cypress;
  }
  return develop;
}

export const formatMeasure = (value: NumberLike, unit: string | null = null, frac = 2, maxFrac = 2): string | null => {
  const hasUnit = unit != null && unit !== '';

  if (value == null) {
    return null;
  }

  let formatted = value.toString();

  if (Number.isFinite(value)) {
    formatted = value.toLocaleString('de-DE', {
      minimumFractionDigits: frac,
      maximumFractionDigits: maxFrac,
    });
  }

  return hasUnit ? formatted + '\u2009' + unit : formatted;
};

export const formatNumber = (value: NumberLike): string | null => formatMeasure(value, null, 2, 2);

export function apiGatewayBaseUrl(): string {
  return configForEnvironment().apigwUrl;
}

export function getOrderPrintPageStorageKey(id: string): string {
  return `OrderPrintPage_${id}`;
}

export function millisecondsToMinutes(ms: NumberLike): number {
  return (ms || 0) / 1000 / 60;
}

export function scheduleNextRun(run: () => void, signal: AbortSignal, timeout = 1000) {
  setTimeout(() => {
    if (!signal.aborted) {
      run();
    }
  }, timeout);
}
