/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios';
import axios from 'axios';
import { AnalysisModel } from './optimateTypes';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || 'https://api.optimate.de/' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Optimate API
 * @version 1.0
 * @baseUrl https://api.optimate.de/
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  GET = (path: string, options: object = {}) => {
    return this.request<string, any>({
      path,
      method: 'GET',
      ...options,
    });
  };

  api = {
    /**
     * No description
     *
     * @tags api
     * @name GetHealth
     * @request GET:/api/health
     */
    getHealth: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/health`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name GetVersion
     * @request GET:/api/version
     */
    getVersion: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/version`,
        method: 'GET',
        ...params,
      }),
  };
  v3 = {
    /**
     * No description
     *
     * @tags v3/analysis
     * @name PostRequestPartAnalysis
     * @request POST:/v3/analysis
     */
    postRequestPartAnalysis: (
      data: {
        /** .step|.stp|.sldprt|.sldasm file to analyze */
        file: File;
        /** One of ["1.0038", "1.4301", "AlMg3"], optionally suffixed the thickness, i.e. "1.0038-10") with which the part will be produced */
        material: string;
        /**
         * Batch size for the part, greater or equal to one
         * @default 1
         */
        batch_size?: number;
        /** Optional tensile strength of the material used for bend pressure checks. Defaults to 700N/mm^2 for 1.4301, 300N/mm^2 for AlMg3 and 450N/mm^2 for 1.0038. */
        tensile_strength?: number;
        /**
         * One of ["any", "horizontal", "vertical"]. Determines how the part should be placed on the sheet metal.
         * @default "any"
         */
        sheet_positioning?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AnalysisModel, Error>({
        path: `/v3/analysis`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags v3/analysis
     * @name GetRequestPartAnalysis
     * @request GET:/v3/analysis
     */
    getRequestPartAnalysis: (
      query?: {
        /**
         * The start date from which to query in ISO 8601 format. Must be older than end_date.
         * @default "0001-01-01"
         */
        start_date?: string;
        /**
         * The end date from which to query in ISO 8601 format. Must be earlier than start_date.
         * @default "9999-01-01"
         */
        end_date?: string;
        /**
         * The key is used to get the next page of the paginated result. It should be set to the next_page_key from the previously queried page
         * @default ""
         */
        pagination_start_key?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/v3/analysis`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags v3/analysis
     * @name GetGetPartAnalysis
     * @request GET:/v3/analysis/{analysis_id}
     */
    getGetPartAnalysis: (
      analysisId: string,
      query?: {
        /** Id of the analysis. */
        analysis_id: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AnalysisModel, Error>({
        path: `/v3/analysis/${analysisId}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags v3/analysis
     * @name PostDissolve
     * @request POST:/v3/analysis/{analysis_id}/dissolve
     */
    postDissolve: (analysisId: string, params: RequestParams = {}) =>
      this.request<void, Error>({
        path: `/v3/analysis/${analysisId}/dissolve`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags v3/analysis
     * @name PostOptimize
     * @request POST:/v3/analysis/{analysis_id}/optimize
     */
    postOptimize: (analysisId: string, params: RequestParams = {}) =>
      this.request<void, Error>({
        path: `/v3/analysis/${analysisId}/optimize`,
        method: 'POST',
        ...params,
      }),
  };
}
