import './CustomerOrdersPage.css';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { localized } from '../../../config/localization';
import { fetchCustomerOrders, removeOrder } from '../../../services/api/blechconApi';
import { UserOrdersTabMenu } from './UserOrdersTabMenu';
import { BlechconOrder } from '../../../blechcon';
import { Routes } from '../../../app/Routes';
import { useSearchParams } from '../../../services/useSearchParams';
import { Page } from '../../../components/Page/Page';
import { Button } from '../../../components/Button/Button';
import { CenteredRing } from '../../../components/CenteredRing/CenteredRing';
import { formatDate } from '../../../components/helper';
import { formatMeasure } from '../../../utils';
import { StatusBadge } from '../../../components/StatusBadge/StatusBadge';
import { Menu } from '../../../components/Menu/Menu';
import { Modal } from '../../../components/Modal';
import { toast } from 'react-toastify';

export function CustomerOrdersPage(): JSX.Element {
  const { filter } = useSearchParams();

  const customerFilterActive = filter === 'customer';

  return (
    <Page title={localized.RECENT_ORDERS}>
      <CustomerOrdersListView key={customerFilterActive.toString()} customerFilterActive={customerFilterActive} />
    </Page>
  );
}

type Props = {
  readonly customerFilterActive: boolean;
};

function CustomerOrdersListView({ customerFilterActive }: Props): JSX.Element {
  const history = useHistory();

  const [showRemoveOrderModal, setShowRemoveOrderModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<BlechconOrder | null>(null);

  const [state, setState] = useState({
    isFetching: false,
    orders: [] as BlechconOrder[],
    lastEvaluatedKey: null,
  });

  const fetchAllOrders = useCallback(
    async (lastEvaluatedKey = null) => {
      setState((current) => ({
        ...current,
        isFetching: true,
      }));

      if (lastEvaluatedKey === undefined) {
        return;
      }

      fetchCustomerOrders(customerFilterActive, lastEvaluatedKey)
        .then((result) => {
          setState((current) => ({
            ...current,
            orders: [...current.orders, ...result.Items],
            lastEvaluatedKey: result.LastEvaluatedKey,
          }));
        })
        .catch((error) => {
          console.log(error);
          toast.error(String(error));
        })
        .finally(() => {
          setState((current) => ({
            ...current,
            isFetching: false,
          }));
        });
    },
    [customerFilterActive]
  );

  useEffect(() => {
    fetchAllOrders(null);
  }, [fetchAllOrders]);

  const deleteOrder = useCallback(async () => {
    if (selectedOrder) {
      try {
        await removeOrder(selectedOrder.id);

        setState((current) => ({
          ...current,
          orders: current.orders.filter((it) => it.id !== selectedOrder?.id),
        }));

        setShowRemoveOrderModal(false);
        setSelectedOrder(null);
      } catch (error) {
        toast.error(String(error));
      }
    }
  }, [selectedOrder]);

  const onClick = (order: BlechconOrder) => {
    if (order.orderStatus === 'confirmed' || order.orderStatus === 'submitted' || order.orderStatus === 'withdraw') {
      history.push({
        pathname: Routes.customerOrdersDetails(order.id),
      });
    } else {
      history.push({
        pathname: Routes.checkoutStep(order.checkoutStep)(order.id),
      });
    }
  };

  return (
    <>
      {showRemoveOrderModal && (
        <Modal
          title={localized.REMOVE_OFFER}
          onCancel={{
            label: localized.ABORT,
            execute: () => {
              setSelectedOrder(null);
              setShowRemoveOrderModal(false);
            },
          }}
          onSuccess={{
            label: localized.REMOVE,
            execute: () => deleteOrder(),
          }}>
          <p className="text-label text-lg text-opacity-50">{localized.REMOVE_OFFER_CONFIRMATION}</p>
        </Modal>
      )}

      <UserOrdersTabMenu isCustomerFilterActive={customerFilterActive} />

      <table className="mt-4 table-auto">
        <thead>
          <tr className="w-full bg-contentHeader text-primary font-bold">
            <td className="py-4 uppercase">{localized.OFFER_ID}</td>
            <td className="py-4 uppercase">{localized.DATE_ORDERED}</td>
            <td className="py-4 uppercase">{localized.CUSTOMER_REFERENCE}</td>
            <td className="py-4 uppercase">{localized.MATERIAL_COST}</td>
            <td className="py-4 uppercase">{localized.SHIPPING_COST}</td>
            <td className="py-4 uppercase">{localized.TOTAL}</td>
            <td className="py-4 uppercase">{localized.STATUS}</td>
            <td className="py-4 uppercase"></td>
            <td />
          </tr>
        </thead>

        <tbody>
          {state.orders.map((order) => (
            <tr key={order.id} onClick={() => onClick(order)} className="h-20 hover:bg-gray-300 border-t">
              <td className="text-mutedText">{order.id}</td>
              <td className="text-mutedText">{formatDate(order.orderedAt)}</td>
              <td className="text-mutedText">{order.customerReference}</td>
              <td className="text-mutedText">{formatMeasure(order.prices.materialTotalPrice)}</td>
              <td className="text-mutedText">{formatMeasure(order.prices.shippingCost?.price)}</td>
              <td className="text-label font-bold">{formatMeasure(order.prices.totalPrice)}</td>
              <td>
                <StatusBadge className="h-12" orderStatus={order.orderStatus} />
              </td>
              <td
                onClick={(event) => {
                  // unterbinde onClick auf tr
                  event.stopPropagation();
                }}>
                {order.orderStatus === 'draft' && (
                  <Menu dots>
                    <Menu.Item
                      onSelect={() => {
                        setSelectedOrder(order);
                        setShowRemoveOrderModal(true);
                      }}>
                      {localized.REMOVE_OFFER}
                    </Menu.Item>
                  </Menu>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {state.isFetching ? (
        <div className="flex flex-1 items-center justify-center">
          <CenteredRing />
        </div>
      ) : null}

      {!state.isFetching && state.lastEvaluatedKey ? (
        <div className="customer_orders_page__load-more-button">
          <Button
            disabled={state.isFetching}
            onClick={() => {
              fetchAllOrders(state.lastEvaluatedKey);
            }}
            secondary>
            {localized.LOAD_MORE}
          </Button>
        </div>
      ) : null}
    </>
  );
}
