import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';
import {
  CreateNesting2Response,
  NestingFileRequest,
  NestingJobRequest,
  NestingJobResponse,
  NestingUpdateResponse,
  PublicFileIdentifier,
  PublicFileInformation,
  UnfoldModel,
  UnfoldResponse,
} from './wicamTypes';

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
}

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  secure?: boolean;
  path: string;
  type?: ContentType;
  query?: QueryParamsType;
  format?: ResponseType;
  body?: unknown;
  responseType?: ResponseType;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
}

/*
 * TODO Generierten HttpClient durch eigene Implementierung ersetzen
 */
export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;

  constructor({ securityWorker, secure, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
    this.secure = secure;
    this.securityWorker = securityWorker;
  }

  private mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      //@ts-ignore
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    responseType,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType,
      data: body,
      url: path,
    });
  };
}

export class Api extends HttpClient {
  GET = (path: string, options: object = {}) => {
    return this.request<string, any>({
      path,
      method: 'GET',
      ...options,
    });
  };

  file = {
    fileDetail: (collectionId: string, fileId: string, query?: { format?: string }) =>
      this.request<string, any>({
        path: `/file/${collectionId}/${fileId}`,
        method: 'GET',
        query: query,
        responseType: 'blob',
      }),
    infoDetail: (collectionId: string, fileId: string, params: RequestParams = {}) =>
      this.request<PublicFileInformation, any>({
        path: `/file/${collectionId}/${fileId}/info`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
    fileCreate: (data: File) => {
      const form = new FormData();
      form.append('File', data);
      return this.request<PublicFileIdentifier, any>({
        path: `/file`,
        method: 'POST',
        body: form,
        type: ContentType.FormData,
      });
    },
  };
  nesting = {
    nestingUpdate: (jobId: string, data: NestingFileRequest, params: RequestParams = {}) =>
      this.request<NestingUpdateResponse, any>({
        path: `/Nesting/${jobId}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
    nestingCreate: (jobId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/Nesting/${jobId}`,
        method: 'POST',
        ...params,
      }),
    nestingDetail: (jobId: string, params: RequestParams = {}) =>
      this.request<NestingJobResponse, any>({
        path: `/Nesting/${jobId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
    nestingCreate2: (data: NestingJobRequest, params: RequestParams = {}) =>
      this.request<CreateNesting2Response, any>({
        path: `/Nesting`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  unfold = {
    unfoldCreate: (data: UnfoldModel, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/unfold`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
    unfoldDetail: (
      collectionId: string,
      fileId: string,
      query?: { bendConfig?: string; materialNumber?: number },
      params: RequestParams = {}
    ) =>
      this.request<UnfoldResponse, any>({
        path: `/unfold/${collectionId}/${fileId}`,
        method: 'GET',
        query: query,
        ...params,
      }),
  };
}
