import React, { useState } from 'react';
import { BlechconAddress, BlechconUserInfo } from '../../blechcon';
import { WelcomeWizard1 } from './WelcomeWizard1';
import { WelcomeWizard2 } from './WelcomeWizard2';

type Props = {
  userInfo: BlechconUserInfo | null;
  onChange: (userInfo: Partial<BlechconUserInfo>) => void;
};

export function WelcomePageContent({ userInfo, onChange }: Props): JSX.Element {
  const [findUsOption, setFindUsOption] = useState<{ option: string | null; freetext: string } | null>(null);

  return (
    <div className="welcome-page__content">
      {!findUsOption ? (
        <WelcomeWizard1 onChange={(value) => setFindUsOption(value)} />
      ) : (
        <WelcomeWizard2
          invoiceAddress={userInfo?.invoiceAddresses[0]}
          onChange={(address) => {
            let invoiceAddresses = userInfo?.invoiceAddresses || [];
            let shippingAddresses: Array<BlechconAddress> = [];

            if (invoiceAddresses.length > 0) {
              invoiceAddresses[0] = address;
              if (address.useForShipping) {
                shippingAddresses[0] = address;
              }
            } else {
              invoiceAddresses = [address];
              if (address.useForShipping) {
                shippingAddresses = [address];
              }
            }

            onChange({
              invoiceAddresses,
              shippingAddresses,
              welcomePageVisited: true,
              findUsOption: findUsOption?.option || null,
              findUsFreetext: findUsOption?.freetext || null,
            });
          }}
        />
      )}
    </div>
  );
}
