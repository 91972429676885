import './OrderPrintPage.css';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { BlechconOrder } from '../../blechcon';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { localized } from '../../config/localization';
import { BlechconLogoPrint } from '../../assets/svg/BlechconLogoPrint';
import { formatMeasure, formatNumber, getOrderPrintPageStorageKey } from '../../utils';
import { Checkbox } from '../../components/Checkbox/Checkbox';
import { PreviewImagePlaceholder } from '../../components/Images/PreviewImagePlaceholder';
import { PreviewImages } from '../../components/Images/PreviewImages';
import { formatDate } from '../../components/helper';

type ParamsType = {
  id: string | undefined;
};

function getOrderFromSessionStorage(id: string): BlechconOrder | null {
  const order = sessionStorage.getItem(getOrderPrintPageStorageKey(id));

  try {
    return order ? JSON.parse(order) : null;
  } catch (error) {
    console.error(error);
    toast.error(localized.GENERAL_ERROR);
    return null;
  }
}

export function OrderPrintPage(): JSX.Element | null {
  const { id = null } = useParams<ParamsType>();

  const [order, setOrder] = useState<BlechconOrder | null>(null);

  useLayoutEffect(() => {
    if (id) {
      setOrder(getOrderFromSessionStorage(id));
    }
  }, [id]);

  useEffect(() => {
    if (order) {
      setTimeout(() => {
        window.print();
        window.close();
      }, 500);
    }
  }, [order]);

  return order ? (
    <div className="order-print-page">
      <div className="order-print-page__header">
        <div>
          <span className="order-print-page__company-address--dense">
            BlechCon GmbH & Co. KG &bull; Hermesstraße 3 &bull; 42719 Solingen
          </span>

          <br />
          <br />

          <div className="order-print-page__address">
            <address>
              <span>{localized.INVOICE_ADDRESS}</span>
              <br />
              {order.delivery?.invoiceAddress?.addressLine}
              <br />
              {order.delivery?.invoiceAddress?.street}
              <br />
              {order.delivery?.invoiceAddress?.zip} {order.delivery?.invoiceAddress?.city}
            </address>
            {order?.delivery?.invoiceAddress?.id !== order?.delivery?.shippingAddress?.id && (
              <address>
                <span>{localized.SHIPPING_ADDRESS}</span>
                <br />
                {order.delivery?.shippingAddress?.addressLine}
                <br />
                {order.delivery?.shippingAddress?.street}
                <br />
                {order.delivery?.shippingAddress?.zip} {order.delivery?.shippingAddress?.city}
              </address>
            )}
          </div>

          <br />
          <br />

          <table className="order-print-page__order-details">
            <tbody>
              <tr>
                <td>{localized.ADDRESS_LINE2}:</td>
                <td>{order.delivery?.invoiceAddress?.addressLineTwo}</td>

                <td></td>
                <td>Service</td>
              </tr>

              <tr>
                <td>{localized.CUSTOMER_ID}:</td>
                <td>{order?.customerId}</td>

                <td>Tel.:</td>
                <td>+49 212 2337390</td>
              </tr>
              <tr>
                <td>{localized.USTID}:</td>
                <td>{order?.ustid}</td>

                <td>E-Mail:</td>
                <td>info@blechcon.de</td>
              </tr>
              <tr>
                <td>{localized.CUSTOMER_REFERENCE}:</td>
                <td>{order.customerReference}</td>

                <td>{localized.OFFER_VALID_UNTIL}:</td>
                <td>{formatDate(order.prices.expiresAtDate)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="order-print-page__logo">
          <BlechconLogoPrint />
        </div>
      </div>
      <br />
      <br />
      <span className="order-print-page__offer-title">
        {localized.formatString(localized.OFFER_FROM, order.id, formatDate(order.prices.calculatedAtDate))}
      </span>

      <span>{localized.PRINT_OFFER_PAGE_THANK_YOU}:</span>

      <br />
      <br />

      <table className="order-print-page__order-table">
        <thead>
          <tr>
            <td className="order-print-page__article-column">{localized.PRODUCT}</td>
            <td className="order-print-page__amount-column">{localized.QUANTITY}</td>
            <td className="order-print-page__price-column">{localized.UNIT_PRICE} (EUR)</td>
            <td className="order-print-page__price-column">{localized.TOTAL} (EUR)</td>
          </tr>
        </thead>
        <tbody>
          {order.files.map((file, index) => (
            <React.Fragment key={index}>
              {file.parts.map((part, partIndex) => {
                return (
                  <>
                    {file.isAssembly && partIndex === 0 && (
                      <tr className="order-print-page__order-row">
                        <td>
                          <div className="order-print-page__order-article-cell">
                            <div>
                              <span>{index + 1}</span>
                              <PreviewImagePlaceholder />
                            </div>
                            <div>
                              <div>{file.name}</div>
                              <div className="order-print-page__order-file-detail">
                                <div>{localized.WEIGHT_NETTO}</div>
                                <div>{formatNumber(file.weightNetto) + ' kg'}</div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="order-print-page__order-row-amount">{file.quantity}</td>
                        <td className="order-print-page__order-row-price">
                          {formatNumber(file.prices.materialTotalPrice)}
                        </td>
                      </tr>
                    )}
                    <tr key={partIndex + index} className="order-print-page__order-row">
                      <td>
                        <div className="order-print-page__order-article-cell">
                          <div>
                            <span>
                              {index + 1}
                              {part.isAssemblyPart ? `.${partIndex + 1}` : ''}
                            </span>
                            <PreviewImages rotationAngle={part.rotationAngle} images={part.previewImage} />
                          </div>
                          <div>
                            {!file.isAssembly && <div>{file.name}</div>}
                            <div>{part.material?.designation}</div>
                            <div className="order-print-page__order-file-detail">
                              <div>{localized.THICKNESS}</div>
                              <div>{`${formatNumber(part.material?.materialThickness)} mm`}</div>
                            </div>
                            <div className="order-print-page__order-file-detail">
                              <div>{localized.FORMAT}</div>
                              <div>{`${formatNumber(part.length)} mm x ${formatNumber(part.width)} mm`}</div>
                            </div>
                            <div className="order-print-page__order-file-detail">
                              <div>{localized.WEIGHT_NETTO}</div>
                              <div>{formatNumber(part.weightNetto) + ' kg'}</div>
                            </div>
                            <div className="order-print-page__order-file-detail">
                              <div>{localized.ROTATION}</div>
                              <div>{localized.getString(`ROTATION_TYPE_${part.rotation.toUpperCase()}`)}</div>
                            </div>
                            <div className="order-print-page__order-file-detail">
                              <div>{localized.ROTATION_ANGLE}</div>
                              <div>{part.rotationAngle}</div>
                            </div>
                            <div className="order-print-page__order-file-detail">
                              <div>{localized.BENDING}</div>
                              <div>{part.bending ? localized.YES : localized.NO}</div>
                            </div>
                            <div className="order-print-page__order-file-detail">
                              <div>{localized.EDGES_ROUNDED}</div>
                              <div>{part.edgesRounded ? localized.YES : localized.NO}</div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="order-print-page__order-row-amount">{part.quantity}</td>
                      <td className="order-print-page__order-row-price">
                        {formatNumber(part.prices.materialUnitPrice)}
                      </td>
                      <td className="order-print-page__order-row-price">
                        {formatNumber(part.prices.materialTotalPrice)}
                      </td>
                    </tr>
                  </>
                );
              })}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      {order.delivery?.desiredDate && (
        <div className="order-print-page__shipping-date">
          <span>{formatDate(order.delivery?.desiredDate)}</span>
          <span>{order.delivery?.selfPickup ? localized.PICKUP_DATE_DESIRED : localized.DELIVERY_DATE_DESIRED}</span>
        </div>
      )}

      {order.delivery?.deliveryDate && (
        <div className="order-print-page__shipping-date">
          <span>{formatDate(order.delivery?.deliveryDate)}</span>
          <span>
            {order.delivery?.selfPickup ? localized.PICKUP_DATE_ESTIMATED : localized.DELIVERY_DATE_ESTIMATED}
          </span>
        </div>
      )}

      <div className="order-print-page__pickup">
        <Checkbox
          checked={order.delivery?.selfPickup || false}
          onClick={() => ({})}
          label={localized.PICKUP_YOURSELF}
        />
      </div>

      <div className="order-print-page__prices">
        <table>
          <thead>
            <tr>
              <th className="order-print-page__prices_position">{localized.ORDER_SUMMARY}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="order-print-page__prices_position">{localized.MATERIAL_COST}</td>
              <td>{formatMeasure(order.prices.materialTotalPrice, '€')}</td>
            </tr>
            {(order.prices.discountPrice || 0) > 0 && (
              <tr>
                <td className="order-print-page__prices_position">Discount</td>
                <td>- {formatMeasure(order.prices.discountPrice, '€')}</td>
              </tr>
            )}
            {(order.prices.shippingCost?.price || 0) > 0 || order.delivery?.selfPickup ? (
              <>
                <tr>
                  <td className="order-print-page__prices_position">{localized.SHIPPING_COST}</td>
                  <td>{order.delivery?.selfPickup ? '-' : formatMeasure(order.prices.shippingCost?.price, '€')}</td>
                </tr>
                <tr>
                  <td className="order-print-page__prices_position">{localized.TOTAL}</td>
                  <td>{formatMeasure(order.prices.totalPrice, '€')}</td>
                </tr>
              </>
            ) : (
              <tr>
                {
                  <td colSpan={2}>
                    <div>{localized.SHIPPING_COST_MANUALLY}</div>
                  </td>
                }
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="order-print-page__footer">
        <p>{localized.PRINT_PAGE_LEGAL}</p>

        <br />

        <p>
          BlechCon GmbH & Co. KG Hermesstraße 3 42719 Solingen Tel. +49 212 233739-0 info@blechcon.de www.blechcon.de
        </p>
        <p>
          Bankverbindung IBAN DE77 3425 0000 0001 7739 36 Kreditinstitut Stadt-Sparkasse Solingen UStID-Nr. DE324851046
        </p>
        <p>St.-Nr. 128/5901/5465 Handelsregister Amtsgericht Wuppertal HRA 24887 Geschäftsführer Frank Wedel</p>
      </div>
    </div>
  ) : null;
}
