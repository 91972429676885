import React, { useEffect, useState } from 'react';
import { Modal } from '../../../components/Modal';
import { localized } from '../../../config/localization';
import { BlechconParameterCSV } from '../../../blechcon';

const errorClass = 'customer_material_page__csv-error';

type Props = {
  csvData: BlechconParameterCSV[] | null;
  onClose: () => void;
};

export function ParametersPageCsvErrorModal({ csvData, onClose }: Props): JSX.Element {
  const [errorData, setErrorData] = useState<BlechconParameterCSV[] | null>(null);

  useEffect(() => {
    if (csvData) {
      setErrorData(csvData);
    }
  }, [csvData]);

  return (
    <Modal
      title={localized.ERROR_CSV}
      successDisabled={false}
      onSuccess={{
        label: localized.CLOSE,
        execute: () => onClose(),
      }}>
      <div className="customer_material_page__csv-error-table-container">
        <table className="customer_material_page__material-table customer_material_page__csv-error-table">
          <thead>
            <tr className="customer_material_page__material-table_header">
              <td>{localized.FILE_NAME}</td>
              <td>{localized.CUSTOM_MATERIAL_NAME}</td>
              <td>{localized.THICKNESS}</td>
              <td>{localized.QUANTITY}</td>
              <td>{localized.EDGES_ROUNDED}</td>
              <td />
            </tr>
          </thead>

          <tbody>
            {errorData?.map((row, index) => (
              <tr key={index} className="customer_material_page__material-table_row">
                <td className={row.file.error ? errorClass : ''}>
                  <div>{row.file.value}</div>
                  {row.file.error && <div>{localized.getString(row.file.error)}</div>}
                </td>
                <td className={row.material.error ? errorClass : ''}>
                  <div>{row.material.value}</div>
                  {row.material.error && <div>{localized.getString(row.material.error)}</div>}
                </td>
                <td className={row.thickness.error ? errorClass : ''}>
                  <div>{row.thickness.value}</div>
                  {row.thickness.error && <div>{localized.getString(row.thickness.error)}</div>}
                </td>
                <td className={row.quantity.error ? errorClass : ''}>
                  <div>{row.quantity.value}</div>
                  {row.quantity.error && <div>{localized.getString(row.quantity.error)}</div>}
                </td>
                <td className={row.roundedEdges.error ? errorClass : ''}>
                  <div>{row.roundedEdges?.value?.toString()}</div>
                  {row.roundedEdges.error && <div>{localized.getString(row.roundedEdges.error)}</div>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
}
