import { OptimateAuthToken, StorageKeys, optimateAccessToken } from '../api/Internal/Api';
import { BaseUrls } from '../api/Internal/URL';
import { removeItem } from '../storage';
import * as optimateApi from './optimateApi';

export * from './optimateApi';

export const api = new optimateApi.Api({
  baseURL: BaseUrls.OPTIMATE_BASE,
});

api.instance.interceptors.request.use(
  async (config: any) => {
    const token = await OptimateAuthToken.getToken();
    return {
      ...config,
      headers: {
        ...config.headers,
        ...(token && {
          Authorization: `Bearer ${token}`,
        }),
      },
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      removeItem(StorageKeys.OPTIMATE_ACCESS_TOKEN);
      return optimateAccessToken()
        .then(() => {
          return api.instance(originalRequest);
        })
        .catch((err) => {
          console.log('ERROR ', err);
        });
    }
    return Promise.reject(error);
  }
);
