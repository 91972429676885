import './Tooltip.css';
import React, { useState } from 'react';
import { Information } from '../../assets/svg/Information';

type Props = {
  children: React.ReactNode;
};

export function Tooltip({ children }: Props): JSX.Element {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <div className="tooltip" onClick={() => setShowPopup(!showPopup)}>
      <Information />
      {showPopup && <span className="tooltip__text">{children}</span>}
    </div>
  );
}
