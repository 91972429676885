import './Checkbox.css';
import React from 'react';

type Props = {
  label: string | (string | JSX.Element)[];
  checked: boolean;
  onClick: (value: boolean) => void;
  disabled?: boolean;
};

export function Checkbox({ label, checked, onClick, disabled = false }: Props): JSX.Element {
  return (
    <label className={`checkbox ${disabled ? 'checkbox--disabled' : ''}`} data-cy={`${label}-checkbox`}>
      <span>{label}</span>
      <input
        type="checkbox"
        value={checked ? 'true' : 'false'}
        checked={checked}
        disabled={disabled}
        onChange={(event) => {
          if (!disabled) {
            const value = event.target.value === 'true';
            onClick(!value);
          }
        }}
      />
      <span className="checkbox__checkmark"></span>
    </label>
  );
}
