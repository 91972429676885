import { api as wicamApi } from '../../services/wicam';

export async function getObjFile(collectionId: string, fileId: string, signal: AbortSignal): Promise<string | null> {
  const objFile = await wicamApi.file.fileDetail(collectionId, fileId, { format: 'obj' });

  if (signal.aborted) {
    return null;
  }

  return await new Blob([objFile.data]).text();
}
