import './OrderPage.css';
import React, { useCallback, useEffect, useState } from 'react';
import {
  BlechconCustomerConfiguration,
  BlechconOrder,
  BlechconOrderDelivery,
  BlechconUserInfo,
  CustomerReference,
} from '../../../blechcon';
import { localized } from '../../../config/localization';
import { FileTableRowComponent } from '../../../components/FileTableRowComponent/FileTableRowComponent';
import { Routes } from '../../../app/Routes';
import { OrderAddress } from './OrderAddress';
import { OrderTotal } from '../../../components/OrderTotal/OrderTotal';
import { DateInput } from '../../../components/DateInput/DateInput';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { useHistory } from 'react-router';
import isAfter from 'date-fns/isAfter';
import { useDebouncedCallback } from 'use-debounce';
import { toast } from 'react-toastify';
import { Button } from '../../../components/Button/Button';
import { Input } from '../../../components/Input/Input';
import { persistAnalysisModels } from '../../../components/PartViewer/persistAnalysisModels';
import { OrderPrintButton } from '../../../components/OrderPrintButton';

type PageProps = {
  readonly order: BlechconOrder;
  readonly userInfo: BlechconUserInfo;
  readonly isAdmin: boolean;
  readonly configuration: BlechconCustomerConfiguration;
  readonly persistPending: boolean;
  readonly persistOrder: (order: Partial<BlechconOrder>) => Promise<BlechconOrder | null>;
  readonly finalizeOrder: (order: Partial<BlechconOrder>) => Promise<BlechconOrder | null>;
};

export function OrderPage({
  order,
  userInfo,
  persistPending,
  isAdmin,
  persistOrder,
  finalizeOrder,
  configuration,
}: PageProps): JSX.Element | null {
  const history = useHistory();
  const [desiredDate, setDesiredDate] = useState<string | undefined>(order.delivery?.desiredDate);
  const [customerReference, setCustomerReference] = useState<CustomerReference | undefined>(order.customerReference);
  const [areTermsAndConditionsAccepted, setAreTermsAndConditionsAccepted] = useState<boolean>(false);
  const [isConfirming, setIsConfirming] = useState<boolean>(false);

  useEffect(() => {
    if (order.delivery) {
      setDesiredDate(order.delivery.desiredDate);
    }
  }, [order.delivery]);

  const persistOrderDebounced = useDebouncedCallback(async (partial: Partial<BlechconOrder>) => {
    try {
      if (!isConfirming) {
        await persistOrder({ id: order.id, ...partial });
      }
    } catch (error) {
      toast.error(`${localized.GENERAL_ERROR}: ${String(error)}`);
    }
  }, 2000);

  const confirmOrder = useCallback(async () => {
    setIsConfirming(true);
    try {
      const updatedOrder = await finalizeOrder({
        id: order.id,
        customerReference,
        delivery: { ...order.delivery, desiredDate },
      });

      if (updatedOrder) {
        history.push(Routes.checkoutSuccess(order.id), {
          skipSubmitCheck: true,
        });
      }
    } catch (error) {
      setIsConfirming(false);
      toast.error(`${localized.GENERAL_ERROR}: ${String(error)}`);
    }
  }, [customerReference, desiredDate, finalizeOrder, history, order.delivery, order.id]);

  const minimumOrderValueInCent =
    configuration.customerMinimumOrderValueInCent || configuration.MINIMUM_ORDER_VALUE_IN_CENT;
  const minMaterialTotalPriceReached = (order.prices.materialTotalPrice ?? 0) > minimumOrderValueInCent / 100;
  const calculationExpired = isAfter(new Date(), new Date(order.prices.expiresAtDate ?? 0));

  const isConfirmEnabled =
    areTermsAndConditionsAccepted &&
    (order.prices.totalPrice ?? 0) > 0 &&
    !calculationExpired &&
    minMaterialTotalPriceReached &&
    !persistPending &&
    order.delivery?.invoiceAddress &&
    (order.delivery.selfPickup || order.delivery.shippingAddress);

  return (
    <>
      <table className="w-full mt-4 table-auto">
        <thead>
          <tr className="bg-contentHeader h-10 text-primary">
            <td className="font-bold text-sm w-3/5 uppercase" style={{ padding: '1rem' }}>
              {localized.PRODUCT}
            </td>
            <td className="font-bold text-sm uppercase">{localized.QUANTITY}</td>
            <td className="font-bold text-sm uppercase">{localized.UNIT_PRICE} EUR</td>
            <td className="font-bold text-sm uppercase text-right pr-4">{localized.TOTAL} EUR</td>
          </tr>
        </thead>
        <tbody className="order-page__table-body">
          {order.files.map((file, index) => {
            return (
              <FileTableRowComponent
                key={file.id}
                file={file}
                index={index}
                disabled={persistPending}
                adminOnly={isAdmin}
                onAnalysisResult={async (analysisModels) => {
                  await persistAnalysisModels(order, file, analysisModels, persistOrder, !isConfirming);
                }}
                configuration={configuration}
              />
            );
          })}
        </tbody>
      </table>

      <OrderAddress
        userInfo={userInfo}
        delivery={order.delivery}
        disabled={persistPending}
        onChange={(delivery: BlechconOrderDelivery) => persistOrderDebounced({ id: order.id, delivery })}
      />

      <div className="order-page__delivery_dates">
        <DateInput
          name="delivery_date_desired"
          label={order.delivery?.selfPickup ? localized.PICKUP_DATE_DESIRED : localized.DELIVERY_DATE_DESIRED}
          placeholder={order.delivery?.selfPickup ? localized.PICKUP_DATE_DESIRED : localized.DELIVERY_DATE_DESIRED}
          value={desiredDate ? new Date(desiredDate) : undefined}
          minDays={configuration.DESIRED_DATE_MIN_DAYS}
          maxDays={configuration.DESIRED_DATE_MAX_DAYS}
          workDaysOnly={true}
          disabled={persistPending}
          onChange={(value) => {
            setDesiredDate(value?.toISOString());
            persistOrderDebounced({
              delivery: {
                ...order.delivery,
                desiredDate: value?.toISOString(),
              },
            });
          }}
        />
        <DateInput
          name="delivery_date_estimated"
          label={order.delivery?.selfPickup ? localized.PICKUP_DATE_ESTIMATED : localized.DELIVERY_DATE_ESTIMATED}
          readonly={true}
          value={order.delivery?.deliveryDate ? new Date(order.delivery.deliveryDate) : undefined}
        />
      </div>

      <div>
        <div className="mt-4 font-bold text-sm text-primary uppercase ">{localized.CUSTOMER_REFERENCE}</div>
        <div className="w-1/2">
          <Input
            name="customerReference"
            type="text"
            placeholder={localized.CUSTOMER_REFERENCE}
            values={{ customerReference: customerReference ?? '' }}
            disabled={persistPending}
            onChange={(event) => {
              const value = event.target.value;
              setCustomerReference(value);
              persistOrderDebounced({ customerReference: value });
            }}
          />
        </div>
        <div className="text-sm">
          <Checkbox
            checked={areTermsAndConditionsAccepted}
            onClick={() => setAreTermsAndConditionsAccepted(!areTermsAndConditionsAccepted)}
            label={localized.formatString<JSX.Element>(
              localized.ACCEPT_TERMS,
              <a
                href="https://blechcon.de/de/agb.html"
                target="_blank"
                onClick={(event) => event.stopPropagation()}
                rel="noopener noreferrer"
                className="text-primary font-bold underline">
                {localized.TERMS_AND_CONDITIONS}
              </a>
            )}
          />
        </div>

        <OrderTotal
          totalPrice={order.prices.totalPrice ?? 0}
          materialTotalPrice={order.prices.materialTotalPrice ?? 0}
          shippingCost={order.prices.shippingCost}
          selfPickup={order.delivery?.selfPickup ?? false}
          discountPrice={order.prices.discountPrice ?? 0}
        />
      </div>

      <div className="order_page__actions">
        <Button
          disabled={false}
          onClick={() => {
            history.push(Routes.checkoutCalculation(order.id));
          }}
          secondary>
          {localized.BACK_TO_CALCULATION}
        </Button>
        {calculationExpired ? (
          <span className="text-red-500 font-bold flex-grow text-right p-2">{localized.PRICE_EXPIRED}</span>
        ) : null}
        {!minMaterialTotalPriceReached ? (
          <span className="text-red-500 font-bold flex-grow text-right p-2">
            {localized.formatString(localized.MIN_VALUE_NOT_REACHED, minimumOrderValueInCent / 100 + '€')}
          </span>
        ) : null}

        <div>
          <OrderPrintButton disabled={!(isConfirmEnabled && order.customerId === userInfo.customerId)} order={order} />

          <Button disabled={!(isConfirmEnabled && order.userId === userInfo.userId)} onClick={confirmOrder}>
            {localized.CONFIRM_ORDER}
          </Button>
        </div>
      </div>
    </>
  );
}
