import './App.css';

import React, { useContext } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { EmailConfirmationPage } from '../pages/EmailConfirmationPage/EmailConfirmationPage';
import { ForgotPasswordPage } from '../pages/ForgotPasswordPage/ForgotPasswordPage';
import { LoginPage } from '../pages/LoginPage/LoginPage';
import { NewPasswordPage } from '../pages/NewPasswordPage/NewPasswordPage';
import { NotFoundPage } from '../pages/NotFoundPage/NotFoundPage';
import { SignUpPage } from '../pages/SignUpPage/SignUpPage';
import { WaitingForApprovalPage } from '../pages/WaitingForApprovalPage/WaitingForApprovalPage';
import { WelcomePage } from '../pages/WelcomePage/WelcomePage';
import { AdminCustomersPage } from '../pages/administration/AdminCustomersPage/AdminCustomersPage';
import { AdminOrderDetailsPage } from '../pages/administration/AdminOrderDetailsPage/AdminOrderDetailsPage';
import { AdminOrdersPage } from '../pages/administration/AdminOrdersPage/AdminOrdersPage';
import { AdminUsersPage } from '../pages/administration/AdminUsersPage/AdminUsersPage';
import { CalculationPage } from '../pages/checkout/CalculationPage/CalculationPage';
import { Checkout } from '../pages/checkout/Checkout';
import { OrderPage } from '../pages/checkout/OrderPage/OrderPage';
import { OrderPrintPage } from '../pages/OrderPrintPage/OrderPrintPage';
import { ParametersPage } from '../pages/checkout/ParametersPage/ParametersPage';
import { SuccessPage } from '../pages/checkout/SuccessPage/SuccessPage';
import { UploadPage } from '../pages/checkout/UploadPage/UploadPage';
import { CustomerOrderDetailsPage } from '../pages/customer/CustomerOrderDetailsPage/CustomerOrderDetailsPage';
import { CustomerOrdersPage } from '../pages/customer/CustomerOrdersPage/CustomerOrdersPage';
import { UserSettingsPage } from '../pages/user/UserSettingsPage/UserSettingsPage';
import { AuthContext, AuthContextProps } from '../services/AuthContext';
import { Routes } from './Routes';

export function AppRouter(): JSX.Element | null {
  const { user, isActive, isAdmin, isAuthenticationDone } = useContext<AuthContextProps>(AuthContext);

  const isEmailVerified = () => {
    return user?.emailVerified;
  };

  const isWaitingForApproval = () => {
    return user?.emailVerified && !isActive;
  };

  function PublicOnlyRoute({ children, ...rest }: any) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          user ? (
            <Redirect
              to={{
                pathname: Routes.START_PAGE,
                state: { from: location },
              }}
            />
          ) : (
            children
          )
        }
      />
    );
  }

  function ProtectedConditionalRoute({ children, condition, fallbackPath, ...rest }: any) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          !user || !condition ? (
            <Redirect
              to={{
                pathname: !user ? Routes.LOGIN : fallbackPath,
                state: { from: location },
              }}
            />
          ) : (
            children
          )
        }
      />
    );
  }

  function ProtectedRoute({ adminOnly, children, ...rest }: any) {
    const isLoggedIn = !!user;
    let redirectTo: string | null = null;

    if (!isLoggedIn) {
      redirectTo = Routes.LOGIN;
    } else if (!isEmailVerified()) {
      redirectTo = Routes.EMAIL_CONFIRMATION;
    } else if (isWaitingForApproval()) {
      redirectTo = Routes.WAITING_FOR_APPROVAL;
    } else if (adminOnly && !isAdmin) {
      redirectTo = Routes.START_PAGE;
    }

    return (
      <Route
        {...rest}
        render={({ location }) =>
          redirectTo ? (
            <Redirect
              to={{
                pathname: redirectTo,
                state: { from: location },
              }}
            />
          ) : (
            children
          )
        }
      />
    );
  }

  if (!isAuthenticationDone) {
    return null;
  }

  return (
    <main className="app_router">
      <BrowserRouter>
        <Switch>
          <ProtectedConditionalRoute
            path={Routes.WAITING_FOR_APPROVAL}
            condition={isWaitingForApproval()}
            fallbackPath={Routes.START_PAGE}>
            <WaitingForApprovalPage />
          </ProtectedConditionalRoute>

          <ProtectedRoute path={Routes.adminUsers(':status')} adminOnly>
            <AdminUsersPage />
          </ProtectedRoute>

          <ProtectedRoute path={Routes.ADMIN_CUSTOMERS} adminOnly>
            <AdminCustomersPage />
          </ProtectedRoute>

          <ProtectedRoute path={Routes.adminOrders(':status')} adminOnly>
            <AdminOrdersPage />
          </ProtectedRoute>

          <ProtectedConditionalRoute
            path={Routes.WELCOME_PAGE}
            condition={isWaitingForApproval()}
            fallbackPath={Routes.WAITING_FOR_APPROVAL}>
            <WelcomePage />
          </ProtectedConditionalRoute>

          <ProtectedRoute exact path={Routes.CHECKOUT}>
            <Redirect to={Routes.CHECKOUT_UPLOAD} />
          </ProtectedRoute>
          <ProtectedRoute exact path={Routes.CHECKOUT_UPLOAD}>
            <Checkout component={UploadPage} />
          </ProtectedRoute>
          <ProtectedRoute exact path={Routes.checkoutUpload(':id')}>
            <Checkout component={UploadPage} />
          </ProtectedRoute>
          <ProtectedRoute exact path={Routes.checkoutParameters(':id')}>
            <Checkout component={ParametersPage} />
          </ProtectedRoute>
          <ProtectedRoute exact path={Routes.checkoutCalculation(':id')}>
            <Checkout component={CalculationPage} />
          </ProtectedRoute>
          <ProtectedRoute exact path={Routes.checkoutOrder(':id')}>
            <Checkout component={OrderPage} />
          </ProtectedRoute>

          {/* Route mit nächsten Deployment entfernen */}
          <ProtectedRoute exact path={Routes.checkoutOrderPrint(':id')}>
            <Redirect to={Routes.orderPrint(':id')} />
          </ProtectedRoute>
          <ProtectedRoute exact path={Routes.checkoutSuccess(':id')}>
            <Checkout component={SuccessPage} />
          </ProtectedRoute>

          <ProtectedRoute exact path={Routes.orderPrint(':id')}>
            <OrderPrintPage />
          </ProtectedRoute>

          <ProtectedRoute exact path={Routes.CUSTOMER_ORDERS}>
            <CustomerOrdersPage />
          </ProtectedRoute>

          <ProtectedRoute exact path={Routes.customerOrdersDetails(':id')}>
            <CustomerOrderDetailsPage />
          </ProtectedRoute>

          <ProtectedRoute exact path={'/'}>
            <Redirect to={Routes.CHECKOUT_UPLOAD} />
          </ProtectedRoute>

          <ProtectedRoute exact path={Routes.userSettings()}>
            <Redirect to={Routes.userSettings('shippingAddress')} />
          </ProtectedRoute>

          <ProtectedRoute exact path={Routes.userSettings(':setting')}>
            <UserSettingsPage />
          </ProtectedRoute>

          <ProtectedRoute exact path={Routes.adminOrderDetails(':id')} adminOnly>
            <AdminOrderDetailsPage />
          </ProtectedRoute>

          <PublicOnlyRoute exact path={Routes.NEW_PASSWORD}>
            <NewPasswordPage />
          </PublicOnlyRoute>
          <PublicOnlyRoute exact path={Routes.FORGOT_PASSWORD}>
            <ForgotPasswordPage />
          </PublicOnlyRoute>
          <PublicOnlyRoute exact path={Routes.LOGIN}>
            <LoginPage />
          </PublicOnlyRoute>
          <PublicOnlyRoute path={Routes.SIGN_UP}>
            <SignUpPage />
          </PublicOnlyRoute>
          <PublicOnlyRoute path={Routes.EMAIL_CONFIRMATION}>
            <EmailConfirmationPage />
          </PublicOnlyRoute>
          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
      </BrowserRouter>
    </main>
  );
}
