import './CustomerMaterialComponent.css';

import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  BlechconMaterial,
  BlechconMaterialAlias,
  BlechconRawMaterialId,
  BlechconUserInfo,
  CustomerMaterialId,
} from '../../../blechcon';
import { Button } from '../../../components/Button/Button';
import { CenteredRing } from '../../../components/CenteredRing/CenteredRing';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { Input } from '../../../components/Input/Input';
import { MaterialSelection } from '../../../components/MaterialSelection/MaterialSelection';
import { Menu } from '../../../components/Menu/Menu';
import { Modal } from '../../../components/Modal';
import { localized } from '../../../config/localization';
import {
  deleteCustomerMaterial,
  fetchCustomerMaterials,
  upsertCustomerMaterial,
} from '../../../services/api/blechconApi';
import { formatMeasure } from '../../../utils';

type Props = {
  readonly userInfo: BlechconUserInfo;
  readonly onChangeRetainCsvImportOrder: (value: boolean) => void;
};

export function CustomerMaterialPageComponent({ userInfo, onChangeRetainCsvImportOrder }: Props): JSX.Element {
  const [showRemoveMaterialModal, setShowRemoveMaterialModal] = useState(false);
  const [showMaterialModal, setShowMaterialModal] = useState(false);
  const [isAddDialog, setIsAddDialog] = useState(false);

  const [customerMaterialName, setCustomerMaterialName] = useState<CustomerMaterialId | null>(null);
  const [blechconMaterialId, setBlechconMaterialId] = useState<BlechconRawMaterialId | null>(null);
  const [customerMaterialId, setCustomerMaterialId] = useState<CustomerMaterialId | null>(null);
  const [blechconMaterial, setBlechconMaterial] = useState<BlechconMaterial | null>(null);

  const [state, setState] = useState({
    isFetching: false,
    aliases: [] as BlechconMaterialAlias[],
    availableMaterials: [] as BlechconMaterial[],
    lastEvaluatedKey: null,
  });

  const fetchAllMaterials = useCallback(
    async (lastEvaluatedKey = null) => {
      setState((current) => ({
        ...current,
        isFetching: true,
      }));

      if (lastEvaluatedKey === undefined) {
        return;
      }

      if (userInfo?.customerId) {
        fetchCustomerMaterials(userInfo.customerId, lastEvaluatedKey)
          .then((result) => {
            setState((current) => ({
              ...current,
              aliases: result.Items,
              availableMaterials: result.availableMaterials,
              lastEvaluatedKey: result.LastEvaluatedKey,
            }));
          })
          .catch((error) => {
            console.log(error);
            toast.error(String(error));
          })
          .finally(() => {
            setState((current) => ({
              ...current,
              isFetching: false,
            }));
          });
      }
    },
    [userInfo?.customerId]
  );

  useEffect(() => {
    fetchAllMaterials(null);
  }, [fetchAllMaterials]);

  const resetIds = () => {
    setCustomerMaterialName(null);
    setBlechconMaterialId(null);
    setBlechconMaterial(null);
  };

  const persistMaterial = useCallback(async () => {
    if (userInfo?.customerId && customerMaterialName && blechconMaterialId) {
      upsertCustomerMaterial(userInfo?.customerId, customerMaterialId, customerMaterialName, blechconMaterialId)
        .then(() => {
          setShowMaterialModal(false);
          resetIds();
          fetchAllMaterials();
        })
        .catch((error) => {
          console.log(error);
          toast.error(String(error));
        });
    }
  }, [userInfo?.customerId, customerMaterialId, customerMaterialName, blechconMaterialId, fetchAllMaterials]);

  const deleteMaterial = useCallback(async () => {
    if (userInfo?.customerId && customerMaterialId && blechconMaterialId) {
      deleteCustomerMaterial(userInfo.customerId, customerMaterialId)
        .then(() => {
          setShowRemoveMaterialModal(false);
          resetIds();
          fetchAllMaterials();
        })
        .catch((error) => {
          console.log(error);
          toast.error(String(error));
        });
    }
  }, [userInfo?.customerId, customerMaterialId, blechconMaterialId, fetchAllMaterials]);

  const updateMaterial = (material: BlechconMaterial) => {
    if (material.rawMaterialID) {
      setBlechconMaterialId(material.rawMaterialID);
    } else {
      setBlechconMaterialId(null);
    }
  };

  return (
    <div className="customer_material">
      {showRemoveMaterialModal && (
        <Modal
          title={localized.REMOVE_MATERIAL}
          onCancel={{
            label: localized.ABORT,
            execute: () => {
              resetIds();
              setShowRemoveMaterialModal(false);
            },
          }}
          onSuccess={{
            label: localized.REMOVE,
            execute: () => deleteMaterial(),
          }}>
          <p className="customer_material__delete-modal-text">{localized.REMOVE_MATERIAL_CONFIRMATION}</p>
        </Modal>
      )}
      {showMaterialModal && (
        <Modal
          className="customer_material__add-material-modal"
          title={isAddDialog ? localized.ADD_MATERIAL : localized.EDIT_MATERIAL}
          successDisabled={!(customerMaterialName && blechconMaterialId)}
          onCancel={{
            label: localized.ABORT,
            execute: () => {
              resetIds();
              setShowMaterialModal(false);
            },
          }}
          onSuccess={{
            label: isAddDialog ? localized.ADD_MATERIAL : localized.SAVE,
            execute: () => persistMaterial(),
          }}>
          <Input
            name="customerMaterialName"
            type="text"
            placeholder={localized.CUSTOM_MATERIAL_NAME}
            values={{ customerMaterialName: customerMaterialName || '' }}
            readOnly={!isAddDialog}
            autoFocus
            onChange={(event) => {
              setCustomerMaterialName(event.target.value);
            }}
          />
          <MaterialSelection
            materials={state.availableMaterials}
            partMaterial={blechconMaterial}
            updateMaterial={updateMaterial}
          />
        </Modal>
      )}

      <div className="customer_material__action-container">
        <Checkbox
          label={localized.CUSTOMER_MATERIAL_SORT_BY_CSV}
          checked={userInfo.retainCsvImportOrder || false}
          onClick={onChangeRetainCsvImportOrder}
        />

        <Button
          onClick={() => {
            setShowMaterialModal(true);
            setIsAddDialog(true);
          }}
          secondary>
          {localized.ADD_MATERIAL}
        </Button>
      </div>
      <table className="customer_material__material-table">
        <thead>
          <tr className="customer_material__material-table_header">
            <td>{localized.CUSTOM_MATERIAL_NAME}</td>
            <td>{localized.CUSTOM_MATERIAL_THICKNESS}</td>
            <td>{localized.BLECHCON_MATERIAL_NAME}</td>
            <td />
          </tr>
        </thead>

        <tbody>
          {state.aliases.map((alias, index) => (
            <tr key={index} className="customer_material__material-table_row">
              <td>{alias.customerMaterialName}</td>
              <td>{formatMeasure(alias.customerMaterialThickness, 'mm')}</td>
              <td>
                {formatMeasure(alias.customerMaterialThickness, 'mm')} {alias.blechconMaterialDesignation}
              </td>
              <td>
                <Menu dots>
                  <Menu.Item
                    onSelect={() => {
                      setBlechconMaterialId(alias.blechconMaterialId);
                      setCustomerMaterialId(alias.customerMaterialId);
                      setCustomerMaterialName(alias.customerMaterialName);
                      setBlechconMaterial(
                        state.availableMaterials.find((m) => m.rawMaterialID === alias.blechconMaterialId) || null
                      );
                      setShowMaterialModal(true);
                      setIsAddDialog(false);
                    }}>
                    {localized.EDIT}
                  </Menu.Item>
                  <Menu.Item
                    onSelect={() => {
                      setBlechconMaterialId(alias.blechconMaterialId);
                      setCustomerMaterialId(alias.customerMaterialId);
                      setShowRemoveMaterialModal(true);
                    }}>
                    {localized.REMOVE}
                  </Menu.Item>
                </Menu>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {state.isFetching ? <CenteredRing /> : null}

      {!state.isFetching && state.lastEvaluatedKey ? (
        <div className="customer_material__load-more-button">
          <Button
            disabled={state.isFetching}
            onClick={() => {
              fetchAllMaterials(state.lastEvaluatedKey);
            }}
            secondary>
            {localized.LOAD_MORE}
          </Button>
        </div>
      ) : null}
    </div>
  );
}
