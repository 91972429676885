/* eslint sonarjs/no-duplicate-string: 0 */
export const config = {
  aws_project_region: 'eu-central-1',
  aws_cognito_identity_pool_id: 'eu-central-1:1d91a9c6-518f-4ac8-85f3-f096c75bed6c',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_lL8UTTPVq',
  aws_user_pools_web_client_id: '1k8ivebhglk0phrj17b9g4ehlj',
  aws_user_files_s3_bucket: 'blechcons3100923-cypress',
  aws_user_files_s3_bucket_region: 'eu-central-1',
};

export const wicamV2Url = 'https://api-v2-development.wicam.com/';

export const optimateUrl = 'https://api.optimate.de/';

export const apigwUrl = 'https://nw1i1lout3.execute-api.eu-central-1.amazonaws.com/cypress/';
