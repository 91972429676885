import { BlechconUserGroupType, CheckoutSteps, OrderId } from '../blechcon';

export const Routes = {
  WELCOME_PAGE: '/welcome',
  START_PAGE: '/checkout/upload',
  CHECKOUT: '/checkout',
  CHECKOUT_UPLOAD: '/checkout/upload',
  checkoutUpload: (id: OrderId): string => `/checkout/upload/${id}`,
  checkoutParameters: (id: OrderId): string => `/checkout/parameters/${id}`,
  checkoutCalculation: (id: OrderId): string => `/checkout/calculation/${id}`,
  checkoutOrder: (id: OrderId): string => `/checkout/order/${id}`,
  checkoutOrderPrint: (id: OrderId): string => `/checkout/order/${id}/print`,
  orderPrint: (id: OrderId): string => `/order/print/${id}`,
  checkoutSuccess: (id: OrderId): string => `/checkout/success/${id}`,
  checkoutStep: (step: CheckoutSteps): ((id: OrderId) => string) => {
    if (step === 2) {
      return Routes.checkoutParameters;
    } else if (step === 3) {
      return Routes.checkoutCalculation;
    } else if (step === 4) {
      return Routes.checkoutOrder;
    }
    return Routes.checkoutUpload;
  },
  LOGIN: '/login',
  SIGN_UP: '/signup',
  EMAIL_CONFIRMATION: '/emailConfirmation',
  WAITING_FOR_APPROVAL: '/waitingForApproval',
  userSettings: (setting?: string) => `/user/settings${setting ? '/' + setting : ''}`,
  FORGOT_PASSWORD: '/forgotPassword',
  NEW_PASSWORD: '/newPassword',
  adminOrders: (orderStatus: string): string => `/administration/orders/${orderStatus}`,
  adminUsers: (userStatus: BlechconUserGroupType | ':status'): string => `/administration/users/${userStatus}`,
  ADMIN_CUSTOMERS: '/administration/customers',
  CUSTOMER_ORDERS: '/customer/orders',
  customerOrdersDetails: (orderId: OrderId): string => `/customer/orders/v2/${orderId}`,
  adminOrderDetails: (id: string): string => `/administration/order/v2/${id}`,
};
