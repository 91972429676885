import { BlechconAsset } from '../../blechcon';

export async function stringToImageDataURL(data: string, type: string): Promise<BlechconAsset> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = function () {
      resolve(reader.result as string);
    };

    reader.onerror = reject;
    reader.onabort = () => reject(new Error('image preview generation aborted'));

    reader.readAsDataURL(new Blob([data], { type }));
  });
}
