import groupBy from 'lodash/groupBy';
import {
  BlechconOrderFile,
  BlechconOrderPrices,
  BendingCostsPerHour,
  BlechconCustomerConfiguration,
  BlechconOrderFilePart,
  ProcessingTimeOfPlate,
} from '../../../blechcon';

type CostByPart = {
  [key: string]: {
    partArea: number;
    neededMaterialArea: number;
    setupTimePerPart: number;
    neededPalettes: number;
    materialSetupTimeTotal: number;
  };
};

export type PriceCalculation = {
  files: BlechconOrderFile[];
  prices: BlechconOrderPrices;
};

const TOO_HEAVY_TO_BEND_ERROR = 'BLC_TOO_HEAVY_TO_BEND_ERROR';

function calculateFilePrice(
  file: BlechconOrderFile,
  setupCostByPart: CostByPart,
  configuration: BlechconCustomerConfiguration,
  cuttingCostPerHour: number
): BlechconOrderFile {
  for (const part of file.parts) {
    part.prices.processingTimeMin = (part.averageProcessingTimeMs ?? 0) / (60 * 1000);
    part.prices.bendingTimeMin = (part.bendingTimeMs ?? 0) / (60 * 1000);
    part.quantity = part.quantity ?? 1;

    const partQuantityWithSurcharge = getPartQuantityWithSurcharge(
      configuration.PART_QUANTITY_SURCHARGE,
      part.quantity
    );

    const setupCostTotal = (cuttingCostPerHour / 60) * setupCostByPart[part.fileId].materialSetupTimeTotal;

    part.prices.setUpCost =
      ((setupCostByPart[part.fileId].partArea / setupCostByPart[part.fileId].neededMaterialArea) * setupCostTotal) /
      part.quantity;
    part.prices.cuttingCostPerHour = cuttingCostPerHour;
    part.prices.setupTimeTotal = setupCostByPart[part.fileId].setupTimePerPart;
    part.prices.neededPalettes = setupCostByPart[part.fileId].neededPalettes;
    part.prices.priceForLaserCuts =
      ((part.prices.processingTimeMin * cuttingCostPerHour) / 60) * (partQuantityWithSurcharge / part.quantity);
    part.prices.priceForBending = 0;
    part.prices.setupBendingCostPerUnit = 0;
    part.prices.bendingCostPerUnit = 0;

    const bendingCostPerHour = findBendingCostPerHour(
      configuration.customerBendingCostsPerHour ?? configuration.BENDING_COSTS_PER_HOUR,
      part.weightNetto
    );

    if (part.prices.bendingTimeMin > 0 && partQuantityWithSurcharge > 0 && bendingCostPerHour) {
      part.prices.setupBendingCostPerUnit =
        (configuration.BENDING_SETUP_TIME_PER_PLATE * configuration.BENDING_SETUP_COST_PER_HOUR) / 60 / part.quantity;
      part.prices.bendingCostPerUnit =
        ((part.prices.bendingTimeMin * bendingCostPerHour) / 60) * (partQuantityWithSurcharge / part.quantity);
      part.prices.priceForBending = part.prices.setupBendingCostPerUnit + part.prices.bendingCostPerUnit;
    }

    if (!Number.isFinite(bendingCostPerHour) && part.bending) {
      part.errorCode = TOO_HEAVY_TO_BEND_ERROR;
    }

    //@ts-ignore
    let price = part.prices.setUpCost + part.prices.priceForLaserCuts + part.prices.priceForMaterial;

    if (part.edgesRounded) {
      price = price + configuration.ROUNDED_EDGE_PER_PART * (partQuantityWithSurcharge / part.quantity);
    }

    if (part.bending) {
      price = price + part.prices.priceForBending;
    }

    const materialUnitPriceCent = toRoundedCentAmount(price);
    part.prices.materialUnitPrice = toEuroAmount(materialUnitPriceCent);
    part.prices.materialTotalPrice = toEuroAmount(part.quantity * materialUnitPriceCent);
  }

  file.prices.materialTotalPrice = 0;
  file.prices.materialUnitPrice = 0;
  file.weightBrutto = 0;
  file.weightNetto = 0;

  for (const part of file.parts) {
    file.prices.materialTotalPrice += part?.prices.materialTotalPrice ?? 0;
    file.prices.materialUnitPrice += (part?.prices.materialUnitPrice ?? 0) * (part.assemblyCountPerPart ?? 0);
    file.weightBrutto += (part?.weightGross ?? 0) * (part?.assemblyCountPerPart ?? 0);
    file.weightNetto += (part?.weightNetto ?? 0) * (part?.assemblyCountPerPart ?? 0);
  }

  return file;
}

function calculateMaterialTotalPrice(
  files: BlechconOrderFile[],
  configuration: BlechconCustomerConfiguration
): BlechconOrderFile[] {
  const parts = files.flatMap((file) => [...file.parts]);
  const partsByMaterial = groupBy(parts, (part) => part.material?.rawMaterialID);
  const setupCostByPart: CostByPart = {};
  let sumProcessingTimeInMinTotal = 0;
  let sumSetupTimeTotal = 0;

  for (const partsOfMaterial of Object.values(partsByMaterial)) {
    const nestedPlateRepeats = partsOfMaterial[0].material?.nestedPlateRepeats ?? 0;
    const materialSetupTimeTotal =
      configuration.LASER_SETUP_TIME_PER_MATERIAL + nestedPlateRepeats * configuration.CHANGE_OF_PLATE;

    const neededMaterialArea = calculateNeededMaterialArea(partsOfMaterial, configuration);

    for (const part of partsOfMaterial) {
      const { areaGross = 0, assemblyCountPerPart = 0, quantity = 0 } = part;

      const materialPriceSurcharge = calculateMaterialSurcharge(part, configuration, nestedPlateRepeats);

      const partQuantityWithSurcharge = getPartQuantityWithSurcharge(
        configuration.PART_QUANTITY_SURCHARGE,
        part.quantity ?? 1
      );

      part.prices.priceForMaterial =
        (part.weightGross ?? 0) *
        (part.material?.pricePerKg ?? 0) *
        materialPriceSurcharge *
        (partQuantityWithSurcharge / (part.quantity ?? 1));

      const partArea =
        areaGross *
        assemblyCountPerPart *
        getPartQuantityWithSurcharge(configuration.PART_QUANTITY_SURCHARGE, quantity);

      setupCostByPart[part.fileId] = {
        partArea,
        neededMaterialArea,
        setupTimePerPart: materialSetupTimeTotal / partsOfMaterial.length,
        neededPalettes: nestedPlateRepeats,
        materialSetupTimeTotal,
      };

      part.averageProcessingTimeMs = calculateWeightedAverage(part.processingTimeOfPlates ?? []);
      sumProcessingTimeInMinTotal += calculateSumOfProcessingTime(part.processingTimeOfPlates ?? []);
    }
    sumSetupTimeTotal += materialSetupTimeTotal;
  }

  const sumOfAllMaterialSetupTimesAndProcessingTimes = sumProcessingTimeInMinTotal + sumSetupTimeTotal;
  const cuttingCostPerHour = calculateCuttingCostPerHour(configuration, sumOfAllMaterialSetupTimesAndProcessingTimes);
  return files.map((file) => calculateFilePrice(file, setupCostByPart, configuration, cuttingCostPerHour));
}

function calculateNeededMaterialArea(partsOfMaterial: BlechconOrderFilePart[], configuration: BlechconCustomerConfiguration) {
  let neededMaterialArea = 0;
  for (const part of partsOfMaterial) {
    const { areaGross = 0, assemblyCountPerPart = 0, quantity = 0 } = part;
    neededMaterialArea +=
      areaGross * assemblyCountPerPart * getPartQuantityWithSurcharge(configuration.PART_QUANTITY_SURCHARGE, quantity);
  }
  return neededMaterialArea;
}

export function calculateSumOfProcessingTime(processingTimeOfPlates: ProcessingTimeOfPlate[]) {
  const averageMs = calculateWeightedAverage(processingTimeOfPlates);
  let amount = 0;
  for (const plate of processingTimeOfPlates) {
    amount += plate.amount * plate.repeats;
  }
  return (amount * averageMs) / 1000 / 60;
}

export function calculatePrices(files: BlechconOrderFile[], configuration: BlechconCustomerConfiguration): PriceCalculation {
  const calculatedFiles = calculateMaterialTotalPrice(files, configuration);
  const calculatedAtDate = new Date();
  const materialTotalPrice = calculateOrderMaterialTotalPrice(calculatedFiles);

  const prices = {
    materialTotalPrice,
    calculatedAtDate: calculatedAtDate.toISOString(),
  };

  return {
    prices,
    files: calculatedFiles,
  };
}

export function findBendingCostPerHour(
  bendingCostsPerHour: BendingCostsPerHour[],
  weightNetto: number | null
): number | undefined {
  if (weightNetto === null) {
    return undefined;
  }
  return bendingCostsPerHour.find((it) => weightNetto >= it.minWeightNetto && weightNetto <= it.maxWeightNetto)
    ?.costPerHour;
}

function calculateOrderMaterialTotalPrice(files: BlechconOrderFile[]): number {
  let totalPrice = 0;
  for (const file of files) {
    totalPrice += file.prices.materialTotalPrice ?? 0;
  }
  return totalPrice;
}

export function getPartQuantityWithSurcharge(PART_QUANTITY_SURCHARGE: number, partQuantity: number) {
  return Math.floor(partQuantity * PART_QUANTITY_SURCHARGE);
}

function calculateMaterialSurcharge(
  part: BlechconOrderFilePart,
  configuration: BlechconCustomerConfiguration,
  nestedPlateRepeats: number
) {
  const materialSurchargeConfig = configuration.MATERIAL_SURCHARGE.find(
    (materialSurcharge) => materialSurcharge.materialTypeId === part.material?.materialTypeID
  );
  if (!materialSurchargeConfig) {
    throw new Error();
  }

  return (
    (materialSurchargeConfig.max - materialSurchargeConfig.min) /
      (nestedPlateRepeats * materialSurchargeConfig.exponentialFactor +
        (1 - materialSurchargeConfig.exponentialFactor)) +
    materialSurchargeConfig.min
  );
}

function calculateCuttingCostPerHour(configuration: BlechconCustomerConfiguration, setupTimeTotal: number) {
  const max = configuration.CUTTING_COST_PER_HOUR.max;
  const min = configuration.CUTTING_COST_PER_HOUR.min;
  const exponentialFactor = configuration.CUTTING_COST_PER_HOUR.exponentialFactor;

  return (max - min) / ((setupTimeTotal / 10) * exponentialFactor + (1 - exponentialFactor)) + min;
}

export function calculateWeightedAverage(calculatedProcessingTimeOfPlates: ProcessingTimeOfPlate[]) {
  const filteredPlates = calculatedProcessingTimeOfPlates.filter((plate) => plate.amount > 0);
  let divident = 0;
  let divisor = 0;
  for (const plate of filteredPlates) {
    divident += plate.amount * plate.partProcessingTime;
    divisor += plate.amount;
  }

  return divident / divisor;
}

export function toRoundedCentAmount(value: number): number {
  return Math.round(value * 100);
}

export function toEuroAmount(cent: number): number {
  return cent / 100;
}
