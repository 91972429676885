import React from 'react';

type Props = {
  height?: number;
  width?: number;
  fill?: string;
};

export function CheckMark({ width = 112, height = 112, fill = '' }: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 112 112" fill={fill}>
      <path
        d="M58,2a56,56,0,1,0,56,56A56,56,0,0,0,58,2ZM89.959,45.159l-39.2,39.2a5.6,5.6,0,0,1-7.918,0l-16.8-16.8a5.6,5.6,0,0,1,7.918-7.918L46.8,72.482,82.041,37.241a5.6,5.6,0,0,1,7.918,7.918Z"
        transform="translate(-2 -2)"
      />
    </svg>
  );
}
