import './CustomerOrderDetailsPage.css';
import React, { useCallback, useEffect, useState } from 'react';
import { Page } from '../../../components/Page/Page';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router';
import { BlechconOrder } from '../../../blechcon';
import { localized } from '../../../config/localization';
import { fetchOrder, updateOrder, withdrawRequest } from '../../../services/orderApi';
import { ArrowLeft } from '../../../assets/svg/ArrowLeft';
import { OrderDetailView } from '../../../components/OrderDetailView';
import { OrderDocuments } from '../../../components/OrderDocuments';
import { downloadDocumentsInBatches } from '../../../components/helper';
import { Button } from '../../../components/Button/Button';
import { FileTableRowComponent } from '../../../components/FileTableRowComponent/FileTableRowComponent';
import DownloadAdditionalFile from '../../../components/DownloadAdditionalFile';
import { CenteredRing } from '../../../components/CenteredRing/CenteredRing';
import { useUserInfo } from '../../../services/useUserInfo';
import { ConfirmButton } from '../../../components/ConfirmButton/ConfirmButton';
import { persistAnalysisModels } from '../../../components/PartViewer/persistAnalysisModels';
import { OrderPrintButton } from "../../../components/OrderPrintButton";

type ParamsType = {
  id: string;
};

export function CustomerOrderDetailsPage(): JSX.Element {
  const { id } = useParams<ParamsType>();
  const history = useHistory();

  const [userInfo] = useUserInfo();

  const [order, setOrder] = useState<BlechconOrder>();

  useEffect(() => {
    fetchOrder(id)
      .then((fetchedOrder) => {
        setOrder(fetchedOrder);
      })
      .catch((error) => toast.error(String(error)));
  }, [id]);

  const persistOrder = useCallback(
    (partial: Partial<BlechconOrder>) => {
      updateOrder(partial, id)
        .then(setOrder)
        .catch((error) => {
          toast.error(String(error));
        });
    },
    [id]
  );

  const downloadFiles = async () => {
    if (order) {
      await downloadDocumentsInBatches(order.fileDrawings);
    }
  };

  return (
    <Page title={localized.RECENT_ORDERS} loading={!userInfo}>
      {order ? (
        <>
          <div className="flex flex-row mb-8 items-center cursor-pointer" onClick={() => history.goBack()}>
            <ArrowLeft />
            <div className="text-primary ml-4">{localized.BACK_TO_ORDERS}</div>
          </div>
          <OrderDetailView order={order}>
            {({ file, index }) => {
              return (
                <FileTableRowComponent
                  key={file.id}
                  file={file}
                  index={index}
                  onAnalysisResult={async (analysisModels) => {
                    await persistAnalysisModels(order, file, analysisModels, persistOrder);
                  }}>
                  {({ part }) => {
                    return <>{part.document && <DownloadAdditionalFile document={part.document} />}</>;
                  }}
                </FileTableRowComponent>
              );
            }}
          </OrderDetailView>
          <div className="customer-order-details-page__status-buttons">
            {order.orderStatus === 'submitted' && (
                <OrderPrintButton order={order} />
            )}

            {order.userId === userInfo?.userId && order.orderStatus === 'submitted' && (
              <ConfirmButton
                title={localized.WITHDRAW_REQUEST}
                description={localized.WITHDRAW_REQUEST_CONFIRMATION}
                danger
                onConfirm={() => {
                  withdrawRequest(order.id)
                    .then((updatedOrder) => setOrder(updatedOrder))
                    .catch((error) => toast.error(String(error)));
                }}>
                {localized.WITHDRAW_REQUEST}
              </ConfirmButton>
            )}
            {order.userId === userInfo?.userId && order.orderStatus === 'withdraw' && (
              <span className="customer-order-details-page__withdraw-info">
                {localized.WITHDRAW_REQUEST_INFORMATION}
              </span>
            )}
            <Button onClick={downloadFiles} secondary>
              {localized.DOWNLOAD_FILES}
            </Button>
          </div>
          <OrderDocuments order={order} />
        </>
      ) : (
        <CenteredRing />
      )}
    </Page>
  );
}
