import './CalculationPage.css';

import React, { useEffect, useRef, useState } from 'react';
import { localized } from '../../../config/localization';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../app/Routes';
import { Modal } from '../../../components/Modal';
import { FileTableRowComponent } from '../../../components/FileTableRowComponent/FileTableRowComponent';
import { Button } from '../../../components/Button/Button';
import { BlechconCustomerConfiguration, BlechconOrder, BlechconUserInfo } from '../../../blechcon';
import { calculateFiles } from '../../../services/wicam/calculateFile';
import { toast } from 'react-toastify';
import { calculatePrices } from './materialPriceCalculation';
import { PartDetails } from '../../../components/PartDetails/PartDetails';
import { persistAnalysisModels } from '../../../components/PartViewer/persistAnalysisModels';

type Props = {
  order: BlechconOrder;
  userInfo: BlechconUserInfo;
  persistPending: boolean;
  isAdmin: boolean;
  persistOrder: (order: Partial<BlechconOrder>) => Promise<BlechconOrder | null>;
  configuration: BlechconCustomerConfiguration;
};

export function CalculationPage({
  order,
  userInfo,
  persistPending,
  isAdmin,
  persistOrder,
  configuration,
}: Props): JSX.Element {
  const history = useHistory();
  const abortRef = useRef(new AbortController());
  const [showBackModal, setShowBackModal] = useState(false);

  useEffect(() => {
    const copy = abortRef.current;
    return () => {
      copy.abort();
    };
  }, []);

  useEffect(() => {
    const materialTotalPrice = order?.prices?.materialTotalPrice ?? 0;

    if (materialTotalPrice <= 0) {
      calculateFiles(abortRef.current.signal, order, configuration)
        .then(({ files, nestingFiles }) => {
          const calculatedOrder = calculatePrices(files, configuration);
          return persistOrder({
            id: order.id,
            prices: { ...calculatedOrder.prices },
            files,
            nestingFiles,
            parameters: {
              nestingJob: configuration.NESTING_JOB_TEMPLATE,
              configurationForCalculationPage: configuration,
            },
          });
        })
        .catch((error) => {
          if (abortRef.current.signal.aborted) {
            return;
          }
          console.error('error in calculation', error);
          toast.error(`error in file`);
        })
        .finally(() => {
          abortRef.current.abort();
        });
    }
  }, [configuration, order, persistOrder, abortRef]);

  async function handleNext() {
    const updatedOrder = await persistOrder({ id: order.id, checkoutStep: 4 });
    if (updatedOrder) {
      history.push(Routes.checkoutOrder(order.id));
    }
  }

  return (
    <>
      {showBackModal && (
        <Modal
          title={localized.BACK_TO_PARAMETERS}
          onCancel={{
            label: localized.ABORT,
            execute: () => {
              setShowBackModal(false);
            },
          }}
          onSuccess={{
            label: localized.BACK_TO_PARAMETERS,
            execute: () => {
              history.push(Routes.checkoutParameters(order.id));
            },
          }}>
          <p className="text-label text-lg text-opacity-50">{localized.BACK_TO_PARAMETERS_TEXT}</p>
        </Modal>
      )}

      <div className="h-full flex flex-col">
        <table className="mt-4 table-auto">
          <thead>
            <tr className="bg-contentHeader text-primary">
              <td className="font-bold uppercase text-sm w-3/5" style={{ padding: '1rem' }}>
                {localized.PRODUCT}
              </td>
              <td className="font-bold uppercase text-sm">{localized.QUANTITY}</td>
              <td className="font-bold uppercase text-sm">{localized.UNIT_PRICE} EUR</td>
              <td className="font-bold uppercase text-sm text-right pr-4">{localized.TOTAL} EUR</td>
            </tr>
          </thead>
          <tbody className="calculation-page__table-body">
            {order.files.map((file, index) => (
              <FileTableRowComponent
                key={index}
                file={file}
                index={index}
                disabled={persistPending || !order?.prices?.materialTotalPrice}
                adminOnly={isAdmin}
                onAnalysisResult={async (analysisModels) => {
                  await persistAnalysisModels(order, file, analysisModels, persistOrder);
                }}
                configuration={configuration}>
                {({ part }) => {
                  return isAdmin ? <PartDetails part={part}></PartDetails> : null;
                }}
              </FileTableRowComponent>
            ))}
          </tbody>
        </table>

        <div className="flex px-4 justify-between mt-8 bottom-0 py-8">
          <Button
            onClick={() => {
              setShowBackModal(true);
            }}
            secondary>
            {localized.BACK_TO_PARAMETERS}
          </Button>
          <Button
            disabled={
              persistPending ||
              (order.prices.materialTotalPrice || 0) === 0 ||
              order.userId !== userInfo.userId ||
              order.files.flatMap((f) => f.parts).some((p) => p.errorCode)
            }
            dataCy="button-checkout-next"
            onClick={handleNext}>
            {localized.CONTINUE_TO_OFFER}
          </Button>
        </div>
      </div>
    </>
  );
}
