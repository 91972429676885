import { api } from './index';
import { NestingJobResponse } from './wicamTypes';

const timeout = 2000;

export async function nestingDetails(signal: AbortSignal, nestingJobId: string): Promise<NestingJobResponse> {
  return new Promise((resolve, reject) => {
    async function run() {
      try {
        const response = await api.nesting.nestingDetail(nestingJobId);

        if (response.status === 206) {
          setTimeout(() => {
            if (!signal.aborted) {
              run();
            } else {
              reject();
            }
          }, timeout);
        } else {
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    }

    run();
  });
}
