import { Api } from './Internal/Api';
import { BlechconUrls } from './Internal/URL';
import {
  BlechconCustomerConfiguration,
  BlechconCustomer,
  BlechconDocument,
  BlechconMaterial,
  BlechconOrder,
  BlechconOrderStatusGroup,
  BlechconRawMaterialId,
  BlechconTranslations,
  BlechconUser,
  BlechconUserGroupType,
  BlechconUserInfo,
  CustomerId,
  CustomerMaterialId,
  Email,
  LastEvaluatedKey,
  OrderId,
  UserId,
  Username,
  UstId,
} from '../../blechcon';

const blechconApi = Api();

export function updateUserMe(userInfo: Partial<BlechconUserInfo>): Promise<BlechconUserInfo> {
  return blechconApi
    .post(BlechconUrls.USERS_ME, userInfo)
    .then((response) => response.data)
    .catch(handleError);
}

export function getOrders(
  lastEvaluatedKey: LastEvaluatedKey,
  orderStatus: BlechconOrderStatusGroup
): Promise<{ Items: Array<BlechconOrder>; LastEvaluatedKey: string | null }> {
  return blechconApi.get(BlechconUrls.ADMIN_ORDERS(lastEvaluatedKey, orderStatus)).then((response) => response.data);
}

export function findDistance(address: string): Promise<{
  distance: number;
  country: string;
}> {
  return blechconApi.post(BlechconUrls.FIND_DISTANCE, { address }).then((response) => response.data);
}

export function getUsers(
  status: BlechconUserGroupType,
  nextToken?: LastEvaluatedKey
): Promise<{ Items: Array<BlechconUser & BlechconUserInfo>; NextToken: string | undefined }> {
  return blechconApi.get(BlechconUrls.USERS(status, nextToken)).then((response) => response.data);
}

export function getCustomers(
  nextToken?: LastEvaluatedKey
): Promise<{ Items: BlechconCustomer[]; NextToken: string | undefined }> {
  return blechconApi.get(BlechconUrls.ADMIN_CUSTOMERS(nextToken)).then((response) => response.data);
}

export function updateCustomer(
  customer: Required<Pick<BlechconCustomer, 'customerId'>> & Partial<BlechconCustomer>
): Promise<BlechconCustomer> {
  if (customer.customerId) {
    return blechconApi
      .post(BlechconUrls.ADMIN_CUSTOMER(customer.customerId), customer)
      .then((response) => response.data);
  } else {
    throw new Error('customerId is undefined');
  }
}

export function updateUserInfo(userId: UserId, customerId: CustomerId, ustid: UstId): Promise<void> {
  return blechconApi
    .post(BlechconUrls.ADMIN_USER(userId), {
      customerId,
      ustid,
    })
    .then(() => undefined);
}

export function toggleUserStatus(username: Username, email: Email, isActive: boolean): Promise<void> {
  return blechconApi
    .post(BlechconUrls.TOGGLE_USER_STATUS, {
      username,
      email,
      isActive,
    })
    .then(() => undefined);
}

export function updateUserGroups(username: Username, groups: BlechconUserGroupType[]): Promise<void> {
  return blechconApi
    .put(BlechconUrls.ADMIN_USER_GROUPS(username), {
      username,
      groups,
    })
    .then(() => undefined);
}

export function resetPassword(username: Username): Promise<void> {
  return blechconApi.post(BlechconUrls.ADMIN_RESET_PASSWORD, { username }).then(() => undefined);
}

export function getUsersMe(): Promise<BlechconUserInfo> {
  return blechconApi.get(BlechconUrls.USERS_ME).then((response) => response.data);
}

export function fetchCustomerOrders(customerFilter: boolean, lastEvaluatedKey: LastEvaluatedKey) {
  return blechconApi
    .get(BlechconUrls.CUSTOMER_ORDERS(customerFilter, lastEvaluatedKey))
    .then((response) => response.data);
}

export function fetchCustomerMaterials(customerId: string, lastEvaluatedKey?: LastEvaluatedKey) {
  return blechconApi
    .get(BlechconUrls.CUSTOMER_MATERIALS(customerId, lastEvaluatedKey))
    .then((response) => response.data);
}

export function upsertCustomerMaterial(
  customerId: string,
  customerMaterialId: CustomerMaterialId | null,
  customerMaterialName: string,
  blechconMaterialId: BlechconRawMaterialId
) {
  return blechconApi
    .post(BlechconUrls.CUSTOMER_MATERIALS(customerId), {
      customerMaterialId,
      customerMaterialName,
      blechconMaterialId,
    })
    .then((response) => response.data);
}

export function deleteCustomerMaterial(customerId: string, customerMaterialId: CustomerMaterialId) {
  return blechconApi
    .delete(BlechconUrls.DELETE_CUSTOMER_MATERIAL(customerId, customerMaterialId))
    .then((response) => response.data);
}

export function removeOrder(id: OrderId) {
  return blechconApi.delete(BlechconUrls.ORDER_ID(id)).then(() => undefined);
}

export function parseParametersCsv(id: OrderId, document: BlechconDocument) {
  return blechconApi.post(BlechconUrls.PARSE_PARAMETERS_CSV(id), { document }).then((response) => response.data);
}

export function getBlechconMaterials(): Promise<BlechconMaterial[]> {
  return blechconApi.get(BlechconUrls.MATERIALS).then((response) => response.data);
}

export function getBlechconConfiguration(): Promise<BlechconCustomerConfiguration> {
  return blechconApi.get(BlechconUrls.CUSTOMER_CONFIGURATION).then((response) => response.data);
}

export function getTranslations(): Promise<BlechconTranslations> {
  return blechconApi.get(BlechconUrls.TRANSLATIONS).then((response) => response.data);
}

function handleError(error: any) {
  if (error?.response?.data?.error) {
    throw new Error(error.response.data.error);
  }
  throw error;
}
