import './Button.css';
import React from 'react';

export type Props = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'submit' | 'reset';
  secondary?: boolean;
  danger?: boolean;
  disabled?: boolean;
  rounded?: boolean;
  dataCy?: string;
  className?: string;
  children: React.ReactNode;
};

export function Button({
  type = 'button',
  disabled = false,
  onClick,
  dataCy,
  secondary,
  danger,
  rounded,
  className = '',
  children,
}: Props): JSX.Element {
  const secondaryClass = secondary ? 'button--secondary' : '';
  const dangerClass = danger ? 'button--danger' : '';
  const roundedClass = rounded ? 'button--rounded' : '';

  return (
    <button
      className={`button ${secondaryClass} ${dangerClass} ${roundedClass} ${className}`}
      disabled={disabled}
      onClick={onClick}
      type={type}
      data-cy={dataCy}>
      {children}
    </button>
  );
}
