import { Milliseconds } from '../blechcon';

export function convertToMilliseconds(value: string): Milliseconds {
  const timeComponents = value.split(':');

  if (timeComponents.length < 3) {
    throw new Error(`invalid time "${value}"`);
  }

  const secondsMilliseconds = timeComponents[2].split('.');

  let milliseconds = 0;
  if (secondsMilliseconds.length === 2) {
    milliseconds = Number(secondsMilliseconds[1]) / 10000;
  }

  const hours = Number(timeComponents[0]) * 60 * 60 * 1000;
  const minutes = Number(timeComponents[1]) * 60 * 1000;
  const seconds = Number(secondsMilliseconds[0]) * 1000;

  return hours + minutes + seconds + milliseconds;
}
