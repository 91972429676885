import React from 'react';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from '../services/AuthContext';
import { AppRouter } from './AppRouter';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

export const App = (): JSX.Element => {
  return (
    <AuthProvider>
      <ToastContainer position="top-center" />
      <AppRouter />
    </AuthProvider>
  );
};
