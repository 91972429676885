import './LanguageSelector.css';
import React, { useEffect, useRef, useState } from 'react';
import { Globe } from '../../assets/svg/Globe';
import { localized } from '../../config/localization';
import { AvailableLanguagesType } from '../../blechcon';

type Props = {
  onClick: (language: AvailableLanguagesType) => void;
};

export function LanguageSelector({ onClick }: Props): JSX.Element {
  const selectorRef = useRef(null);
  const [languageSelector, setLanguageSelector] = useDetectOutsideClick(selectorRef, false);

  function isSameLanguage(language: string) {
    return localized.getLanguage() === language;
  }
  function activeClass(language: string) {
    return isSameLanguage(language) ? 'language-selector__item--selected' : '';
  }

  function handleClick(language: AvailableLanguagesType) {
    setLanguageSelector(false);

    if (!isSameLanguage(language)) {
      onClick(language);
    }
  }

  return (
    <div ref={selectorRef} className="language-selector">
      {languageSelector ? (
        <>
          <span className={activeClass('de')} onClick={() => handleClick('de')}>
            DE
          </span>
          <span className={activeClass('en')} onClick={() => handleClick('en')}>
            EN
          </span>
        </>
      ) : (
        <Globe onClick={() => setLanguageSelector(true)} />
      )}
    </div>
  );
}

const useDetectOutsideClick = (
  el: React.MutableRefObject<HTMLDivElement | null>,
  initialState: boolean
): [boolean, (state: boolean) => void] => {
  const [isActive, setIsActive] = useState(initialState);

  useEffect(() => {
    const onClick = (event: any) => {
      // If the active element exists and is clicked outside of
      if (el.current !== null && !el.current.contains(event.target)) {
        setIsActive(!isActive);
      }
    };

    // If the item is active (ie open) then listen for clicks outside
    if (isActive) {
      window.addEventListener('click', onClick);
    }

    return () => {
      window.removeEventListener('click', onClick);
    };
  }, [isActive, el]);

  return [isActive, setIsActive];
};
