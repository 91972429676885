import './DateInput.css';
import React, { useRef } from 'react';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { localized } from '../../config/localization';

type Props = {
  readonly name: string;
  readonly label: string;
  readonly placeholder?: string;
  readonly value?: Date;
  readonly minDays?: number;
  readonly maxDays?: number;
  readonly workDaysOnly?: boolean;
  readonly readonly?: boolean;
  readonly disabled?: boolean;
  readonly onChange?: (value: Date | null) => void;
};

export function DateInput({
  name,
  label,
  placeholder,
  value,
  minDays,
  maxDays,
  workDaysOnly = false,
  readonly = false,
  disabled = false,
  onChange = () => ({}),
}: Props): JSX.Element {
  return (
    <div className="date-input">
      <label htmlFor={name}>{label}</label>
      {readonly
        ? Readable(name, value)
        : Writeable({
            name,
            value,
            placeholder,
            minDays,
            maxDays,
            workDaysOnly,
            disabled,
            onChange,
          })}
    </div>
  );
}

function Readable(name: string, value?: Date) {
  return (
    <input
      id={name}
      name={name}
      readOnly={true}
      disabled={true}
      className="date-input__input"
      value={value ? format(value, 'dd.MM.yyy') : ''}></input>
  );
}

type WritableProps = {
  readonly name: string;
  readonly value?: Date;
  readonly placeholder?: string;
  readonly minDays?: number;
  readonly maxDays?: number;
  readonly workDaysOnly?: boolean;
  readonly disabled?: boolean;
  readonly onChange: (value: Date | null) => void;
};

const dateFormat = 'yyyy-MM-dd';

function Writeable({
  name,
  value,
  placeholder,
  minDays,
  maxDays,
  workDaysOnly = false,
  disabled = false,
  onChange,
}: WritableProps) {
  const inputRef = useRef<(HTMLInputElement & { showPicker: () => void }) | null>(null);

  const minDate = minDays !== undefined ? format(addDays(new Date(), minDays), dateFormat) : '';
  const maxDate = maxDays !== undefined ? format(addDays(new Date(), maxDays), dateFormat) : '';

  const handleChange = (dateString: string) => {
    if (dateString === '') {
      onChange(null);
    } else {
      const date = parse(`${dateString} 12:00`, `${dateFormat} HH:mm`, new Date());
      date.setHours(12);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);

      if (workDaysOnly && isWeekend(date)) {
        alert(localized.DATE_INPUT_INVALID_WORKDAY);
      } else {
        onChange(date);
      }
    }
  };

  return (
    <>
      <input
        readOnly
        id={name}
        name={name}
        className="date-input__input date-input__input--picker"
        placeholder={placeholder}
        value={value ? format(value, 'dd.MM.yyyy') : ''}
        disabled={disabled}
        onClick={() => inputRef?.current?.showPicker()}
      />
      <input
        ref={inputRef}
        type="date"
        className="date-input__input--hidden"
        value={value ? format(value, dateFormat) : ''}
        min={minDate}
        max={maxDate}
        disabled={disabled}
        onKeyDown={(event) => event.preventDefault()}
        onChange={(event) => handleChange(event.target.value)}
      />
    </>
  );
}

function isWeekend(date: Date) {
  return [0, 6].includes(date.getUTCDay());
}
