import './AdminUsersPage.css';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from '../../../services/useSearchParams';
import { useHistory, useParams } from 'react-router';
import { localized } from '../../../config/localization';
import { UserView } from './UserView';
import { Page } from '../../../components/Page/Page';
import { getUsers } from '../../../services/api/blechconApi';
import { toast } from 'react-toastify';
import {
  BlechconUser,
  BlechconUserGroups,
  BlechconUserGroupType,
  BlechconUserInfo,
  LastEvaluatedKey,
} from '../../../blechcon';
import { Routes } from '../../../app/Routes';
import { CenteredRing } from '../../../components/CenteredRing/CenteredRing';
import { Button } from '../../../components/Button/Button';
import { FilterTiles } from '../../../components/FilterTiles/FilterTiles';

type Params = {
  status: BlechconUserGroupType;
};

export function AdminUsersPage(): JSX.Element {
  const { status = 'newuser' } = useParams<Params>();
  const { search = '' } = useSearchParams();

  return <AdminUsersPageView key={status} status={status} search={search} />;
}

type Props = {
  readonly status: BlechconUserGroupType;
  readonly search: string;
};

export function AdminUsersPageView({ status, search }: Props): JSX.Element {
  const history = useHistory();

  const [state, setState] = useState<{
    isLoading: boolean;
    users: Array<BlechconUser & BlechconUserInfo>;
    nextToken: string | undefined;
  }>({
    isLoading: false,
    users: [],
    nextToken: undefined,
  });

  const fetchUsers = useCallback(
    (nextToken: LastEvaluatedKey = undefined) => {
      setState((current) => ({
        ...current,
        isLoading: true,
      }));

      if (nextToken === undefined) {
        return;
      }

      getUsers(status, nextToken)
        .then((result) => {
          setState((current) => ({
            ...current,
            users: [...current.users, ...result.Items],
            nextToken: result.NextToken,
          }));
        })
        .catch((error) => {
          console.error(error);
          toast.error(String(error));
        })
        .finally(() => {
          setState((current) => ({
            ...current,
            isLoading: false,
          }));
        });
    },
    [status]
  );

  useEffect(() => {
    fetchUsers(null);
  }, [fetchUsers]);

  return (
    <Page title={localized.REQUESTS}>
      <FilterTiles
        tiles={BlechconUserGroups.map((it) => ({ label: localized.getString(`${it.toUpperCase()}_USERS`), value: it }))}
        activeTile={status}
        onClick={(it) => {
          history.push({
            pathname: Routes.adminUsers(it),
            search: search && `?search=${search}`,
          });
        }}
      />

      <table className="admin_users_page__table">
        <thead>
          <tr>
            <th>{localized.EMAIL}</th>
            <th>{localized.CUSTOMER_ID}</th>
            <th>{localized.USTID}</th>
            <th>{localized.ADDRESS}</th>
            <th>{localized.VERIFICATION_STATUS}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {state.users.map((request: BlechconUser & BlechconUserInfo) => {
            return (
              <UserView
                key={request.userId}
                request={request}
                fetchUsers={() => {
                  setState((current) => ({
                    ...current,
                    users: [],
                  }));
                  fetchUsers(null);
                }}
              />
            );
          })}
        </tbody>
      </table>

      {state.isLoading && <CenteredRing />}

      {!state.isLoading && state.nextToken ? (
        <div className="admin-users__load-more-button">
          <Button onClick={() => fetchUsers(state.nextToken)}>{localized.LOAD_MORE}</Button>
        </div>
      ) : null}
    </Page>
  );
}
