import React, { useCallback, useReducer, useState } from 'react';
import { Routes } from '../../app/Routes';
import { useHistory } from 'react-router-dom';
import { localized } from '../../config/localization';
import { EMAIL_PATTERN, PASSWORD_PATTERN } from '../../utils';
import { Button } from '../../components/Button/Button';
import { Input } from '../../components/Input/Input';
import { formReducer } from '../../components/form/formReducer';
import { toast } from 'react-toastify';
import { BlankPage } from '../../components/BlankPage/BlankPage';
import { signUp } from '../../services/Auth';

export function SignUpPage(): JSX.Element {
  const history = useHistory();

  const [state, dispatch] = useReducer(formReducer, {
    values: {
      email: '',
      password: '',
      confirmPassword: '',
      ustid: '',
    },
    errors: {
      email: null,
      password: null,
      confirmPassword: null,
      ustid: null,
    },
    valid: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setIsSubmitting(true);
      const userCredentials = { username: state.values.email, password: state.values.password };

      signUp(userCredentials.username, userCredentials.password, state.values.ustid)
        .then(() => history.push(Routes.EMAIL_CONFIRMATION, userCredentials))
        .catch((error) => {
          if (error.message === localized.EMAIL_EXISTS_ERROR) {
            dispatch({ errors: { email: localized.EMAIL_EXISTS_ERROR } });
          } else {
            toast.error(String(error));
          }
        })
        .finally(() => setIsSubmitting(false));
    },
    [history, state.values.email, state.values.password, state.values.ustid]
  );

  return (
    <BlankPage title={localized.SIGN_UP_HEADER} showGoBack>
      <form onSubmit={handleSubmit}>
        <Input
          autoFocus
          name="email"
          type="text"
          autoComplete="email"
          placeholder={localized.EMAIL}
          errors={state.errors}
          values={state.values}
          onChange={(event) => {
            const value = event.target.value.toLocaleLowerCase().trim();
            dispatch({
              values: { email: value },
              errors: { email: !EMAIL_PATTERN.test(value) ? localized.LOGIN_EMAIL_INVALID : null },
            });
          }}
          dataCy="signUpEmailField"
        />
        <Input
          name="ustid"
          type="text"
          autoComplete="off"
          placeholder={localized.USTID}
          errors={state.errors}
          values={state.values}
          onChange={(event) => {
            const value = event.target.value.trim();
            dispatch({
              values: { ustid: value },
            });
          }}
          dataCy="signUpUstidField"
        />
        <Input
          name="password"
          type="password"
          autoComplete="new-password"
          placeholder={localized.PASSWORD}
          errors={state.errors}
          values={state.values}
          onChange={(event) => {
            const value = event.target.value.trim();
            dispatch({
              values: { password: value },
              errors: {
                password: value.length < 12 && !PASSWORD_PATTERN.test(value) ? localized.LOGIN_PASSWORD_RULE : null,
                confirmPassword:
                  value.length === 0 || state.values.confirmPassword !== value ? localized.SIGN_UP_PW_MISMATCH : null,
              },
            });
          }}
          dataCy="signUpPasswordField"
        />
        <Input
          name="confirmPassword"
          type="password"
          autoComplete="new-password"
          placeholder={localized.REPEAT_PASSWORD}
          errors={state.errors}
          values={state.values}
          onChange={(event) => {
            const value = event.target.value.trim();
            dispatch({
              values: { confirmPassword: value },
              errors: {
                confirmPassword:
                  value.length === 0 || state.values.password !== value ? localized.SIGN_UP_PW_MISMATCH : null,
              },
            });
          }}
          dataCy="signUpConfirmPasswordField"
        />

        <BlankPage.Buttons>
          <Button type="submit" disabled={!state.valid || isSubmitting} dataCy="signUpSubmitButton">
            {localized.SIGN_UP}
          </Button>
        </BlankPage.Buttons>
      </form>
    </BlankPage>
  );
}
