import './AddressTypeSelection.css';

import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { Button } from '../../../components/Button/Button';
import { localized } from '../../../config/localization';
import { BlechconAddress } from '../../../blechcon';
import { AddressForm } from '../../../components/AddressForm';
import { CheckMark } from '../../../assets/svg/CheckMark';
import { Colors } from '../../../config/colors';
import { Modal } from '../../../components/Modal';

const initialAddress: BlechconAddress = {
  id: '',
  label: '',
  addressLine: '',
  addressLineTwo: '',
  street: '',
  city: '',
  state: '',
  country: '',
  zip: '',
  distance: 0,
  useForShipping: false,
};

type Props = {
  addresses: BlechconAddress[];
  onChange: (addresses: BlechconAddress[]) => void;
};

export function AddressTypeSelection({ addresses, onChange }: Props): JSX.Element {
  const [selectedAddress, setSelectedAddress] = useState<BlechconAddress | null>(null);
  const [showRemoveAddressModal, setShowRemoveAddressModal] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [addressValues, setAddressValues] = useState<BlechconAddress[]>(clone(addresses));

  useEffect(() => {
    setAddressValues(clone(addresses));
  }, [addresses]);

  const onChangeAddress = (address: BlechconAddress) => {
    setSelectedAddress(null);
    setShowSuccessMessage(true);
    onChange(addressValues.map((it) => (it.id === address.id ? address : it)));
  };

  const onCancel = () => {
    setShowSuccessMessage(false);
    setSelectedAddress(null);
    setAddressValues(addresses);
  };

  return (
    <>
      {showRemoveAddressModal && (
        <Modal
          title={localized.REMOVE_ADDRESS}
          onCancel={{
            label: localized.ABORT,
            execute: () => {
              setShowRemoveAddressModal(false);
            },
          }}
          onSuccess={{
            label: localized.REMOVE,
            execute: () => {
              setSelectedAddress(null);
              setShowSuccessMessage(true);
              setShowRemoveAddressModal(false);
              onChange(addressValues.filter((it) => it.id !== selectedAddress?.id));
            },
          }}>
          <p className="remove_address_modal-text">{localized.REMOVE_ADDRESS_CONFIRMATION}</p>
        </Modal>
      )}
      <div className="address_type_selection-address_selection_menu">
        {addressValues?.map((address: BlechconAddress) => (
          <div
            key={address.id}
            onClick={() => {
              if (!addresses.find((it) => it.id === selectedAddress?.id)) {
                setAddressValues((current) => current.filter((it) => it.id !== selectedAddress?.id));
              }
              setSelectedAddress(address);
            }}
            className={`address_type_selection-address_selection_menu_item ${
              address.id === selectedAddress?.id ? 'address_type_selection-address_selection_menu_item-selected' : ''
            }`}>
            <p>{!address.label ? 'Address name' : address.label}</p>
          </div>
        ))}
        <div className="address_type_selection-address_selection_menu_item-add_button-container">
          {!selectedAddress && (
            <Button
              onClick={() => {
                const newAddress = {
                  ...initialAddress,
                  id: v4(),
                };
                setAddressValues([...addressValues, newAddress]);
                setSelectedAddress(newAddress);
              }}
              dataCy="btn-add-new-address"
              secondary>
              {'+ ' + localized.ADD_ADDRESS}
            </Button>
          )}
        </div>
      </div>
      <div className="address_type_selection-address_form_container">
        {selectedAddress ? (
          <AddressForm
            showUseForShippingCheckbox={false}
            key={selectedAddress.id}
            addressData={selectedAddress}
            onChangeAddress={onChangeAddress}
            onRemoveAddress={() => setShowRemoveAddressModal(true)}
            onCancel={onCancel}
          />
        ) : (
          showSuccessMessage && (
            <div className="address_type_selection-address_form-success_message_container" data-cy="success-message">
              <CheckMark width={64} height={64} fill={Colors.green} />
              <p>{localized.ADD_ADDRESS_SUCCESS}</p>
            </div>
          )
        )}
      </div>
    </>
  );
}

function clone(addresses: Array<BlechconAddress>): Array<BlechconAddress> {
  return addresses.map((it) => ({ ...it }));
}
