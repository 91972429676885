import LocalizedStrings from 'react-localization';
import { BlechconTranslations } from '../blechcon';

const translations: BlechconTranslations = {
  en: {
    ACTIVE_USERS: 'Active Users',
    ACCEPT_TERMS: 'I have read and accept the {0}',
    ADD_ADDRESS: 'Add new address',
    ADD_ADDRESS_SUCCESS: 'Operation completed successfully',
    ADD_FILES: 'Add files to order',
    ADD_MATERIAL: 'Add new material',
    ADDRESS: 'Address',
    ADDRESS_LABEL: 'Short descriptor for the Calculator (e.g. headquarters)',
    ADDRESS_LINE1: 'Company name',
    ADDRESS_LINE2: 'Contact person',
    ADDRESS_STREET: 'Street and house number',
    ADDRESS_CITY: 'City',
    ZIP: 'ZIP',
    ADDRESS_COUNTRY: 'Country',
    ADMIN_ORDERS: 'Manage orders',
    AND: 'and',
    APPLY_PARAMETERS: 'Apply to all parts',
    APPLY_PARAMETERS_LABEL: 'Carry over parameters',
    APPLY_ON_ALL_PARAMETERS_LABEL: 'Carry over parameters to all parts',
    APPROVAL_STATUS: 'Status',
    APPROVE: 'Approve account',
    ASSEMBLIES: 'Overview of assemblies',
    BACK: 'Go back',
    BACK_TO_CALCULATION: 'Back to offer',
    BACK_TO_ORDERS: 'Back to order list',
    BACK_TO_PARAMETERS: 'Back to parameters',
    BACK_TO_PARAMETERS_TEXT: 'Changing to the previous step will delete the displayed calculation results.',
    BENDING: 'Bending',
    BLECHCON_MATERIAL_NAME: 'Blechcon material',
    CALCULATOR: 'Calculator',
    CALCULATOR_V2: 'Calculator',
    CANCEL: 'Cancel',
    CANCELED: 'Canceled',
    CLOSE: 'Close',
    ABORT: 'Abort',
    CONFIRM: 'Confirm',
    CONFIRMED: 'Confirmed',
    CONFIRM_OFFER: 'Confirm',
    CONFIRM_ORDER: 'Order',
    PRINT_ORDER: 'Print',
    CONFIRM_ORDER_CONFIRMATION: 'Do you want to confirm this order?',
    CANCEL_ORDER_CONFIRMATION: 'Do you want to cancel this order?',
    CONFIRMED_ORDERS: 'Confirmed Orders',
    CONTINUE: 'Continue',
    CONTINUE_TO_OFFER: 'Continue to Offer',
    CREATE_NEW_ORDER: 'Create new order',
    CUSTOMER: 'Customer',
    CUSTOMER_ID: 'Customer ID',
    CUSTOM_MATERIALS: 'Material list (CSV parameter import)',
    CUSTOM_MATERIAL_NAME: 'Customer material',
    CUSTOM_MATERIAL_THICKNESS: 'Customer material thickness',
    DATE_CREATED: 'Creation date',
    DATE_ORDERED: 'Order date',
    DEACTIVATE: 'Deactivate account',
    DEACTIVATE_USER: 'Deactivate user',
    DEACTIVATE_USER_CONFIRMATION: 'Are you sure to deactivate {0}',
    ADMIN_GROUP_USER_CONFIRMATION: 'Are you sure to change admin group for {0}',
    ADMIN_RESET_PASSWORD_CONFIRMATION: 'Are you sure to reset password for {0}',
    ADMIN_RESET_PASSWORD_TEMPORARY: 'The temporary password is {0}',
    EDGES_ROUNDED: 'Edges rounded',
    DELIVERY_DATE: 'Scheduled delivery date',
    DELIVERY_DATE_DESIRED: 'Desired delivery date',
    PICKUP_DATE_DESIRED: 'Desired pickup date',
    DELIVERY_DATE_ESTIMATED: 'Estimated delivery date',
    PICKUP_DATE_ESTIMATED: 'Estimated pickup date',
    DIMENSIONS: 'Format (length x width)',
    DOCUMENTS: 'Documents',
    DOWNLOAD: 'Download customer document',
    DOWNLOAD_FILES: 'Download files',
    DOWNLOAD_NESTING: 'Download nesting',
    DOWNLOAD_CSV: 'Download csv',
    CSV: 'CSV parameters import',
    DRAFT: 'Draft',
    EDIT: 'Edit',
    EDIT_MATERIAL: 'Edit material',
    EMAIL: 'E-Mail',
    ENTER_CUSTOMER_ID: 'Enter customer ID for {0} to confirm their request',
    FILE: 'File',
    FILE_NAME: 'Filename',
    FORGOT_PASSWORD: 'Forgot password?',
    FORMAT: 'Format',
    IMPRINT: 'Imprint',
    SUBMITTED: 'Requested',
    INVALID_NUMBER: 'Please enter a valid number.',
    INVOICE_ADDRESS: 'Invoice address',
    INVOICE_ADDRESS_NEEDED: 'Before uploading a file make sure you have entered at least an invoice address.',
    INVOICE_ADDRESS_NEEDED_EXPLANATION: 'You can fill in the details by clicking on ',
    INVOICE_ADDRESS_SELECT: 'Select invoice address...',
    INVOICE_AND_SHIPPING_ADDRESS: 'Invoice and shipping address',
    LENGTH: 'Length',
    LOAD_MORE: 'Load more...',
    LOGIN_EMAIL_INVALID: 'The given e-mail address is invalid.',
    LOGIN_EMAIL_MISSING: 'Please enter an e-mail address.',
    LOGIN_PASSWORD_MISSING: 'Please enter a password.',
    EMAIL_CONFIRMATION_ENTER_PASSCODE: 'Enter a passcode from your email.',
    PASSCODE: 'Passcode',
    EMAIL_CONFIRMATION_SEND_VERIFICATION: 'Send passcode to email.',
    EMAIL_CONFIRMATION_BUTTON_SEND_CODE: 'Send code',
    LOGIN_PASSWORD_RULE: 'The Password must be at least 12 characters long, contain lowercase, uppercase and number',
    LOGIN_INCORRECT_CREDENTIALS: 'Incorrect combination of email and password',
    LOGIN_WELCOME: 'Get started by signing in to your account.',
    MATERIAL: 'Material',
    MATERIAL_COST: 'Material cost',
    MATERIAL_DROPDOWN: 'Choose material...',
    DROPDOWN_SELECTION: 'Choose ...',
    MATERIAL_LOADING: 'Material list loading...',
    MATERIAL_THICKNESS_UNAVAILABLE: 'Thickness unavailable for this material type',
    MATERIAL_TYPE: 'Material type',
    MATERIAL_TYPE_DROPDOWN: 'Select material type...',
    MATERIAL_TYPE_LOADING: 'Material types loading...',
    NO: 'No',
    NO_IMAGE: 'No image available',
    NOT_VERIFIED: 'Not verified',
    OFFER: 'Offer',
    OVERVIEW: 'Overview',
    OFFER_ID: 'Offer Id',
    OR: 'or',
    ORDER: 'Order',
    ORDER_ID: 'Order Id',
    ORDER_ADD_FILES: 'Add files',
    ORDER_CHOOSE_FILE: 'Choose file',
    ORDER_CONFIRMATION: 'Order Confirmation',
    ORDER_DRAG_FILE: 'Drag CAD file(s) here (max. 25MB)',
    ORDER_DROP_FILE: 'Drop file(s) here...',
    ORDER_PLACED1: 'Your order has been placed.',
    ORDER_PLACED2: 'Please visit the',
    ORDER_PLACED3: 'section to check the',
    ORDER_PLACED4: 'order progress and its details.',
    ORDER_SUMMARY: 'Summary',
    PAGE_NOT_FOUND: 'Page not found',
    ORDER_NOT_FOUND: 'Order not found',
    PAGE_NOT_FOUND_DETAILS: 'Oops, the page you were looking for was not found.',
    PARAMETERS: 'Parameters',
    PARTS: 'Required parts',
    PASSWORD: 'Password',
    PASSWORD_RESET: 'Reset password',
    PROCESSING_FILES: 'Processing files...',
    PRODUCT: 'Product',
    PRIVACY_POLICY: 'Privacy policy',
    QUANTITY: 'Quantity',
    RECENT_ORDERS: 'My orders',
    REJECT: 'Reject',
    REJECT_ORDER: 'Reject order',
    REJECT_ORDER_CONFIRMATION: 'Are you sure to reject this order?',
    REJECTED: 'Not accepted',
    REMOVE: 'Remove',
    REMOVE_ADDRESS: 'Remove address',
    REMOVE_ADDRESS_CONFIRMATION: 'Are you sure to remove this address?',
    REMOVE_FILE: 'Remove file or part',
    REMOVE_FILE_CONFIRMATION: 'Are you sure to remove this file or part from the list?',
    REMOVE_MATERIAL: 'Remove material',
    REMOVE_OFFER: 'Remove offer',
    REMOVE_OFFER_CONFIRMATION: 'Are you sure to remove this offer?',
    REMOVE_MATERIAL_CONFIRMATION: 'Are you sure to remove this material?',
    WITHDRAW_REQUEST: 'Withdraw request',
    WITHDRAW_REQUEST_INFORMATION: 'The cancellation request will be processed by our team.',
    WITHDRAW: 'Withdraw requested',
    WITHDRAW_REQUEST_CONFIRMATION: 'Are you sure you want to cancel the order?',
    REMOVE_PART: 'Remove part',
    REMOVE_PART_CONFIRMATION: 'Are you sure to remove this part from the list?',
    REPEAT_PASSWORD: 'Repeat password',
    RETRY: 'Retry',
    REQUESTS: 'User requests',
    REQUEST_APPROVAL: 'Registration status',
    REQUESTED_ORDERS: 'Order requests',
    ROTATION: 'Orientation',
    ROTATION_ANGLE: 'Angle (0° - 360°)',
    ROTATION_DROPDOWN: 'Select orientation...',
    SAVE: 'Save',
    SEARCH: 'Search',
    SETTINGS: 'Settings',
    SHIPPING_ADDRESS: 'Shipping address',
    SHIPPING_ADDRESS_SELECT: 'Select address for shipping...',
    SHIPPING_ADDRESS_USE: 'Use for shipping',
    SHIPPING_COST: 'Shipping cost',
    SHIPPING_COST_CUSTOM: 'Custom shipping cost',
    SHIPPING_COST_MANUALLY: 'The shipping costs for this offer need to be calculated manually.',
    SIGN_IN: 'Sign in',
    SIGN_OUT: 'Sign out',
    GO_BACK: 'Go back',
    SIGN_UP: 'Create account',
    SIGN_UP_BACK: 'Back to Sign in',
    SIGN_UP_HEADER: 'New to the BlechBoard? Sign up here.',
    SIGN_UP_PW_MISMATCH: 'The passwords do not match.',
    SIGN_UP_PW_MISSING: 'The password confirmation is missing.',
    SIGN_UP_USTID_MISSING: 'The VAT ID is missing.',
    STATUS: 'Status',
    TERMS_AND_CONDITIONS: 'Terms and Conditions',
    THANK_YOU: 'Thank you!',
    THICKNESS: 'Thickness',
    THICKNESS_DROPDOWN: 'Select thickness...',
    THICKNESS_LOADING: 'Loading thicknesses...',
    TOTAL: 'Total',
    UNACTIVATED_USERS: 'Inactive Users',
    NEWUSER_USERS: 'New Users',
    ADMIN_USERS: 'Administrators',
    UNIT_PRICE: 'Unit price',
    UPLOAD: 'Upload',
    UPLOAD_BACK: 'Back to upload',
    UPLOAD_RUNNING: 'Uploading files...',
    USER_MANAGEMENT: 'Manage Users',
    CUSTOMER_MANAGEMENT: 'Manage Customers',
    ADMIN_STATUS: 'Admin status',
    REMOVE_GROUP_ADMIN: 'Remove admin rights',
    ADD_GROUP_ADMIN: 'Add admin rights',
    USTID: 'VAT ID',
    VERIFICATION_CODE: 'The verification code consists of 6 digits.',
    VERIFICATION_RESEND: 'Resend passcode',
    VERIFICATION_STATUS: 'Verification status',
    VERIFIED: 'Verified',
    WAIT_FOR_CONFIRMATION: 'Please wait until Blechcon approves your account.',
    WEIGHT_NETTO: 'Net weight',
    WIDTH: 'Width',
    YES: 'Yes',
    TO_SETTINGS: 'Go to Settings',
    DURATION_WARNING: 'This might take some time...',
    PICKUP_YOURSELF: 'Pick up yourself',
    PICKUP_MESSAGE: 'Scheduled for pick-up',
    CUSTOMER_REFERENCE: 'Customer reference',
    PAYLOAD_TOO_LARGE: 'Upload of file {0} failed due to too large payload',
    ERROR_CSV: 'CSV process error',
    INVALID_BLECHCON_NUMBER_FORMAT: 'Only numeric values allowed',
    INVALID_BLECHCON_BOOLEAN_FORMAT: 'Only true/false values allowed',
    INVALID_BLECHCON_FILE_NAME: 'Error in filename',
    INVALID_BLECHCON_FILE_NAME_NOT_FOUND: 'File not found',
    INVALID_BLECHCON_MATERIAL: 'No matching material found',
    INVALID_BLECHCON_MATERIAL_THICKNESS: 'No matching material thickness found',
    INVALID_BLECHCON_DOCUMENT: 'Invalid document',
    INVALID_BLECHCON_DOCUMENT_FORMAT: 'Invalid document format',
    INVALID_STATUS_NOT_DRAFT: 'Order not in status draft',
    ERROR_UPLOADING_FILE: 'Error while uploading a file',
    ERROR_UPLOADING_FILE_OTHER: 'An error occurred. Please contact us to resolve this issue.',
    ERRORS: 'Errors',
    STEP: 'Step',
    VIEW_2D: '2D View',
    VIEW_3D: '3D View',
    INVALID_PART: 'invalid part',
    PERSONAL: 'Personal',
    OTHERS: 'Others',
    BENDS: 'Bends',
    CUT_LENGTH: 'Cut length',
    CUTS: 'Cuts',
    TIME: 'Laser time',
    AVERAGE_LASER_TIME: 'Average laser time',
    AREA: 'Area (brutto)',
    AREA_NETTO: 'Area (netto)',
    BEND_TIME: 'Bend time',
    PRICE_FOR_BENDING: 'Price for bending',
    PRICE_FOR_LASER_CUTS: 'Price for laser cuts',
    SETUP_COST_BENDING: 'Setup cost for bending',
    SETUP_COST_LASER: 'Setup cost for laser',
    PRICE_EXPIRED: 'The price is no longer valid. Please calculate again.',
    MIN_VALUE_NOT_REACHED: 'Minimum material value of {0} not reached',
    EMAIL_EXISTS_ERROR: 'An account with the given email already exists.',
    USER_NOT_FOUND: 'An account with the given email does not exist',
    CODE_MISMATCH: 'Invalid verification code provided',
    LIMIT_EXCEEDED_EXCEPTION: 'Attempt limit exceeded, please try after some time',
    OK: 'ok',
    CANCELED_ORDERS: 'Canceled',
    CANCEL_ORDER: 'Cancel order',
    DESIRED_DATE: 'Desired date',
    MATERIALS_LOADING: '',
    ROTATIONS_LOADING: '',
    ADD_PDF: 'Add a PDF',
    WICAM_JOB_FILE_ID: 'Wicam Job File ID',
    TOOLTIP_BENDING: 'This option defines that your part will be bent with our bending machines.',
    BENDING_FAQ_URL: 'https://blechcon.de/de/technologien/abkanten.html',
    TOOLTIP_EDGES_ROUNDED:
      'If you select this option, we will deburr and round the edges of your component on both sides.',
    EDGES_ROUNDED_FAQ_URL: 'https://blechcon.de/de/technologien/entgraten.html',
    TOOLTIP_ADDITIONAL_FILE:
      'With the help of a PDF file you can provide us with further information such as tolerances, measuring distances, etc.',
    TOOLTIP_ROTATION_SELECT:
      'Option "Free" causes the part to be placed freely on the board. Option "Set rolling direction" gives the possibility to adjust the rolling direction of the object. For ground/brushed material: rolling direction = grinding direction.',
    TOOLTIP_ROTATION_ANGLE_INPUT:
      'The angle indicates the angle at which the part is positioned on the sheet. For ground/brushed material: rolling direction = grinding direction.',
    TOOLTIP_REMOVE_PART: 'Remove this part from your assembly.',
    FURTHER_INFORMATION: 'For further information, please click here.',
    MATERIAL_TYPE_1: 'Steel',
    MATERIAL_TYPE_2: 'Stainless Steel',
    MATERIAL_TYPE_3: 'Aluminum',
    ROTATION_TYPE_1: '90°',
    ROTATION_TYPE_2: '180°',
    ROTATION_TYPE_3: 'Free',
    ROTATION_TYPE_4: 'None',
    ROTATION_TYPE_FIXED: 'Set rolling direction',
    ROTATION_TYPE_FREE: 'Free',
    NESTING_OPTION: 'Orientation',
    ADDRESS_NOT_VALID: 'Address is not valid, cannot calculate a distance, please try different one.',
    GENERAL_ERROR: 'An error occurred. Please contact your support.',
    USER_INFO_LOAD_ERROR: 'Could not load account details.',
    CURRENT_TIME_LOAD_ERROR: 'Could not load current time.',
    CONFIGURATION_LOAD_ERROR: 'Could not load configuration.',
    FILE_SIZE_TOO_LARGE_ERROR: 'File size too large (max. 25 MB)',
    MUST_CONTAIN_AT_LEAST_CHARACTERS: 'Must contain at least {0} characters.',
    DATE_INPUT_INVALID_WORKDAY: 'Only working days are allowed',
    WELCOME: 'Welcome',
    HOW_DID_YOU_FIND_US: 'How did you find us?',
    ALREADY_A_CUSTOMER: 'Already a customer',
    REFERRAL: 'Referral',
    GOOGLE: 'Google',
    WHO_PROVIDES_WHAT: 'Who provides what',
    OTHER: 'Sonstiges',
    OFFER_VALID_UNTIL: 'Offer valid until',
    OFFER_FROM: 'Offer {0} from {1}',
    PRINT_OFFER_PAGE_THANK_YOU: 'We thank you for your inquiry and submit you the following offer without engagement',
    DATE_FORMAT: 'yyyy-MM-dd',
    PRINT_PAGE_LEGAL:
      "Lasercutting at BlechCon is according to ISO9013 - accuracy class 1 for thermal cutting. Parts are not deburred and not straightened. Bending is according to ISO2768-m, everything else requires written agreement from our site. All specifications and regulations included in the customer's inquiry are not binding, as far as BlechCon has not confirmed or explicitly commented back to the customer. Prices are valid only in case of complete order of the parts and quantity offered. Only our general terms and conditions apply exclusively, to be find here: www.blechcon.de/de/agb.html.",
    CUSTOMER_MATERIAL_SORT_BY_CSV: 'Sort files by CSV order',
    DISCOUNT: 'Discount',
    ADDITIONAL_DELIVERY_DAYS: 'Additional delivery days',
    MINIMUM_ORDER_VALUE_IN_CENT: 'Minimum order value in cent',
    BENDING_COSTS_PER_HOUR: 'Bending costs per hour',
    HAS_CSV_PARAMETER_IMPORT_RIGHT: 'CSV Parameter import',
    REMOVE_CSV_PARAMETER_IMPORT_RIGHT: 'Remove CSV parameter import',
    ADD_CSV_PARAMETER_IMPORT_RIGHT: 'Add CSV parameter import',
    OPTIMATE_ACTIVE: 'Optimate active',
    REMOVE_OPTIMATE_ACTIVE: 'Remove Optimate active',
    ADD_OPTIMATE_ACTIVE: 'Add Optimate active',
    SHOW_3D_VIEW: 'Show 3D view',
    OPTIMIZATION_BUTTON: 'Optimization',
    PART_VIEWER_LEGEND_LMT_TO: 'left click to',
    PART_VIEWER_LEGEND_RMT_TO: 'right click to',
    PART_VIEWER_LEGEND_SCROLL_TO: 'scroll to',
    PART_VIEWER_LEGEND_ROTATE: 'rotate',
    PART_VIEWER_LEGEND_PAN: 'pan',
    PART_VIEWER_LEGEND_ZOOM: 'zoom',
    COULD_NOT_LOAD_3D_VIEW: 'Could not load 3D view',
    ANALYSIS_NO_ISSUES: 'No problem detected',
    BEND_ANGLE: 'Bend angle',
    REQUIRED_DISTANCE: 'Required distance',
    CUTOUT_DISTANCE: 'Cutout distance',
    INNER_BEND_RADIUS: 'Inner bend radius',
    MINIMUM_INNER_BEND_RADIUS: 'Min. inner bend radius',
    CUTOUT_DIAMETER: 'Cutout diameter',
    MINIMUM_CUTOUT_DIAMETER: 'Min. cutout diameter',
    L_MIN: 'L Min',
    FLANGE_LENGTH: 'Flange length',
    BEND_LENGTH: 'Bend length',
    MAX_BEND_LENGTH: 'Max. bend length',
    BEND_PRESSURE: 'Bend pressure',
    MAX_BEND_PRESSURE: 'Max. bend pressure',
    DIFFERENCE: 'Difference',
    UNIT_CONVERTED: 'Unit was converted to mm.',
  },
  de: {
    ACTIVE_USERS: 'Aktive Benutzer',
    ACCEPT_TERMS: 'Ich habe die {0} gelesen und akzeptiere diese.',
    ADD_ADDRESS: 'Neue Adresse hinzufügen',
    ADD_ADDRESS_SUCCESS: 'Änderung wurde erfolgreich ausgeführt.',
    ADD_FILES: 'Dateien hinzufügen',
    ADD_MATERIAL: 'Neues Material hinzufügen',
    ADDRESS: 'Adresse',
    ADDRESS_LABEL: 'Kurztitel für das BlechBoard (z.B. Hauptsitz)',
    ADDRESS_LINE1: 'Firmenname',
    ADDRESS_LINE2: 'Kontaktperson',
    ADDRESS_STREET: 'Straße und Hausnummer',
    ADDRESS_CITY: 'Stadt',
    ASSEMBLIES: 'Liste der Baugruppen',
    ZIP: 'PLZ',
    ADDRESS_COUNTRY: 'Land',
    ADMIN_ORDERS: 'Auftragsverwaltung',
    AND: 'und',
    APPLY_PARAMETERS: 'Auf alle anwenden',
    APPLY_PARAMETERS_LABEL: 'Parameter übernehmen',
    APPLY_ON_ALL_PARAMETERS_LABEL: 'Parameter auf alle anwenden',
    APPROVAL_STATUS: 'Status',
    APPROVE: 'Konto freischalten',
    BACK: 'Zurück',
    BACK_TO_CALCULATION: 'Zurück zum Angebot',
    BACK_TO_ORDERS: 'Zurück zur Auftragsübersicht',
    BACK_TO_PARAMETERS: 'Zurück zu Parametern',
    BACK_TO_PARAMETERS_TEXT: 'Wenn Sie jetzt zurückspringen, werden die angezeigten Berechnungen gelöscht.',
    BENDING: 'Abkanten',
    BLECHCON_MATERIAL_NAME: 'Blechcon Material',
    CALCULATOR: 'Kalkulator',
    CALCULATOR_V2: 'Kalkulator',
    CANCEL: 'Stornieren',
    CANCELED: 'Storniert',
    CLOSE: 'Schließen',
    ABORT: 'Abbrechen',
    CONFIRM: 'Bestätigen',
    CONFIRMED: 'Bestätigt',
    CONFIRM_OFFER: 'Bestätigen',
    CONFIRM_ORDER: 'Bestellen',
    PRINT_ORDER: 'Angebot drucken',
    CONFIRM_ORDER_CONFIRMATION: 'Sind Sie sicher, dass der Auftrag angenommen werden soll?',
    CANCEL_ORDER_CONFIRMATION: 'Sind Sie sicher, dass der Auftrag storniert werden soll?',
    CONFIRMED_ORDERS: 'Bestätigte Aufträge',
    CONTINUE: 'Weiter',
    CONTINUE_TO_OFFER: 'Angebot erstellen',
    CREATE_NEW_ORDER: 'Neuen Auftrag erstellen',
    CUSTOMER: 'Kunde',
    CUSTOMER_ID: 'Kundennummer',
    CUSTOM_MATERIALS: 'Material-Liste (CSV-Parameter-Import)',
    CUSTOM_MATERIAL_NAME: 'Kundenmaterial',
    CUSTOM_MATERIAL_THICKNESS: 'Kundenmaterialdicke',
    DATE_CREATED: 'Erstellt am',
    DATE_ORDERED: 'Bestellt am',
    DEACTIVATE: 'Konto deaktivieren',
    DEACTIVATE_USER: 'Benutzer deaktivieren',
    DEACTIVATE_USER_CONFIRMATION: 'Sind Sie sicher, dass {0} deaktiviert werden soll?',
    ADMIN_GROUP_USER_CONFIRMATION: 'Sind Sie sicher, dass die Adminzugehörigkeit für {0} geändert werden soll?',
    ADMIN_RESET_PASSWORD_CONFIRMATION: 'Sind Sie sicher, dass das Passwort für {0} zurückgesetzt werden soll?',
    ADMIN_RESET_PASSWORD_TEMPORARY: 'Das temporäre Passwort lautet {0}',
    DELIVERY_DATE: 'Geplanter Liefertermin',
    DELIVERY_DATE_DESIRED: 'Wunschliefertermin',
    PICKUP_DATE_DESIRED: 'Wunschabholtermin',
    DELIVERY_DATE_ESTIMATED: 'voraussichtlicher Liefertermin',
    PICKUP_DATE_ESTIMATED: 'voraussichtlicher Abholtermin',
    DIMENSIONS: 'Format (Länge x Breite)',
    DOCUMENTS: 'Dokumente',
    DOWNLOAD: 'Kundendokument herunterladen',
    DOWNLOAD_FILES: 'Dateien herunterladen',
    DOWNLOAD_NESTING: 'Nesting herunterladen',
    DOWNLOAD_CSV: 'CSV herunterladen',
    CSV: 'CSV-Parameter-Import',
    DRAFT: 'In Erstellung',
    EDGES_ROUNDED: 'Kanten verrunden',
    EDIT: 'Bearbeiten',
    EDIT_MATERIAL: 'Material bearbeiten',
    EMAIL: 'E-Mail',
    ENTER_CUSTOMER_ID: 'Bitte die Kundennummer für {0} eingeben, um die Nutzeranfrage zu bestätigen.',
    FILE: 'Datei',
    FILE_NAME: 'Dateiname',
    FORGOT_PASSWORD: 'Passwort vergessen?',
    FORMAT: 'Format',
    IMPRINT: 'Impressum',
    SUBMITTED: 'Angefragt',
    INVALID_NUMBER: 'Bitte ein gültiges Zahlenformat eingeben.',
    INVOICE_ADDRESS: 'Rechnungsanschrift',
    INVOICE_ADDRESS_NEEDED:
      'Bitte stellen Sie sicher, dass Sie vor dem Hochladen einer Datei zumindest Ihre Rechnungsadresse erfasst haben.',
    INVOICE_ADDRESS_NEEDED_EXPLANATION: 'Die Verwaltung Ihrer Rechnungs- und Lieferadressen finden Sie in den ',
    INVOICE_ADDRESS_SELECT: 'Bitte Rechnungsanschrift wählen',
    INVOICE_AND_SHIPPING_ADDRESS: 'Rechnungs- und Lieferanschrift',
    LENGTH: 'Länge',
    LOAD_MORE: 'Lade weitere...',
    LOGIN_EMAIL_INVALID: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
    LOGIN_EMAIL_MISSING: 'Bitte geben Sie eine E-Mail-Adresse ein.',
    LOGIN_PASSWORD_MISSING: 'Bitte geben Sie ein Passwort ein.',
    LOGIN_PASSWORD_RULE:
      'Das Passwort muss mindestens 12 Zeichen lang sein und Kleinbuchstaben, Großbuchstaben und Zahlen',
    LOGIN_INCORRECT_CREDENTIALS: 'Diese Kombination aus E-Mail und Passwort ist nicht korrekt.',
    LOGIN_WELCOME: 'Bitte melden Sie sich an, um das BlechBoard zu nutzen.',
    MATERIAL: 'Material',
    MATERIAL_COST: 'Materialpreis',
    DROPDOWN_SELECTION: 'Bitte wählen...',
    MATERIAL_DROPDOWN: 'Bitte Material wählen...',
    MATERIAL_LOADING: 'Lade Materialliste...',
    MATERIAL_TYPE: 'Materialart',
    MATERIAL_TYPE_DROPDOWN: 'Bitte Materialart wählen...',
    MATERIAL_TYPE_LOADING: 'Lade Materialarten...',
    MATERIAL_THICKNESS_UNAVAILABLE: 'Gewünschte Dicke ist für diese Materialart nicht verfügbar.',
    NO: 'Nein',
    NO_IMAGE: 'Kein Bild verfügbar',
    NOT_VERIFIED: 'Unbestätigt',
    OR: 'oder',
    OFFER: 'Angebot',
    OFFER_ID: 'Angebotsnummer',
    ORDER: 'Auftrag',
    OVERVIEW: 'Übersicht',
    ORDER_ADD_FILES: 'Dateien hinzufügen',
    ORDER_CHOOSE_FILE: 'Datei auswählen',
    ORDER_CONFIRMATION: 'Order Confirmation',
    ORDER_DRAG_FILE: 'CAD-Datei(en) hier hinziehen (max. 25MB)',
    ORDER_DROP_FILE: 'Datei(en) hier ablegen...',
    ORDER_ID: 'Auftragsnr.',
    ORDER_PLACED1: 'Ihr Auftrag wurde übermittelt.',
    ORDER_PLACED2: 'Bitte rufen Sie ',
    ORDER_PLACED3: 'auf,',
    ORDER_PLACED4: 'um den Fortschritt und Auftragsdetails abzurufen.',
    ORDER_SUMMARY: 'Zusammenfassung',
    PAGE_NOT_FOUND: 'Seite nicht gefunden',
    ORDER_NOT_FOUND: 'Auftrag nicht gefunden',
    PAGE_NOT_FOUND_DETAILS: 'Leider konnte die von Ihnen gesuchte Seite nicht gefunden werden.',
    PARAMETERS: 'Parameter',
    PARTS: 'Benötigte Teile',
    PASSWORD: 'Passwort',
    PASSWORD_RESET: 'Passwort zurücksetzen',
    PRIVACY_POLICY: 'Datenschutz',
    PROCESSING_FILES: 'Verarbeitung läuft...',
    PRODUCT: 'Artikel',
    QUANTITY: 'Menge',
    RECENT_ORDERS: 'Meine Aufträge',
    REJECT: 'Ablehnen',
    REJECT_ORDER: 'Auftrag ablehnen',
    REJECT_ORDER_CONFIRMATION: 'Möchten Sie diesen Auftrag wirklich ablehnen?',
    REJECTED: 'Nicht angenommen',
    REMOVE: 'Entfernen',
    REMOVE_ADDRESS: 'Adresse entfernen',
    REMOVE_ADDRESS_CONFIRMATION: 'Möchten Sie diese Adresse wirklich entfernen?',
    REMOVE_FILE: 'Datei oder Bauteil entfernen',
    REMOVE_FILE_CONFIRMATION: 'Möchten Sie diese Datei oder Bauteil wirklich entfernen?',
    REMOVE_MATERIAL: 'Material entfernen',
    REMOVE_OFFER: 'Angebot entfernen',
    REMOVE_OFFER_CONFIRMATION: 'Sind Sie sicher, dass das Angebot entfernt werden soll?',
    REMOVE_MATERIAL_CONFIRMATION: 'Sind Sie sicher, dass das Material entfernt werden soll?',
    WITHDRAW_REQUEST: 'Auftrag stornieren',
    WITHDRAW_REQUEST_INFORMATION: 'Die Stornierunganfrage wird von unserem Team bearbeitet.',
    WITHDRAW: 'Stornierung angefragt',
    WITHDRAW_REQUEST_CONFIRMATION: 'Sind Sie sicher, dass der Auftrag storniert werden soll?',
    REMOVE_PART: 'Teil entfernen',
    REMOVE_PART_CONFIRMATION: 'Möchten Sie dieses Teil wirklich aus der Liste entfernen?',
    REPEAT_PASSWORD: 'Passwort wiederholen',
    REQUESTS: 'Nutzeranfragen',
    REQUEST_APPROVAL: 'Registrierungsstatus',
    REQUESTED_ORDERS: 'Auftragsanfragen',
    RETRY: 'Neu laden',
    ROTATION: 'Teileausrichtung',
    ROTATION_ANGLE: 'Winkel zur Walzrichtung (0°-360°)',
    ROTATION_DROPDOWN: 'Bitte Teileausrichtung wählen...',
    SAVE: 'Speichern',
    SEARCH: 'Suche',
    SETTINGS: 'Einstellungen',
    SHIPPING_ADDRESS: 'Lieferanschrift',
    SHIPPING_ADDRESS_SELECT: 'Bitte Lieferanschrift wählen...',
    SHIPPING_ADDRESS_USE: 'Als Lieferanschrift verwenden',
    SHIPPING_COST: 'Frachtkosten',
    SHIPPING_COST_CUSTOM: 'Individuelle Frachtkosten',
    SHIPPING_COST_MANUALLY: 'Für diesen Auftrag müssen die Frachtkosten individuell kalkuliert werden.',
    SIGN_IN: 'Anmelden',
    SIGN_OUT: 'Abmelden',
    SIGN_UP: 'Konto erstellen',
    GO_BACK: 'Zurück',
    SIGN_UP_BACK: 'Zurück zur Anmeldung',
    SIGN_UP_HEADER: 'Neu im BlechBoard? Jetzt freischalten lassen.',
    SIGN_UP_PW_MISMATCH: 'Die Passwörter stimmen nicht überein.',
    SIGN_UP_PW_MISSING: 'Bitte geben Sie das Passwort in beiden Feldern ein.',
    SIGN_UP_USTID_MISSING: 'Bitte geben Sie Ihre UStID ein.',
    STATUS: 'Auftragsstatus',
    TERMS_AND_CONDITIONS: 'Allgemeinen Geschäftsbedingungen',
    THANK_YOU: 'Vielen Dank!',
    THICKNESS: 'Materialdicke',
    THICKNESS_DROPDOWN: 'Bitte Materialdicke auswählen...',
    THICKNESS_LOADING: 'Lade Materialdicken...',
    TOTAL: 'Gesamtpreis',
    UNACTIVATED_USERS: 'Inaktive Benutzer',
    NEWUSER_USERS: 'Neue Benutzer',
    ADMIN_USERS: 'Administratoren',
    UNIT_PRICE: 'Einzelpreis',
    UPLOAD: 'Upload',
    UPLOAD_BACK: 'Zurück zum Upload',
    UPLOAD_RUNNING: 'Upload läuft...',
    USER_MANAGEMENT: 'Benutzerverwaltung',
    CUSTOMER_MANAGEMENT: 'Kundenverwaltung',
    ADMIN_STATUS: 'Admin-Status',
    REMOVE_GROUP_ADMIN: 'Adminrechte entfernen',
    ADD_GROUP_ADMIN: 'Adminrechte hinzufügen',
    USTID: 'USt-Id-Nr.',
    VERIFICATION_CODE: 'Der Bestätigungscode muss aus 6 Ziffern bestehen.',
    VERIFICATION_RESEND: 'Erneut senden',
    VERIFICATION_STATUS: 'E-Mail bestätigt',
    VERIFIED: 'Bestätigt',
    WAIT_FOR_CONFIRMATION: 'Bitte warten Sie bis Blechcon Ihr Benutzerkonto bestätigt hat.',
    WEIGHT_NETTO: 'Gewicht Netto',
    WIDTH: 'Breite',
    YES: 'Ja',
    TO_SETTINGS: 'Einstellungen',
    DURATION_WARNING: 'Die Berechnung kann einige Zeit dauern...',
    PICKUP_YOURSELF: 'Selbstabholung',
    PICKUP_MESSAGE: 'Zur Abholung vorgesehen',
    CUSTOMER_REFERENCE: 'Kunden-Referenz',
    PAYLOAD_TOO_LARGE: 'Die Datei {0} konnte nicht hochgeladen werden, weil sie die Maximalgröße übersteigt.',
    ERROR_CSV: 'CSV Verarbeitungsfehler',
    INVALID_BLECHCON_NUMBER_FORMAT: 'ungültige Zahl',
    INVALID_BLECHCON_BOOLEAN_FORMAT: 'Nur true/false Werte erlaubt',
    INVALID_BLECHCON_FILE_NAME: 'Fehler im Dateinamen',
    INVALID_BLECHCON_FILE_NAME_NOT_FOUND: 'Datei nicht gefunden',
    INVALID_BLECHCON_MATERIAL: 'Kein passendes Material gefunden',
    INVALID_BLECHCON_MATERIAL_THICKNESS: 'Materialdicke nicht verfügbar',
    INVALID_BLECHCON_DOCUMENT: 'Kein Dokument erkannt',
    INVALID_BLECHCON_DOCUMENT_FORMAT: 'Der Inhalt der CSV konnte nicht verarbeitet werden',
    INVALID_STATUS_NOT_DRAFT: 'Auftrag nicht im Status "In Erstellung"',
    ERROR_UPLOADING_FILE: 'Fehler beim Hochladen einer Datei',
    ERROR_UPLOADING_FILE_OTHER:
      'Es ist ein Fehler aufgetreten. Gerne kontaktieren sie uns dazu, damit wir das Problem lösen können.',
    ERRORS: 'Fehler',
    STEP: 'Schritt',
    VIEW_2D: '2D-Ansicht',
    VIEW_3D: '3D-Ansicht',
    INVALID_PART: 'ungültiges Teil',
    PERSONAL: 'Persönlich',
    OTHERS: 'Andere',
    BENDS: 'Abkantungen',
    CUT_LENGTH: 'Schnittlänge',
    CUTS: 'Schnitte',
    TIME: 'Laser Zeit',
    AVERAGE_LASER_TIME: 'Durchschnittliche Laserzeit',
    AREA: 'Fläche (brutto)',
    AREA_NETTO: 'Fläche (netto)',
    BEND_TIME: 'Abkantzeit',
    PRICE_FOR_BENDING: 'Abkantpreis',
    PRICE_FOR_LASER_CUTS: 'Schneidepreis',
    SETUP_COST_BENDING: 'Rüstkosten für Abkanten',
    SETUP_COST_LASER: 'Rüstkosten für Laser',
    PRICE_EXPIRED: 'Der Preis ist nicht mehr gültig. Bitte kalkurieren Sie erneut.',
    MIN_VALUE_NOT_REACHED: 'Minimaler Materialbetrag von {0} nicht erreicht',
    EMAIL_EXISTS_ERROR: 'Ein Account mit dieser E-Mail-Adresse ist schon vorhanden.',
    USER_NOT_FOUND: 'Konto nicht vorhanden',
    CODE_MISMATCH: 'Der Verifizierungscode ist ungültig',
    LIMIT_EXCEEDED_EXCEPTION: 'Versuchslimit überschritten, bitte versuchen Sie es nach einiger Zeit erneut',
    OK: 'Ok',
    CANCELED_ORDERS: 'Storniert',
    CANCEL_ORDER: 'Stornieren',
    DESIRED_DATE: 'Gewünschter Liefertermin',
    MATERIALS_LOADING: '',
    ROTATIONS_LOADING: '',
    EMAIL_CONFIRMATION_ENTER_PASSCODE: 'Bitte geben Sie den Zugangscode aus Ihrer E-Mail ein.',
    PASSCODE: 'Zugangscode',
    EMAIL_CONFIRMATION_SEND_VERIFICATION: 'Verschicke Zugangscode an E-Mail-Adresse.',
    EMAIL_CONFIRMATION_BUTTON_SEND_CODE: 'Senden',
    ADD_PDF: 'PDF hinzufügen',
    WICAM_JOB_FILE_ID: 'Wicam Job File ID',
    TOOLTIP_BENDING:
      'Diese Option legt fest, dass Ihr Teil mithilfe unserer Kantbänke abgekantet wird. Sie kann nur bei erkannter Kantlinie ausgewählt werden.',
    BENDING_FAQ_URL: 'https://blechcon.de/de/technologien/abkanten.html',
    TOOLTIP_EDGES_ROUNDED:
      'Bei Auswahl dieser Option werden wir die Kanten Ihres Bauteils beidseitig entgraten und verrunden.',
    EDGES_ROUNDED_FAQ_URL: 'https://blechcon.de/de/technologien/entgraten.html',
    TOOLTIP_ADDITIONAL_FILE:
      'Mithilfe einer PDF können Sie uns weitere Informationen wie z.B. Toleranzen, Messabstände, etc. zur Verfügung stellen.',
    TOOLTIP_REMOVE_PART: 'Entfernen Sie dieses Teil aus der Baugruppe.',
    TOOLTIP_ROTATION_SELECT:
      'Option „Frei“ bewirkt, dass das Teil frei auf der Platine platziert wird. Option „Walzrichtung festlegen“ gibt die Möglichkeit, die Walzrichtung des Objekts anzupassen. Bei geschliffenem/gebürstetem Material: Walzrichtung = Schliffrichtung.',
    TOOLTIP_ROTATION_ANGLE_INPUT:
      'Der „Winkel zur Walzrichtung“ gibt an, in welchem Winkel das Teil auf der Tafel positioniert wird. Bei geschliffenem/gebürstetem Material: Walzrichtung = Schliffrichtung.',
    FURTHER_INFORMATION: 'Weitere Informationen erhalten Sie hier.',
    MATERIAL_TYPE_1: 'Stahl',
    MATERIAL_TYPE_2: 'Edelstahl',
    MATERIAL_TYPE_3: 'Aluminium',
    ROTATION_TYPE_1: '90°',
    ROTATION_TYPE_2: '180°',
    ROTATION_TYPE_3: 'Frei',
    ROTATION_TYPE_4: 'Keine',
    ROTATION_TYPE_FIXED: 'Walzrichtung festlegen',
    ROTATION_TYPE_FREE: 'Frei',
    NESTING_OPTION: 'Teileausrichtung',
    ADDRESS_NOT_VALID:
      'Für die angegebene Adresse kann keine Entfernung berechnet werden. Bitte versuchen Sie eine valide Adresse.',
    GENERAL_ERROR: 'Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie den Support.',
    USER_INFO_LOAD_ERROR: 'Kontoinformationen konnten nicht geladen werden.',
    CURRENT_TIME_LOAD_ERROR: 'Aktuelle Uhrzeit konnten nicht geladen werden.',
    CONFIGURATION_LOAD_ERROR: 'Konfiguration konnte nicht geladen werden.',
    FILE_SIZE_TOO_LARGE_ERROR: 'Datei ist zu groß (max. 25 MB)',
    MUST_CONTAIN_AT_LEAST_CHARACTERS: 'Muss mindestens {0} Zeichen enthalten.',
    DATE_INPUT_INVALID_WORKDAY: 'Es sind nur Werktage erlaubt',
    WELCOME: 'Willkommen',
    HOW_DID_YOU_FIND_US: 'Wie haben Sie uns gefunden?',
    ALREADY_A_CUSTOMER: 'Bereits Kunde',
    REFERRAL: 'Empfehlung',
    GOOGLE: 'Google',
    WHO_PROVIDES_WHAT: 'Wer liefert was',
    OTHER: 'Sonstiges',
    OFFER_VALID_UNTIL: 'Angebot gültig bis',
    OFFER_FROM: 'Angebot {0} vom {1}',
    PRINT_OFFER_PAGE_THANK_YOU:
      'Wir bedanken uns für Ihre Anfrage und unterbreiten Ihnen folgendes freibleibendes Angebot',
    DATE_FORMAT: 'dd.MM.yyyy',
    PRINT_PAGE_LEGAL:
      'BlechCon lasert gemäß ISO9013 – Genauigkeitsklasse 1 für thermische Zuschnitte. Teile nicht entgratet und nicht gerichtet. Kantungen erfolgen gemäß ISO2768-m, alles andere Bedarf der schriftlichen Absprache. Alle in der Anfrage des Kunden enthaltenen Spezifikationen und Vorschriften gelten als ausgeschlossen, insoweit BlechCon diese nicht bestätigt oder explizit kommentiert an den Kunden zurückgesendet hat. Preise gelten nur bei kompletter Bestellung der hier angebotenen Teile und Menge. Es gelten ausschließlich unsere allgemeinen Geschäftsbedingungen.¶Diese finden Sie im Internet unter www.blechcon.de/de/agb.html.',
    CUSTOMER_MATERIAL_SORT_BY_CSV: 'Dateien nach der CSV-Reihenfolge sortieren',
    DISCOUNT: 'Discount',
    ADDITIONAL_DELIVERY_DAYS: 'zusätzliche Liefertage',
    MINIMUM_ORDER_VALUE_IN_CENT: 'Minimum Bestellwert in Cent',
    BENDING_COSTS_PER_HOUR: 'Biegungskosten pro Std',
    HAS_CSV_PARAMETER_IMPORT_RIGHT: 'CSV Parameter import',
    REMOVE_CSV_PARAMETER_IMPORT_RIGHT: 'CSV-Parameter Import entfernen',
    ADD_CSV_PARAMETER_IMPORT_RIGHT: 'CSV-Parameter Import hinzufügen',
    OPTIMATE_ACTIVE: "Optimate aktiv",
    REMOVE_OPTIMATE_ACTIVE: 'Optimate deaktivieren',
    ADD_OPTIMATE_ACTIVE: 'Optimate aktivieren',
    SHOW_3D_VIEW: '3D Ansicht',
    OPTIMIZATION_BUTTON: 'Potenzialerkennung',
    PART_VIEWER_LEGEND_LMT_TO: 'linke Maustaste zum',
    PART_VIEWER_LEGEND_RMT_TO: 'rechte Maustaste zum',
    PART_VIEWER_LEGEND_SCROLL_TO: 'Mausrad zum',
    PART_VIEWER_LEGEND_ROTATE: 'Drehen',
    PART_VIEWER_LEGEND_PAN: 'Bewegen',
    PART_VIEWER_LEGEND_ZOOM: 'Zoomen',
    COULD_NOT_LOAD_3D_VIEW: '3D Ansicht konnte nicht geladen werden',
    ANALYSIS_NO_ISSUES: 'Kein Problem festgestellt',
    BEND_ANGLE: 'Biegewinkel',
    CUTOUT_DISTANCE: 'Ausschnittweite',
    REQUIRED_DISTANCE: 'erforderliche Entfernung',
    INNER_BEND_RADIUS: 'innerer Krümmungsradius',
    MINIMUM_INNER_BEND_RADIUS: 'min. innerer Krümmungsradius',
    CUTOUT_DIAMETER: 'Ausschnittdurchmesser',
    MINIMUM_CUTOUT_DIAMETER: 'min. Ausschnittsdurchmesser',
    L_MIN: 'L Min',
    FLANGE_LENGTH: 'Flanschlänge',
    BEND_LENGTH: 'Länge der Biegung',
    MAX_BEND_LENGTH: 'max. Biegelänge',
    BEND_PRESSURE: 'Biegedruck',
    MAX_BEND_PRESSURE: 'Max. Biegedruck',
    DIFFERENCE: 'Differenz',
    UNIT_CONVERTED: 'Die Maßeinheit wurde in mm geändert.',
  },
};

export function addTranslations(additionalTranslations: BlechconTranslations): void {
  const keys = Object.keys(additionalTranslations) as (keyof BlechconTranslations)[];
  const updatedTranslations: BlechconTranslations = { ...translations };
  const actualLanguage = localized.getLanguage();

  for (const key of keys) {
    updatedTranslations[key] = {
      ...translations[key],
      ...additionalTranslations[key],
    };
  }

  localized.setContent(updatedTranslations);

  // Sprache wird mit setContent zurückgesetzt (╯°□°)╯︵ ┻━┻
  localized.setLanguage(actualLanguage);
}

/*
 * l steht für localization
 */
export enum l {
  ABORT,
  SAVE,
  WORKING_STEPS,
  WORKING_STEPS_MODAL_TITLE,
  WORKING_STEPS_DROPDOWN_PLACEHOLDER,
  WORKING_STEPS_BUTTON_TEXT,
}

/*
 * t steht für translate
 */
export function t(key: l | string, ...keyParts: string[]): string {
  let keyString = typeof key === 'string' ? key : l[key];
  
  if (keyParts.length > 0) {
    keyString += '_' + keyParts.map(it => it.toUpperCase()).join('_');
  }

  return localized.getString(keyString) ?? keyString;
}

export const localized = new LocalizedStrings(translations);
