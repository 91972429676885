import './OrderTotal.css';
import React from 'react';
import { localized } from '../../config/localization';
import { formatMeasure } from '../../utils';
import { useAuthContext } from '../../services/useAuthContext';
import { ShippingCost } from '../../blechcon';

type Props = {
  totalPrice: number;
  shippingCost: ShippingCost | undefined;
  selfPickup: boolean;
  discountPrice: number;
  materialTotalPrice: number;
};

export function OrderTotal({
  totalPrice,
  shippingCost,
  selfPickup,
  discountPrice,
  materialTotalPrice,
}: Props): JSX.Element {
  const { isAdmin } = useAuthContext();

  return (
    <div className="order-total">
      <table>
        <thead>
          <tr>
            <th>{localized.ORDER_SUMMARY}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{localized.MATERIAL_COST}</td>
            <td>{formatMeasure(materialTotalPrice, '€')}</td>
          </tr>
          {discountPrice > 0 && (
            <tr>
              <td>Discount</td>
              <td>- {formatMeasure(discountPrice, '€')}</td>
            </tr>
          )}
          {(shippingCost?.price || 0) > 0 || selfPickup ? (
            <>
              <tr>
                <td>{localized.SHIPPING_COST}</td>
                <td>{selfPickup ? '-' : formatMeasure(shippingCost?.price, '€')}</td>
              </tr>
              {isAdmin && !selfPickup ? (
                <tr>
                  <td></td>
                  <td>{shippingCost?.name}</td>
                </tr>
              ) : null}
              <tr className="order-total__total">
                <td>{localized.TOTAL}</td>
                <td>{totalPrice ? formatMeasure(totalPrice, '€') : '-'}</td>
              </tr>
            </>
          ) : (
            <tr className="order-total__cost_manually">
              <td colSpan={2}>
                <div>{localized.SHIPPING_COST_MANUALLY}</div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
