import { api as optimateApi } from '.';
import { BlechconDocument, BlechconMaterial } from '../../blechcon';
import { api as wicamApi } from '../wicam';
import { AnalysisModel } from './optimateTypes';
import { scheduleNextRun } from '../../utils';

function formatThickness(materialThickness?: number): string {
  if (!materialThickness) {
    return '';
  }
  return '-' + materialThickness * 10;
}

export function materialToMaterialTypeId(material: BlechconMaterial): string {
  switch (material.materialTypeID) {
    case 1:
      return '1.0038' + formatThickness(material.materialThickness);
    case 2:
      return '1.4301' + formatThickness(material.materialThickness);
    case 3:
      return 'AlMg3' + formatThickness(material.materialThickness);
    default:
      return '';
  }
}

export async function submitPartAnalysis(
  cadFile: BlechconDocument,
  material: BlechconMaterial,
  signal: AbortSignal
): Promise<AnalysisModel | null> {
  const cadResult = await wicamApi.GET(cadFile.data, { responseType: 'blob' });
  const cadData = cadResult.data as unknown as Blob;

  const file = new File([cadData], cadFile.name, {
    type: cadData.type,
  });

  const { data } = await optimateApi.v3.postRequestPartAnalysis({
    file,
    material: materialToMaterialTypeId(material),
  });

  if (signal.aborted) {
    return null;
  }

  return await getPartAnalysis(signal, data.id);
}

export async function getPartAnalysis(signal: AbortSignal, id: string): Promise<AnalysisModel> {
  return new Promise((resolve, reject) => {
    async function run() {
      try {
        const response = await optimateApi.v3.getGetPartAnalysis(id);
        const state = response.data.state;

        if (state === 'completed') {
          resolve(response.data);
        } else if (state === 'pending') {
          scheduleNextRun(run, signal);
        } else {
          reject(response.data.result?.message);
        }
      } catch (error) {
        reject(error);
      }
    }

    run();
  });
}
