import React from 'react';

type Props = {
  number: number;
  name: string;
  active: boolean;
  done: boolean;
};

export function Step({ number, name, active, done }: Props): JSX.Element {
  const futureStep = !active && !done;

  const classes = [];
  if (done) {
    classes.push('checkout-steps__step--done');
  } else if (futureStep) {
    classes.push('checkout-steps__step--next');
  }

  return (
    <div className={`checkout-steps__step ${classes.join(' ')}`}>
      <div className={`checkout-steps__circle`}>{number}</div>
      <div>{name}</div>
    </div>
  );
}
