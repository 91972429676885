import React, { useState } from 'react';
import { localized } from '../config/localization';
import { Button } from './Button/Button';
import { Download } from '../assets/svg/Download';
import { Bin } from '../assets/svg/Bin';
import { Modal } from './Modal';
import { BlechconDocument, BlechconOrder } from '../blechcon';
import { presentDownloadDialogForS3Key } from './helper';

type Props = {
  order: BlechconOrder;
  onChangeDocuments?: (documents: BlechconDocument[]) => void;
};

export function OrderDocuments({ order, onChangeDocuments }: Props): JSX.Element | null {
  const [documentForDeletion, setDocumentForDeletion] = useState<BlechconDocument | null>(null);

  const removeDocument = (document: BlechconDocument | null) => {
    if (!document) {
      return;
    }
    const documents = order.documents.filter((it: BlechconDocument) => it.data !== document.data);
    onChangeDocuments && onChangeDocuments(documents);
    setDocumentForDeletion(null);
  };

  if (order.documents?.length === 0) {
    return null;
  }

  return (
    <>
      {documentForDeletion && (
        <Modal
          title={localized.REMOVE_FILE}
          successDisabled={false}
          onCancel={{
            label: localized.ABORT,
            execute: () => {
              setDocumentForDeletion(null);
            },
          }}
          onSuccess={{
            label: localized.CONFIRM,
            execute: () => removeDocument(documentForDeletion),
          }}>
          <div className="flex flex-1 text-label text-lg text-opacity-50 py-2">
            {localized.REMOVE_FILE_CONFIRMATION}
          </div>
        </Modal>
      )}

      <table className="my-12 table-auto">
        <thead>
          <tr className="w-full bg-contentHeader text-primary font-bold">
            <td className="py-4 px-8 uppercase">{localized.FILE_NAME}</td>
            <td className="py-4 px-8" />
          </tr>
        </thead>
        <tbody>
          {order.documents?.map((document, index: number) => (
            <tr key={index} className="border-b-2 border-border">
              <td className="py-2 px-8 text-mutedText">{document.name}</td>
              <td className="py-2 flex justify-end text-mutedText">
                <Button
                  onClick={() => {
                    if (document.data) {
                      presentDownloadDialogForS3Key(document.name, document.data);
                    }
                  }}>
                  {<Download fill="currentColor" />}&nbsp;{localized.DOWNLOAD}
                </Button>

                {onChangeDocuments && (
                  <Button
                    className="ml-8"
                    onClick={() => {
                      setDocumentForDeletion(document);
                    }}
                    danger>
                    {<Bin height={16} width={16} />}&nbsp;{localized.REMOVE}
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
