import './StatusBadge.css';
import React from 'react';
import { localized } from '../../config/localization';
import { BlechconOrderStatus } from '../../blechcon';

type Props = {
  orderStatus: BlechconOrderStatus;
  className?: string;
};

export function StatusBadge({ orderStatus, className }: Props): JSX.Element {
  const status = orderStatus || '';
  return (
    <div className={`status-badge ${className || ''} status-badge--${status}`}>
      {localized.getString(status.toUpperCase())}
    </div>
  );
}
