import { BlechconPartAnalysis, BlechconOrder, BlechconOrderFile } from '../../blechcon';

/*
 * TODO Diese Funktion stellt einen Workaround für das Datenmodell dar.
 * Wir können keine einzelnen Parts sondern nur ganze Files speichern.
 * Das Datenmodell sollte umgebaut und Parts in eine eigene Tabelle
 * ausgelagert werden.
 */
export async function persistAnalysisModels(
  order: BlechconOrder,
  file: BlechconOrderFile,
  partAnalyses: BlechconPartAnalysis[],
  persistOrder: (order: Partial<BlechconOrder>) => void,
  shouldPersist = true
) {
  let hasMatchingParts = false;

  const parts = file.parts.map((part) => {
    const model = partAnalyses.find((analysis) => analysis.partId === part.id);

    if (model) {
      if (part.analysisId !== model.analysisId) {
        hasMatchingParts = true;
      }
      return {
        ...part,
        analysisId: model.analysisId,
      };
    }

    return part;
  });

  if (shouldPersist && hasMatchingParts) {
    await persistOrder({
      id: order.id,
      files: order.files.map((f) => {
        return f.id === file.id ? { ...f, parts } : f;
      }),
    });
  }
}
