import React from 'react';
import { Button } from './Button/Button';
import { localized } from '../config/localization';
import { getOrderPrintPageStorageKey } from '../utils';
import { Routes } from '../app/Routes';
import { BlechconOrder } from '../blechcon';

type Props = {
  order: BlechconOrder;
  disabled?: boolean;
};

export function OrderPrintButton({ order, disabled }: Props) {
  return (
    <Button
      disabled={disabled}
      onClick={() => {
        sessionStorage.setItem(getOrderPrintPageStorageKey(order.id), JSON.stringify(order));
        window.open(Routes.orderPrint(order.id), '_blank');
      }}
      secondary>
      {localized.PRINT_ORDER}
    </Button>
  );
}
