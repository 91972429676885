import { BlechconMaterial } from '../../blechcon';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { localized } from '../../config/localization';
import { getBlechconMaterials } from '../../services/api/blechconApi';

export function useMaterial(): BlechconMaterial[] | null {
  const [materials, setMaterials] = useState<BlechconMaterial[] | null>(null);

  useEffect(() => {
    getBlechconMaterials()
      .then((response: BlechconMaterial[]) => {
        setMaterials(response);
      })
      .catch((error) => {
        console.error('Error in useMaterial', error);
        toast.error(localized.CONFIGURATION_LOAD_ERROR);
      });
  }, []);

  return materials;
}
