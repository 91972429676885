import React from 'react';

type Props = {
  className?: string;
  fill?: string;
};

export function Order({ className = '', fill = 'currentColor' }: Props): JSX.Element {
  return (
    <svg width={22} height={22} viewBox="0 0 16 16" className={className} fill={fill}>
      <path
        d="M4.778,3A1.777,1.777,0,0,0,3,4.778V6.556A1.777,1.777,0,0,0,4.778,8.333H6.556A1.777,1.777,0,0,0,8.333,6.556h5.488a2.667,2.667,0,0,0,1.623,1.622v5.49a1.777,1.777,0,0,0-1.778,1.778H8.333a1.777,1.777,0,0,0-1.778-1.778H4.778A1.777,1.777,0,0,0,3,15.444v1.778A1.777,1.777,0,0,0,4.778,19H6.556a1.777,1.777,0,0,0,1.778-1.778h5.333A1.777,1.777,0,0,0,15.444,19h1.778A1.777,1.777,0,0,0,19,17.222V15.444a1.777,1.777,0,0,0-1.778-1.778V8.179a2.665,2.665,0,1,0-3.4-3.4H8.333A1.777,1.777,0,0,0,6.556,3Z"
        transform="translate(-3 -3)"
      />
    </svg>
  );
}
