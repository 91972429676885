import React, { useState } from 'react';
import { BlechconAddress } from '../../../blechcon';
import { Toggle } from '../../../components/Toggle/Toggle';

type Props = {
  addresses: BlechconAddress[];
};

export function DisplayInvoiceAddress({ addresses = [] }: Props): JSX.Element | null {
  const [open, setOpen] = useState(false);

  if (addresses.length === 0) {
    return null;
  }

  const address = addresses[0];
  const addressClasses = [open ? 'admin-users__invoice-address--visible' : 'admin-users__invoice-address--invisible'];

  return (
    <div className="admin-users__invoice-address-block">
      <Toggle on={open} onClick={() => setOpen(!open)} />

      <address className={addressClasses.join(' ')}>
        <p>{address.addressLine}</p>
        <p>{address.street}</p>
        <p>
          {address.zip} {address.city}
        </p>
      </address>
    </div>
  );
}
