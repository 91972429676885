import React from 'react';
import { Tooltip as GenericTooltip } from '../../../components/Tooltip/Tooltip';
import { localized } from '../../../config/localization';

export function BendingTooltip(): JSX.Element {
  return <Tooltip description={localized.TOOLTIP_BENDING} href={localized.BENDING_FAQ_URL} />;
}

export function EdgesRoundedTooltip(): JSX.Element {
  return <Tooltip description={localized.TOOLTIP_EDGES_ROUNDED} href={localized.EDGES_ROUNDED_FAQ_URL} />;
}

export function RotationSelectTooltip(): JSX.Element {
  return <Tooltip description={localized.TOOLTIP_ROTATION_SELECT} />;
}

export function RotationAngleInputTooltip(): JSX.Element {
  return <Tooltip description={localized.TOOLTIP_ROTATION_ANGLE_INPUT} />;
}

type TooltipProps = {
  description: string;
  href?: string;
};

function Tooltip({ description, href }: TooltipProps): JSX.Element {
  return (
    <GenericTooltip>
      {description}
      <br />
      <br />
      {href ? (
        <a href={href} className="underline" rel="noopener noreferrer" target="_blank">
          {localized.FURTHER_INFORMATION}
        </a>
      ) : null}
    </GenericTooltip>
  );
}
