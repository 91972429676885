import React from 'react';
import { useHistory } from 'react-router-dom';
import { localized } from '../../../config/localization';
import { Routes } from '../../../app/Routes';

type Props = {
  isCustomerFilterActive: boolean;
};

export function UserOrdersTabMenu({ isCustomerFilterActive }: Props) {
  const history = useHistory();

  return (
    <div className="flex justify-end my-10">
      <div className="flex w-200">
        <div
          className={`px-5 py-2 cursor-pointer ${isCustomerFilterActive ? 'border-2 text-lightGrayText' : ''}`}
          onClick={() => {
            history.push({
              pathname: Routes.CUSTOMER_ORDERS,
            });
          }}>
          {localized.PERSONAL}
        </div>
        <div
          className={`px-5 py-2 cursor-pointer ${!isCustomerFilterActive ? 'border-2 text-lightGrayText' : ''}`}
          onClick={() => {
            history.push({
              pathname: Routes.CUSTOMER_ORDERS,
              search: `?filter=customer`,
            });
          }}>
          {localized.OTHERS}
        </div>
      </div>
    </div>
  );
}
