import './ParametersPage.css';

import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { useDebounce } from 'use-debounce';
import { Routes } from '../../../app/Routes';
import { Bin } from '../../../assets/svg/Bin';
import {
  BlechconCustomerConfiguration,
  BlechconDocument,
  BlechconMaterial,
  BlechconOrder,
  BlechconOrderFile,
  BlechconOrderFilePart,
  BlechconParameterCSV,
  BlechconUserInfo,
  clearPrices,
  parameterCsvHasError,
} from '../../../blechcon';
import { Button } from '../../../components/Button/Button';
import { CenteredRing } from '../../../components/CenteredRing/CenteredRing';
import { FileUploadButton } from '../../../components/FileUploadButton/FileUploadButton';
import { Label } from '../../../components/Label/Label';
import { Modal } from '../../../components/Modal';
import { localized } from '../../../config/localization';
import { parseParametersCsv } from '../../../services/api/blechconApi';
import { useMaterial } from '../useMaterial';
import { FileParameterSelection } from './FileParameterSelection';
import { ParametersPageCsvErrorModal } from './ParametersPageCsvErrorModal';
import { bestMatchingMaterial, calculatePartQuantity } from './ParametersPageHelper';
import { Colors } from '../../../config/colors';
import { persistAnalysisModels } from '../../../components/PartViewer/persistAnalysisModels';

type Props = {
  readonly userInfo: BlechconUserInfo;
  readonly order: BlechconOrder;
  readonly persistPending: boolean;
  readonly isAdmin: boolean;
  readonly persistOrder: (order: Partial<BlechconOrder>) => Promise<BlechconOrder | null>;
  readonly configuration: BlechconCustomerConfiguration;
};

export function ParametersPage(props: Props): JSX.Element {
  const materials = useMaterial();

  return materials ? <ParametersComponent {...props} materials={materials} /> : <CenteredRing />;
}

export function ParametersComponent({
  userInfo,
  order,
  materials,
  persistPending,
  isAdmin,
  persistOrder,
  configuration,
}: Props & { materials: BlechconMaterial[] }): JSX.Element {
  const history = useHistory();

  const [fileToRemove, setFileToRemove] = useState<BlechconOrderFile | null>(null);
  const [partToRemove, setPartToRemove] = useState<BlechconOrderFilePart | null>(null);
  const [highlightErrors, setHighlightErrors] = useState(false);
  const [files, setFiles] = useState<BlechconOrderFile[]>(order.files);
  const [partTemplate, setPartTemplate] = useState<BlechconOrderFilePart | null>(null);
  const [showCsvErrorModal, setShowCsvErrorModal] = useState<boolean>(false);
  const [csvErrorData, setCsvErrorData] = useState<BlechconParameterCSV[] | null>(null);

  useEffect(() => {
    setFiles(order.files);
  }, [order.files]);

  const updateFile = (file: BlechconOrderFile) => {
    setFiles((current) => current.map((it) => (it.id === file.id ? file : it)));
  };

  const removeFile = (fileModel: BlechconOrderFile) => {
    setFiles((current) => current.filter((it) => it.id !== fileModel.id));
  };

  const removePart = (partModel: BlechconOrderFilePart) => {
    const fileWithPart = files.find((file) => file.parts.find((part) => part.id === partModel.id));

    if (fileWithPart) {
      if (fileWithPart.parts.length <= 1) {
        setFileToRemove(fileWithPart);
        return;
      }

      setFiles((current) =>
        current.map((file) =>
          file.id === fileWithPart.id ? { ...file, parts: file.parts.filter((part) => part.id !== partModel.id) } : file
        )
      );
    }
  };

  async function handleBack() {
    await persistOrder({ ...order, files });
    history.push(Routes.checkoutUpload(order.id));
  }

  async function handleNext() {
    setHighlightErrors(true);
    const materialSelected = files.every((f) => f.parts.every((p) => p.material));
    if (!materialSelected) {
      return;
    }
    const quantityGreaterZero = files.every((f) => f.parts.every((p) => p.quantity && p.quantity > 0));
    if (!quantityGreaterZero) {
      return;
    }

    const updatedOrder = await persistOrder({ id: order.id, checkoutStep: 3, ...clearPrices({ files }) });
    if (updatedOrder) {
      history.push(Routes.checkoutCalculation(order.id));
    }
  }

  const matchCsvParameters = useCallback(
    (result: BlechconParameterCSV[]) => {
      setFiles((currentFiles) => {
        for (const iterator of result) {
          const material = materials.find((it) => it.rawMaterialID === iterator.material.rawMaterialId) || null;
          const file = currentFiles.find(
            (it) => it.name.toLowerCase() === (iterator.file.value as string).toLowerCase()
          );

          if (file && file.parts) {
            file.quantity = iterator.quantity.value as number;
            file.parts = file.parts.map((part) => ({
              ...part,
              quantity: calculatePartQuantity(part, iterator.quantity.value as number),
              edgesRounded: iterator.roundedEdges.value as boolean,
              material: { ...material },
            }));
          }
        }
        return userInfo.retainCsvImportOrder ? sortFilesByCSV(currentFiles, result) : [...currentFiles];
      });
    },
    [materials, userInfo.retainCsvImportOrder]
  );

  const handleCsv = useCallback(
    async (document: BlechconDocument) => {
      setCsvErrorData(null);

      parseParametersCsv(order.id, document)
        .then((result) => {
          // Fehler anzeigen, jedoch die andere valide Einträge übernehmen
          if (parameterCsvHasError(result)) {
            setCsvErrorData(result);
            setShowCsvErrorModal(true);
          }
          setCsvErrorData(null);
          matchCsvParameters(result);
        })
        .catch((error) => {
          if (error.response.data.error) {
            toast.error(String(localized.getString(error.response.data.error)));
          } else {
            toast.error(String(error));
          }
        });
    },
    [matchCsvParameters, order.id]
  );

  const applyMaterialSelection = () => {
    if (!partTemplate) {
      return;
    }

    let appliedFiles: BlechconOrderFile[];

    if (!partTemplate.isAssemblyPart) {
      appliedFiles = applyMaterialsForStandalonePart(partTemplate, files, materials);
    } else {
      appliedFiles = applyMaterialsForAssemblyPart(partTemplate, files, materials);
    }

    setFiles(appliedFiles);
    setPartTemplate(null);
  };

  const allPartsHaveMaterial = files.every((file) => file.parts.every((part) => part.material?.rawMaterialID));
  const allPartsAreValid =
    files.every((file) => file.parts.every((part) => !part.blechconErrorCode && !part.errorCode)) && files.length > 0;
  const notStorable = order.files.length === 0 || persistPending || !allPartsHaveMaterial;
  const nextDisabled = notStorable || !allPartsAreValid;

  const [debouncedFiles] = useDebounce(files, 750);

  const saveForTimeline = useCallback(() => {
    if (notStorable) {
      return;
    }

    if (filesChanged(files, order.files) && filesChanged(debouncedFiles, order.files)) {
      persistOrder({ id: order.id, checkoutStep: 2, ...clearPrices({ files }) }).catch((error) =>
        toast.error(String(error))
      );
    }
  }, [debouncedFiles, files, notStorable, order.files, order.id, persistOrder]);

  return (
    <>
      {fileToRemove && (
        <Modal
          title={localized.REMOVE_FILE}
          successDisabled={persistPending}
          onCancel={{
            label: localized.ABORT,
            execute: () => setFileToRemove(null),
          }}
          onSuccess={{
            label: localized.REMOVE_FILE,
            execute: () => {
              removeFile(fileToRemove);
              setFileToRemove(null);
            },
          }}>
          <p className="text-label text-lg text-opacity-50">{localized.REMOVE_FILE_CONFIRMATION}</p>
        </Modal>
      )}

      {partToRemove && (
        <Modal
          title={localized.REMOVE_PART}
          successDisabled={persistPending}
          onCancel={{
            label: localized.ABORT,
            execute: () => setPartToRemove(null),
          }}
          onSuccess={{
            label: localized.REMOVE_PART,
            execute: () => {
              removePart(partToRemove);
              setPartToRemove(null);
            },
          }}>
          <p className="text-label text-lg text-opacity-50">{localized.REMOVE_PART_CONFIRMATION}</p>
        </Modal>
      )}

      {partTemplate && (
        <Modal
          onCancel={{
            label: localized.ABORT,
            execute: () => {
              setPartTemplate(null);
            },
          }}
          onSuccess={{
            label: localized.APPLY_PARAMETERS,
            execute: applyMaterialSelection,
          }}>
          <p className="text-xl text-mutedText mb-10 min-w-100">{localized.APPLY_PARAMETERS_LABEL}</p>
          <div>
            <Label classes="mt-8">{localized.MATERIAL}</Label>
            <div>{partTemplate?.material ? partTemplate.material.designation : ''}</div>
            <Label classes="mt-8">{localized.ROTATION}</Label>
            <div>{localized.getString(`ROTATION_TYPE_${partTemplate?.rotation.toUpperCase()}`)}</div>
            <Label classes="mt-8">{localized.BENDING}</Label>
            <div>{partTemplate?.bending ? localized.YES : localized.NO}</div>
            <Label classes="mt-8">{localized.EDGES_ROUNDED}</Label>
            <div>{partTemplate?.edgesRounded ? localized.YES : localized.NO}</div>
          </div>
        </Modal>
      )}
      {showCsvErrorModal && (
        <ParametersPageCsvErrorModal csvData={csvErrorData} onClose={() => setShowCsvErrorModal(false)} />
      )}

      <div className="parameters-page__container">
        {userInfo.csvParameterImportEnabled && (
          <div className="parameters-page__upload-csv">
            <FileUploadButton accept=".csv" disabled={persistPending} onUpload={handleCsv} secondary>
              {localized.CSV}
            </FileUploadButton>
          </div>
        )}

        <h3 className="parameters-page__parts-header">
          {localized.PARTS} ({files.length})
        </h3>

        <div className="parameters-page__list">
          {files.map((file, index) => {
            return (
              <div className="parameters-page__list-item" key={index} data-cy={`file-item-${index}`}>
                <div className="parameters-page__list-item-header">
                  <span>{index + 1}</span>
                  <div data-cy={`file-name-${index}`}>{file.name}</div>
                  <Button
                    danger
                    disabled={persistPending}
                    onClick={() => setFileToRemove(file)}
                    dataCy={`file-removeButton-${index}`}>
                    <Bin fill={persistPending ? Colors.label : undefined} />
                    &nbsp;{localized.REMOVE}
                  </Button>
                </div>
                <div
                  className={`${
                    highlightErrors && (file.quantity === 0 || file.parts.some((part) => !part.material))
                      ? 'bg-notAppliedBackground'
                      : ''
                  }`}
                  data-cy={`file-parameters-${index}`}>
                  <FileParameterSelection
                    materials={materials}
                    key={index}
                    disabled={persistPending}
                    file={file}
                    index={index}
                    updateFile={updateFile}
                    handleApplyForAll={setPartTemplate}
                    handleRemove={setPartToRemove}
                    onAnalysisResult={async (analysisModels) => {
                      await persistAnalysisModels({ ...order, files }, file, analysisModels, persistOrder);
                    }}
                    configuration={configuration}
                  />
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex justify-between mt-2 bottom-0 py-8">
          <Button secondary disabled={persistPending} onClick={handleBack} dataCy="backButton">
            {localized.UPLOAD_BACK}
          </Button>

          {isAdmin && (
            <Button disabled={notStorable || persistPending} onClick={saveForTimeline}>
              &#128190; TIMELINE
            </Button>
          )}

          <Button disabled={nextDisabled || persistPending} onClick={handleNext} dataCy="continueButton">
            {localized.CONTINUE}
          </Button>
        </div>
      </div>
    </>
  );
}

export function applyBestMatchingMaterial(
  part: BlechconOrderFilePart,
  partTemplate: BlechconOrderFilePart,
  materials: BlechconMaterial[]
): BlechconOrderFilePart {
  const bending = part.bendable && partTemplate.bendable ? partTemplate.bending : part.bending;

  return {
    ...part,
    bending: bending,
    material: bestMatchingMaterial(part, partTemplate, materials),
    rotation: partTemplate.rotation,
    rotationAngle: partTemplate.rotationAngle,
    edgesRounded: partTemplate.edgesRounded,
  };
}

function sortFilesByCSV(currentFiles: BlechconOrderFile[], csvOrder: BlechconParameterCSV[]) {
  const sortedFiles: BlechconOrderFile[] = [];
  const files = [...currentFiles];
  csvOrder.forEach((csvEntry) => {
    const foundIndex = files.findIndex(
      (file) => file.name.toLowerCase() === (csvEntry.file.value as string).toLowerCase()
    );

    if (foundIndex !== -1) {
      sortedFiles.push(...files.splice(foundIndex, 1));
    }
  });

  return [...sortedFiles, ...files];
}

export function filesChanged(current: BlechconOrderFile[], saved: BlechconOrderFile[]): boolean {
  return JSON.stringify(current) !== JSON.stringify(saved);
}

function applyMaterialsForAssemblyPart(
  part: BlechconOrderFilePart,
  files: BlechconOrderFile[],
  materials: BlechconMaterial[]
): BlechconOrderFile[] {
  return files.map((file) => {
    const partBelongsToFile = part.isAssemblyPart && file.parts.some(({ fileId }) => fileId === part.fileId);

    if (partBelongsToFile) {
      const parts = file.parts.map((filePart) => applyBestMatchingMaterial(filePart, part, materials));
      return { ...file, parts };
    }
    return file;
  });
}

function applyMaterialsForStandalonePart(
  part: BlechconOrderFilePart,
  files: BlechconOrderFile[],
  materials: BlechconMaterial[]
): BlechconOrderFile[] {
  return files.map((file) => {
    if (!file.isAssembly) {
      const parts = file.parts.map((filePart) => applyBestMatchingMaterial(filePart, part, materials));
      return { ...file, parts };
    }
    return file;
  });
}
