import './Input.css';
import React, { useEffect, useRef, useState } from 'react';

type Props = {
  readonly name: string;
  readonly values: { [key: string]: string };
  readonly type?: 'text' | 'password' | 'number';
  readonly placeholder?: string;
  readonly readOnly?: boolean;
  readonly onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readonly onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  readonly errors?: { [key: string]: string | null };
  readonly autoFocus?: boolean;
  readonly autoComplete?: string;
  readonly dataCy?: string;
  readonly list?: string;
  readonly step?: number;
  readonly disabled?: boolean;
  readonly rounded?: boolean;
};

export function Input({
  name,
  values,
  type = 'text',
  placeholder,
  readOnly,
  onChange,
  onBlur,
  errors = {},
  autoFocus,
  autoComplete,
  dataCy,
  list,
  step,
  disabled,
  rounded,
}: Props): JSX.Element {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [touched, setTouched] = useState(false);
  const [focused, setIsFocused] = useState(false);

  const value = values[name];
  const error = errors[name];
  const hasValidationError = error && touched;

  useEffect(() => {
    if (autoFocus) {
      inputRef?.current?.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    if (focused && !disabled) {
      inputRef?.current?.focus();
    }
  }, [disabled, focused]);

  return (
    <div className={`input__wrapper ${hasValidationError ? 'error' : ''}`}>
      <input
        ref={inputRef}
        name={name}
        value={value}
        /*
           Input Step Buttons über JS verstecken, da ansonsten in Firefox der Wert sehr oft und sehr schnell
           verändert wird, wenn das Feld deaktiviert wird und zugleich ein Input Step Buttons geklickt wird.
        */
        type={disabled ? 'text' : type}
        placeholder={placeholder}
        readOnly={readOnly}
        autoComplete={autoComplete}
        step={step}
        disabled={disabled}
        className={rounded ? 'input__rounded' : ''}
        onFocus={() => setIsFocused(true)}
        onBlur={(event) => {
          setIsFocused(false);
          if (!touched) {
            setTouched(true);
            onChange && onChange(event);
            onBlur && onBlur(event);
          }
        }}
        onChange={(event) => onChange && onChange(event)}
        data-cy={dataCy}
        list={list}
      />
      {hasValidationError && <p className="input__error">{error}</p>}
    </div>
  );
}
