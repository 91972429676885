import './AdminOrdersPage.css';
import React, { useCallback, useEffect, useState } from 'react';
import { localized } from '../../../config/localization';
import format from 'date-fns/format';
import { DraftListView } from './DraftListView';
import { Button } from '../../../components/Button/Button';
import { BlechconOrder, BlechconOrderStatusGroup, LastEvaluatedKey } from '../../../blechcon';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../app/Routes';
import { useParams } from 'react-router';
import { useSearchParams } from '../../../services/useSearchParams';
import { CenteredRing } from '../../../components/CenteredRing/CenteredRing';
import { Page } from '../../../components/Page/Page';
import { getOrders } from '../../../services/api/blechconApi';
import { formatDate } from '../../../components/helper';
import { formatMeasure } from '../../../utils';
import { StatusBadge } from '../../../components/StatusBadge/StatusBadge';
import { FilterTiles } from '../../../components/FilterTiles/FilterTiles';

const tiles: {
  value: BlechconOrderStatusGroup;
  label: string;
}[] = [
  {
    value: 'draft',
    label: localized.DRAFT,
  },
  {
    value: 'submitted_withdraw',
    label: localized.REQUESTED_ORDERS,
  },
  {
    value: 'confirmed',
    label: localized.CONFIRMED_ORDERS,
  },
  {
    value: 'rejected',
    label: localized.REJECTED,
  },
  {
    value: 'canceled',
    label: localized.CANCELED_ORDERS,
  },
];

type Params = {
  status: BlechconOrderStatusGroup;
};

export function AdminOrdersPage(): JSX.Element {
  const { status = 'submitted_withdraw' } = useParams<Params>();
  const { search = '' } = useSearchParams();

  return <AdminOrderListView key={status} status={status} search={search} />;
}

type Props = {
  readonly status: BlechconOrderStatusGroup;
  readonly search: string;
};

function AdminOrderListView({ status, search }: Props) {
  const history = useHistory();

  const [state, setState] = useState<{
    isFetching: boolean;
    orders: Array<BlechconOrder>;
    lastEvaluatedKey: LastEvaluatedKey;
  }>({
    isFetching: false,
    orders: [],
    lastEvaluatedKey: null,
  });

  const fetchAllOrders = useCallback(
    async (lastEvaluatedKey: LastEvaluatedKey = null) => {
      setState((current) => ({
        ...current,
        isFetching: true,
      }));

      if (lastEvaluatedKey === undefined) {
        return;
      }

      getOrders(lastEvaluatedKey, status)
        .then((result) => {
          setState((current) => ({
            ...current,
            orders: [...current.orders, ...result.Items],
            lastEvaluatedKey: result.LastEvaluatedKey,
          }));
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setState((current) => ({
            ...current,
            isFetching: false,
          }));
        });
    },
    [status]
  );

  useEffect(() => {
    fetchAllOrders(null);
  }, [fetchAllOrders]);

  const getDataForTab = (): BlechconOrder[] => {
    return filter(
      state.orders,
      [
        (order: BlechconOrder) => order.id,
        (order: BlechconOrder) => order.email,
        (order: BlechconOrder) => format(new Date(order.createdAt), 'dd.MM.yyyy'),
      ],
      search
    );
  };

  return (
    <Page title={localized.ADMIN_ORDERS}>
      <FilterTiles
        tiles={tiles}
        activeTile={status}
        onClick={(tab) => {
          history.push({
            pathname: Routes.adminOrders(tab),
            search: search && `?search=${search}`,
          });
        }}
      />
      <div className="admin_orders_page__search">
        <label htmlFor="search">{localized.SEARCH}</label>
        <input
          id="search"
          defaultValue={search}
          onChange={(event) => {
            history.push({
              pathname: Routes.adminOrders(status),
              search: event.target.value && `?search=${event.target.value}`,
            });
          }}
        />
      </div>
      {status === 'draft' ? (
        <DraftListView draftOrders={getDataForTab()} />
      ) : (
        <table className="admin_orders_page__table">
          <thead>
            <tr>
              <th>{localized.OFFER_ID}</th>
              <th>{localized.CUSTOMER}</th>
              <th>{localized.CUSTOMER_ID}</th>
              <th>{localized.CUSTOMER_REFERENCE}</th>
              <th>{localized.DATE_CREATED}</th>
              <th>{localized.DATE_ORDERED}</th>
              <th>{localized.MATERIAL_COST} (EUR)</th>
              <th>{localized.SHIPPING_COST} (EUR)</th>
              <th>{localized.TOTAL} (EUR)</th>
              <th>{localized.APPROVAL_STATUS}</th>
            </tr>
          </thead>
          <tbody>
            {getDataForTab().map((order) => {
              return (
                <tr key={order.id} onClick={() => history.push(Routes.adminOrderDetails(order.id))}>
                  <td>{order.id}</td>
                  <td>{order.email}</td>
                  <td>{order.customerId}</td>
                  <td>{order.customerReference}</td>
                  <td>{formatDate(order.createdAt)}</td>
                  <td>{formatDate(order.orderedAt)}</td>
                  <td>{formatMeasure(order.prices.materialTotalPrice)}</td>
                  <td>
                    {formatMeasure(order.prices.shippingCost?.price)} <br /> {order.prices.shippingCost?.name}
                  </td>
                  <td>{formatMeasure(order.prices.totalPrice)}</td>
                  <td>
                    <StatusBadge orderStatus={order.orderStatus} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {state.isFetching && <CenteredRing />}

      {!state.isFetching && state.lastEvaluatedKey ? (
        <div className="admin_orders_page__load-more-button">
          <Button onClick={() => fetchAllOrders(state.lastEvaluatedKey)}>{localized.LOAD_MORE}</Button>
        </div>
      ) : null}
    </Page>
  );
}

function filter(
  unfilteredRows: BlechconOrder[],
  searchFieldSelectors: ((value: BlechconOrder) => string)[],
  searchTerm: string
) {
  return (unfilteredRows || []).filter((row) =>
    searchFieldSelectors
      .map((searchFieldSelector) => searchFieldSelector(row))
      .filter((value) => value)
      .map((value) => value.toLowerCase())
      .some((value) => value.includes(searchTerm.toLowerCase()))
  );
}
