import { AnalysisIssue, PartAnalysis } from '../../services/optimate/optimateTypes';

const ERROR_GROUP = 'g not_conform_faces_in_';

export function mapObjToPartAnalysis(objContent: string): PartAnalysis {
  const partAnalysis = {
    notes: [],
    models: [],
    issues: [],
  } as PartAnalysis;

  partAnalysis.models.push({ id: 'model', data: objContent, visible: true, type: 'OBJ' });

  if (objContent.indexOf('\r\n') !== -1) {
    // This is faster than String.split with regex that splits on both
    // see https://github.com/mrdoob/three.js/blob/d34df9328092529e14e3f7e8b6b4930d5e1d36cc/examples/jsm/loaders/OBJLoader.js#L495
    objContent = objContent.replace(/\r\n/g, '\n');
  }

  if (objContent.indexOf('\\\n') !== -1) {
    // join lines separated by a line continuation character (\)
    // see https://github.com/mrdoob/three.js/blob/d34df9328092529e14e3f7e8b6b4930d5e1d36cc/examples/jsm/loaders/OBJLoader.js#L502
    objContent = objContent.replace(/\\\n/g, '');
  }

  let errorStack = 0;
  const lines = objContent.split('\n');
  let analysisIssue: AnalysisIssue | null = null;

  for (let i = 0, l = lines.length; i < l; i++) {
    const line = lines[i].trimStart();

    if (line.length === 0) {
      continue;
    }

    const lineFirstChar = line.charAt(0);

    if (lineFirstChar === '#') {
      continue; // skip comments
    }

    if (line.startsWith(ERROR_GROUP)) {
      errorStack += 1;

      const issueName = line.substring(2);

      analysisIssue = {
        id: issueName,
        data: objContent,
        visible: false,
        issueId: errorStack,
        issueName,
        issueDetails: [],
        type: 'OBJ',
      } as AnalysisIssue;

      partAnalysis.issues.push(analysisIssue);
    }
  }

  return partAnalysis;
}
