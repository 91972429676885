import './UserSettingsPage.css';

import React, { useCallback } from 'react';
import { localized } from '../../../config/localization';
import { Page } from '../../../components/Page/Page';
import { useUserInfo } from '../../../services/useUserInfo';
import { BlechconAddress, BlechconUserInfo } from '../../../blechcon';
import { AddressTypeSelection } from './AddressTypeSelection';
import { updateUserMe } from '../../../services/api/blechconApi';
import { toast } from 'react-toastify';
import { Redirect, useParams } from 'react-router';
import { Routes } from '../../../app/Routes';
import { NavLink } from 'react-router-dom';
import { CustomerMaterialPageComponent } from './CustomerMaterialComponent';

type SettingType = 'shippingAddress' | 'invoiceAddress' | 'materialAlias';

type Params = {
  setting: SettingType;
};

export function UserSettingsPage(): JSX.Element {
  const { setting = 'shippingAddress' } = useParams<Params>();
  const [userInfo, setUserInfo] = useUserInfo();

  if (userInfo && !userInfo.csvParameterImportEnabled && setting === 'materialAlias') {
    return <Redirect to={Routes.userSettings('shippingAddress')} />;
  }

  return (
    <Page title={userInfo ? localized.SETTINGS : ''} loading={!userInfo}>
      {userInfo && <UserSettingsPageComponent setting={setting} userInfo={userInfo} setUserInfo={setUserInfo} />}
    </Page>
  );
}

type Props = {
  setting: SettingType;
  userInfo: BlechconUserInfo;
  setUserInfo: (value: BlechconUserInfo) => void;
};

function UserSettingsPageComponent({ setting, userInfo, setUserInfo }: Props): JSX.Element {
  const onChange = useCallback(
    (addresses: BlechconAddress[]) => {
      updateUserMe(
        setting === 'invoiceAddress'
          ? {
              invoiceAddresses: addresses,
            }
          : {
              shippingAddresses: addresses,
            }
      )
        .then((updatedUserInfo) => {
          setUserInfo(updatedUserInfo);
        })
        .catch((error) => {
          console.error('Failed to update user info data', error);
          toast.error(String(error));
        });
    },
    [setting, setUserInfo]
  );

  const onChangeRetainCsvImportOrder = useCallback(
    (retainCsvImportOrder: boolean) => {
      updateUserMe({ retainCsvImportOrder })
        .then((updatedUserInfo) => {
          setUserInfo(updatedUserInfo);
        })
        .catch((error) => {
          toast.error(error.message);
        });
    },
    [setUserInfo]
  );

  return (
    <div className="user-settings-page">
      <div className="user-settings-page-setting_menu">
        <SettingMenuItem label={localized.SHIPPING_ADDRESS} setting="shippingAddress" dataCy="shipping-addresses" />
        <SettingMenuItem label={localized.INVOICE_ADDRESS} setting="invoiceAddress" dataCy="invoice-addresses" />
        {userInfo.csvParameterImportEnabled && (
          <SettingMenuItem label={localized.CUSTOM_MATERIALS} setting="materialAlias" dataCy="material-alias" />
        )}
      </div>

      <div className="user-settings-page-setting_menu_selection">
        {setting === 'invoiceAddress' && (
          <AddressTypeSelection addresses={userInfo.invoiceAddresses} onChange={onChange} />
        )}
        {setting === 'shippingAddress' && (
          <AddressTypeSelection addresses={userInfo.shippingAddresses} onChange={onChange} />
        )}
        {setting === 'materialAlias' && (
          <CustomerMaterialPageComponent
            userInfo={userInfo}
            onChangeRetainCsvImportOrder={onChangeRetainCsvImportOrder}
          />
        )}
      </div>
    </div>
  );
}

type SettingMenuItemProps = {
  label: string;
  setting: SettingType;
  dataCy: string;
};

function SettingMenuItem({ label, setting, dataCy }: SettingMenuItemProps): JSX.Element {
  return (
    <NavLink
      to={Routes.userSettings(setting)}
      className="user-settings-page__menu-item"
      activeClassName="user-settings-page__menu-item--active"
      data-cy={dataCy}>
      {label}
    </NavLink>
  );
}
