import { apiGatewayBaseUrl, configForEnvironment } from '../../../utils';
import {
  BlechconOrderStatusGroup,
  BlechconUserGroupType,
  CustomerId,
  CustomerMaterialId,
  LastEvaluatedKey,
  OrderId,
  UserId,
} from '../../../blechcon';

export const BlechconUrls = {
  TOKEN: 'protected/token',
  OPTIMATE_TOKEN: 'protected/optimatetoken',
  USERS: (userStatus: BlechconUserGroupType, nextToken?: LastEvaluatedKey): string => {
    let path = `protected/users?status=${userStatus}`;
    if (nextToken) {
      path += `&nextToken=${nextToken}`;
    }
    return path;
  },
  TOGGLE_USER_STATUS: 'protected/toggleUserStatus',
  MATERIALS: 'protected/materials',
  ADMIN_CUSTOMER: (id: UserId | CustomerId): string => `protected/admin/customer/${id}`,
  ADMIN_USER: (id: UserId): string => `protected/admin/user/${id}`,
  ADMIN_CUSTOMERS: (lastEvaluatedKey?: LastEvaluatedKey) =>
    'protected/admin/customer' + (lastEvaluatedKey ? `?lastEvaluatedKey=${lastEvaluatedKey}` : ''),
  ADMIN_USER_GROUPS: (id: UserId): string => `protected/admin/user/${id}/userGroups`,
  ADMIN_RESET_PASSWORD: 'protected/admin/user/resetPassword',
  USERS_ME: 'protected/users/me',
  CUSTOMER_CONFIGURATION: 'protected/customer/configuration',
  ADMIN_ORDERS: (lastEvaluatedKey: LastEvaluatedKey, orderStatus: BlechconOrderStatusGroup): string => {
    let path = `protected/admin/orders?orderStatusGroup=${orderStatus}`;
    if (lastEvaluatedKey) {
      path += `&lastEvaluatedKey=${lastEvaluatedKey}`;
    }
    return path;
  },
  ORDER: 'protected/order',
  ORDER_ID: (id: OrderId): string => `protected/order/${id}`,
  ORDER_CSV: (id: OrderId): string => `protected/admin/order/${id}/csv`,
  SUBMIT_ORDER: (id: OrderId): string => `protected/order/${id}/submitted`,
  REJECT_ORDER: (id: OrderId): string => `protected/order/${id}/rejected`,
  CANCEL_ORDER: (id: OrderId): string => `protected/order/${id}/canceled`,
  ACCEPT_ORDER: (id: OrderId): string => `protected/order/${id}/confirmed`,
  WITHDRAW_REQUEST_ORDER: (id: OrderId): string => `protected/order/${id}/withdraw`,
  DELETE_CUSTOMER_MATERIAL: (customerId: string, customerMaterialId: CustomerMaterialId): string =>
    `protected/materialAlias/${customerId}/${encodeURIComponent(customerMaterialId)}`,
  CUSTOMER_MATERIALS: (customerId: string, lastEvaluatedKey?: LastEvaluatedKey): string =>
    `protected/materialAlias/${customerId}` + (lastEvaluatedKey ? `?lastEvaluatedKey=${lastEvaluatedKey}` : ''),
  CUSTOMER_ORDERS: (customerFilter: boolean, lastEvaluatedKey?: LastEvaluatedKey): string => {
    let path = `protected/customer/orders`;
    if (customerFilter || lastEvaluatedKey) {
      path += '?';
    }
    if (customerFilter) {
      path += `&filter=customer`;
    }
    if (lastEvaluatedKey) {
      path += `&lastEvaluatedKey=${lastEvaluatedKey}`;
    }
    return path;
  },
  FIND_DISTANCE: 'protected/geo/findDistance',
  PARSE_PARAMETERS_CSV: (id: OrderId): string => `protected/order/${id}/parseParametersCsv`,
  TRANSLATIONS: 'public/translations',
};

export const BaseUrls = {
  OPTIMATE_BASE: optimateBaseUrl(),
  WICAM_BASE: wicamBaseUrl(),
  BLECHCON_BASE: apiGatewayBaseUrl(),
};

function wicamBaseUrl(): string {
  if (window.location.host.match(/localhost/) !== null) {
    console.log('localhost detected');
    return 'http://localhost:3000/wicamv2';
  }

  return configForEnvironment().wicamV2Url;
}

function optimateBaseUrl(): string {
  if (window.location.host.match(/localhost/) !== null) {
    console.log('localhost detected');
    return 'http://localhost:3000/optimate';
  }

  return configForEnvironment().optimateUrl;
}
