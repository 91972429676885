import './CheckoutSteps.css';
import React from 'react';
import { Step } from './Step';
import { Steps } from './Steps';
import { localized } from '../../config/localization';
import { useRouteMatch } from 'react-router-dom';
import { Routes } from '../../app/Routes';

export function CheckoutSteps(): JSX.Element | null {
  const isUploadPage = useRouteMatch(Routes.CHECKOUT_UPLOAD) !== null;
  const isParametersPage = useRouteMatch(Routes.checkoutParameters(':id')) !== null;
  const isCalculationPage = useRouteMatch(Routes.checkoutCalculation(':id')) !== null;
  const isOrderPage = useRouteMatch(Routes.checkoutOrder(':id')) !== null;
  const isSuccessPage = useRouteMatch(Routes.checkoutSuccess(':id')) !== null;
  const isOrderSuccessPage = isOrderPage || isSuccessPage;

  return (
    <Steps>
      <Step
        number={1}
        name={localized.UPLOAD}
        active={isUploadPage}
        done={isParametersPage || isCalculationPage || isOrderSuccessPage}
      />
      <Step
        number={2}
        name={localized.PARAMETERS}
        active={isParametersPage}
        done={isCalculationPage || isOrderSuccessPage}
      />
      <Step number={3} name={localized.OVERVIEW} active={isCalculationPage} done={isOrderSuccessPage} />
      <Step number={4} name={localized.OFFER} active={isOrderSuccessPage} done={isSuccessPage} />
    </Steps>
  );
}
