import React from 'react';
import { BlechconOrder, BlechconOrderFile } from '../blechcon';
import { localized } from '../config/localization';
import { OrderTotal } from './OrderTotal/OrderTotal';
import { AddressComponent } from './AddressComponent';
import { formatDate } from './helper';
import { StatusBadge } from './StatusBadge/StatusBadge';

type Props = {
  order: BlechconOrder;
  children?: (({ file }: { file: BlechconOrderFile; index: number }) => React.ReactNode) | React.ReactNode;
};

export function OrderDetailView({ order, children }: Props): JSX.Element {
  return (
    <>
      <div className="flex justify-between">
        <div>
          <div className="text-primary font-bold mb-1">
            {localized.ORDER_ID}
            <span className="font-normal">: {order.id}</span>
          </div>
          <div className="text-primary font-bold mb-1 flex flex-row items-center">
            {localized.APPROVAL_STATUS}:
            <div className="ml-2">
              <StatusBadge orderStatus={order.orderStatus} />
            </div>
          </div>
          <div className="text-primary font-bold mb-1">
            {localized.DATE_ORDERED}
            <span className="font-normal">: {formatDate(order.orderedAt)}</span>
          </div>
          <div className="text-primary font-bold mb-1">
            {localized.DATE_CREATED}
            <span className="font-normal">: {formatDate(order.createdAt)}</span>
          </div>
          <div className="text-primary font-bold mb-1">
            {localized.DESIRED_DATE}
            <span className="font-normal">: {formatDate(order.delivery?.desiredDate)}</span>
          </div>
          <div className="text-primary font-bold mb-1">
            {order.delivery?.selfPickup ? localized.PICKUP_MESSAGE : localized.DELIVERY_DATE}
            <span className="font-normal">: {formatDate(order.delivery?.deliveryDate)}</span>
          </div>
          <div className="text-primary font-bold mb-1">
            {localized.CUSTOMER_REFERENCE}
            <span className="font-normal">: {order.customerReference}</span>
          </div>
        </div>
        <AddressComponent
          address={order.delivery?.invoiceAddress}
          title={
            order.delivery?.invoiceAddress?.id === order.delivery?.shippingAddress?.id && !order.delivery?.selfPickup
              ? localized.INVOICE_AND_SHIPPING_ADDRESS
              : localized.INVOICE_ADDRESS
          }
          selfPickup={order.delivery?.selfPickup}
        />
        {order.delivery?.invoiceAddress?.id !== order.delivery?.shippingAddress?.id && !order.delivery?.selfPickup ? (
          <AddressComponent address={order.delivery?.shippingAddress} title={localized.SHIPPING_ADDRESS} />
        ) : null}
      </div>
      <table className="w-full mt-4 table-auto">
        <thead>
          <tr className="bg-contentHeader h-10 screen:text-primary">
            <td className="font-bold text-sm w-3/5 uppercase">{localized.PRODUCT}</td>
            <td className="font-bold text-sm uppercase">{localized.QUANTITY}</td>
            <td className="font-bold text-sm uppercase">{localized.UNIT_PRICE} (EUR)</td>
            <td className="font-bold text-sm uppercase text-right pr-4">{localized.TOTAL} (EUR)</td>
          </tr>
        </thead>
        <tbody>
          {order.files.map((file, index) => {
            return typeof children === 'function'
              ? children({ file, index })
              : React.Children.map(children, (child) => child);
          })}
        </tbody>
      </table>
      <OrderTotal
        totalPrice={order.prices.totalPrice || 0}
        materialTotalPrice={order.prices.materialTotalPrice || 0}
        shippingCost={order.prices.shippingCost}
        selfPickup={order.delivery?.selfPickup || false}
        discountPrice={order.prices.discountPrice || 0}
      />
    </>
  );
}
