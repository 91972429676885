import React from 'react';
import { AddressForm } from '../../components/AddressForm';
import { BlechconAddress } from '../../blechcon';
import { localized } from '../../config/localization';
import { Label } from '../../components/Label/Label';

type Props = {
  invoiceAddress: BlechconAddress | undefined;
  onChange: (address: BlechconAddress) => void;
};

export function WelcomeWizard2({ invoiceAddress, onChange }: Props): JSX.Element {
  const address = invoiceAddress || ({} as BlechconAddress);

  return (
    <>
      <Label>{localized.INVOICE_ADDRESS_SELECT}</Label>
      <AddressForm showUseForShippingCheckbox={true} addressData={address} onChangeAddress={onChange} />
    </>
  );
}
