import './MaterialSelection.css';

import uniq from 'lodash/uniq';
import React, { useEffect, useState } from 'react';
import { BlechconMaterial, BlechconMaterialTypeIds, BlechconRawMaterialId } from '../../blechcon';
import { localized } from '../../config/localization';
import { formatMeasure } from '../../utils';
import { Label } from '../Label/Label';
import { Dropdown } from '../Dropdown/Dropdown';

type Props = {
  readonly materials: BlechconMaterial[];
  readonly partMaterial: BlechconMaterial | null;
  readonly partThickness?: number;
  readonly disabled?: boolean;
  readonly updateMaterial: (part: BlechconMaterial) => void;
};

export function MaterialSelection({
  materials,
  partMaterial,
  disabled,
  updateMaterial,
  partThickness,
}: Props): JSX.Element {
  const currentMaterialTypeId = materials.find(
    (it) => it.materialTypeID === partMaterial?.materialTypeID
  )?.materialTypeID;
  const [selectedMaterialType, setSelectedMaterialType] = useState<number | undefined>(currentMaterialTypeId);
  const selectableMaterialThicknesses = matchingThicknesses(Number(selectedMaterialType), partThickness, materials);

  const foundThickness = selectableMaterialThicknesses.includes(partMaterial?.materialThickness ?? -1);

  let currentMaterialThickness: number | undefined = undefined;
  let currentMaterialRawMaterialID: BlechconRawMaterialId | undefined = undefined;

  if (foundThickness) {
    currentMaterialThickness = materials.find(
      (it) => it.materialThickness === partMaterial?.materialThickness
    )?.materialThickness;

    currentMaterialRawMaterialID = materials.find(
      (it) => it.rawMaterialID === partMaterial?.rawMaterialID
    )?.rawMaterialID;
  }

  const [selectedMaterialThickness, setSelectedMaterialThickness] = useState<number | undefined>(
    currentMaterialThickness
  );

  const [selectedRawMaterialId, setSelectedRawMaterialId] = useState<BlechconRawMaterialId | undefined>(
    currentMaterialRawMaterialID
  );

  useEffect(() => {
    setSelectedMaterialType(currentMaterialTypeId);
    setSelectedMaterialThickness(currentMaterialThickness);
    setSelectedRawMaterialId(currentMaterialRawMaterialID);
  }, [currentMaterialTypeId, currentMaterialThickness, currentMaterialRawMaterialID]);

  const materialTypes: BlechconMaterialTypeIds[] = Array.from(new Set(materials.map((it) => it.materialTypeID))).sort(
    (left, right) => (left ?? 0) - (right ?? 0)
  );

  const selectableBlechconMaterials = materials
    .filter((it) => it.materialTypeID === selectedMaterialType)
    .filter((it) => it.materialThickness === selectedMaterialThickness)
    .sort((left, right) => {
      // die Sortierung wird von Blechcon verwaltet
      const sortLeft = left?.sortPosition ?? 0;
      const sortRight = right?.sortPosition ?? 0;
      return sortLeft - sortRight;
    });

  return (
    <>
      <div className="material-selection">
        <Label>{localized.MATERIAL_TYPE}</Label>
        <Dropdown
          disabled={materials.length === 0 || disabled}
          placeholder={localized.DROPDOWN_SELECTION}
          options={materialTypes.map((type) => ({
            label: localized.getString(`MATERIAL_TYPE_${type}`),
            value: String(type),
          }))}
          onChange={(value) => {
            setSelectedMaterialType(Number(value));
            setSelectedMaterialThickness(undefined);
            setSelectedRawMaterialId(null);
            updateMaterial({
              materialTypeID: Number(value) as BlechconMaterialTypeIds,
            });
          }}
          value={selectedMaterialType ? localized.getString(`MATERIAL_TYPE_${selectedMaterialType}`) : ''}
        />
      </div>

      <div className="material-selection">
        <Label>{localized.THICKNESS}</Label>
        <Dropdown
          disabled={selectableMaterialThicknesses.length === 0 || disabled}
          placeholder={localized.DROPDOWN_SELECTION}
          options={selectableMaterialThicknesses.map((thick, i) => ({
            label: `${formatMeasure(thick, 'mm')}`,
            value: String(i),
          }))}
          onChange={(value) => {
            setSelectedMaterialThickness(selectableMaterialThicknesses[Number(value)]);
            setSelectedRawMaterialId(null);
            updateMaterial({
              materialThickness: selectableMaterialThicknesses[Number(value)],
              materialTypeID: selectedMaterialType as BlechconMaterialTypeIds,
            });
          }}
          value={selectedMaterialThickness ? `${formatMeasure(selectedMaterialThickness, 'mm')}` : ''}
        />
      </div>

      <div className="material-selection">
        <Label>{localized.MATERIAL}</Label>
        <Dropdown
          disabled={!selectedMaterialThickness || disabled}
          placeholder={localized.DROPDOWN_SELECTION}
          options={selectableBlechconMaterials.map((concreteMaterial) => ({
            label: `${concreteMaterial.designation} - ${formatMeasure(concreteMaterial.materialThickness, 'mm')}`,
            value: concreteMaterial.rawMaterialID ?? '',
            className: concreteMaterial.isCustomerMaterial ? 'material-selection__materialSelection-customer' : '',
          }))}
          onChange={(value) => {
            const material = { ...materials.find((it) => it.rawMaterialID === value) };
            setSelectedRawMaterialId(material.rawMaterialID);
            updateMaterial(material);
          }}
          value={selectedRawMaterialId ?? ''}
        />
      </div>
    </>
  );
}

export function matchingThicknesses(
  materialTypeId: number | undefined,
  thickness: number | undefined,
  materials: BlechconMaterial[]
): number[] {
  return uniq(
    materials
      .filter((it: BlechconMaterial) => it.materialTypeID === materialTypeId)
      .filter((it) => (thickness ? thickness === it.materialThickness : it.materialThickness))
      .map((it) => it.materialThickness)
      .filter((it) => it)
  ).sort((left = 0, right = 0) => (left > right ? 1 : -1)) as number[];
}
