import { useEffect, useState } from 'react';
import { BlechconCustomerConfiguration } from '../../blechcon';
import { toast } from 'react-toastify';
import { localized } from '../../config/localization';
import { getBlechconConfiguration } from '../../services/api/blechconApi';

export function useConfiguration(): BlechconCustomerConfiguration | null {
  const [configuration, setConfiguration] = useState<BlechconCustomerConfiguration | null>(null);

  useEffect(() => {
    getBlechconConfiguration()
      .then((response: BlechconCustomerConfiguration) => {
        setConfiguration(response);
      })
      .catch((error) => {
        console.error('Error in useConfiguration', error);
        toast.error(localized.CONFIGURATION_LOAD_ERROR);
      });
  }, []);

  return configuration;
}
