import React, { Fragment, useState } from 'react';
import { localized, t } from '../../../config/localization';
import { Bin } from '../../../assets/svg/Bin';
import { UploadPageViewModel } from './UploadPageViewModel';
import { Modal } from '../../../components/Modal';
import { formatNumber } from '../../../utils';
import { Button } from '../../../components/Button/Button';
import { CenteredRing } from '../../../components/CenteredRing/CenteredRing';
import { PreviewImagePlaceholder } from '../../../components/Images/PreviewImagePlaceholder';
import { PreviewImages } from '../../../components/Images/PreviewImages';
import { BlechconOrderFilePart } from '../../../blechcon';
import { Colors } from '../../../config/colors';

type Props = {
  readonly disabled: boolean;
  readonly viewModels: UploadPageViewModel[];
  readonly removeViewModel: (file: UploadPageViewModel) => void;
  readonly updateViewModel: (file: UploadPageViewModel) => void;
};

export function UploadPageListComponent({
  disabled,
  viewModels,
  removeViewModel,
  updateViewModel,
}: Props): JSX.Element {
  return (
    <>
      <table className="upload-page__table">
        <tr className="upload-page__table-header">
          <th>{localized.PRODUCT}</th>
          <th>{localized.FILE}</th>
          <th>{localized.DIMENSIONS}</th>
          <th></th>
        </tr>
        {viewModels.map((viewModel, index) => {
          return (
            <ListItem
              key={index}
              index={index}
              disabled={disabled}
              viewModel={viewModel}
              removeViewModel={removeViewModel}
              updateViewModel={updateViewModel}
            />
          );
        })}
      </table>
    </>
  );
}

type ListItemProps = {
  readonly disabled: boolean;
  readonly viewModel: UploadPageViewModel;
  readonly index: number;
  readonly removeViewModel: (file: UploadPageViewModel) => void;
  readonly updateViewModel: (file: UploadPageViewModel) => void;
};

function ListItem({ disabled, viewModel, index, removeViewModel, updateViewModel }: ListItemProps): JSX.Element | null {
  const [viewModelToRemove, setViewModelToRemove] = useState<UploadPageViewModel | null>(null);
  const [partToRemove, setPartToRemove] = useState<BlechconOrderFilePart | null>(null);

  const buttonFill = disabled ? Colors.label : undefined;

  if (viewModel.file) {
    return (
      <tr className={viewModel.failed ? ' uploadPage__entry__error' : ''} data-cy={`assembly-head-${index}`}>
        <td>
          <div>
            <span>{index + 1}</span>
            <PreviewImagePlaceholder />
          </div>
        </td>
        <td>
          <div className="upload-page__list-item__file-name" data-cy={`file-name-${viewModel.file.name}`}>
            {viewModel.file.name}
          </div>
        </td>
        <td></td>
        <td>
          {!viewModel.failed ? (
            <>
              <CenteredRing />
              <div className="upload-page__loading-state__text">{localized.DURATION_WARNING}</div>
            </>
          ) : (
            <Button
              className="upload-page__remove-button"
              danger
              disabled={disabled}
              onClick={() => removeViewModel(viewModel)}>
              <Bin fill={buttonFill} />
              &nbsp;{localized.REMOVE}
            </Button>
          )}
        </td>
      </tr>
    );
  }

  if (viewModel.orderFile) {
    return (
      <Fragment key={index}>
        {viewModelToRemove && (
          <Modal
            title={localized.REMOVE_FILE}
            onCancel={{
              label: localized.ABORT,
              execute: () => {
                setPartToRemove(null);
                setViewModelToRemove(null);
              },
            }}
            onSuccess={{
              label: localized.REMOVE,
              execute: () => {
                if (!viewModelToRemove || !viewModelToRemove.orderFile) {
                  setPartToRemove(null);
                  setViewModelToRemove(null);
                  return;
                }

                if (!partToRemove) {
                  removeViewModel(viewModelToRemove);
                } else {
                  const parts = viewModelToRemove.orderFile?.parts.filter((it) => it.id !== partToRemove.id);
                  if (parts.length === 0) {
                    removeViewModel(viewModelToRemove);
                  } else {
                    updateViewModel({
                      ...viewModelToRemove,
                      orderFile: {
                        ...viewModelToRemove.orderFile,
                        parts,
                      },
                    });
                  }
                }

                setPartToRemove(null);
                setViewModelToRemove(null);
              },
            }}>
            <p>{localized.REMOVE_FILE_CONFIRMATION}</p>
          </Modal>
        )}
        {viewModel.orderFile?.isAssembly && (
          <tr data-cy={`assembly-head-${index}`}>
            <td className="upload-page__preview">
              <div>
                <span>{index + 1}</span>
                <PreviewImagePlaceholder />
              </div>
            </td>
            <td>
              <div className="upload-page__list-item__file-name" data-cy={`file-name-${viewModel.orderFile.name}`}>
                {viewModel.orderFile.name}
              </div>
            </td>
            <td></td>
            <td>
              <Button
                className="upload-page__remove-button"
                danger
                disabled={disabled}
                onClick={() => setViewModelToRemove(viewModel)}>
                <Bin fill={buttonFill} />
                &nbsp;{localized.REMOVE}
              </Button>
            </td>
          </tr>
        )}

        {(viewModel.orderFile?.parts || []).map((part, partIndex) => (
          <tr
            className={'uploadPage__entry' + (part.errorCode ? ' uploadPage__entry__error' : '')}
            key={`${index} ${partIndex}`}
            data-cy={`file-part-${index}-${partIndex}`}>
            <td>
              <span>
                {index + 1}
                {part.isAssemblyPart ? `.${partIndex + 1}` : ''}
              </span>
              <PreviewImages rotationAngle={part.rotationAngle} images={part.previewImage} showOnly2D />
            </td>
            <td className="upload-page__list-item__file-name">
              {!viewModel.orderFile?.isAssembly && (
                <div data-cy={`file-name-${viewModel.orderFile?.name}`}>{viewModel.orderFile?.name}</div>
              )}
            </td>
            <td>
              <span className="upload-page__dimensions">
                {`${formatNumber(part.length)} mm x ${formatNumber(part.width)} mm`}
              </span>
              {part.errorCode && <div className="text-error">{t(part.errorCode)}</div>}
              {viewModel.unitConverted && <div className="text-error">{t('UNIT_CONVERTED')}</div>}
            </td>
            <td>
              <Button
                className="upload-page__remove-button"
                danger
                disabled={disabled}
                onClick={() => {
                  setPartToRemove(part);
                  setViewModelToRemove(viewModel);
                }}>
                <Bin fill={buttonFill} />
                &nbsp;{localized.REMOVE}
              </Button>
            </td>
          </tr>
        ))}
      </Fragment>
    );
  }

  return null;
}
