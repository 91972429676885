import './assets/tailwind.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';
import { App } from './app/App';
import { configForEnvironment } from './utils';
import { getTranslations } from './services/api/blechconApi';
import { addTranslations } from './config/localization';

Amplify.configure(configForEnvironment().config);

getTranslations()
  .then(addTranslations)
  .then(() => {
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById('root')
    );
  });
