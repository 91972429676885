import './FileStats.css';

import React from 'react';

type FileStatsProps = {
  label: React.ReactNode;
  value: React.ReactNode;
  unit?: string;
  className?: string;
  dataCy?: string;
};

export const FileStats = ({ label, value, unit = undefined, className = '', dataCy }: FileStatsProps) => {
  return (
    <div className={`file-stats__container ${className}`}>
      <div className="file-stats__label">{label}</div>
      <div className="file-stats__value" data-cy={dataCy}>
        {value ? value : '-'}
        {unit && value ? ` ${unit}` : ''}
      </div>
    </div>
  );
};
