/* eslint sonarjs/no-duplicate-string: 0 */
export const config = {
  aws_project_region: 'eu-central-1',
  aws_cognito_identity_pool_id: 'eu-central-1:9e066402-87e1-4f52-9c61-c86567a34d2a',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_RNdblCAOi',
  aws_user_pools_web_client_id: '5cmi7vimsorqifk1gv9sh4t11l',
  aws_user_files_s3_bucket: 'blechcons3104958-prod',
  aws_user_files_s3_bucket_region: 'eu-central-1',
};

export const wicamV2Url = 'https://api-v2-prod.wicam.com/';

export const optimateUrl = 'https://api.optimate.de/';

export const apigwUrl = 'https://akfsqmfj3k.execute-api.eu-central-1.amazonaws.com/prod/';
