import React from 'react';
import { Upload as UploadIcon } from '../../../assets/svg/Upload';
import { localized } from '../../../config/localization';
import { Button } from '../../../components/Button/Button';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { cadExtensions } from '../../../blechcon';

type Props = {
  readonly classNames: string;
  readonly disabled?: boolean;
  readonly onDropFiles: (files: File[]) => void;
};

export const DropZoneInput = ({ classNames, disabled, onDropFiles }: Props): JSX.Element => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxSize: 25 * 1000 * 1000, // 25 MB
    accept: cadExtensions.map((it) => `.${it}`),
    onDrop: (files) => onDropFiles(files),
    onDropRejected(fileRejections) {
      const errors = fileRejections.flatMap((rejection) => rejection.errors);
      const errorCodes = errors.map((error) => error.code);
      if (errorCodes.includes('file-too-large')) {
        toast.error(localized.FILE_SIZE_TOO_LARGE_ERROR);
      }
    },
  });

  return (
    <div {...getRootProps()} data-cy="dropzone">
      <input {...getInputProps()} disabled={disabled} />
      {isDragActive ? (
        <div className={classNames}>
          <UploadIcon />
          <p className="upload-page__upload-area__text">
            {localized.ORDER_DROP_FILE} <br />
            <span className="upload-page__upload-area__text__dragActive">{localized.OR}</span>
          </p>
          <span className="upload-page__upload-area__text__dragActive">
            <Button disabled={disabled}>{localized.ORDER_CHOOSE_FILE}</Button>
          </span>
        </div>
      ) : (
        <div className={classNames}>
          <UploadIcon />
          <p className="upload-page__upload-area__text">
            {localized.ORDER_DRAG_FILE} <br />
            {localized.OR}
          </p>
          <Button disabled={disabled}>{localized.ORDER_CHOOSE_FILE}</Button>
        </div>
      )}
    </div>
  );
};
