import './UploadPage.css';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { localized } from '../../../config/localization';
import { Button } from '../../../components/Button/Button';
import { UploadPageViewModel } from './UploadPageViewModel';
import { UploadPageListComponent } from './UploadPageListComponent';
import { BlechconCustomerConfiguration, BlechconOrder, BlechconOrderFile, isValidFile } from '../../../blechcon';
import { processFile } from '../../../services/wicam/processFile';
import { Routes } from '../../../app/Routes';
import { DropZoneInput } from './DropZoneInput';
import { CenteredRing } from '../../../components/CenteredRing/CenteredRing';

type Props = {
  readonly order: BlechconOrder | null;
  readonly configuration: BlechconCustomerConfiguration;
  readonly persistPending: boolean;
  readonly persistOrder: (order: Partial<BlechconOrder>) => Promise<BlechconOrder | null>;
};

type ParamsType = {
  readonly id: string | undefined;
};

export function UploadPage({ order, configuration, persistPending, persistOrder }: Props): JSX.Element | null {
  const history = useHistory();
  const { id = null } = useParams<ParamsType>();

  const abortRef = useRef(new AbortController());
  const [viewModels, setViewModels] = useState<UploadPageViewModel[]>(mapToViewModels(order?.files));

  const isLoading = viewModels.some((it) => !it.orderFile) || persistPending;
  const hasInvalidFiles = viewModels.some((it) => !it.orderFile || !isValidFile(it.orderFile));

  /*
   * Kunden, für die die Option freigeschaltet ist, können trotz Fehlers in Schritt-1 mit Schritt-2 fortfahren.
   * In Schritt-2 kann der Kunde dann über Optimate das fehlerhafte Teil prüfen/optimieren.
   */
  const optimateActive = configuration.optimateActive;
  const continueDisabled = (!optimateActive && hasInvalidFiles) || isLoading;

  useEffect(() => {
    setViewModels(mapToViewModels(order?.files));
  }, [order?.files]);

  useEffect(() => {
    const copy = abortRef.current;
    return () => {
      copy.abort();
    };
  }, []);

  useEffect(() => {
    const orderFiles = viewModels.map((it) => {
      if (it.orderFile) {
        it.orderFile.unitConverted = it.unitConverted;
      }
      return it.orderFile;
    });

    const files = determineUnsavedFiles(orderFiles, order?.files ?? []);

    if (files) {
      persistOrder({ id: order?.id, checkoutStep: 1, files }).then((updatedOrder) => {
        if (!id && updatedOrder?.id) {
          history.push(Routes.checkoutUpload(updatedOrder.id));
        }
      });
    }
  }, [history, id, order?.files, order?.id, persistOrder, viewModels]);

  useEffect(() => {
    const process = (viewModel: UploadPageViewModel) => {
      if (viewModel.file) {
        processFile(abortRef.current.signal, viewModel.file)
          .then((result) => {
            onOrderFileProcessed(viewModel.index, result);
          })
          .catch(() => {
            if (abortRef.current.signal.aborted) {
              return;
            }

            setViewModels((currentViewModels) => {
              const newViewModels: UploadPageViewModel[] = [];

              for (const currentViewModel of currentViewModels) {
                if (currentViewModel.index === viewModel.index) {
                  newViewModels.push({
                    ...viewModel,
                    failed: true,
                    processing: false,
                  });
                } else {
                  newViewModels.push(currentViewModel);
                }
              }

              return newViewModels;
            });
          });
      }
    };
    for (let viewModel of viewModels) {
      if (!viewModel.failed && !viewModel.processing && viewModel.file) {
        viewModel.processing = true;

        if (isDxfFile(viewModel)) {
          viewModel.file.text().then((textString) => {
            viewModel = checkAndReplaceUnitEntry(textString, viewModel);
            process(viewModel);
          });
        } else {
          process(viewModel);
        }
      }
    }
  }, [viewModels]);

  async function onFilesDrop(files: File[]) {
    setViewModels((current) => {
      let index = current[current.length - 1]?.index;

      if (index === undefined) {
        index = -1;
      }

      const newViewModels: UploadPageViewModel[] = [];

      for (const file of files) {
        newViewModels.push({
          index: index + 1,
          processing: false,
          failed: false,
          file: file,
        });
        index += 1;
      }

      return [...current, ...newViewModels];
    });
  }

  async function handleNext() {
    const updatedOrder = await persistOrder({ id: order?.id, checkoutStep: 2 });
    if (updatedOrder) {
      history.push(Routes.checkoutParameters(updatedOrder.id));
    }
  }

  const onOrderFileProcessed = (index: number, orderFile: BlechconOrderFile) => {
    setViewModels((current) => {
      return current.map((viewModel) => {
        if (viewModel.index === index) {
          return {
            index: viewModel.index,
            processing: false,
            failed: false,
            orderFile: orderFile,
            unitConverted: viewModel.unitConverted,
          };
        } else {
          return viewModel;
        }
      });
    });
  };

  const removeViewModel = (viewModel: UploadPageViewModel) => {
    setViewModels((current) => {
      return current.filter((it) => it.index !== viewModel.index);
    });
  };

  const updateViewModel = (viewModel: UploadPageViewModel) => {
    setViewModels((current) => {
      return current.map((it) => {
        return it.orderFile?.id !== viewModel.orderFile?.id ? it : viewModel;
      });
    });
  };

  return !persistPending ? (
    <>
      <DropZoneInput classNames="upload-page__upload-area" disabled={persistPending} onDropFiles={onFilesDrop} />
      <div className="upload-page__list-container">
        {viewModels.length > 0 ? (
          <UploadPageListComponent
            disabled={persistPending}
            viewModels={viewModels}
            removeViewModel={removeViewModel}
            updateViewModel={updateViewModel}
          />
        ) : null}

        <div className="upload-page__next-button">
          {viewModels.length > 0 && (
            <Button disabled={continueDisabled} onClick={handleNext} dataCy="continueButton">
              {localized.CONTINUE}
            </Button>
          )}
        </div>
      </div>
    </>
  ) : (
    <CenteredRing />
  );
}

function checkAndReplaceUnitEntry(textString: string, viewModel: UploadPageViewModel) {
  const unitRegExToMatch = /(\$INSUNITS).*?(?=\$)/gs;
  const match = textString.match(unitRegExToMatch);
  if (match) {
    const matchStrings = match[0].split('\r\n');
    // 0 = Unset / 4 = mm
    if (matchStrings && matchStrings[2] && matchStrings[2].trim() !== '0' && matchStrings[2].trim() !== '4') {
      textString = textString.replace(unitRegExToMatch, '');
      viewModel.file = new File([textString], viewModel.file?.name ?? '');
      viewModel.unitConverted = true;
    }
  }
  return viewModel;
}

function isDxfFile(viewModel: UploadPageViewModel) {
  return viewModel.file?.name.toLowerCase().endsWith('.dxf');
}

function mapToViewModels(files: BlechconOrderFile[] | undefined) {
  if (files) {
    const tmp: UploadPageViewModel[] = [];
    let index = 0;

    for (const file of files) {
      tmp.push({
        index,
        processing: false,
        failed: false,
        orderFile: file,
        unitConverted: file.unitConverted,
      });
      index += 1;
    }

    return tmp;
  }
  return [];
}

export function determineUnsavedFiles(
  newFilesWithUndefined: (BlechconOrderFile | undefined)[],
  oldFiles: BlechconOrderFile[]
): BlechconOrderFile[] | undefined {
  // wir speichern nur dann ab, wenn alle Dateien verarbeitet wurden, um race conditions zu vermeiden
  if (newFilesWithUndefined.some((file) => file === undefined)) {
    return undefined;
  }

  const newFiles = newFilesWithUndefined.filter((it) => it) as BlechconOrderFile[];
  const newIds = newFiles.map((o) => o.id);

  const filesEqual = oldFiles.every((f) => newIds.includes(f.id)) && oldFiles.length === newFiles.length;

  const partsEqual = oldFiles.every((oldF) => {
    const newFile = newFiles.find((newF) => newF.id === oldF?.id);
    return newFile?.parts?.length === oldF.parts?.length;
  });

  return filesEqual && partsEqual ? undefined : newFiles;
}
