import './FileUploadButton.css';
import React, { ChangeEvent, useRef } from 'react';
import { toast } from 'react-toastify';
import { Button, Props as ButtonProps } from '../../components/Button/Button';
import { localized } from '../../config/localization';
import { BlechconDocument } from '../../blechcon';

type Props = {
  accept?: string;
  onUpload: (document: BlechconDocument) => void;
} & ButtonProps;

export function FileUploadButton(props: Props): JSX.Element {
  const ref = useRef<HTMLInputElement>(null);

  const { onUpload, onClick, accept, children, ...buttonProps } = props;

  const clearInput = () => {
    if (ref.current) {
      ref.current.value = '';
    }
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files && event.target?.files[0];

    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      onUpload({
        name: file.name,
        mimetype: file.type,
        data: reader.result,
      } as BlechconDocument);

      clearInput();
    };

    reader.onerror = reader.onabort = () => {
      clearInput();
      toast.error(localized.GENERAL_ERROR);
    };

    reader.readAsDataURL(file);
  };

  return (
    <>
      <Button
        {...buttonProps}
        onClick={(event) => {
          ref.current?.click();
          onClick && onClick(event);
        }}>
        {children}
      </Button>

      <input
        ref={ref}
        accept={accept || '*'}
        onChange={onChange}
        className="file-upload-button__input--hidden"
        type="file"
      />
    </>
  );
}
