import './TopNavigation.css';
import React, { useContext } from 'react';
import { BlechconLogo } from '../../assets/svg/BlechconLogo';
import { localized } from '../../config/localization';
import { AuthContext, AuthContextProps } from '../../services/AuthContext';
import { LanguageSelector } from './LanguageSelector';

export function TopNavigation(): JSX.Element {
  const { userEmail, logout, setInterfaceLanguage, user } = useContext<AuthContextProps>(AuthContext);

  return (
    <nav className="top-navigation">
      <div className="top-navigation__logo">
        <BlechconLogo />
      </div>

      <div className="page__top-navigation__actions">
        <LanguageSelector onClick={(language) => setInterfaceLanguage(language)} />

        {user && (
          <>
            <div onClick={logout} className="text-mutedText cursor-pointer pl-6" data-cy="signOutButton">
              {localized.SIGN_OUT} ({userEmail})
            </div>
          </>
        )}
      </div>
    </nav>
  );
}
