import { Plus } from '../../assets/svg/Plus';
import './Toggle.css';
import React from 'react';

type Props = {
  on: boolean;
  onClick: () => void;
};

export function Toggle({ on, onClick }: Props): JSX.Element {
  return (
    <div className="toggle" onClick={() => onClick()}>
      {on ? <Minus /> : <Plus />}
    </div>
  );
}

function Minus() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
      <path className="a" d="M0 10h24v4h-24z" />
    </svg>
  );
}
