import React from 'react';

type QuantityProps = {
  readonly disabled: boolean;
  readonly value: number;
  readonly setValue: (value: number) => void;
};

export const Quantity = ({ disabled, value, setValue }: QuantityProps): JSX.Element => {
  function handleChangeValue(event: React.ChangeEvent<HTMLInputElement>) {
    const { value: targetValue } = event.target;

    if (targetValue === '' || !/^\d+$/.test(targetValue)) {
      setValue(0);
    } else {
      setValue(parseInt(targetValue));
    }
  }

  function decrement() {
    if (value > 1) {
      setValue(value - 1);
    }
  }

  function increment() {
    setValue(value + 1);
  }

  return (
    <div className="flex items-center">
      <button
        disabled={disabled}
        onClick={decrement}
        className="bg-white text-md text-gray-600 border border-background rounded w-10 h-10">
        -
      </button>
      <div className="bg-white text-primary text-sm border border-background rounded mx-2 pt-1 w-12 h-10 flex items-center justify-center">
        <input
          type="text"
          value={value}
          disabled={disabled}
          className="w-full text-center quantity__input"
          onChange={handleChangeValue}
          data-cy="input-quantity"
        />
      </div>
      <button
        disabled={disabled}
        onClick={increment}
        className="bg-white text-md text-gray-600 border border-background rounded w-10 h-10">
        +
      </button>
    </div>
  );
};
