import chunk from 'lodash/chunk';
import format from 'date-fns/format';
import { BlechconFile } from '../blechcon';
import { api as wicamApi } from '../services/wicam';
import { localized } from '../config/localization';
import { S3Client } from '../services/s3Client';

function presentDownloadDialogForUrl(name: string, url: string): void {
  const link = document.createElement('a');
  link.href = url;
  link.download = name;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
}

export function presentDownloadDialog(name: string, blob: Blob | string): void {
  const url = window.URL.createObjectURL(new Blob([blob]));
  presentDownloadDialogForUrl(name, url);
}

export function presentDownloadDialogForS3Key(name: string, key: string): void {
  S3Client.constructPreSignedUrl(key).then((url) => presentDownloadDialogForUrl(name, url));
}

const DOWNLOAD_BATCH_SIZE = 10; // Number of items per batch
const DOWNLOAD_TIME_OFFSET = 1500; // Time between download batches in ms

/**
 * Downloads lists of files in smaller batches to counteract limits of browsers.
 * More than 10 concurrent downloads in Chrome lead to files being left out.
 *
 * The time between batches makes it less likely that a new batch starts while others are still loading,
 * which would skip some items of the new batch.
 */
export async function downloadDocumentsInBatches(nestingFiles: BlechconFile[]): Promise<void> {
  const nestingFileBatches = chunk(nestingFiles, DOWNLOAD_BATCH_SIZE);

  for (const [i, nestingFileBatch] of nestingFileBatches.entries()) {
    setTimeout(() => {
      for (const document of nestingFileBatch) {
        if (document.href) {
          wicamApi
            .GET(document.href, { responseType: 'blob' })
            .then(({ data }) => presentDownloadDialog(document.name, data));
        }
      }
    }, DOWNLOAD_TIME_OFFSET * i);
  }
}

/**
 *  Formatiert yyyy-MM-dd'T'HH:mm:ss.SSSZ zu dd.MM.yyyy oder '-' wenn {@param dateString} undefined oder null ist.
 */
export function formatDate(dateString: string | null | undefined): string {
  let formatted = '-';
  try {
    formatted = dateString ? format(new Date(dateString), localized.DATE_FORMAT) : '-';
  } catch (error) {
    // ignore me
  }
  return formatted;
}
