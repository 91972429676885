import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { localized } from '../../../config/localization';
import { ShippingCost } from '../../../blechcon';
import { Input } from '../../../components/Input/Input';

type Props = {
  disabled?: boolean;
  shippingCost: ShippingCost | undefined;
  onChange: (shippingCost: ShippingCost) => void;
};

export function CustomShippingCostInput({ disabled, shippingCost, onChange }: Props): JSX.Element {
  const [customShippingCost, setCustomShippingCost] = useState(shippingCost?.price || '');
  const [error, setError] = useState<string | null>(null);

  const handleCustomShippingCost = useDebouncedCallback((value: string) => {
    const valueAsNumber = Number(value);

    if (isFinite(valueAsNumber)) {
      onChange({
        name: 'custom',
        price: valueAsNumber,
      });
      setError(null);
    } else {
      setError(localized.INVALID_NUMBER);
    }
  }, 500);

  useEffect(() => {
    setCustomShippingCost(shippingCost?.price || '');
  }, [shippingCost]);

  return (
    <>
      <label className="admin-order-details-custom-shipping-cost-label">{localized.SHIPPING_COST_CUSTOM}</label>
      <Input
        name="customShippingCost"
        type="text"
        values={{ customShippingCost: String(customShippingCost) }}
        disabled={disabled}
        errors={{ customShippingCost: error }}
        onChange={(event) => {
          setCustomShippingCost(event.target.value);
          handleCustomShippingCost(event.target.value);
        }}
      />
    </>
  );
}
