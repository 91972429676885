import React from 'react';
import { localized } from '../config/localization';
import { Button } from './Button/Button';
import { Download } from '../assets/svg/Download';
import { BlechconDocument } from '../blechcon';
import { presentDownloadDialogForS3Key } from './helper';

type Props = {
  document: BlechconDocument;
};

export default function DownloadAdditionalFile({ document }: Props): JSX.Element {
  return (
    <>
      {document && (
        <Button
          onClick={() => {
            if (document.data) {
              presentDownloadDialogForS3Key(document.name, document.data);
            }
          }}>
          <Download fill="currentColor" />
          {localized.DOWNLOAD}
        </Button>
      )}
    </>
  );
}
