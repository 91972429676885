import React, { Fragment } from 'react';

type Props = {
  className?: string;
  children: React.ReactNode[];
};

export function Steps({ className = '', children }: Props): JSX.Element {
  return (
    <div className={`checkout-steps ${className}`}>
      {children.map((step, index) => {
        return (
          <Fragment key={index}>
            {step}
            {index < children.length - 1 && (
              <div className="checkout-steps__connection">
                <div />
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
}
