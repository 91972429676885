/**
 * https://github.com/JoshK2/react-spinners-css/blob/8540b946205edc616d6da09e7532d100f41528b7/src/components/Ring/index.js
 */
import React from 'react';
import './Ring.css';

export type Props = {
  color?: string;
  size?: number;
  style?: { [key: string]: any };
  className?: '';
};

export function Ring({ color = '#94C21A', size = 80, style = {}, className = '' }: Props): JSX.Element {
  const circles = [...Array(4)].map((_, index) => {
    return (
      <div
        key={index}
        style={{
          borderColor: `${color} transparent transparent transparent`,
          width: size * 0.8,
          height: size * 0.8,
          margin: size * 0.1,
          borderWidth: size * 0.1,
        }}
      />
    );
  });

  return (
    <div className={`lds-ring ${className}`} style={{ width: size, height: size, ...style }} data-cy="loadingSpinner">
      {circles}
    </div>
  );
}
