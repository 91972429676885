import React from 'react';

type Props = {
  style?: React.CSSProperties;
  className?: string;
};

export const ArrowBidirectional = (props: Props): JSX.Element => {
  return (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512.04 512.04" {...props}>
      <g>
        <g>
          <path
            d="M508.933,248.353L402.267,141.687c-4.267-4.053-10.987-3.947-15.04,0.213c-3.947,4.16-3.947,10.667,0,14.827
        l88.427,88.427H36.4l88.427-88.427c4.053-4.267,3.947-10.987-0.213-15.04c-4.16-3.947-10.667-3.947-14.827,0L3.12,248.353
        c-4.16,4.16-4.16,10.88,0,15.04L109.787,370.06c4.267,4.053,10.987,3.947,15.04-0.213c3.947-4.16,3.947-10.667,0-14.827
        L36.4,266.593h439.147L387.12,355.02c-4.267,4.053-4.373,10.88-0.213,15.04c4.053,4.267,10.88,4.373,15.04,0.213
        c0.107-0.107,0.213-0.213,0.213-0.213l106.667-106.667C513.093,259.34,513.093,252.513,508.933,248.353z"
          />
        </g>
      </g>
    </svg>
  );
};
