import React from 'react';
import { BlechconAddress } from '../blechcon';
import { localized } from '../config/localization';

type Props = {
  address: BlechconAddress | undefined;
  title: string;
  selfPickup?: boolean;
};

export function AddressComponent({ address, title, selfPickup }: Props): JSX.Element {
  if (!address) {
    return <></>;
  }
  return (
    <div className="min-w-40 text-primary">
      <div className="font-bold">{title}</div>
      <div>{address.addressLine}</div>
      <div>{address.addressLineTwo}</div>
      <div>{address.street}</div>
      <div>{address.city}</div>
      <div>{address.state}</div>
      {selfPickup && <div className="font-bold">{localized.PICKUP_YOURSELF}</div>}
    </div>
  );
}
