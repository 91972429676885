import { api } from './index';
import { AnalysisModel, AnalysisIssue, PartAnalysis, PartModel } from './optimateTypes';

async function fetchStl(url: string): Promise<string> {
  const result = await api.GET(url);
  const fileLocation = result.headers['location'];
  const fileResponse = await fetch(fileLocation);
  return blobToBase64(await fileResponse.blob());
}

export async function analysisModelToPartAnalysis(analysisModel: AnalysisModel): Promise<PartAnalysis> {
  const models: PartModel[] = [];

  if (analysisModel.result?.preview_url) {
    const data = await fetchStl(analysisModel.result?.preview_url);
    models.push({ id: analysisModel.id, data, visible: true, type: 'STL' });
  }

  if (analysisModel.result?.unrecognized_features_preview_url) {
    const data = await fetchStl(analysisModel.result?.unrecognized_features_preview_url);
    models.push({ id: analysisModel.id, data, visible: false, unrecognized: true, type: 'STL' });
  }

  const notes = analysisModel.result?.notes?.map((note) => note.code) ?? [];
  const issues: AnalysisIssue[] = [];

  if (analysisModel.result?.issues) {
    for (const issue of analysisModel.result?.issues ?? []) {
      if (issue.preview_url) {
        issues.push({
          id: analysisModel.id + issue.id,
          data: await fetchStl(issue.preview_url),
          visible: false,
          issueId: issue.id,
          issueName: issue.name,
          issueDetails: issue.details ?? [],
        } as AnalysisIssue);
      } else {
        notes.push(issue.name!);
      }
    }
  }

  return {
    analysisId: analysisModel.id,
    models,
    issues,
    notes,
  };
}

async function blobToBase64(blob: Blob) {
  const reader = new FileReader();
  await new Promise((resolve, reject) => {
    reader.onload = resolve;
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
  const base64 = reader.result as string;
  return base64.substring(base64.indexOf(',') + 1);
}
