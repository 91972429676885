import 'react-dropdown/style.css';
import React, { useCallback, useEffect, useRef } from 'react';
import ReactDropdown, { Option } from 'react-dropdown';

type Props = {
  readonly placeholder?: string;
  readonly value?: string | string[];
  readonly options: {
    readonly label: React.ReactNode;
    readonly value: string;
  }[];
  readonly disabled?: boolean;
  readonly onChange: (value: string) => void;
  readonly expanded?: boolean;
};

export function Dropdown({ placeholder, value, options, disabled, onChange, expanded }: Props): JSX.Element {
  const dropdownRef = useRef<{ setState: (state: object) => void } & ReactDropdown>(null);

  const multi = Array.isArray(value);
  const values = multi ? value : [value];
  let convertedOption = options as Option[];

  const enforceExpanded = useCallback(() => {
    if (expanded) {
      setTimeout(() => {
        dropdownRef.current?.setState((current: object) => ({ ...current, isOpen: true }));
      });
    }
  }, [expanded]);

  useEffect(() => {
    enforceExpanded();
  }, [enforceExpanded]);

  if (multi) {
    convertedOption = options.map((it) => ({
      ...it,
      label: values?.includes(it.value) ? <strong>{it.value}</strong> : it.value,
    }));
  }

  return (
    <ReactDropdown
      ref={dropdownRef}
      placeholder={placeholder}
      options={convertedOption}
      disabled={disabled}
      onChange={(option) => {
        enforceExpanded();
        onChange(option.value);
      }}
      value={values.join(', ')}
    />
  );
}
