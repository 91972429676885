import { localized } from '../../../config/localization';
import React, { useCallback, useEffect, useState } from 'react';
import { BlechconAddress, BlechconOrderDelivery, BlechconUserInfo } from '../../../blechcon';
import { formatMeasure } from '../../../utils';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { Dropdown } from '../../../components/Dropdown/Dropdown';

type Props = {
  readonly userInfo: BlechconUserInfo;
  readonly disabled?: boolean;
  readonly delivery: BlechconOrderDelivery | undefined;
  readonly onChange: (delivery: BlechconOrderDelivery) => void;
};

export function OrderAddress({ userInfo, disabled, delivery, onChange }: Props): JSX.Element {
  const [state, setState] = useState<BlechconOrderDelivery | undefined>(delivery);

  const invoiceAsShipping =
    state?.invoiceAddress?.id === state?.shippingAddress?.id && state?.invoiceAddress?.id !== undefined;
  const addresses = invoiceAsShipping ? userInfo.invoiceAddresses : userInfo.shippingAddresses;
  const selfPickup = state?.selfPickup ?? false;

  useEffect(() => {
    setState(delivery);
  }, [delivery]);

  const onChangeConditionally = useCallback(
    (newDelivery: BlechconOrderDelivery) => {
      setState(newDelivery);

      onChange({
        ...delivery,
        ...newDelivery,
      });
    },
    [delivery, onChange]
  );

  return (
    <section className="mt-4 mb-2">
      <div>
        <div className="font-bold text-sm text-primary uppercase">{localized.INVOICE_ADDRESS}</div>

        <div className="mb-6 w-1/2">
          <Dropdown
            placeholder={localized.INVOICE_ADDRESS_SELECT}
            options={(userInfo.invoiceAddresses || []).map((address) => ({
              value: address.id,
              label: formatAddress(address),
            }))}
            onChange={(value) => {
              const invoiceAddress = userInfo.invoiceAddresses.find((address) => address.id === value);
              const shippingAddress = invoiceAddress?.useForShipping ? invoiceAddress : undefined;
              onChangeConditionally({
                ...state,
                invoiceAddress,
                shippingAddress,
              });
            }}
            value={state?.invoiceAddress?.id ?? ''}
            disabled={disabled}
          />
        </div>
      </div>

      <div className="mb-6 w-1/2 text-sm flex">
        <div className="flex-grow">
          <Checkbox
            disabled={!state?.invoiceAddress?.id || selfPickup || disabled}
            checked={invoiceAsShipping}
            label={localized.SHIPPING_ADDRESS_USE}
            onClick={() => {
              if (!invoiceAsShipping) {
                onChangeConditionally({
                  ...state,
                  shippingAddress: userInfo.invoiceAddresses.find(
                    (address) => address.id === state?.invoiceAddress?.id
                  ),
                });
              } else {
                onChangeConditionally({
                  ...state,
                  shippingAddress: undefined,
                });
              }
            }}
          />
        </div>
        <div className="flex-grow">
          <Checkbox
            checked={selfPickup}
            disabled={disabled}
            onClick={() =>
              onChangeConditionally({
                ...state,
                shippingAddress: !selfPickup ? undefined : state?.shippingAddress,
                selfPickup: !selfPickup,
              })
            }
            label={localized.PICKUP_YOURSELF}
          />
        </div>
      </div>
      <div>
        <div className="font-bold text-sm text-primary uppercase">{localized.SHIPPING_ADDRESS}</div>
        <div className="mb-6 w-1/2">
          <Dropdown
            disabled={state?.invoiceAddress === undefined || invoiceAsShipping || selfPickup || disabled}
            placeholder={localized.SHIPPING_ADDRESS_SELECT}
            options={addresses.map((address) => ({
              value: address.id,
              label: formatAddress(address, true),
            }))}
            onChange={(value) => {
              onChangeConditionally({
                ...state,
                shippingAddress: userInfo.shippingAddresses.find((address) => address.id === value),
              });
            }}
            value={state?.shippingAddress?.id}
          />
        </div>
      </div>
    </section>
  );
}

const formatAddress = (address: BlechconAddress, withDistance?: boolean) => {
  const formattedAddress = `${address.addressLine}, ${address.city}, ${address.state}`;

  if (withDistance && address.distance) {
    return `${formattedAddress} (${formatMeasure(Math.round(address.distance / 1000), 'km', 0)})`;
  }

  return formattedAddress;
};
