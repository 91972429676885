import React from 'react';
import { localized } from '../config/localization';
import getYear from 'date-fns/getYear';

export const Impressum = () => {
  return (
    <div className="my-6 print:hidden">
      <div className="flex">
        <a
          className="underline text-mutedText"
          target="_blank"
          rel="noopener noreferrer"
          href="https://blechcon.de/de/impressum.html">
          {localized.IMPRINT}
        </a>
        <div className="mx-1">{localized.AND}</div>
        <a
          className="underline text-mutedText"
          target="_blank"
          rel="noopener noreferrer"
          href="https://blechcon.de/de/datenschutz.html">
          {localized.PRIVACY_POLICY}
        </a>
      </div>
      <div className="print:hidden">
        Blechcon {'\u00a9'}
        {getYear(new Date())}
      </div>
    </div>
  );
};
