import React, { useContext } from 'react';
import { Routes } from '../../app/Routes';
import { useHistory } from 'react-router-dom';
import { localized } from '../../config/localization';
import { AuthContext, AuthContextProps } from '../../services/AuthContext';
import { Button } from '../../components/Button/Button';
import { BlankPage } from '../../components/BlankPage/BlankPage';

export function WaitingForApprovalPage(): JSX.Element {
  const history = useHistory();
  const { refreshUser } = useContext<AuthContextProps>(AuthContext);

  return (
    <BlankPage title={localized.WAIT_FOR_CONFIRMATION}>
      <BlankPage.Buttons>
        <Button onClick={() => refreshUser().then(() => history.push(Routes.WELCOME_PAGE))}>{localized.RETRY}</Button>
      </BlankPage.Buttons>
    </BlankPage>
  );
}
