import './FileTableRowComponent.css';
import React, { Fragment } from 'react';
import {
  BlechconPartAnalysis,
  BlechconOrderFile,
  BlechconOrderFilePart,
  BlechconCustomerConfiguration,
} from '../../blechcon';
import { l, localized, t } from '../../config/localization';
import { FileStats } from '../form/FileStats';
import { formatNumber } from '../../utils';
import { Ring } from '../Ring/Ring';
import { Colors } from '../../config/colors';
import { PreviewImages } from '../Images/PreviewImages';
import { PreviewImagePlaceholder } from '../Images/PreviewImagePlaceholder';
import { PartViewerButton } from '../PartViewer/PartViewerButton';

type Props = {
  readonly file: BlechconOrderFile;
  readonly index: number;
  readonly disabled?: boolean;
  readonly adminOnly?: boolean;
  readonly onAnalysisResult?: (partAnalyses: BlechconPartAnalysis[]) => void;
  readonly configuration?: BlechconCustomerConfiguration | null;
  readonly children?:
    | (({ file, part }: { file: BlechconOrderFile; part: BlechconOrderFilePart }) => React.ReactNode)
    | React.ReactNode;
};

export function FileTableRowComponent({
  file,
  index: fileIndex,
  disabled,
  adminOnly,
  onAnalysisResult,
  configuration,
  children,
}: Props): JSX.Element {
  const fileHasError = file.parts.some((part) => part.errorCode);

  return (
    <Fragment key={fileIndex}>
      {file.parts.map((part, partIndex) => (
        <Fragment key={partIndex}>
          {file.isAssembly && partIndex === 0 && (
            <tr className="file-table-row__list-item" data-cy={`assembly-head-${fileIndex}`}>
              <td className="text-xs font-bold text-mutedText pt-4">
                <div className="flex">
                  <div>
                    <span>{fileIndex + 1}</span>
                    <PreviewImagePlaceholder />
                  </div>
                  <div className=" w-full pl-12 pt-4 text-primary font-bold text-base">
                    <div className="py-1 font-normal" data-cy="name">
                      {file.name}
                    </div>
                    <FileStats
                      label={localized.WEIGHT_NETTO}
                      value={
                        <FormatValue
                          value={file.weightNetto}
                          hasError={fileHasError}
                          format={() => formatNumber(file.weightNetto) + ' kg'}
                        />
                      }
                      dataCy="weight"
                    />
                  </div>
                </div>
              </td>
              <td className="text-base font-bold text-primary align-top pt-4" data-cy="quantity">
                {file.quantity || <Ring color={Colors.primary} size={16} />}
              </td>
              <td className="text-base align-top pt-4" data-cy="unitPrice">
                {file.prices.materialUnitPrice ? (
                  formatNumber(file.prices.materialUnitPrice)
                ) : (
                  <Ring color={Colors.primary} size={16} />
                )}
              </td>
              <td className="text-base font-bold text-primary align-top text-right pr-4 pt-4" data-cy="totalPrice">
                {file.prices.materialTotalPrice ? (
                  formatNumber(file.prices.materialTotalPrice)
                ) : (
                  <Ring color={Colors.primary} size={16} />
                )}
              </td>
            </tr>
          )}
          <tr
            key={`${fileIndex} ${partIndex}`}
            className="file-table-row__list-item bi-avoid"
            data-cy={`file-part-${fileIndex}-${partIndex}`}>
            <td className="text-xs font-bold text-mutedText pt-4">
              <div className="flex">
                <div>
                  <span>
                    {fileIndex + 1}
                    {part.isAssemblyPart ? `.${partIndex + 1}` : ''}
                  </span>
                  <PreviewImages rotationAngle={part.rotationAngle} images={part.previewImage}>
                    <>
                      <PartViewerButton disabled={disabled} parts={[part]} />
                      {configuration?.optimateActive && (
                        <PartViewerButton disabled={disabled} parts={[part]} onAnalysisResult={onAnalysisResult} />
                      )}
                    </>
                  </PreviewImages>
                </div>
                <div className="w-full pl-12 pt-4 text-primary font-bold text-base">
                  {!file.isAssembly && (
                    <div className="py-1" data-cy="name">
                      {file.name}
                    </div>
                  )}
                  <div className="py-1 font-normal" data-cy="designation">
                    {part.material?.designation}
                  </div>
                  <FileStats
                    label={localized.THICKNESS}
                    value={part.material?.materialThickness}
                    unit="mm"
                    dataCy="thickness"
                  />
                  <FileStats
                    label={localized.FORMAT}
                    value={
                      part.length && part.width ? (
                        `${formatNumber(part.length)} mm x ${formatNumber(part.width)} mm`
                      ) : (
                        <Ring color={Colors.primary} size={16} />
                      )
                    }
                    dataCy="format"
                  />
                  <FileStats
                    label={localized.WEIGHT_NETTO}
                    value={
                      <FormatValue
                        value={part.weightNetto}
                        hasError={part.errorCode}
                        format={() => formatNumber(part.weightNetto) + ' kg'}
                      />
                    }
                    dataCy="weight"
                  />
                  <FileStats
                    label={localized.ROTATION}
                    value={localized.getString(`ROTATION_TYPE_${part.rotation.toUpperCase()}`)}
                    dataCy="rotation"
                  />
                  <FileStats label={localized.ROTATION_ANGLE} value={part.rotationAngle} dataCy="rotationAngle" />
                  <FileStats
                    label={localized.BENDING}
                    value={part.bending ? localized.YES : localized.NO}
                    dataCy="bending"
                  />
                  <FileStats
                    label={localized.EDGES_ROUNDED}
                    value={part.edgesRounded ? localized.YES : localized.NO}
                    dataCy="edgesRounded"
                  />
                  {adminOnly && (
                    <FileStats
                      label={t(l.WORKING_STEPS)}
                      value={part.workingSteps?.map((it) => t(l.WORKING_STEPS, it.name)).join(', ')}
                      dataCy="workingSteps"
                    />
                  )}
                  {part.errorCode && (
                    <FileStats
                      label={localized.ERRORS}
                      value={t(part.errorCode)}
                      className="text-error"
                      dataCy="errorCode"
                    />
                  )}
                  {typeof children === 'function'
                    ? children({ file, part })
                    : React.Children.map(children, (child) => child)}
                </div>
              </div>
            </td>
            <td className="text-base align-top pt-4" data-cy="quantity">
              {part.quantity || <Ring color={Colors.primary} size={16} />}
            </td>
            <td className="text-base align-top pt-4" data-cy="unitPrice">
              <FormatValue
                value={part.prices.materialUnitPrice}
                hasError={part.errorCode}
                format={() => formatNumber(part.prices.materialUnitPrice)}
              />
            </td>
            <td className="text-base align-top text-right pr-4 pt-4" data-cy="totalPrice">
              <FormatValue
                value={part.prices.materialTotalPrice}
                hasError={part.errorCode}
                format={() => formatNumber(part.prices.materialTotalPrice)}
              />
            </td>
          </tr>
        </Fragment>
      ))}
    </Fragment>
  );
}

type FormatValueProps = {
  value: number | null | undefined;
  hasError: boolean | string | null | undefined;
  format: () => string | null;
};

function FormatValue({ value, hasError, format }: FormatValueProps): JSX.Element {
  if (hasError) {
    return <>-</>;
  } else if (value) {
    return <>{format()}</>;
  } else {
    return <Ring color={Colors.primary} size={16} />;
  }
}
