import React, { useState } from 'react';
import { localized } from '../../config/localization';
import { Input } from '../../components/Input/Input';
import { Label } from '../../components/Label/Label';
import { Button } from '../../components/Button/Button';
import { Dropdown } from '../../components/Dropdown/Dropdown';

const errorMessage = localized.formatString(localized.MUST_CONTAIN_AT_LEAST_CHARACTERS, 2) as string;
const OTHER = 'OTHER';

type Props = {
  onChange: (value: { option: string | null; freetext: string } | null) => void;
};

export function WelcomeWizard1({ onChange }: Props): JSX.Element {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [inputState, setInputState] = useState<{ values: { other: string }; errors: { other: string | null } }>({
    values: { other: '' },
    errors: { other: null },
  });

  return (
    <div className="welcome-page__find-us-option">
      <Label>{localized.HOW_DID_YOU_FIND_US}</Label>

      <Dropdown
        placeholder={localized.DROPDOWN_SELECTION}
        options={[
          { label: localized.ALREADY_A_CUSTOMER, value: 'ALREADY_A_CUSTOMER' },
          { label: localized.REFERRAL, value: 'REFERRAL' },
          { label: localized.GOOGLE, value: 'GOOGLE' },
          { label: localized.WHO_PROVIDES_WHAT, value: 'WHO_PROVIDES_WHAT' },
          { label: localized.OTHER, value: 'OTHER' },
        ]}
        onChange={(value) => {
          if (value !== 'OTHER') {
            setInputState((current) => ({
              ...current,
              values: {
                other: '',
              },
              errors: {
                other: null,
              },
            }));
          }
          setSelectedOption(value);
        }}
      />

      {selectedOption === OTHER && (
        <Input
          name="other"
          values={inputState.values}
          errors={inputState.errors}
          autoFocus
          onChange={(event) => {
            const value = event.target.value;

            setInputState((current) => ({
              ...current,
              values: {
                other: value,
              },
              errors: {
                other: value.trim().length === 0 ? errorMessage : null,
              },
            }));
          }}
        />
      )}

      <Button
        disabled={selectedOption === 'OTHER' && inputState.values.other === ''}
        onClick={() => onChange({ option: selectedOption, freetext: inputState.values.other })}>
        {localized.CONTINUE}
      </Button>
    </div>
  );
}
