import './CenteredRing.css';
import React from 'react';
import { Ring } from '../Ring/Ring';

export function CenteredRing(): JSX.Element {
  return (
    <div className="centered-ring">
      <Ring />
    </div>
  );
}
