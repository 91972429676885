import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

function toSearchParams(search: string) {
  const query: { [key: string]: string } = {};

  // @ts-ignore
  for (const [key, value] of new URLSearchParams(search).entries()) {
    query[key] = value;
  }
  return query;
}

export function useSearchParams() {
  const search = useLocation().search || '';
  return useMemo(() => toSearchParams(search), [search]);
}
