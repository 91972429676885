import './AdminCustomersPage.css';

import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { BlechconCustomer } from '../../../blechcon';
import { Button } from '../../../components/Button/Button';
import { CenteredRing } from '../../../components/CenteredRing/CenteredRing';
import { Page } from '../../../components/Page/Page';
import { localized } from '../../../config/localization';
import { getCustomers, updateCustomer } from '../../../services/api/blechconApi';
import { Menu } from '../../../components/Menu/Menu';

export function AdminCustomersPage(): JSX.Element {
  return <AdminCustomersPageView />;
}

export function AdminCustomersPageView(): JSX.Element {
  const [state, setState] = useState<{
    isLoading: boolean;
    customers: BlechconCustomer[];
    nextToken: string | undefined;
  }>({
    isLoading: false,
    customers: [],
    nextToken: undefined,
  });

  const fetchCustomers = useCallback((nextToken: string | undefined | null = undefined) => {
    setState((current) => ({
      ...current,
      isLoading: true,
    }));

    if (nextToken === undefined) {
      return;
    }

    getCustomers(nextToken)
      .then((result) => {
        setState((current) => ({
          ...current,
          customers: [...current.customers, ...result.Items],
          nextToken: result.NextToken,
        }));
      })
      .catch((error) => {
        console.error(error);
        toast.error(String(error));
      })
      .finally(() => {
        setState((current) => ({
          ...current,
          isLoading: false,
        }));
      });
  }, []);

  useEffect(() => {
    fetchCustomers(null);
  }, [fetchCustomers]);

  const handleMenuItemSelect = useCallback(
    (partial: Required<Pick<BlechconCustomer, 'customerId'>> & Partial<BlechconCustomer>) => {
      setState((current) => ({
        ...current,
        isLoading: true,
      }));

      updateCustomer(partial)
        .then(() => {
          setState((current) => ({
            ...current,
            customers: [],
          }));
          fetchCustomers(null);
        })
        .catch((error) => {
          console.error(error);
          toast.error(String(error));
        });
    },
    [fetchCustomers]
  );

  return (
    <Page title={localized.CUSTOMER_MANAGEMENT}>
      <table className="admin_users_page__table">
        <thead>
          <tr>
            <th>{localized.CUSTOMER_ID}</th>
            <th>{localized.DISCOUNT}</th>
            <th>{localized.ADDITIONAL_DELIVERY_DAYS}</th>
            <th>{localized.MINIMUM_ORDER_VALUE_IN_CENT}</th>
            <th>{localized.BENDING_COSTS_PER_HOUR}</th>
            <th>{localized.HAS_CSV_PARAMETER_IMPORT_RIGHT}</th>
            <th>{localized.OPTIMATE_ACTIVE}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {state.customers.map((customer) => {
            return (
              <tr key={customer.customerId} className="admin-users__user-list-item">
                <td>{customer.customerId}</td>
                <td>{customer.discount}</td>
                <td>{customer.additionalDeliveryDays}</td>
                <td>{customer.minimumOrderValueInCent}</td>
                <td>
                  <pre>{JSON.stringify(customer.bendingCostsPerHour, null, 2)}</pre>
                </td>
                <td>{JSON.stringify(customer.csvParameterImportEnabled)}</td>
                <td>{JSON.stringify(customer.optimateActive)}</td>
                <td>
                  <Menu dots>
                    <Menu.Item
                      onSelect={() =>
                        handleMenuItemSelect({
                          csvParameterImportEnabled: !customer.csvParameterImportEnabled,
                          customerId: customer.customerId,
                        })
                      }>
                      {customer.csvParameterImportEnabled
                        ? localized.REMOVE_CSV_PARAMETER_IMPORT_RIGHT
                        : localized.ADD_CSV_PARAMETER_IMPORT_RIGHT}
                    </Menu.Item>
                    <Menu.Item
                      onSelect={() =>
                        handleMenuItemSelect({
                          optimateActive: !customer.optimateActive,
                          customerId: customer.customerId,
                        })
                      }>
                      {customer.optimateActive ? localized.REMOVE_OPTIMATE_ACTIVE : localized.ADD_OPTIMATE_ACTIVE}
                    </Menu.Item>
                  </Menu>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {state.isLoading && <CenteredRing />}

      {!state.isLoading && state.nextToken ? (
        <div className="admin-users__load-more-button">
          <Button onClick={() => fetchCustomers(state.nextToken)}>{localized.LOAD_MORE}</Button>
        </div>
      ) : null}
    </Page>
  );
}
