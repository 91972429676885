import React, { useState } from 'react';
import { Modal } from '../../../components/Modal';
import { l, t } from '../../../config/localization';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { BlechconCustomerConfiguration, BlechconOrderFilePart } from '../../../blechcon';
import { Button } from '../../../components/Button/Button';

type Props = {
  readonly configuration: BlechconCustomerConfiguration;
  readonly disabled?: boolean;
  readonly part: BlechconOrderFilePart;
  readonly onChange: (values: string[]) => void;
};

export function WorkingStepsButton({ configuration, disabled, part, onChange }: Props): JSX.Element | null {
  const [showModal, setShowModal] = useState(false);
  const [workingSteps, setWorkingSteps] = useState<string[]>(part.workingSteps?.map((it) => it.name) ?? []);

  return (
    <>
      {showModal && (
        <Modal
          className="parameters-page__working-steps-modal"
          title={t(l.WORKING_STEPS_MODAL_TITLE)}
          onCancel={{
            label: t(l.ABORT),
            execute: () => setShowModal(false),
          }}
          onSuccess={{
            label: t(l.SAVE),
            execute: () => {
              setShowModal(false);
              onChange(workingSteps);
            },
          }}>
          <Dropdown
            placeholder={t(l.WORKING_STEPS_DROPDOWN_PLACEHOLDER)}
            options={configuration?.OPTIONAL_WORKING_STEPS.map(({ name }) => ({
              value: name,
              label: t(l.WORKING_STEPS, name),
            }))}
            disabled={disabled}
            onChange={(value) => {
              if (workingSteps.includes(value)) {
                setWorkingSteps(workingSteps.filter((it) => it !== value));
              } else {
                setWorkingSteps([...workingSteps, value]);
              }
            }}
            value={workingSteps}
            expanded
          />
        </Modal>
      )}

      <Button disabled={disabled} onClick={() => setShowModal(true)}>
        {t(l.WORKING_STEPS_BUTTON_TEXT)}
      </Button>
    </>
  );
}
