import { api } from './index';
import {
  BlechconCustomerConfiguration,
  BlechconMaterial,
  BlechconOrder,
  BlechconOrderFile,
  BlechconOrderFilePart,
  NestingFile,
} from '../../blechcon';
import { NestingJobResponse } from './wicamTypes';
import { convertToMilliseconds } from '../timeUtils';
import groupBy from 'lodash/groupBy';
import sum from 'lodash/sum';
import { nestingDetails } from './nestingDetails';
import { getPartQuantityWithSurcharge } from '../../pages/checkout/CalculationPage/materialPriceCalculation';
import { calculateProcessingTimeOfPlates } from './calculateProcessingTime';

function convertPartInfo(
  nestingResult: NestingJobResponse,
  nestingJobFileId: string,
  configuration: BlechconCustomerConfiguration
): Partial<BlechconOrderFilePart> {
  let errorCode: string | undefined = undefined;

  if (nestingResult.errors?.[0]?.code) {
    errorCode = nestingResult.errors[0].code;
  }

  const basicResults = nestingResult.basicResults?.find((basicResult) => basicResult.id === nestingJobFileId);

  if (basicResults) {
    let weightGross = undefined;
    let weightNetto = undefined;
    let processingTimeMs = undefined;
    let bendingTimeMs = undefined;
    let areaGross = undefined;
    let areaNetto = undefined;
    let cuts = undefined;
    let cutLength = undefined;
    let nestingJobId = undefined;

    if (basicResults.weights) {
      weightGross = basicResults.weights.weightGross;
      weightNetto = basicResults.weights.weight;
    }
    if (basicResults.areas) {
      areaGross = basicResults.areas.areaGross;
      areaNetto = basicResults.areas.area;
    }
    if (basicResults.laserResult) {
      cuts = basicResults.laserResult.cuts;
      cutLength = basicResults.laserResult.cutLength;
    }

    if (basicResults.times) {
      if (basicResults.times.timeGross) {
        processingTimeMs = convertToMilliseconds(basicResults.times.timeGross);
      }
      if (basicResults.times.timeBending) {
        bendingTimeMs = convertToMilliseconds(basicResults.times.timeBending);
      }
    }

    if (nestingResult.id) {
      nestingJobId = nestingResult.id;
    }

    const processingTimeOfPlates = calculateProcessingTimeOfPlates(
      nestingResult.nestedPlates || [],
      configuration.PLATE_PROCESSING_TIME,
      nestingJobFileId
    );

    return {
      errorCode,
      weightGross,
      weightNetto,
      // TODO: Entfällt mit BLEC-311
      processingTimeMs,
      processingTimeOfPlates,
      bendingTimeMs,
      areaGross,
      areaNetto,
      cuts,
      cutLength,
      nestingJobId,
      material: {
        nestedPlateRepeats: sum(nestingResult.nestedPlates?.map((it) => it.repeats)),
      },
    };
  }
  return {
    errorCode,
  };
}

function retrieveNestedPlateRepeats(nestingResult: NestingJobResponse): number | undefined {
  return sum(nestingResult.nestedPlates?.map((it) => it.repeats));
}

export async function calculateFiles(
  signal: AbortSignal,
  order: BlechconOrder,
  configuration: BlechconCustomerConfiguration
): Promise<{ files: BlechconOrderFile[]; nestingFiles: any }> {
  const allParts = order.files.flatMap((file) => file.parts);
  const partsByMaterial = groupBy(allParts, (part) => part.material?.rawMaterialID);
  let filesCopy = [...order.files];
  let nestingFiles: NestingFile[] = [];

  for (const parts of Object.values(partsByMaterial)) {
    const material = parts[0].material;

    if (material) {
      const nestingJobId = await createNestingJob(material, configuration);
      const addNestingJobFileResults = [];

      for (const part of parts) {
        const nestingJobFileId = await addNestingJobFile(
          nestingJobId,
          getPartQuantityWithSurcharge(configuration.PART_QUANTITY_SURCHARGE, part.quantity ?? 0),
          part
        );
        addNestingJobFileResults.push({
          nestingJobFileId,
          part: {
            ...part,
          },
        });
      }

      const nestingResult = await getNestingJob(signal, nestingJobId);
      const alreadyFetched = nestingFiles.map((it) => it.nestingJobId).includes(nestingJobId);

      if (!alreadyFetched) {
        nestingFiles = nestingFiles.concat(await getNestingFilesForNestingJob(nestingJobId, signal));
      }

      for (const result of addNestingJobFileResults) {
        const info = convertPartInfo(nestingResult, result.nestingJobFileId, configuration);

        const mergedPart = {
          ...result.part,
          ...info,
          material: {
            ...result.part.material,
            nestedPlateRepeats: retrieveNestedPlateRepeats(nestingResult),
          },
        };

        filesCopy = filesCopy.map((file) => ({
          ...file,
          parts: file.parts.map((part) => (mergedPart.id === part.id ? mergedPart : part)),
        }));
      }
    } else {
      throw new Error('Part has rawMaterialID but no material property');
    }
  }

  return {
    files: filesCopy,
    nestingFiles,
  };
}

async function createNestingJob(material: BlechconMaterial, configuration: BlechconCustomerConfiguration): Promise<string> {
  const response = await api.nesting.nestingCreate2({
    ...configuration.NESTING_JOB_TEMPLATE,
    job: {
      machine: 2171,
      material: material.materialIDExt,
      thickness: material.materialThickness,
    },
    sheets: [
      {
        name: material.rawMaterialID,
        length: material.length,
        width: material.width,
      },
    ],
  });
  if (response.status === 201) {
    return response.data.nestingJobId;
  } else {
    throw new Error(`createNestingJob failed with ${response.status}`);
  }
}

async function addNestingJobFile(nestingJobId: string, quantity: number, part: BlechconOrderFilePart): Promise<string> {
  /*
   * Increment with which the part is rotated. Integer value that indicates the angle. e.g. an integer value 4,
   * means that the part is rotated in 4 steps and that each rotation is then 90 degrees (since 360/4=90).
   */
  let rotation = undefined;
  let preRotationAngle = undefined;

  if (part.rotation === 'fixed') {
    rotation = 2;
    preRotationAngle = part.rotationAngle ?? 0;
  } else if (part.rotation === 'free') {
    rotation = 8;
  }

  const nestingFileData = {
    fileCollectionId: part.collectionId,
    fileId: part.fileId,
    amount: quantity,
    rotation,
    preRotationAngle,
  };

  const response = await api.nesting.nestingUpdate(nestingJobId, nestingFileData);
  if (response.status === 200) {
    return response.data.nestingJobFileId;
  } else {
    throw new Error(`nestingUpdateResult failed with ${response.status}`);
  }
}

async function startNestingJob(nestingJobFileId: string): Promise<void> {
  const response = await api.nesting.nestingCreate(nestingJobFileId);
  if (response.status !== 200) {
    throw new Error(`nestingCreate failed with ${response.status}`);
  }
}

async function getNestingJob(signal: AbortSignal, nestingJobId: string): Promise<NestingJobResponse> {
  return new Promise((resolve, reject) => {
    async function run() {
      try {
        await startNestingJob(nestingJobId);
        const result = await nestingDetails(signal, nestingJobId);
        resolve(result);
      } catch (error) {
        reject(error);
      }
    }

    run();
  });
}

async function getNestingFilesForNestingJob(nestingJobId: string, signal: AbortSignal): Promise<NestingFile[]> {
  const nestingResult = await nestingDetails(signal, nestingJobId);

  if (nestingResult.nestedPlates && nestingResult.nestedPlates.length > 0) {
    const filePairs = nestingResult.nestedPlates.map((plate) => {
      return [
        {
          name: `${nestingJobId}${plate.svgFileId}.svg`,
          nestingJobId,
          fileId: plate.svgFileId,
        },
        {
          name: `${nestingJobId}${plate.dxfFileId}.dxf`,
          nestingJobId,
          fileId: plate.dxfFileId,
        },
      ];
    });
    return filePairs.flatMap((it) => it);
  }
  return [];
}
