import React from 'react';

type Props = {
  className?: string;
  fill?: string;
};

export function Orders({ className = '', fill = 'currentColor' }: Props): JSX.Element {
  return (
    <svg width={24} height={24} viewBox="0 0 13.818 16" className={className}>
      <path
        d="M4.455,1A1.459,1.459,0,0,0,3,2.455v9.455a.727.727,0,1,0,1.455,0V2.455h8a.727.727,0,1,0,0-1.455ZM7.364,3.909A1.459,1.459,0,0,0,5.909,5.364V15.545A1.459,1.459,0,0,0,7.364,17h8a1.459,1.459,0,0,0,1.455-1.455v-7.4a1.455,1.455,0,0,0-.426-1.028L13.608,4.335a1.455,1.455,0,0,0-1.028-.426ZM9.545,9.727h3.636a.727.727,0,1,1,0,1.455H9.545a.727.727,0,1,1,0-1.455Zm0,2.909h3.636a.727.727,0,1,1,0,1.455H9.545a.727.727,0,1,1,0-1.455Z"
        transform="translate(-3 -1)"
        fill={fill}
      />
    </svg>
  );
}
