/* eslint sonarjs/no-duplicate-string: 0 */
export const config = {
  aws_project_region: 'eu-central-1',
  aws_cognito_identity_pool_id: 'eu-central-1:d51376f9-31b6-4bec-88d8-6abcdbe8e2fc',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_9S26UO1xC',
  aws_user_pools_web_client_id: '1a2kdbfv7v7s3866no9amb06te',
  aws_user_files_s3_bucket: 'blechcons3104242-develop',
  aws_user_files_s3_bucket_region: 'eu-central-1',
};

export const wicamV2Url = 'https://api-v2-development.wicam.com/';

export const optimateUrl = 'https://api.optimate.de/';

export const apigwUrl = 'https://v73l3kzdhb.execute-api.eu-central-1.amazonaws.com/develop/';
