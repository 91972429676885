import React from 'react';

type Props = {
  fill?: string;
};

export function Download({ fill = '' }: Props) {
  return (
    <svg width={16} height={16} viewBox="0 0 12 12" fill={fill}>
      <path
        fill={fill}
        d="M7.4,2a.6.6,0,0,0-.6.6V7.4H4.7a.3.3,0,0,0-.212.512l.019.018L7.57,10.818l.02.019a.6.6,0,0,0,.82,0l.008-.007.006-.007,3.062-2.887.018-.016,0,0,0,0A.3.3,0,0,0,11.3,7.4H9.2V2.6A.6.6,0,0,0,8.6,2H7.4ZM2.6,12.8a.6.6,0,1,0,0,1.2H13.4a.6.6,0,1,0,0-1.2Z"
        transform="translate(-2 -2)"
      />
    </svg>
  );
}
