import './BlankPage.css';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { localized } from '../../config/localization';
import { BlechconLogo } from '../../assets/svg/BlechconLogo';
import { ArrowLeft } from '../../assets/svg/ArrowLeft';
import { ExclamationMark } from '../../pages/LoginPage/ExclamationMark';
import { Colors } from '../../config/colors';

type Props = {
  title: string;
  showGoBack?: boolean;
  children: React.ReactNode;
};

export function BlankPage({ title, showGoBack, children }: Props): JSX.Element {
  const history = useHistory();

  return (
    <div className="blank-page">
      <div className="blank-page__content">
        {showGoBack && (
          <a onClick={() => history.goBack()} className="blank-page__go-back">
            <ArrowLeft />
            {localized.GO_BACK}
          </a>
        )}

        <div className="blank-page__logo-with-title">
          <BlechconLogo />
          <p>{title}</p>

          <div>{children}</div>
        </div>
      </div>
    </div>
  );
}

type ButtonsProps = {
  children: React.ReactNode;
};

BlankPage.Buttons = ({ children }: ButtonsProps) => {
  return <div className="blank-page__buttons">{children}</div>;
};

type LinksProps = {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  title: string;
};

BlankPage.Links = ({ onClick, title }: LinksProps) => {
  return (
    <div onClick={onClick} className="blank-page__links">
      {title}
    </div>
  );
};

type ErrorsProps = {
  message: string | null;
};

BlankPage.Errors = ({ message }: ErrorsProps) => {
  return message ? (
    <div className="blank-page__errors" data-cy="loginError">
      <ExclamationMark fill={Colors.error} />
      {message}
    </div>
  ) : null;
};
