const storage = localStorage;

const prefix = 'BLECHBOARD_';

function prefixed(key: string): string {
  return prefix + key;
}
export function getItem<T = unknown>(key: string): T | null {
  try {
    return JSON.parse(storage.getItem(prefixed(key)) || '') || {};
  } catch {
    // ignore
  }
  return null;
}

export function setItem(key: string, object: unknown) {
  storage.setItem(prefixed(key), JSON.stringify(object));
}

export function removeItem(key: string) {
  storage.removeItem(prefixed(key));
}
