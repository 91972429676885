import { BlechconMaterial, BlechconOrderFilePart } from '../../../blechcon';
import { matchingThicknesses } from '../../../components/MaterialSelection/MaterialSelection';

export function bestMatchingMaterial(
  part: BlechconOrderFilePart,
  partTemplate: BlechconOrderFilePart,
  materials: BlechconMaterial[]
): Partial<BlechconMaterial> | null {
  // BLEC-181: Wenn die Materialdicke schon von WiCam vorgegeben ist, darf diese nicht überschrieben werden.
  let thickness = partTemplate.thickness === part.thickness ? partTemplate.thickness : part.thickness;
  const selectableThicknesses = matchingThicknesses(partTemplate.material?.materialTypeID, thickness, materials);

  if (selectableThicknesses.length === 0) {
    return part.material;
  }

  if (selectableThicknesses.length === 1) {
    if (selectableThicknesses.find((it) => it === partTemplate.thickness)) {
      thickness = partTemplate.thickness;
    } else {
      return part.material;
    }
  }

  const selectableMaterials = materials.filter((it) => it.materialThickness === thickness);
  const selectedMaterial = selectableMaterials.find((it) => it.rawMaterialID === partTemplate.material?.rawMaterialID);

  if (selectedMaterial?.rawMaterialID) {
    return selectedMaterial;
  } else {
    const materialTypeID = selectableMaterials.find((it) => it.materialTypeID === partTemplate.material?.materialTypeID)
      ?.materialTypeID;
    return materialTypeID
      ? {
          materialTypeID,
          materialThickness: thickness,
        }
      : partTemplate.material;
  }
}

export function calculatePartQuantity(part: BlechconOrderFilePart, quantity: number) {
  return (part.assemblyCountPerPart || 1) * quantity;
}
