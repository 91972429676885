import './Navigation.css';

import React, { useContext } from 'react';
import { Routes } from '../app/Routes';
import { List } from '../assets/svg/List';
import { Requests } from '../assets/svg/Requests';
import { Settings } from '../assets/svg/Settings';
import { Orders } from '../assets/svg/Orders';
import { Order } from '../assets/svg/Order';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { localized } from '../config/localization';
import { AuthContext, AuthContextProps } from '../services/AuthContext';
import { Building } from '../assets/svg/Building';

type Props = {
  show: boolean;
};

export function Navigation({ show }: Props): JSX.Element {
  const history = useHistory();

  const { isAdmin } = useContext<AuthContextProps>(AuthContext);

  const isAdminOrders = useRouteMatch(Routes.adminOrders(':status')) !== null;
  const isAdminUsers = useRouteMatch(Routes.adminUsers(':status')) !== null;
  const isAdminCustomers = useRouteMatch(Routes.ADMIN_CUSTOMERS) !== null;
  const isCustomerOrders = useRouteMatch(Routes.CUSTOMER_ORDERS) !== null;
  const isCheckout = useRouteMatch(Routes.CHECKOUT) !== null;
  const isUserSettings = useRouteMatch(Routes.userSettings()) !== null;

  const isActive = (path: string) => {
    const pathName = history.location?.pathname;
    if (path === pathName) {
      return true;
    }
    if (pathName && (pathName.match(/\//g) || []).length > 1) {
      const pathNameRoot = '/' + pathName.split('/')[1];
      return path === pathNameRoot;
    }
    return false;
  };

  const navigate = (route: string) => {
    history.push(route);
  };

  if (!show) {
    return <div className="navigation-background" />;
  }

  return (
    <div className="navigation-background">
      <NavigationItem
        label={localized.CALCULATOR}
        route={Routes.CHECKOUT_UPLOAD}
        isActive={isCheckout || isActive(Routes.CHECKOUT_UPLOAD)}
        navigate={navigate}
        dataCy="menu-calculator">
        <Order />
      </NavigationItem>
      <NavigationItem
        label={localized.RECENT_ORDERS}
        route={Routes.CUSTOMER_ORDERS}
        isActive={isCustomerOrders}
        navigate={navigate}
        dataCy="menu-recent-orders">
        <List />
      </NavigationItem>
      <NavigationItem
        label={localized.ADMIN_ORDERS}
        route={Routes.adminOrders('submitted_withdraw')}
        isActive={isAdminOrders}
        navigate={navigate}
        rendered={isAdmin}
        dataCy="menu-admin-orders">
        <Orders />
      </NavigationItem>
      <NavigationItem
        rendered={isAdmin}
        label={localized.USER_MANAGEMENT}
        route={Routes.adminUsers('newuser')}
        isActive={isAdminUsers}
        navigate={navigate}
        dataCy="menu-customer-management">
        <Requests />
      </NavigationItem>
      <NavigationItem
        rendered={isAdmin}
        label={localized.CUSTOMER_MANAGEMENT}
        route={Routes.ADMIN_CUSTOMERS}
        isActive={isAdminCustomers}
        navigate={navigate}
        dataCy="menu-user-management">
        <Building />
      </NavigationItem>
      <NavigationItem
        label={localized.SETTINGS}
        route={Routes.userSettings()}
        isActive={isUserSettings}
        navigate={navigate}
        dataCy="menu-settings">
        <Settings />
      </NavigationItem>
    </div>
  );
}

type NavigationItemProps = {
  rendered?: boolean;
  label: string;
  route: string;
  isActive: boolean;
  navigate: (route: string) => void;
  children?: React.ReactNode;
  dataCy?: string;
};

function NavigationItem({
  rendered = true,
  label,
  route,
  isActive,
  navigate,
  children,
  dataCy,
}: NavigationItemProps): JSX.Element | null {
  if (!rendered) {
    return null;
  }

  return (
    <div
      className={`navigation_item ${isActive ? 'navigation_item-active' : ''}`}
      onClick={() => navigate(route)}
      data-cy={dataCy}>
      {children}
      {label}
    </div>
  );
}
