import React, { useCallback, useReducer } from 'react';
import { localized } from '../../config/localization';
import { Routes } from '../../app/Routes';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import { Input } from '../../components/Input/Input';
import { EMAIL_PATTERN } from '../../utils';
import { formReducer } from '../../components/form/formReducer';
import { BlankPage } from '../../components/BlankPage/BlankPage';
import { forgotPassword } from '../../services/Auth';

export function ForgotPasswordPage(): JSX.Element {
  const [state, dispatch] = useReducer(formReducer, {
    values: {
      email: '',
    },
    errors: {
      email: null,
    },
    valid: false,
  });

  const history = useHistory();

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      dispatch({ errors: { email: null } });

      forgotPassword(state.values.email)
        .then(() => {
          history.push({
            pathname: Routes.NEW_PASSWORD,
            state: { email: state.values.email },
          });
        })
        .catch((error) => dispatch({ errors: { email: error.message } }));
    },
    [state.values.email, history]
  );

  return (
    <BlankPage title={localized.PASSWORD_RESET} showGoBack>
      <form onSubmit={onSubmit}>
        <Input
          autoFocus
          name="email"
          values={state.values}
          placeholder={localized.EMAIL}
          errors={state.errors}
          onChange={(event) => {
            const value = event.target.value.toLocaleLowerCase().trim();
            dispatch({
              values: { email: value },
              errors: { email: !EMAIL_PATTERN.test(value) ? localized.LOGIN_EMAIL_INVALID : null },
            });
          }}
        />
        <BlankPage.Buttons>
          <Button type="submit" disabled={!state.valid}>
            {localized.CONFIRM}
          </Button>
        </BlankPage.Buttons>
      </form>
    </BlankPage>
  );
}
