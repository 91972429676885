import './PartDetails.css';
import React, { useCallback, useRef, useState } from 'react';
import { FileStats } from '../form/FileStats';
import { localized } from '../../config/localization';
import { formatMeasure, formatNumber, millisecondsToMinutes } from '../../utils';
import { BlechconOrderFilePart } from '../../blechcon';
import { Ring } from '../Ring/Ring';
import { nestingDetails } from '../../services/wicam/nestingDetails';
import { Toggle } from '../Toggle/Toggle';

type Props = {
  readonly part: BlechconOrderFilePart;
};

export function PartDetails({ part }: Props): JSX.Element | null {
  const abortRef = useRef(new AbortController());
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nesting, setNesting] = useState<unknown>();

  const showMoreDetails = useCallback(() => {
    if (nesting) {
      setOpen(!open);
    } else {
      setLoading(true);
      nestingDetails(abortRef.current.signal, part.nestingJobId || '')
        .then((response) => {
          setNesting(response);
          setOpen(!open);
        })
        .finally(() => setLoading(false));
    }
  }, [nesting, open, part.nestingJobId]);

  if (loading) {
    return <Ring />;
  }

  return (
    <>
      {part.nestingJobId && <Toggle on={open} onClick={showMoreDetails} />}
      {open && (
        <div>
          <FileStats label="File ID" value={part.fileId} />
          <FileStats label="Collection ID" value={part.collectionId} />
          <FileStats label={localized.BENDS} value={part.bendingCount} />
          <FileStats
            label={localized.BEND_TIME}
            value={formatMeasure(millisecondsToMinutes(part.bendingTimeMs), null, 1, 5)}
            unit="min"
          />
          <FileStats
            label={localized.SETUP_COST_BENDING}
            value={formatNumber(part.prices.setupBendingCostPerUnit || 0)}
            unit="€"
          />
          <FileStats
            label={localized.PRICE_FOR_BENDING}
            value={formatNumber(part.prices.bendingCostPerUnit || 0)}
            unit="€"
          />
          <FileStats
            label={localized.PRICE_FOR_LASER_CUTS}
            value={formatNumber(part.prices.priceForLaserCuts || 0)}
            unit="€"
          />
          <FileStats
            label={'Cutting Cost per Hour'}
            value={formatNumber(part.prices.cuttingCostPerHour || 0)}
            unit="€"
          />
          <FileStats label={localized.SETUP_COST_LASER} value={formatNumber(part.prices.setUpCost || 0)} unit="€" />
          <FileStats label={localized.MATERIAL_COST} value={formatNumber(part.prices.priceForMaterial || 0)} unit="€" />
          <FileStats label={localized.CUT_LENGTH} value={formatMeasure(part.cutLength || 0, null, 3, 3)} unit="mm" />
          <FileStats label={localized.CUTS} value={part.cuts || 0} />

          {/**
           * TODO obsolet mit BLEC-311
           */}
          {!part.processingTimeOfPlates && (
            <FileStats
              label={localized.TIME}
              value={formatMeasure(millisecondsToMinutes(part.processingTimeMs), null, 1, 5)}
              unit="min"
            />
          )}

          {part.averageProcessingTimeMs && (
            <FileStats
              label={localized.AVERAGE_LASER_TIME}
              value={formatMeasure(millisecondsToMinutes(part.averageProcessingTimeMs), null, 1, 5)}
              unit="min"
            />
          )}

          {[
            part.processingTimeOfPlates?.map((plate, index) => (
              <FileStats
                key={index}
                label={`Parts per Plate ${index + 1}/Blechcon min/Wicam min/repeats`}
                value={`(${plate.amount}/${plate.totalPlateAmount}) / ${formatMeasure(
                  millisecondsToMinutes(plate.processingTimeMsBlechcon),
                  null,
                  0,
                  2
                )} / ${formatMeasure(millisecondsToMinutes(plate.processingTimeMsWicam), null, 1, 2)} / x${
                  plate.repeats
                }`}
                unit=""
              />
            )),
          ]}
          <FileStats label={localized.AREA} value={formatMeasure(part.areaGross || 0, null, 3, 3)} unit="m²" />
          <FileStats label={localized.AREA_NETTO} value={formatMeasure(part.areaNetto || 0, null, 3, 3)} unit="m²" />
          <FileStats
            label="Nesting"
            className="flex-col"
            value={<pre className="part-details__nesting">{JSON.stringify(nesting, null, 2)}</pre>}
          />
        </div>
      )}
    </>
  );
}
