const key = 'BLECHCON_WELCOME_VISITED';

export function readVisitedFlag(username: string): boolean {
  const visited = localStorage.getItem(`${key}_${username}`);
  return visited === 'true';
}

export function writeVisitedFlag(username: string): void {
  localStorage.setItem(`${key}_${username}`, 'true');
}
