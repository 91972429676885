import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StorageKeys } from './api/Internal/Api';
import { localized } from '../config/localization';
import { getItem, removeItem, setItem } from './storage';
import { AVAILABLE_LANGUAGES, AvailableLanguagesType, BlechconUser } from '../blechcon';
import { currentAuthenticatedUser, signOut } from './Auth';

export type AuthContextProps = {
  isAdmin: boolean;
  isAuthenticationDone: boolean;
  isActive: boolean;
  user: BlechconUser | undefined | null;
  userEmail: string | null;
  setLoginUser: (user: BlechconUser) => void;
  setInterfaceLanguage: (language: AvailableLanguagesType) => void;
  logout: () => void;
  refreshUser: () => Promise<void>;
};

export const AuthContext = React.createContext<AuthContextProps>({
  isAdmin: false,
  isAuthenticationDone: false,
  isActive: false,
  user: undefined,
  userEmail: null,
  setLoginUser: () => ({}),
  setInterfaceLanguage: () => ({}),
  logout: () => ({}),
  refreshUser: () => new Promise(() => ({})),
});

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props): JSX.Element {
  const [isAuthenticationDone, setIsAuthenticationDone] = useState(false);
  const [language, setLanguage] = useState<AvailableLanguagesType>();
  const [user, setUser] = useState<BlechconUser | undefined | null>();

  const logout = useCallback(() => {
    signOut()
      .then(() => setUser(null))
      .finally(() => removeStoredTokens());
  }, []);

  const refreshUser = useCallback(async () => {
    await currentAuthenticatedUser(true).then((u) => setUser(u));
  }, []);

  const setInterfaceLanguage = useCallback((newLanguage: AvailableLanguagesType) => {
    localized.setLanguage(newLanguage);
    setLanguageToCookies(newLanguage);
    setLanguage(newLanguage);
  }, []);

  useEffect(() => {
    currentAuthenticatedUser()
      .then((u) => setUser(u))
      .catch((error) => console.log('auth error', error))
      .finally(() => setIsAuthenticationDone(true));
  }, []);

  useEffect(() => {
    const languageFromCookies = getLanguageFromCookies();
    setInterfaceLanguage(languageFromCookies);
    setLanguage(languageFromCookies);
  }, [setInterfaceLanguage]);

  const values = useMemo(() => {
    const groups = user?.groups ?? [];

    return {
      user,
      userEmail: user?.email ?? null,
      setLoginUser: (u: BlechconUser | undefined | null) => setUser(u),
      setInterfaceLanguage,
      logout,
      isAdmin: groups.includes('admin'),
      isActive: groups.includes('active'),
      isAuthenticationDone,
      refreshUser,
    };
  }, [isAuthenticationDone, logout, refreshUser, setInterfaceLanguage, user]);

  return (
    <AuthContext.Provider key={language} value={values}>
      {children}
    </AuthContext.Provider>
  );
}

function removeStoredTokens() {
  removeItem(StorageKeys.WICAM_ACCESS_TOKEN);
  removeItem(StorageKeys.BLECHCON_JWT);
}

function setLanguageToCookies(language: AvailableLanguagesType) {
  setItem(StorageKeys.LANGUAGE, language);
}

function getLanguageFromCookies(): AvailableLanguagesType {
  const browserLanguage = localized.getLanguage();
  let defaultLanguage: AvailableLanguagesType = 'de';

  for (const availableLanguage of AVAILABLE_LANGUAGES) {
    if (availableLanguage === browserLanguage) {
      defaultLanguage = browserLanguage;
    }
  }

  return getItem<AvailableLanguagesType>(StorageKeys.LANGUAGE) ?? defaultLanguage;
}
