import './FilterTiles.css';
import React from 'react';

type Props<T> = {
  tiles: Array<{ label: string; value: T }>;
  activeTile: T;
  onClick: (tab: T) => void;
};

export function FilterTiles<T>({ tiles, activeTile, onClick }: Props<T>): JSX.Element {
  const isTabActive = (currentTile: T) => activeTile === currentTile;

  return (
    <div className="filter-tiles">
      {tiles.map((tile) => (
        <div
          key={tile.label}
          className={isTabActive(tile.value) ? 'filter-tiles__tile--selected' : undefined}
          onClick={() => onClick(tile.value)}
          data-cy={tile.value}>
          {tile.label}
        </div>
      ))}
    </div>
  );
}
