import React, { Fragment } from 'react';
import { Button } from '../Button/Button';
import {
  AnalysisIssue,
  IssueCutOutNearBendZone,
  IssueInnerBendingRadiusTooSmall,
  IssueMaximumBendLengthExceeded,
  IssueMaximumBendPressureExceeded,
  IssueMinimumCutoutDiameterNotReached,
  IssueMinimumFlangeLengthNotReached,
  PartAnalysis,
} from '../../services/optimate/optimateTypes';
import { localized, t } from '../../config/localization';
import { formatMeasure } from '../../utils';
import { Eye } from '../../assets/svg/Eye';

type Props = {
  readonly analyses: PartAnalysis[];
  readonly onToggleVisibility: (id: string) => void;
};

export function AnalysisDetails({ analyses, onToggleVisibility }: Props): JSX.Element {
  const filteredAnalyses = analyses.filter((it) => it.issues.length !== 0 || it.notes.length !== 0);

  if (filteredAnalyses.length === 0) {
    return <div className="part-viewer__part-analysis__no-issue">{localized.ANALYSIS_NO_ISSUES}</div>;
  }

  return (
    <>
      {filteredAnalyses.map((it) => (
        <AnalysisDetailsItem key={it.analysisId} partAnalysis={it} onToggleVisibility={onToggleVisibility} />
      ))}
    </>
  );
}

type AnalysisDetailsItemProps = {
  readonly partAnalysis: PartAnalysis;
  readonly onToggleVisibility: (id: string) => void;
};

function AnalysisDetailsItem({ partAnalysis, onToggleVisibility }: AnalysisDetailsItemProps) {
  return (
    <div className="part-viewer__part-analysis">
      {partAnalysis.issues.map((issue) => {
        return (
          <div className="part-viewer__part-analysis-issue" key={issue.id}>
            <div>
              <span>{t(issue.issueName)}</span>
              <Button
                className={`part-viewer__toggle-button ${issue.visible ? 'part-viewer__toggle-button__visible' : ''}`}
                secondary={issue.visible}
                onClick={() => onToggleVisibility(issue.id)}>
                <Eye />
              </Button>
            </div>
            {issue.issueName === 'inner_bend_radius_too_small' && (
              <AnalysisTable {...mapInnerBendingRadiusTooSmall(issue)} />
            )}
            {issue.issueName === 'cutout_near_bend_zone' && <AnalysisTable {...mapCutOutNearBendZone(issue)} />}
            {issue.issueName === 'minimum_cutout_diameter_not_reached' && (
              <AnalysisTable {...mapMinimumCutoutDiameterNotReached(issue)} />
            )}
            {issue.issueName === 'minimum_flange_length_not_reached' && (
              <AnalysisTable {...mapMinimumFlangeLengthNotReached(issue)} />
            )}
            {issue.issueName === 'maximum_bend_length_exceeded' && (
              <AnalysisTable {...mapMaximumBendLengthExceeded(issue)} />
            )}
            {issue.issueName === 'maximum_bend_pressure_exceeded' && (
              <AnalysisTable {...mapMaximumBendPressureExceeded(issue)} />
            )}
          </div>
        );
      })}

      {partAnalysis.notes.length > 0 && (
        <div className="part-viewer__part-analysis-notes">
          {partAnalysis.notes.map((note) => (
            <Fragment key={note}>
              {t(note)}
              <br />
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
}

export function groupIssueDetails<T>(issueDetails: T[]): { occurrence: number; issueDetail: T }[] {
  const groups = new Map<string, { occurrence: number; issueDetail: T }>();

  for (const issueDetail of issueDetails) {
    const key = JSON.stringify(issueDetail);

    if (groups.has(key)) {
      groups.get(key)!.occurrence += 1;
    } else {
      groups.set(key, {
        occurrence: 1,
        issueDetail,
      });
    }
  }

  return Array.from(groups.values()).sort((left, right) => (left.occurrence > right.occurrence ? -1 : 1));
}

type AnalysisTableProps = {
  readonly headers: string[];
  readonly rows: (string | number | null)[][];
};

function mapCutOutNearBendZone(issue: AnalysisIssue<IssueCutOutNearBendZone>): AnalysisTableProps {
  return {
    headers: [localized.BEND_ANGLE, localized.REQUIRED_DISTANCE, localized.CUTOUT_DISTANCE, localized.DIFFERENCE],
    rows: groupIssueDetails(issue.issueDetails).map(({ occurrence, issueDetail }) => [
      occurrence,
      formatMeasure(issueDetail.bend_angle, '°'),
      formatMeasure(issueDetail.required_distance, 'mm'),
      formatMeasure(issueDetail.cutout_distance, 'mm'),
      formatMeasure(issueDetail.required_distance - issueDetail.cutout_distance, 'mm'),
    ]),
  };
}

function mapInnerBendingRadiusTooSmall(issue: AnalysisIssue<IssueInnerBendingRadiusTooSmall>): AnalysisTableProps {
  return {
    headers: [localized.INNER_BEND_RADIUS, localized.MINIMUM_INNER_BEND_RADIUS, localized.DIFFERENCE],
    rows: groupIssueDetails(issue.issueDetails).map(({ occurrence, issueDetail }) => [
      occurrence,
      formatMeasure(issueDetail.inner_bend_radius, 'mm'),
      formatMeasure(issueDetail.minimum_inner_bend_radius, 'mm'),
      formatMeasure(issueDetail.minimum_inner_bend_radius - issueDetail.inner_bend_radius, 'mm'),
    ]),
  };
}

function mapMinimumCutoutDiameterNotReached(
  issue: AnalysisIssue<IssueMinimumCutoutDiameterNotReached>
): AnalysisTableProps {
  return {
    headers: [localized.CUTOUT_DIAMETER, localized.MINIMUM_CUTOUT_DIAMETER, localized.DIFFERENCE],
    rows: groupIssueDetails(issue.issueDetails).map(({ occurrence, issueDetail }) => [
      occurrence,
      formatMeasure(issueDetail.cutout_diameter, 'mm'),
      formatMeasure(issueDetail.minimum_cutout_diameter, 'mm'),
      formatMeasure(issueDetail.minimum_cutout_diameter - issueDetail.cutout_diameter, 'mm'),
    ]),
  };
}

function mapMinimumFlangeLengthNotReached(
  issue: AnalysisIssue<IssueMinimumFlangeLengthNotReached>
): AnalysisTableProps {
  return {
    headers: [localized.BEND_ANGLE, localized.L_MIN, localized.FLANGE_LENGTH, localized.DIFFERENCE],
    rows: groupIssueDetails(issue.issueDetails).map(({ occurrence, issueDetail }) => [
      occurrence,
      formatMeasure(issueDetail.bend_angle, '°'),
      formatMeasure(issueDetail.l_min, 'mm'),
      formatMeasure(issueDetail.flange_length, 'mm'),
      formatMeasure(issueDetail.l_min - issueDetail.flange_length, 'mm'),
    ]),
  };
}

function mapMaximumBendLengthExceeded(issue: AnalysisIssue<IssueMaximumBendLengthExceeded>): AnalysisTableProps {
  return {
    headers: [localized.BEND_LENGTH, localized.MAX_BEND_LENGTH, localized.DIFFERENCE],
    rows: groupIssueDetails(issue.issueDetails).map(({ occurrence, issueDetail }) => [
      occurrence,
      formatMeasure(issueDetail.bend_length, 'mm'),
      formatMeasure(issueDetail.maximum_bend_length, 'mm'),
      formatMeasure(issueDetail.bend_length - issueDetail.maximum_bend_length, 'mm'),
    ]),
  };
}

function mapMaximumBendPressureExceeded(issue: AnalysisIssue<IssueMaximumBendPressureExceeded>): AnalysisTableProps {
  return {
    headers: [localized.BEND_PRESSURE, localized.MAX_BEND_PRESSURE, localized.DIFFERENCE],
    rows: groupIssueDetails(issue.issueDetails).map(({ occurrence, issueDetail }) => [
      occurrence,
      formatMeasure(issueDetail.bend_pressure, 'kN'),
      formatMeasure(issueDetail.maximum_bend_pressure, 'kN'),
      formatMeasure(issueDetail.bend_pressure - issueDetail.maximum_bend_pressure, 'kN'),
    ]),
  };
}

function AnalysisTable({ headers, rows }: AnalysisTableProps): JSX.Element {
  return (
    <table className="part-viewer__analysis-table">
      <thead>
        <tr>
          <th>#</th>
          {headers.map((header) => (
            <th key={header}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => {
          return (
            <tr key={String(row)}>
              {row.map((cell, index) => (
                <td key={String(cell) + index}>{cell}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
