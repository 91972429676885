import React from 'react';

type Props = {
  className?: string;
  fill?: string;
};

export function List({ className = '', fill = 'currentColor' }: Props): JSX.Element {
  return (
    <svg width={22} height={22} viewBox="0 0 16 12.469" className={className} fill={fill}>
      <path
        d="M4.869,3.414a.8.8,0,0,0-.566.234L3.269,4.683H2.8a.8.8,0,1,0,0,1.6h.8a.8.8,0,0,0,.566-.234L5.434,4.78a.8.8,0,0,0-.566-1.366ZM8.4,4.683a.8.8,0,0,0,0,1.6h8.8a.8.8,0,1,0,0-1.6ZM4.869,8.214a.8.8,0,0,0-.566.234L3.269,9.483H2.8a.8.8,0,1,0,0,1.6h.8a.8.8,0,0,0,.566-.234L5.434,9.58a.8.8,0,0,0-.566-1.366ZM8.4,9.483a.8.8,0,1,0,0,1.6h8.8a.8.8,0,1,0,0-1.6ZM4.869,13.014a.8.8,0,0,0-.566.234L3.269,14.283H2.8a.8.8,0,1,0,0,1.6h.8a.8.8,0,0,0,.566-.234L5.434,14.38a.8.8,0,0,0-.566-1.366ZM8.4,14.283a.8.8,0,1,0,0,1.6h8.8a.8.8,0,1,0,0-1.6Z"
        transform="translate(-2 -3.414)"
      />
    </svg>
  );
}
