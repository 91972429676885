import { api } from '.';
import { PublicFileIdentifier, PublicFileInformation, PublicValidationInformation, UnfoldResponse } from './wicamTypes';
import {
  BlechconDocument,
  BlechconOrderFile,
  BlechconOrderFilePart,
  BlechconPreviewImage,
  is3DFile,
} from '../../blechcon';
import { AxiosResponse } from 'axios';
import { stringToImageDataURL } from './stringToImageDataURL';
import { scheduleNextRun } from '../../utils';

type FileInfoUnion = PublicFileIdentifier &
  PublicFileInformation & { fileId3D?: string | null; previewImage: BlechconPreviewImage | null };

/**
 * BLEC-113
 * Wir möchten Abkanten für alle "partType" außer "partType 2" (Unfoldable sheetmetal) deaktivieren.

 * BLEC-200
 * Für das Abkanten wird ein 3D-Modell in .stp oder .step Format benötigt.
 * Die Kantungen können bei 2D-Dateien (.dxf, .dwg) nicht erkannt werden.
 * Für beide 2D-Formate, DXF und DWG, die Option Abkanten deaktivieren.
 * [...] Als Alternative könnte das Objekt „threeDimensional“ geprüft werden,
 * was nur für 3D Dateien gefüllt wird.
 *
 * BLEC-251
 * Die einfachste Lösung wäre, den Teiltype aus "Info-Datei" auszulesen, wenn ein Teil, "partType: 2" (Kantteil) ist,
 * sollte die Option "Abkanten" automatisch ausgewählt werden.
 * Wenn eine Kantlinie erkannt wurde, den Haken "Abkanten" in Schritt 2 als Default setzen.
 */
function isBendPart(file: FileInfoUnion): boolean {
  return file?.threeDimensional?.partType === 2;
}

/**
 * BLEC-224
 * Wir möchten, abhängig von Teiltyp begrenzen, welches Teiltype kalkuliert werden kann.
 * Beim Hochladen, die API erkennt was für einen Teil hochgeladen wird und gibt eine Kategorie ein (partType 1-6). WICAM API V2
 * Wir möchten eine Info/Fehlermeldung zurückgeben, wenn ein nicht unterstützte Teil hochgeladen wird.
 *
 * partType: 1
 * Fehlermeldung: Nicht unterstützte Teiltyp / Konstruktionsfehler
 *
 * partType: 4
 * Fehlermeldung: Nicht unterstützte Teiltyp
 *
 * partType: 5
 * Fehlermeldung: Nicht unterstützte Teiltyp / Bitte per E-Mail anfragen
 *
 * partType: 6
 * Fehlermeldung: Nicht unterstützte Teiltyp / Bitte per E-Mail anfragen
 */
function partTypeToErrorCode(partType: number | undefined): string | null {
  switch (partType) {
    case 1: {
      return 'BLC_DESIGN_ERROR';
    }
    case 4: {
      return 'BLC_UNSUPPORTED_ASSEMBLY_ERROR';
    }
    case 5: {
      return 'BLC_PART_TYPE_REQUEST_EMAIL';
    }
    case 6: {
      return 'BLC_PART_TYPE_REQUEST_EMAIL';
    }
    default: {
      return null;
    }
  }
}

function convert(infoDetail: FileInfoUnion, isAssembly: boolean): BlechconOrderFilePart {
  const assemblyCountPerPart = infoDetail?.threeDimensional?.assembly?.instances?.length || 1;
  const thickness = infoDetail?.threeDimensional?.thickness || undefined;
  const partType = infoDetail?.threeDimensional?.partType || undefined;
  const bendingCount = infoDetail?.threeDimensional?.bendings?.length || 0;

  const blechconErrorCode = partTypeToErrorCode(partType);

  return {
    id: `${infoDetail.fileId}_${infoDetail.collectionId}`,
    fileId: infoDetail.fileId,
    fileId3D: infoDetail.fileId3D ?? null,
    collectionId: infoDetail.collectionId,
    previewImage: infoDetail.previewImage,
    length: infoDetail?.basicInformation?.length ?? 0,
    width: infoDetail?.basicInformation?.width ?? 0,
    errorCode: convertErrorCode(infoDetail),
    blechconErrorCode,
    isAssemblyPart: isAssembly,
    material: null,
    rotation: 'free',
    rotationAngle: null,
    edgesRounded: false,
    bending: isBendPart(infoDetail),
    bendable: isBendPart(infoDetail),
    bendingCount,
    weightGross: null,
    weightNetto: null,
    prices: {},
    document: null,
    assemblyCountPerPart,
    quantity: assemblyCountPerPart,
    thickness,
    partType,
  };
}

function convertErrorCode(infoDetail: FileInfoUnion): string | null {
  const { geometryErrors = [], unfoldErrors } = infoDetail.validation;

  if (geometryErrors && geometryErrors[0] && geometryErrors[0].code) {
    return geometryErrors[0].code;
  }

  if (unfoldErrors && unfoldErrors[0] && unfoldErrors[0].code) {
    return unfoldErrors[0].code;
  }

  // BLEC-199
  const length = infoDetail?.basicInformation?.length ?? 0;
  const width = infoDetail?.basicInformation?.width ?? 0;
  const fitVertical = length <= 2980 && width <= 1480;
  const fitHorizontal = width <= 2980 && length <= 1480;

  if (!(fitVertical || fitHorizontal)) {
    return '54003';
  }

  return null;
}

export async function processFile(signal: AbortSignal, file: File): Promise<BlechconOrderFile> {
  const id = await uploadFile(file);
  let infoDetails: FileInfoUnion[] = [];

  if (is3DFile(file.name)) {
    infoDetails = await collectFileInfos(signal, id);
  } else {
    const infoDetail = await getFileInfo(signal, id);
    infoDetails.push(infoDetail);
  }
  const parts = infoDetails.map((it) => convert(it, infoDetails.length > 1));

  return {
    id: `${id.fileId}_${id.collectionId}`,
    collectionId: id.collectionId,
    fileId: id.fileId,
    name: file.name,
    parts,
    isAssembly: parts.length > 1,
    quantity: 1,
    prices: {},
    cadFile3D: null,
  };
}

const uploadFile = async (file: File): Promise<PublicFileIdentifier> => {
  return api.file.fileCreate(file).then((response: AxiosResponse<PublicFileIdentifier>) => {
    return response.data;
  });
};

async function collectFileInfos(
  signal: AbortSignal,
  id: PublicFileIdentifier,
  infoDetails: FileInfoUnion[] = []
): Promise<FileInfoUnion[]> {
  return new Promise((resolve, reject) => {
    async function run() {
      try {
        await startUnfoldingJob(id);
        const { disassembled, unfolded } = await checkUnfoldingStatus(signal, id);

        if (unfolded !== null) {
          const infoDetail = await getFileInfo(signal, unfolded);
          /*
           * Kein infoDetail.previewImage, dann hat das Teil einen Fehler und die Bilder wurden nicht geholt.
           * Sieht blöd aus, ist aber so.
           */
          if (id && infoDetail.previewImage) {
            infoDetail.previewImage.image3D = await getFilePreview(signal, id, 'png');
          }
          infoDetail.fileId3D = id.fileId;
          infoDetails.push(infoDetail);
        } else if (disassembled !== null) {
          const pendingFileInfos: Promise<FileInfoUnion[]>[] = [];
          for (const assemblyIds of disassembled) {
            pendingFileInfos.push(collectFileInfos(signal, assemblyIds, infoDetails));
          }

          const fileInfos: FileInfoUnion[][] = await Promise.all(pendingFileInfos);
          infoDetails.concat(...fileInfos);
        }

        resolve(infoDetails);
      } catch (error) {
        reject(error);
      }
    }

    run();
  });
}

async function getFileInfo(signal: AbortSignal, id: PublicFileIdentifier): Promise<FileInfoUnion> {
  return new Promise((resolve, reject) => {
    async function run() {
      try {
        const response = await api.file.infoDetail(id.collectionId, id.fileId);

        const {
          basicInformationReady,
          extendedInformationReady,
          information3DReady,
          validation2DReady,
          validation3DReady,
        } = response.data.status;

        if (hasSomeErrors(response.data.validation)) {
          resolve({
            ...id,
            ...response.data,
            previewImage: null,
          });
        } else if (
          basicInformationReady &&
          extendedInformationReady &&
          (validation2DReady || (validation3DReady && information3DReady))
        ) {
          const previewImage = await getFilePreview(signal, id, 'png');

          resolve({
            ...id,
            ...response.data,
            previewImage: {
              image2D: previewImage,
              image3D: null,
            },
          });
        } else {
          scheduleNextRun(run, signal);
        }
      } catch (error) {
        reject(error);
      }
    }

    run();
  });
}

async function getFilePreview(
  signal: AbortSignal,
  id: PublicFileIdentifier,
  format?: string
): Promise<BlechconDocument> {
  return new Promise((resolve, reject) => {
    async function run() {
      try {
        const query = format ? { format } : undefined;
        const name = format ? `${id.collectionId}${id.fileId}.${format}` : `${id.collectionId}${id.fileId}`;
        const response = await api.file.fileDetail(id.collectionId, id.fileId, query);

        if (response.status === 206 || response.status === 201) {
          scheduleNextRun(run, signal);
        } else {
          const mimetype = response.headers['content-type'];
          const dataURL = await stringToImageDataURL(response.data, mimetype);
          resolve({
            name,
            mimetype,
            data: dataURL,
          });
        }
      } catch (error: any) {
        if (error.response && error.response.headers['content-type'] === 'application/json') {
          error.response.data.text().then((text: string) => {
            const json = JSON.parse(text);
            reject(json['Error']);
          });
        } else {
          reject(error);
        }
      }
    }

    run();
  });
}

async function startUnfoldingJob(id: PublicFileIdentifier) {
  return api.unfold.unfoldCreate({
    fileId: id.fileId,
    collectionId: id.collectionId,
    materialNumber: 1,
    bendConfig: 'YRvAvruuZUu0O_FD5NyTBA==',
  });
}

async function checkUnfoldingStatus(signal: AbortSignal, id: PublicFileIdentifier): Promise<UnfoldResponse> {
  return new Promise((resolve, reject) => {
    async function run() {
      try {
        const response = await api.unfold.unfoldDetail(id.collectionId, id.fileId, {
          bendConfig: 'YRvAvruuZUu0O_FD5NyTBA==',
          materialNumber: 1,
        });

        if (response.status === 206) {
          scheduleNextRun(run, signal);
        } else {
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    }

    run();
  });
}

function hasSomeErrors(validation: PublicValidationInformation): boolean {
  const { geometryErrors, unfoldErrors } = validation;
  return (geometryErrors ?? []).length > 0 || (unfoldErrors ?? []).length > 0;
}
