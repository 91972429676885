import './Modal.css';

import React from 'react';
import { createPortal } from 'react-dom';
import { Button } from './Button/Button';

type Props = {
  title?: string | null;
  successDisabled?: boolean;
  onCancel?: { label: string; execute?: () => void };
  onSuccess?: { label: string; execute?: () => void };
  className?: string;
  children: React.ReactNode;
};

export function Modal({
  title = null,
  successDisabled = false,
  onCancel = undefined,
  onSuccess,
  className = '',
  children,
}: Props): JSX.Element {
  return createPortal(
    <>
      <div className="modal" data-cy={title ? `${title}-modal` : 'modal'}>
        <div className="modal_container" onClick={(event) => event.stopPropagation()}>
          <div className={`modal_inner_container ${className}`}>
            {title != null ? (
              <div className="modal_content-header">
                <h3>{title}</h3>
              </div>
            ) : null}
            <div className="modal_content-container-text">{children}</div>
            <div className={`modal_actions ${onCancel !== undefined ? 'with_cancel' : ''}`}>
              {onCancel && (
                <Button onClick={() => onCancel?.execute && onCancel.execute()} dataCy="cancelButton" secondary>
                  {onCancel?.label ? onCancel.label : 'no'}
                </Button>
              )}
              {onSuccess && (
                <Button
                  disabled={successDisabled}
                  onClick={() => onSuccess?.execute && onSuccess.execute()}
                  dataCy="successButton">
                  {onSuccess?.label ? onSuccess.label : 'yes'}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop" />
    </>,
    document.body
  );
}
