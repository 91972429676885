import React, { useCallback, useState } from 'react';
import { localized } from '../../../config/localization';
import { BlechconUser, BlechconUserGroupType, BlechconUserInfo } from '../../../blechcon';
import { resetPassword, toggleUserStatus, updateUserGroups, updateUserInfo } from '../../../services/api/blechconApi';
import { Modal } from '../../../components/Modal';
import { toast } from 'react-toastify';
import { DisplayInvoiceAddress } from './DisplayInvoiceAddress';
import { Menu } from '../../../components/Menu/Menu';

type Props = {
  request: BlechconUser & BlechconUserInfo;
  fetchUsers: () => void;
};

export function UserView({ request, fetchUsers }: Props): JSX.Element {
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [customerId, setCustomerId] = useState<string>('');
  const [showDeactivationModal, setShowDeactivationModal] = useState<boolean>(false);
  const [showUserGroupsModal, setShowUserGroupsModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

  const groups = request.groups;
  const isAdmin = groups.includes('admin');

  const confirmUser = useCallback(
    async (userId: string, email: string, ustid: string) => {
      try {
        await toggleUserStatus(userId, email, true);
        await updateUserInfo(userId, customerId.trim(), ustid.trim());
        fetchUsers();
      } catch (error) {
        console.error(error);
        toast.error(String(error));
      } finally {
        setCustomerId('');
      }
    },
    [fetchUsers, customerId]
  );

  const deactivateUser = useCallback(
    async (username: string, email: string) => {
      try {
        await toggleUserStatus(username, email, false);
        fetchUsers();
      } catch (error) {
        console.error(error);
        toast.error(String(error));
      } finally {
        setCustomerId('');
      }
    },
    [fetchUsers]
  );

  const toggleAdminUserGroup = useCallback(async () => {
    const newGroups: BlechconUserGroupType[] = groups.includes('admin')
      ? groups.filter((group) => group !== 'admin')
      : [...groups, 'admin'];

    updateUserGroups(request.username, newGroups)
      .then(fetchUsers)
      .catch((error) => {
        console.error(error);
        toast.error(String(error));
      });
  }, [fetchUsers, groups, request.username]);

  const resetUserPassword = useCallback(async () => {
    resetPassword(request.username).catch((error) => {
      console.error(error);
      toast.error(String(error));
    });
  }, [request.username]);

  return (
    <>
      {showApproveModal && (
        <Modal
          successDisabled={customerId.trim().length < 5}
          onCancel={{
            label: localized.ABORT,
            execute: () => setShowApproveModal(false),
          }}
          onSuccess={{
            label: localized.APPROVE,
            execute: async () => {
              await confirmUser(request.username, request.email, request.ustid);
              setShowApproveModal(false);
            },
          }}>
          <p className="text-label text-lg text-opacity-50">
            {localized.formatString(localized.ENTER_CUSTOMER_ID, <span className="font-bold">{request.email}</span>)}
          </p>
          <div className="flex items-center border-b border-border py-2 mt-10">
            <input
              name="customerId"
              className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
              type="text"
              autoFocus
              value={customerId}
              placeholder={localized.CUSTOMER_ID}
              onChange={(event) => setCustomerId(event.target.value)}
            />
          </div>
        </Modal>
      )}

      {showDeactivationModal && (
        <Modal
          title={localized.DEACTIVATE_USER}
          onCancel={{
            label: localized.ABORT,
            execute: () => {
              setCustomerId('');
              setShowDeactivationModal(false);
            },
          }}
          onSuccess={{
            label: localized.DEACTIVATE,
            execute: () => {
              deactivateUser(request.username, request.email);
              setShowDeactivationModal(false);
            },
          }}>
          <p className="text-label text-lg text-opacity-50">
            {localized.formatString(localized.DEACTIVATE_USER_CONFIRMATION, request.email)}
          </p>
        </Modal>
      )}

      {showUserGroupsModal && (
        <Modal
          title={localized.ADMIN_STATUS}
          onCancel={{
            label: localized.ABORT,
            execute: () => {
              setShowUserGroupsModal(false);
            },
          }}
          onSuccess={{
            label: isAdmin ? localized.REMOVE_GROUP_ADMIN : localized.ADD_GROUP_ADMIN,
            execute: () => {
              toggleAdminUserGroup();
              setShowUserGroupsModal(false);
            },
          }}>
          <p className="text-label text-lg text-opacity-50">
            {localized.formatString(localized.ADMIN_GROUP_USER_CONFIRMATION, request.email)}
          </p>
        </Modal>
      )}

      {showResetPasswordModal && (
        <Modal
          title={localized.PASSWORD_RESET}
          onCancel={{
            label: localized.ABORT,
            execute: () => {
              setShowResetPasswordModal(false);
            },
          }}
          onSuccess={{
            label: localized.OK,
            execute: () => {
              resetUserPassword();
              setShowResetPasswordModal(false);
            },
          }}>
          <p className="text-label text-lg text-opacity-50">
            {localized.formatString(localized.ADMIN_RESET_PASSWORD_CONFIRMATION, request.email)}
          </p>
          <p className="text-label text-lg text-opacity-50">
            {localized.formatString(
              localized.ADMIN_RESET_PASSWORD_TEMPORARY,
              <span className="font-bold">{fakeRandomPassword()}</span>
            )}
          </p>
        </Modal>
      )}

      <tr key={request.username} className="admin-users__user-list-item">
        <td>{request.email}</td>
        <td>{request.customerId}</td>
        <td>{request.ustid}</td>
        <td>
          <DisplayInvoiceAddress addresses={request.invoiceAddresses} />
        </td>

        <td>
          <div
            className={`admin-users__verified-badge ${
              request.emailVerified
                ? 'admin-users__verified-badge--verified'
                : 'admin-users__verified-badge--unverified'
            }`}>
            {request.emailVerified ? localized.VERIFIED : localized.NOT_VERIFIED}{' '}
          </div>
        </td>

        <td>
          <Menu dots dataCy={`admin_user_menu_${request.email}`}>
            {(groups.includes('unactivated') || groups.includes('newuser')) && (
              <Menu.Item
                dataCy="APPROVE"
                onSelect={() => {
                  setShowApproveModal(true);
                  setCustomerId('');
                }}>
                {localized.APPROVE}
              </Menu.Item>
            )}
            {groups.includes('active') && (
              <Menu.Item
                dataCy="DEACTIVATE"
                onSelect={() => {
                  setShowDeactivationModal(true);
                }}>
                {localized.DEACTIVATE}
              </Menu.Item>
            )}
            <Menu.Item
              dataCy={isAdmin ? 'REMOVE_GROUP_ADMIN' : 'ADD_GROUP_ADMIN'}
              onSelect={() => {
                setShowUserGroupsModal(true);
              }}>
              {isAdmin ? localized.REMOVE_GROUP_ADMIN : localized.ADD_GROUP_ADMIN}
            </Menu.Item>
            <Menu.Item
              dataCy="PASSWORD_RESET"
              onSelect={() => {
                setShowResetPasswordModal(true);
              }}>
              {localized.PASSWORD_RESET}
            </Menu.Item>
          </Menu>
        </td>
      </tr>
    </>
  );
}

function fakeRandomPassword() {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!§$%&';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < 13) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
