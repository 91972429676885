import './SuccessPage.css';
import React from 'react';
import { useHistory } from 'react-router';
import { Routes } from '../../../app/Routes';
import { CheckMark } from '../../../assets/svg/CheckMark';
import { Button } from '../../../components/Button/Button';
import { localized } from '../../../config/localization';
import { Link } from 'react-router-dom';

export function SuccessPage(): JSX.Element {
  const history = useHistory();

  return (
    <div className="success-page">
      <CheckMark fill="currentColor" />
      <div>{localized.THANK_YOU}</div>
      <div>
        {localized.ORDER_PLACED1}
        <div>
          {localized.ORDER_PLACED2} <Link to={Routes.CUSTOMER_ORDERS}>{localized.RECENT_ORDERS} </Link>
          {localized.ORDER_PLACED3}
          <div>{localized.ORDER_PLACED4}</div>
        </div>
      </div>
      <Button
        onClick={() => {
          history.replace(Routes.CHECKOUT_UPLOAD, {
            skipSubmitCheck: true,
          });
        }}
        secondary>
        {localized.CREATE_NEW_ORDER}
      </Button>
    </div>
  );
}
