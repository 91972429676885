import React, { Suspense, useEffect, useState } from 'react';
import './PartViewer.css';
import { BlechconLogoPrint } from '../../assets/svg/BlechconLogoPrint';
import { localized } from '../../config/localization';
import { PartAnalysis } from '../../services/optimate/optimateTypes';
import { AnalysisDetails } from './AnalysisDetails';
import { InputMouseLeft } from '../../assets/svg/InputMouseLeft';
import { InputMouseMiddle } from '../../assets/svg/InputMouseMiddle';
import { InputMouseRight } from '../../assets/svg/InputMouseRight';

const ViewerWrapper = React.lazy(() => import('./ViewerWrapper'));

type Props = {
  readonly analyses: PartAnalysis[];
  readonly viewerOnly: boolean;
};

export function PartViewer(props: Props) {
  return (
    <Suspense fallback={'...'}>
      <PartViewerLazy {...props} />
    </Suspense>
  );
}

function PartViewerLazy(props: Props): JSX.Element {
  const [analyses, setAnalyses] = useState<PartAnalysis[]>([]);

  useEffect(() => {
    setAnalyses(props.analyses);
  }, [props.analyses]);

  function toggleVisibility(id: string) {
    setAnalyses((current) => {
      return current.map((it) => {
        return {
          ...it,
          models: it.models.map((it) => setVisible(id, it)),
          issues: it.issues.map((it) => setVisible(id, it)),
        };
      });
    });
  }

  return (
    <div className="part-viewer">
      <ViewerWrapper analyses={analyses}>
        <BlechconLogoPrint className="part-viewer__viewer-logo-overlay" />
        <div className="part-viewer__viewer-legend-overlay">
          <p>
            <InputMouseLeft />
            <span>
              {localized.PART_VIEWER_LEGEND_LMT_TO} <strong>{localized.PART_VIEWER_LEGEND_ROTATE}</strong>
            </span>
          </p>
          <p>
            <InputMouseMiddle />
            <span>
              {localized.PART_VIEWER_LEGEND_RMT_TO} <strong>{localized.PART_VIEWER_LEGEND_PAN}</strong>
            </span>
          </p>
          <p>
            <InputMouseRight />
            <span>
              {localized.PART_VIEWER_LEGEND_SCROLL_TO} <strong>{localized.PART_VIEWER_LEGEND_ZOOM}</strong>
            </span>
          </p>
        </div>
      </ViewerWrapper>

      {!props.viewerOnly && (
        <div className="part-viewer__analysis-details">
          <AnalysisDetails analyses={analyses} onToggleVisibility={toggleVisibility} />
        </div>
      )}
    </div>
  );
}

function setVisible<T>(id: string, it: { id: string; visible: boolean } & T): T {
  if (it.id === id) {
    return {
      ...it,
      visible: !it.visible,
    };
  }
  return it;
}
