import { PlateProcessingTime, ProcessingTimeOfPlate } from '../../blechcon';
import { convertToMilliseconds } from '../timeUtils';
import { NestedPlateResultResponse, PlateItemResponse } from './wicamTypes';

export type MillisecondType = {
  readonly milliseconds: number;
};

function getBlechconProcessingTime(
  totalPlateAmount: number,
  PLATE_PROCESSING_TIME: PlateProcessingTime[]
): MillisecondType {
  let processingTimeMsBlechcon = 0;

  const blechconProcessingTime = PLATE_PROCESSING_TIME.find((entry) => entry.maxPartCount >= totalPlateAmount);
  if (blechconProcessingTime) {
    processingTimeMsBlechcon = blechconProcessingTime.processingTimeInMinutes * 60 * 1000;
  }
  return { milliseconds: processingTimeMsBlechcon };
}

export function calculateTotalPlateAmount(nestingFiles: PlateItemResponse[], nestingJobFileId?: string) {
  let totalPlateAmount = 0;

  for (const file of nestingFiles) {
    if (nestingJobFileId && nestingJobFileId !== file.id) {
      continue;
    }
    if (file.amount && file.amount >= 1) {
      totalPlateAmount += file.amount;
    } else {
      throw new Error(`Invalid file amount: ${file.amount}`);
    }
  }

  return totalPlateAmount;
}

export function getPartWicamTimeInMs(nestingFiles: PlateItemResponse[], nestingJobFileId: string) {
  const file = nestingFiles.find((file) => file.id === nestingJobFileId);
  if (file) {
    if (file.times?.timeGross) {
      return convertToMilliseconds(file.times.timeGross);
    } else {
      throw new Error(`Invalid timeGross in ${file?.id}`);
    }
  }
  return 0;
}

export function calculateProcessingTimeOfPlates(
  nestedPlates: NestedPlateResultResponse[],
  PLATE_PROCESSING_TIME: PlateProcessingTime[],
  nestingJobFileId: string
): ProcessingTimeOfPlate[] {
  return nestedPlates.map((plate) => {
    const nestingFiles = plate.nestingFiles ?? [];

    const totalPlateAmount = calculateTotalPlateAmount(nestingFiles);
    const plateProcessingTimeBlechcon = getBlechconProcessingTime(totalPlateAmount, PLATE_PROCESSING_TIME);
    const partAmount = calculateTotalPlateAmount(nestingFiles, nestingJobFileId);
    const plateProcessingTimeMsWicam = convertToMilliseconds(plate.times?.cutting ?? '1');
    const partWicamTimeMs = getPartWicamTimeInMs(nestingFiles, nestingJobFileId);

    const procentualPartOfWicamTime = partWicamTimeMs / plateProcessingTimeMsWicam;
    const partBlechconTimeMs = plateProcessingTimeBlechcon.milliseconds * procentualPartOfWicamTime;
    const partProcessingTime =
      plateProcessingTimeBlechcon.milliseconds > plateProcessingTimeMsWicam ? partBlechconTimeMs : partWicamTimeMs;

    return {
      totalPlateAmount,
      amount: partAmount,
      processingTimeMsWicam: plateProcessingTimeMsWicam,
      partProcessingTime,
      processingTimeMsBlechcon: partAmount > 0 ? plateProcessingTimeBlechcon.milliseconds : 0,
      repeats: plate.repeats ?? 1,
      dxfFileId: plate.dxfFileId,
      svgFileId: plate.svgFileId,
      length: plate.length,
      width: plate.width,
      minLength: plate.minLength,
      minWidth: plate.minWidth,
      name: plate.name,
    };
  });
}
