import './WelcomePage.css';
import React, { useCallback, useContext, useState } from 'react';
import { Redirect, useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { AuthContext, AuthContextProps } from '../../services/AuthContext';
import { Routes } from '../../app/Routes';
import { useUserInfo } from '../../services/useUserInfo';
import { Page } from '../../components/Page/Page';
import { localized } from '../../config/localization';
import { WelcomePageContent } from './WelcomePageContent';
import { readVisitedFlag, writeVisitedFlag } from './visitedFlag';
import { BlechconUserInfo } from '../../blechcon';
import { updateUserMe } from '../../services/api/blechconApi';

export function WelcomePage(): JSX.Element | null {
  const { user } = useContext<AuthContextProps>(AuthContext);
  const username = user?.email ?? 'anonymous';

  if (user?.email) {
    return readVisitedFlag(username) ? <Redirect to={Routes.START_PAGE} /> : <WelcomePageBody username={username} />;
  } else {
    return null;
  }
}

function WelcomePageBody({ username }: { username: string }): JSX.Element {
  const history = useHistory();
  const [userInfo] = useUserInfo();

  const [loading, setLoading] = useState(false);

  const onChange = useCallback(
    (partial: Partial<BlechconUserInfo>) => {
      setLoading(true);

      updateUserMe(partial)
        .then(() => {
          history.push(Routes.WAITING_FOR_APPROVAL);
        })
        .catch((error) => {
          console.error('Failed to update user info data', error);
          toast.error(String(error));
        })
        .finally(() => setLoading(false));
    },
    [history]
  );

  if (userInfo?.welcomePageVisited) {
    writeVisitedFlag(username);
    return <Redirect to={Routes.START_PAGE} />;
  }

  return (
    <Page title={userInfo ? localized.WELCOME : ''} showMenu={false} loading={loading || !userInfo}>
      {userInfo && <WelcomePageContent userInfo={userInfo} onChange={onChange} />}
    </Page>
  );
}
