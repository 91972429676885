import React from 'react';

type Props = {
  className?: string;
  fill?: string;
};

export function ExclamationMark({ className = '', fill = '' }: Props): JSX.Element {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" className={className}>
      <path
        d="M10,2a8,8,0,1,0,8,8A8,8,0,0,0,10,2Zm.4,12H9.6a.4.4,0,0,1-.4-.4v-.8a.4.4,0,0,1,.4-.4h.8a.4.4,0,0,1,.4.4v.8A.4.4,0,0,1,10.4,14ZM10,10.8h0a.8.8,0,0,1-.8-.8V6.8A.8.8,0,0,1,10,6h0a.8.8,0,0,1,.8.8V10A.8.8,0,0,1,10,10.8Z"
        transform="translate(-2 -2)"
        fill={fill}
      />
    </svg>
  );
}
